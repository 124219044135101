import { tour } from "./tour";

const inspections = {
  // general tour
  ...tour,
  moduleElement: "検査",
  subModuleElement: "テンプレート",
  // ADD - guide me steps
  step1_inspectionsAdd_title: "1. 追加する",
  step1_inspectionsAdd_text:
    "新しいテンプレートを追加するには、+ アイコンをクリックします。",
  step2_inspectionsAdd_title: "2. タイトル",
  step2_inspectionsAdd_text: "検査のタイトルを入力します。",
  step3_inspectionsAdd_title: "3. 納期",
  step3_inspectionsAdd_text: "検査の納期を選択します。",
  step4_inspectionsAdd_title: "4. ステータス",
  step4_inspectionsAdd_text_1_1: "ステータスを選択します",
  step4_inspectionsAdd_text_1_2: "そのステータスの検査のための修正。",
  step4_inspectionsAdd_text_2_1: "ステータスを選択します",
  step4_inspectionsAdd_text_2_2: "下書きの状態で検査の下書きを保持します。",
  step5_inspectionsAdd_title: "5. プライバシー",
  step5_inspectionsAdd_text: "検査のプライバシーの種類を選択します。",
  step5_inspectionsAdd_text_1:
    "モジュールにアクセス権があるすべてのユーザーが検査を表示できます。",
  step5_inspectionsAdd_text_2: "参加者のみがこの検査を表示できます。",
  step6_inspectionsAdd_title: "6. 場所",
  step6_inspectionsAdd_text: "検査の場所を選択します。",
  step7_inspectionsAdd_title: "7. テンプレートを選択",
  step7_inspectionsAdd_text: "検査で使用するテンプレートを選択します。",
  step8_inspectionsAdd_title: "8. 作成者",
  step8_inspectionsAdd_text: "あなたと同じ権限を持つユーザーを選択します。",
  step9_inspectionsAdd_title: "9. 割り当てられたユーザー",
  step9_inspectionsAdd_text: "検査を承認する人を選択します。",
  step10_inspectionsAdd_title: "10. 通知リスト",
  step10_inspectionsAdd_text: "この検査に関して通知する人を選択します。",
  step11_inspectionsAdd_title: "11. 作成",
  step11_inspectionsAdd_text: "検査を作成するにはここをクリックします。",
  step_final_inspectionsAdd: "検査の作成方法を学びました。",
  // ADD TEMPLATES - guide me steps
  step1_inspectionTemplatesAdd_title: "1. 検査モジュールメニュー",
  step1_inspectionTemplatesAdd_text:
    "オプションを表示するにはここをクリックします。",
  step2_inspectionTemplatesAdd_title: "2. テンプレート",
  step2_inspectionTemplatesAdd_text:
    "テンプレートセクションを開くにはここをクリックします。",
  step3_inspectionTemplatesAdd_title: "3. 追加",
  step3_inspectionTemplatesAdd_text: "＋ アイコンをクリックします。",
  step4_inspectionTemplatesAdd_title: "4. タイトル",
  step4_inspectionTemplatesAdd_text: "検査テンプレートのタイトルを入力します。",
  step5_inspectionTemplatesAdd_title: "5. コード",
  step5_inspectionTemplatesAdd_text: "検査テンプレートのコードを入力します。",
  step6_inspectionTemplatesAdd_title: "6. カテゴリ",
  step6_inspectionTemplatesAdd_text: "カテゴリを選択します。",
  step7_inspectionTemplatesAdd_title: "7. 説明",
  step7_inspectionTemplatesAdd_text: "説明を入力します。",
  step8_inspectionTemplatesAdd_title: "8. 検査リスト",
  step8_inspectionTemplatesAdd_text:
    "クリックして検査する新しいアイテムを追加します。",
  step8_1_inspectionTemplatesAdd_title: "8.1 タイトル",
  step8_1_inspectionTemplatesAdd_text: "セクションのタイトルを入力します。",
  step8_1_1_inspectionTemplatesAdd_title: "8.1.1 要素を追加",
  step8_1_1_inspectionTemplatesAdd_text:
    "検査する要素を追加するにはクリックします。",
  step8_1_2_inspectionTemplatesAdd_title: "8.1.2 タイトル",
  step8_1_2_inspectionTemplatesAdd_text: "タイトルを入力します。",
  step8_1_3_inspectionTemplatesAdd_title: "8.1.3 説明",
  step8_1_3_inspectionTemplatesAdd_text: "要素の説明を入力します。",
  step9_inspectionTemplatesAdd_title: "9. 追加",
  step9_inspectionTemplatesAdd_text: "要素を追加するにはクリックします。",
  step10_inspectionTemplatesAdd_title: "10. 保存",
  step10_inspectionTemplatesAdd_text:
    "テンプレートを保存するにはクリックします。",
  step_final_inspectionTemplatesAdd: "テンプレートの作成方法を学びました。",

  add: "検査を追加",
  edit: "検査を編集",
  guideMe: "ガイド",
  inspection_recycle_bin: "検査 - ゴミ箱",
  inspections: "検査",
  meeting: "ミーティング",
  no_inspections_text:
    "追加する場合は、検査を追加するボタンをクリックしてプロセスを開始してください。",
  no_inspections_title: "現時点では検査は作成されていません",
  title: "検査",
  workflow: "ワークフロー",
  itemsInspected: "検査済みアイテム",
  pass: "合格",
  fail: "不合格",
  na: "N/A",
  sections: "セクション",
  items: "アイテム",
  createTask: "タスクを作成",
  add_button_tour: "新しい検査を作成",
  select_template: "テンプレートを選択",
  add_section: "セクションを追加",
  no_sections: "この検査にはセクションがありません。",
  approve: "承認",
  checkAndSign: "確認と署名",
  createREvision: "改訂を作成",
  observation: "観察",
  addItem: "アイテムを追加",
  deleteInspection: "この検査を削除してもよろしいですか？",
};

export { inspections };
