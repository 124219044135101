/* eslint-disable react/prop-types */
import { datadogRum } from "@datadog/browser-rum";
import {
  AddTask,
  Cancel,
  EditNote,
  RemoveRedEye,
  Send,
  Upload,
} from "@mui/icons-material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PolylineIcon from "@mui/icons-material/Polyline";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import moment from "moment";
import * as React from "react";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";

import { useContext } from "react";
import Calendar from "../../assets/rfi/calendar.png";
import Delivery from "../../assets/rfi/delivery.svg";
import Location from "../../assets/your-location.png";
import Voicenote from "../../components/Voicenote";
import { MessageBox } from "../../components/question/MessageBox";
import {
  DetailsHeader,
  ModalLastEdit,
  ModalSeenBy,
  RelatedUsersDetailsCard,
  SignatureModal,
  TooltipIconButton,
} from "../../components/shared";
import { FilePreviewHorizontalScroll } from "../../components/shared/FilePreviewHorizontalScroll";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import BubbleAvatarDeletable from "../../components/userAvatars/BubbleAvatarDeletable";
import { selectCurrentToken } from "../../features/auth/authSlice";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import {
  useApproveInspectionMutation,
  useCommentMutation,
  useGetInspectionByIdDetailsQuery,
  useGetInspectionByIdQuery,
  useItemInspectionMutation,
  useObservationMutation,
  useRejectInspectionMutation,
  useRevisionInspectionMutation,
} from "../../features/project/modules/inspections/apiSlice";
import { setCurrentInspection } from "../../features/project/modules/inspections/slice";
import { useGetProjectUsersQuery } from "../../features/project/projectApiSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
import CaslContext from "../../utils/caslContext";
import pdf from "../../utils/pdf";

const alert = (message, type) => {
  const Toast = Swal.mixin({
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    toast: true,
  });
  Toast.fire({
    icon: type,
    title: message,
  });
};

const buildBase64 = async (file) => {
  // eslint-disable-next-line no-return-await
  return (
    await new Response(file)
      .blob()
      .then((b) => URL.createObjectURL(b))
      .then((url) => fetch(url))
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      )
  ).replace(/^.*?base64,/, "");
};

function MessageReply({
  reply,
  placeholder,
  isLoading,
  showConnection = true,
}) {
  const fileRef = useRef();

  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const user = useSelector(selectCurrentUser);
  const darkMode = useSelector(selectTheme);

  const { t: tGeneral } = useTranslation("general");

  const handleOnReply = () => {
    reply(message, selectedFiles);
    setMessage("");
    setSelectedFiles([]);
    if (fileRef.current) {
      fileRef.current.value = null;
    }
  };

  const handleOnDeleteFile = (file) => {
    const tempFiles = [...selectedFiles];
    setSelectedFiles(tempFiles.filter((f) => f.name !== file.name));
  };

  const resetMessage = () => {
    setMessage("");
    setSelectedFiles([]);
  };

  return (
    <Box>
      {selectedFiles.length > 0 && (
        <FilePreviewHorizontalScroll
          files={selectedFiles}
          onAddPhotos={(files) => setSelectedFiles(files)}
          onDelete={handleOnDeleteFile}
        />
      )}
      <Box alignItems="center" display="flex" flex={1} position="relative">
        {showConnection && (
          <Box
            sx={{
              border: "1px solid #F2F2F2",
              left: -21,
              position: "absolute",
              width: "30px",
            }}
          />
        )}
        <Avatar
          title={`${user?.firstName} ${user?.lastName}`}
          src={user?.urlAvatar}
        />
        <FormControl
          fullWidth
          sx={{
            height: "55px",
            minHeight: 0,
            ml: "25px",
          }}
        >
          <OutlinedInput
            disabled={isLoading}
            placeholder={placeholder}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            multiline
            maxRows={5}
            endAdornment={
              <Box display="flex">
                <>
                  <Voicenote
                    submitVoicenote
                    handleVoicenote={(message, files) => reply(message, files)}
                    resetMessage={() => resetMessage()}
                  />
                  <IconButton
                    disabled={isLoading}
                    onClick={() => fileRef?.current?.click()}
                  >
                    <input
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      ref={fileRef}
                      onChange={(e) =>
                        setSelectedFiles(Array.from(e.currentTarget.files))
                      }
                    />
                    <Upload />
                  </IconButton>
                </>
                {isLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  <Button
                    onClick={() => {
                      if (message.length > 0) {
                        handleOnReply();
                      }
                    }}
                    disabled={isLoading}
                  >
                    {tGeneral("send")} <Send />
                  </Button>
                )}
              </Box>
            }
            sx={{
              paddingY: 1,
              paddingX: 2,
              backgroundColor: darkMode ? "black" : "white",
            }}
          />
        </FormControl>
      </Box>
    </Box>
  );
}

function InspectionsDetails() {
  const { idInspection, idProject, idEnterprise } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ability = useContext(CaslContext);

  const { t: tGeneral } = useTranslation("general");
  const { t: tInspections } = useTranslation("inspections");

  const currentUser = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);
  const { data: projectUsers } = useGetProjectUsersQuery(idProject);
  const { data: inspection, isLoading: isInspectionLoading } =
    useGetInspectionByIdQuery(
      { idInspection, idProject },
      {
        skip: !idInspection || !idProject,
      },
    );
  const { data: inspections } = useGetInspectionByIdDetailsQuery(
    { idInspection, idProject },
    {
      skip: !idInspection || !idProject,
    },
  );
  const [approveInspection, { isLoading: isApproveLoading }] =
    useApproveInspectionMutation();
  const [rejectInspection, { isLoading: isRejectLoading }] =
    useRejectInspectionMutation();
  const [revisionInspection] = useRevisionInspectionMutation();
  const [itemInspection] = useItemInspectionMutation();
  const [observationMutation] = useObservationMutation();
  const [commentMutation] = useCommentMutation();

  const [inProcess, setInProcess] = useState(false);
  const [isExpanded, setIsExpanded] = useState({});

  const [lastSeen, setLastSeen] = useState(false);
  const [lastEdit, setLastEdit] = useState(false);
  const [isSignOpen, setIsSignOpen] = useState(false);

  const handleCreateRevision = React.useCallback(async () => {
    setInProcess(true);
    try {
      const { data } = await revisionInspection({
        id: inspection.id,
        idProject,
      });
      dispatch(setCurrentInspection(data));
      alert(`Revision ${tGeneral("createdSuccessfully")}`, "success");
      navigate(`../inspections/${data.id}`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      alert(`${tGeneral("cantCreate")} Inspection`, "error");
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Revision Create - Error",
          stack: error.stack ? error.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
    setInProcess(false);
  }, [
    dispatch,
    idEnterprise,
    idProject,
    inspection,
    navigate,
    revisionInspection,
    tGeneral,
  ]);

  const isNewestRevision =
    inspections && inspection?.revision === inspections[0]?.revision;

  const menuActions = useMemo(() => {
    const actions = [
      {
        icon: <PictureAsPdfIcon />,
        label: tGeneral("exportPdf"),
        onClick: async () => {
          await pdf({
            data: {},
            token,
            url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/inspections/${idInspection}`,
            download: `${inspection.title}.pdf`,
          });
        },
      },
    ];
    if (
      inspection &&
      inspection.status === "rejected" &&
      inspection.creators.indexOf(currentUser.id) > -1
    ) {
      actions.push({
        label: tInspections("createRevision"),
        disabled:
          inProcess ||
          !isNewestRevision ||
          inspection.status === "closed" ||
          inspection.status === "in_revision",
        icon: <PolylineIcon />,
        onClick: handleCreateRevision,
      });
    }
    actions.push(
      {
        onClick: () => setLastEdit(true),
        icon: <EditNote />,
        label: tGeneral("lastEdit"),
      },
      {
        onClick: () => setLastSeen(true),
        icon: <RemoveRedEye />,
        label: tGeneral("lastSeen"),
      },
    );
    return actions;
  }, [
    currentUser.id,
    handleCreateRevision,
    idEnterprise,
    idInspection,
    idProject,
    inProcess,
    inspection,
    isNewestRevision,
    tGeneral,
    tInspections,
    token,
  ]);

  const disableEdit = (section, item) => {
    if (inspections && inspections.length >= 2) {
      if (inspections[1]) {
        // eslint-disable-next-line no-restricted-syntax
        for (const s of inspections[1].sections) {
          if (s.number === section.number) {
            // eslint-disable-next-line no-restricted-syntax
            for (const i of s.items) {
              if (i.number === item.number) {
                if (i.status === "pass") {
                  return true;
                }
              }
            }
          }
        }
      }
    }
    return false;
  };

  const itemStatus = async (id, status) => {
    setInProcess(true);
    try {
      await itemInspection({
        idInspection: inspection.id,
        idProject,
        id,
        status,
      }).unwrap();
      alert(`Inspection ${tGeneral("updatedSuccessfully")}`, "success");
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      alert(`${tGeneral("cantEdit")} Inspection`, "error");
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Update - Error",
          stack: error.stack ? error.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
    setInProcess(false);
  };

  const handleApproveInspection = async () => {
    setInProcess(true);
    try {
      await approveInspection({
        id: inspection.id,
        idProject,
        status: inspection.status,
      }).unwrap();
      alert(`Inspection ${tGeneral("approvedSuccessfully")}`, "success");
      navigate("../inspections");
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      alert(`${tGeneral("cantApprove")} Inspection`, "error");
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Approve - Error",
          stack: error.stack ? error.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
    setInProcess(false);
  };

  const handleRejectInspection = async () => {
    setInProcess(true);
    try {
      await rejectInspection({
        id: inspection.id,
        idProject,
      }).unwrap();
      alert(`Inspection ${tGeneral("rejectedSuccessfully")}`, "success");
      navigate("../inspections");
    } catch (error) {
      console.log(error);
    }
  };

  if (isInspectionLoading) {
    return (
      <Box pt="1rem" px="0.5rem">
        <SkeletonLoading isInspections />
      </Box>
    );
  }

  console.log(inspection);

  return (
    <Box>
      <DetailsHeader
        showMore={menuActions.length > 0}
        title={inspection?.title}
        actions={menuActions}
        onBack={() => navigate("./..")}
        showMainAction={
          inspection?.status === "in_revision" ||
          (inspection.status === "open" &&
            (inspection?.assignedTo.indexOf(currentUser?.id) > -1 ||
              ability.can("superAdmin", "inspections") ||
              inspection?.creators.indexOf(currentUser?.id) > -1))
        }
        bPrivate={inspection?.bPrivate}
        mainAction={{
          icon:
            inspection?.status !== "in_revision" ? (
              <CheckCircleIcon />
            ) : (
              <AssignmentTurnedInIcon />
            ),
          label:
            inspection?.status !== "in_revision"
              ? tInspections("approve")
              : tGeneral("sign"),
          onClick:
            inspection?.status === "in_revision"
              ? () => setIsSignOpen(true)
              : handleApproveInspection,
          isLoading:
            inspection?.status !== "in_revision" &&
            (isApproveLoading || isRejectLoading),
        }}
        secondaryAction={
          <TooltipIconButton
            icon={<Cancel />}
            label={tGeneral("reject")}
            onClick={handleRejectInspection}
            color="red"
            isLoading={isRejectLoading}
            disabled={isRejectLoading || isApproveLoading}
          />
        }
        showSecondayAction={
          inspection.status === "open" &&
          (inspection?.assignedTo.indexOf(currentUser?.id) > -1 ||
            ability.can("superAdmin", "inspections") ||
            inspection?.creators.indexOf(currentUser?.id) > -1)
        }
        number={`${inspection?.number}.${inspection?.revision}`}
        status={inspection?.status}
        customElements={[
          <Box
            alignItems="center"
            display="flex"
            gap={1}
            justifyContent="flex-start"
          >
            Revision:
            <Select
              label={tGeneral("revision")}
              labelId="revision"
              onChange={(event) => {
                const inspection = inspections.find(
                  (inspection) => inspection.id === event.target.value,
                );
                dispatch(setCurrentInspection(inspection));
                navigate(`../inspections/${inspection.id}`);
              }}
              required
              select="true"
              size="small"
              type="text"
              variant="outlined"
              value={inspection.id}
            >
              {inspections &&
                inspections.map((inspection) => (
                  <MenuItem key={inspection?.id} value={inspection?.id}>
                    <Box display="flex" alignItems="center">
                      <Typography fontSize="1rem">
                        {inspection?.revision}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </Box>,
        ]}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        width={1}
        p="10px"
        overflow="hidden"
        height="90vh"
      >
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          gap="10px"
          justifyContent="space-between"
          width={1}
        >
          <Box
            alignItems="stretch"
            display="flex"
            columnGap="10px"
            justifyContent="space-between"
            width="100%"
            my="10px"
          >
            <RelatedUsersDetailsCard
              lists={[
                {
                  users: inspection?.creatorsUsers,
                  label: tGeneral("creators"),
                },
                {
                  users: inspection?.assignedToUsers,
                  label: tGeneral("assignees"),
                },
              ]}
            />
            <Box display="flex" flex={2} gap="10px">
              <Card sx={{ display: "flex", alignItems: "stretch", flex: 1 }}>
                <CardContent
                  sx={{
                    "&:last-child": { p: 0 },
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: 0,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    gap={2}
                  >
                    <img src={Location} alt="" width="60px" />
                    <Box display="flex" flexDirection="column" gap="2">
                      <Typography
                        fontSize="12px"
                        color="GrayText"
                        textAlign="left"
                      >
                        {tGeneral("location")}
                      </Typography>
                      <Typography
                        fontSize="14px"
                        fontWeight="medium"
                        textAlign="initial"
                      >
                        {inspection.locationName}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ display: "flex", alignItems: "stretch", flex: 1 }}>
                <CardContent
                  sx={{
                    "&:last-child": { p: 0 },
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: 0,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    gap={2}
                  >
                    <img src={Calendar} alt="" width="60px" />
                    <Box>
                      <Typography
                        color="GrayText"
                        fontSize="12px"
                        textAlign="left"
                      >
                        {tGeneral("createDate")}
                      </Typography>
                      <Typography
                        fontSize="14px"
                        fontWeight="medium"
                        textAlign="initial"
                      >
                        {moment(inspection.createDate).format("D MMMM YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ display: "flex", alignItems: "stretch", flex: 1 }}>
                <CardContent
                  sx={{
                    "&:last-child": { p: 0 },
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: 0,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    gap={2}
                  >
                    <img src={Delivery} alt="" width="60px" />
                    <Box>
                      <Typography
                        color="GrayText"
                        fontSize="12px"
                        textAlign="left"
                      >
                        {tGeneral("deliveryDate")}
                      </Typography>
                      <Typography
                        fontSize="14px"
                        fontWeight="medium"
                        textAlign="initial"
                      >
                        {moment
                          .utc(inspection.deliveryDate)
                          .format("D MMMM YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Box
            alignItems="flex-start"
            display="flex"
            gap={2}
            justifyContent="flex-start"
            width={1}
          >
            <Card sx={{ width: "100%" }}>
              <CardContent sx={{ p: 0, "&:last-child": { p: 0 } }}>
                <Box
                  alignItems="stretch"
                  display="flex"
                  gap={0}
                  justifyContent="space-between"
                  width={1}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    flex={1}
                    p="10px"
                  >
                    <Typography fontWeight="bold" variant="h3">
                      {inspection.sections
                        .map((section) => section.items.length)
                        .reduce((a, b) => a + b, 0)}
                    </Typography>
                    <Typography variant="div">
                      {tInspections("itemsInspected")}
                    </Typography>
                  </Box>
                  <Box
                    alignItems="center"
                    borderBottom="5px solid #45B763"
                    display="flex"
                    columnGap="10px"
                    justifyContent="center"
                    sx={{
                      transition: "background-color 1s",
                      "&:hover": {
                        backgroundColor: "#45B763",
                      },
                    }}
                    flex={1}
                    p="10px"
                  >
                    <CheckCircleIcon
                      sx={{ fill: "#45B763" }}
                      fontSize="large"
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography fontWeight="bold" variant="h5">
                        {tInspections("items")}:{" "}
                        {inspection.sections
                          .map(
                            (section) =>
                              section.items.filter(
                                (item) => item.status === "pass",
                              ).length,
                          )
                          .reduce((a, b) => a + b, 0)}{" "}
                      </Typography>
                      {/* <Typography fontWeight="bold" variant="h5">
                        {tInspections("sections")}:{" "}
                        {
                          inspection.sections.filter(
                            (section) =>
                              section.items.filter(
                                (item) => item.status === "pass",
                              ).length === section.items.length,
                          ).length
                        }{" "}
                      </Typography> */}
                    </Box>
                  </Box>
                  <Box
                    alignItems="center"
                    borderBottom="5px solid red"
                    display="flex"
                    columnGap="10px"
                    justifyContent="center"
                    sx={{
                      transition: "background-color 1s",
                      "&:hover": {
                        backgroundColor: "red",
                      },
                    }}
                    flex={1}
                    p="10px"
                  >
                    <WarningIcon sx={{ fill: "red" }} fontSize="large" />
                    <Box display="flex" flexDirection="column">
                      <Typography fontWeight="bold" variant="h5">
                        {tInspections("items")}:{" "}
                        {inspection.sections
                          .map(
                            (section) =>
                              section.items.filter(
                                (item) => item.status === "fail",
                              ).length,
                          )
                          .reduce((a, b) => a + b, 0)}
                      </Typography>
                      {/* <Typography fontWeight="bold" variant="h5">
                        {tInspections("sections")}:{" "}
                        {
                          inspection.sections.filter(
                            (section) =>
                              section.items.filter(
                                (item) => item.status === "fail",
                              ).length > 0,
                          ).length
                        }
                      </Typography> */}
                    </Box>
                  </Box>
                  <Box
                    alignItems="center"
                    borderBottom="5px solid #999999"
                    display="flex"
                    columnGap="10px"
                    justifyContent="center"
                    sx={{
                      transition: "background-color 1s",
                      "&:hover": {
                        backgroundColor: "#999999",
                      },
                    }}
                    flex={1}
                    p="10px"
                  >
                    <RemoveCircleIcon
                      sx={{ fill: "#999999" }}
                      fontSize="large"
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography fontWeight="bold" variant="h5">
                        {tInspections("items")}:{" "}
                        {inspection.sections
                          .map(
                            (section) =>
                              section.items.filter(
                                (item) =>
                                  item.status !== "pass" &&
                                  item.status !== "fail" &&
                                  item.status !== null,
                              ).length,
                          )
                          .reduce((a, b) => a + b, 0)}{" "}
                      </Typography>
                      {/* <Typography fontWeight="bold" variant="h5">
                        {tInspections("sections")} :{" "}
                        {
                          inspection.sections.filter(
                            (section) =>
                              section.items.filter(
                                (item) => item.status === "n/a",
                              ).length,
                          ).length
                        }{" "}
                      </Typography> */}
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>

        <Divider />

        <Box
          alignItems="flex-start"
          display="flex"
          justifyContent="flex-start"
          width={1}
        >
          <Typography fontWeight="bold" variant="h6">
            {tGeneral("inspections")}
          </Typography>
        </Box>

        <Box flex={1} sx={{ overflowY: "auto", overflowX: "hidden" }}>
          {inspection.sections
            .map((section) => {
              return (
                <Box
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  justifyContent="flex-start"
                  key={section.number}
                  width={1}
                >
                  <Typography fontWeight="bold" variant="h5">
                    {section.title}
                  </Typography>
                  {section.items
                    .map((item) => {
                      return (
                        <Box
                          alignItems="flex-start"
                          display="flex"
                          flexDirection="column"
                          gap={2}
                          justifyContent="flex-start"
                          key={item.number}
                          sx={{ cursor: "pointer" }}
                          width={1}
                        >
                          <Box
                            alignItems="flex-start"
                            display="flex"
                            flexDirection="row"
                            gap={1}
                            justifyContent="flex-start"
                            width={1}
                          >
                            <Box
                              alignItems="flex-start"
                              display="flex"
                              flexDirection="column"
                              gap={1}
                              justifyContent="flex-start"
                            >
                              {isExpanded[item.id] ? (
                                <KeyboardArrowDownIcon
                                  onClick={() => {
                                    setIsExpanded((isExpanded) => {
                                      return {
                                        ...isExpanded,
                                        [item.id]: false,
                                      };
                                    });
                                  }}
                                />
                              ) : (
                                <KeyboardArrowUpIcon
                                  onClick={() => {
                                    setIsExpanded((isExpanded) => {
                                      return {
                                        ...isExpanded,
                                        [item.id]: true,
                                      };
                                    });
                                  }}
                                  sx={{ cursor: "pointer" }}
                                />
                              )}
                            </Box>
                            <Box
                              alignItems="flex-start"
                              display="flex"
                              flex={1}
                              flexDirection="column"
                              gap={1}
                              justifyContent="flex-start"
                              onClick={() => {
                                setIsExpanded((isExpanded) => {
                                  return {
                                    ...isExpanded,
                                    [item.id]: !isExpanded[item.id],
                                  };
                                });
                              }}
                              sx={{ cursor: "pointer" }}
                            >
                              <Typography fontSize="18px" variant="div">
                                {item.title}
                              </Typography>
                              <Typography fontSize="14px" variant="div">
                                {item.description}
                              </Typography>
                              <Box
                                alignItems="center"
                                display="flex"
                                gap={2}
                                sx={{ opacity: 0.25 }}
                              >
                                <Box alignItems="center" display="flex" gap={1}>
                                  <PersonSearchIcon size="10px" />{" "}
                                  {item.observations.length}
                                </Box>
                                <Box alignItems="center" display="flex" gap={1}>
                                  <AttachFileIcon size="10px" />{" "}
                                  {item.observations.reduce(
                                    (prev, curr) => prev + curr.files.length,
                                    0,
                                  )}
                                </Box>
                                <Box alignItems="center" display="flex" gap={1}>
                                  <QuestionAnswerIcon size="10px" />{" "}
                                  {item.observations.length}
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              alignItems="flex-end"
                              display="flex"
                              flexDirection="column"
                              gap="10px"
                              justifyContent="flex-end"
                            >
                              <Box
                                alignItems="flex-end"
                                display="flex"
                                flexDirection="row"
                                gap="10px"
                                justifyContent="flex-end"
                              >
                                {inspection.status === "in_revision" &&
                                  item.status === "fail" &&
                                  inspection?.status !== "open" && (
                                    <TooltipIconButton
                                      icon={<AddTask />}
                                      label={tInspections("createTask")}
                                      onClick={() => {
                                        navigate("../tasks/form");
                                      }}
                                    />
                                  )}
                                <TooltipIconButton
                                  icon={<CheckCircleIcon />}
                                  onClick={async () => {
                                    if (inspection.status === "closed") {
                                      return;
                                    }
                                    await itemStatus(item.id, "pass");
                                  }}
                                  color={
                                    item.status === "pass" ? "#2e7d32" : "gray"
                                  }
                                  label="Pass"
                                  disabled={
                                    inspection.status !== "in_revision" ||
                                    (!!item.status &&
                                      inspection?.revision > 0 &&
                                      disableEdit(section, item))
                                  }
                                />
                                <TooltipIconButton
                                  color={
                                    item.status === "fail" ? "#e05353" : "gray"
                                  }
                                  disabled={
                                    inspection.status !== "in_revision" ||
                                    (!!item.status &&
                                      inspection?.revision > 0 &&
                                      disableEdit(section, item))
                                  }
                                  onClick={async () => {
                                    if (inspection.status === "closed") {
                                      return;
                                    }
                                    await itemStatus(item.id, "fail");
                                  }}
                                  icon={<WarningIcon />}
                                  label="Fail"
                                />
                                <TooltipIconButton
                                  icon={<RemoveCircleIcon />}
                                  color={
                                    item.status === "n/a" ? "#0288d1" : "gray"
                                  }
                                  label="N/A"
                                  disabled={
                                    inspection?.status !== "in_revision" ||
                                    (!!item.status &&
                                      inspection?.revision > 0 &&
                                      disableEdit(section, item))
                                  }
                                  onClick={async () => {
                                    if (inspection.status === "closed") {
                                      return;
                                    }
                                    await itemStatus(item.id, "n/a");
                                  }}
                                />
                              </Box>
                              <Box
                                alignItems="flex-end"
                                display="flex"
                                flexDirection="row"
                                gap="10px"
                                justifyContent="flex-end"
                              >
                                {item.inspectedBy
                                  ? `${
                                      (
                                        projectUsers &&
                                        projectUsers.length > 0 &&
                                        projectUsers
                                          .map((user) => user.user)
                                          .map((user) => {
                                            return {
                                              ...user,
                                              name: `${user.firstName} ${user.lastName}`,
                                            };
                                          })
                                          .find((user) =>
                                            item.inspectedBy.includes(user.id),
                                          )
                                      )?.name
                                    }`
                                  : ""}
                                {item.inspectedBy &&
                                  item.inspectedDate &&
                                  " | "}
                                {item.inspectedDate
                                  ? moment(item.inspectedDate).format(
                                      "D MMMM YYYY [at] HH:mm a",
                                    )
                                  : ""}
                              </Box>
                            </Box>
                          </Box>
                          {isExpanded[item.id] && (
                            <Box
                              alignItems="flex-start"
                              display="flex"
                              flexDirection="column"
                              gap={4}
                              pb={2}
                              justifyContent="flex-start"
                              key={item.number}
                              width={1}
                            >
                              {item.observations.map((observation) => {
                                const user =
                                  projectUsers &&
                                  projectUsers.length > 0 &&
                                  projectUsers
                                    .map((user) => user.user)
                                    .map((user) => {
                                      return {
                                        ...user,
                                        name: `${user.firstName} ${user.lastName}`,
                                      };
                                    })
                                    .find((user) =>
                                      observation.createdBy.includes(user.id),
                                    );
                                return (
                                  <Box
                                    display="flex"
                                    key={observation.id}
                                    sx={{ paddingRight: "20px", width: "100%" }}
                                  >
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                    >
                                      <Card
                                        sx={{ borderRadius: "100%", p: "2px" }}
                                      >
                                        <Avatar src={user?.urlAvatar} />
                                      </Card>
                                      <Box
                                        sx={{
                                          height: "calc(100% - 72px)",
                                          width: "1px",
                                          border: "1px solid #F2F2F2",
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      display="flex"
                                      flex={1}
                                      flexDirection="column"
                                      justifyContent="stretch"
                                      sx={{ width: "100%" }}
                                    >
                                      <MessageBox
                                        bgColor="#f2f2f2"
                                        createdDate={observation.createdDate}
                                        createdBy={user?.name}
                                        files={observation.files}
                                        text={observation.contents}
                                        question
                                        width="100%"
                                      />
                                      {observation.comments.map((comment) => {
                                        const user =
                                          projectUsers &&
                                          projectUsers.length > 0 &&
                                          projectUsers
                                            .map((user) => user.user)
                                            .map((user) => {
                                              return {
                                                ...user,
                                                name: `${user.firstName} ${user.lastName}`,
                                              };
                                            })
                                            .find((user) =>
                                              comment.createdBy.includes(
                                                user.id,
                                              ),
                                            );
                                        return (
                                          <Box key={comment.id}>
                                            <Box
                                              display="flex"
                                              position="relative"
                                              sx={{
                                                paddingRight: "20px",
                                                width: "100%",
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  width: "50px",
                                                  border: "1px solid #F2F2F2",
                                                  position: "absolute",
                                                  top: 20,
                                                  left: -21,
                                                }}
                                              />
                                              <Box
                                                alignItems="center"
                                                display="flex"
                                                flexDirection="column"
                                              >
                                                <Card
                                                  sx={{
                                                    borderRadius: "100%",
                                                    p: "2px",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <Avatar
                                                    src={user?.urlAvatar}
                                                  />
                                                </Card>
                                              </Box>
                                              <Box
                                                flex={1}
                                                sx={{ width: "100%" }}
                                              >
                                                <MessageBox
                                                  bgColor="#dfdfdf"
                                                  createdDate={
                                                    comment?.createdDate
                                                  }
                                                  createdBy={user?.name}
                                                  files={comment?.files}
                                                  text={comment?.contents}
                                                  id={comment.id}
                                                  module="inspections"
                                                  width="100%"
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                        );
                                      })}
                                      {inspection.status === "open" && (
                                        <MessageReply
                                          isLoading={inProcess}
                                          placeholder={tGeneral("comments")}
                                          reply={async (contents, files) => {
                                            setInProcess(true);
                                            try {
                                              const objects = [];
                                              // eslint-disable-next-line no-restricted-syntax
                                              for await (const file of files) {
                                                const extension = file.name
                                                  .split(".")
                                                  .pop();
                                                objects.push({
                                                  extension,
                                                  contentType: file.type,
                                                  contents:
                                                    await buildBase64(file),
                                                  name: file.name,
                                                  type: [
                                                    "gif",
                                                    "jpg",
                                                    "jpeg",
                                                    "png",
                                                  ].includes(extension)
                                                    ? "image"
                                                    : "file",
                                                });
                                              }
                                              const data =
                                                await commentMutation({
                                                  contents,
                                                  files: objects,
                                                  idInspectionSectionItemObservation:
                                                    observation.id,
                                                  idInspectionSectionItem:
                                                    item.id,
                                                  idInspection: inspection.id,
                                                  idProject,
                                                }).unwrap();
                                              alert(
                                                `Comment ${tGeneral(
                                                  "createdSuccessfully",
                                                )}`,
                                                "success",
                                              );
                                              dispatch(
                                                setCurrentInspection(data),
                                              );
                                              navigate(
                                                `../inspections/${inspection.id}`,
                                              );
                                            } catch (error) {
                                              // eslint-disable-next-line no-console
                                              console.error(error);
                                              alert(
                                                `${tGeneral(
                                                  "cantEdit",
                                                )} Comment`,
                                                "error",
                                              );
                                              if (datadogRum?.addError) {
                                                datadogRum.addError({
                                                  message:
                                                    "Inspections - Comment - Error",
                                                  stack: error.stack
                                                    ? error.stack
                                                    : "invalid stack",
                                                  custom: {
                                                    app: "buildpeer-web",
                                                    enterprise: idEnterprise,
                                                    module: "Inspections",
                                                    project: idProject,
                                                  },
                                                  timestamp: Date.now(),
                                                });
                                              }
                                            }
                                            setInProcess(false);
                                          }}
                                          requiredText
                                        />
                                      )}
                                    </Box>
                                  </Box>
                                );
                              })}
                              {inspection.status === "in_revision" && (
                                <Box
                                  sx={{ paddingRight: "20px", width: "100%" }}
                                >
                                  <MessageReply
                                    isLoading={inProcess}
                                    placeholder={tInspections("observation")}
                                    reply={async (contents, files) => {
                                      setInProcess(true);
                                      try {
                                        const objects = [];
                                        // eslint-disable-next-line no-restricted-syntax
                                        for await (const file of files) {
                                          const extension = file.name
                                            .split(".")
                                            .pop();
                                          objects.push({
                                            extension,
                                            contentType: file.type,
                                            contents: await buildBase64(file),
                                            name: file.name,
                                            type: [
                                              "gif",
                                              "jpg",
                                              "jpeg",
                                              "png",
                                            ].includes(extension)
                                              ? "image"
                                              : "file",
                                          });
                                        }
                                        const data = await observationMutation({
                                          contents,
                                          files: objects,
                                          idInspectionSectionItem: item.id,
                                          idInspection: inspection.id,
                                          idProject,
                                        }).unwrap();
                                        alert(
                                          `Observation ${tGeneral(
                                            "createdSuccessfully",
                                          )}`,
                                          "success",
                                        );
                                        dispatch(setCurrentInspection(data));
                                        navigate(
                                          `../inspections/${inspection.id}`,
                                        );
                                      } catch (error) {
                                        // eslint-disable-next-line no-console
                                        console.error(error);
                                        alert(
                                          `${tGeneral("cantEdit")} Observation`,
                                          "error",
                                        );
                                        if (datadogRum?.addError) {
                                          datadogRum.addError({
                                            message:
                                              "Inspections - Observation - Error",
                                            stack: error.stack
                                              ? error.stack
                                              : "invalid stack",
                                            custom: {
                                              app: "buildpeer-web",
                                              enterprise: idEnterprise,
                                              module: "Inspections",
                                              project: idProject,
                                            },
                                            timestamp: Date.now(),
                                          });
                                        }
                                      }
                                      setInProcess(false);
                                    }}
                                    showConnection={false}
                                  />
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      );
                    })
                    .reduce(
                      (a, b) =>
                        a === null
                          ? [b]
                          : [
                              ...a,
                              <Divider
                                key={`${a[0].key}-divider-${b.key}`}
                                width="100%"
                              />,
                              b,
                            ],
                      null,
                    )}
                </Box>
              );
            })
            .reduce(
              (a, b) =>
                a === null
                  ? [b]
                  : [
                      ...a,
                      <Divider
                        key={`${a[0].key}-divider-${b.key}`}
                        width="100%"
                      />,
                      b,
                    ],
              null,
            )}
        </Box>

        <Divider />

        <Box
          alignItems="flex-start"
          display="flex"
          gap={2}
          justifyContent="flex-start"
          width={1}
        >
          <Box flex={1}>
            <Typography fontWeight="bold" variant="h6">
              {tGeneral("distributionList")}
            </Typography>
            <Box
              alignItems="flex-start"
              display="flex"
              flexDirection="row"
              gap={2}
              justifyContent="flex-start"
            >
              {projectUsers &&
                projectUsers.length > 0 &&
                projectUsers
                  .map((user) => user.user)
                  .map((user) => {
                    return {
                      ...user,
                      name: `${user.firstName} ${user.lastName}`,
                    };
                  })
                  .filter((user) =>
                    inspection.distributionList.includes(user.id),
                  )
                  .map((user) => {
                    return (
                      <BubbleAvatarDeletable
                        color="#f7e4b2"
                        key={user.id}
                        sx={{ p: 2 }}
                        user={user}
                      />
                    );
                  })}
            </Box>
          </Box>
          {["open", "closed", "rejected"].indexOf(inspection?.status) > -1 &&
            inspection.signedBy && (
              <Box>
                <Box display="flex" columnGap="5px">
                  <Typography fontWeight="bold">
                    {tGeneral("reviewedBy")}
                  </Typography>
                  <Typography>
                    {inspection.signedBy.firstName}{" "}
                    {inspection.signedBy.lastName},{" "}
                    {moment(inspection.updatedDate).format("DD/MM/YYY, HH:mm")}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                  <img
                    height="50px"
                    src={inspection.signedBy.signature}
                    alt="User signature"
                  />
                </Box>
              </Box>
            )}
        </Box>
        <ModalSeenBy
          isOpen={lastSeen}
          onClose={() => setLastSeen(false)}
          idObject={inspection.id}
        />
        <ModalLastEdit
          isOpen={lastEdit}
          onClose={() => setLastEdit(false)}
          idObject={inspection.id}
        />
        <SignatureModal
          isOpen={isSignOpen}
          onClose={() => setIsSignOpen(false)}
          onSign={handleApproveInspection}
        />
      </Box>
    </Box>
  );
}

export default InspectionsDetails;
