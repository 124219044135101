import { tour } from "./tour";

export const tasks = {
  // general tour
  ...tour,
  moduleElement: "任务",
  // ADD - guide me steps
  task: "任务",
  tasks: "任务",
  high: "高",
  medium: "中",
  low: "低",
  taskDeletedSuccessfully: "任务删除成功",
  taskRestoredSuccessfully: "任务恢复成功",
  noTasksCreated: "当前没有创建任务",
  noTasksContent: "如果您想添加一个任务，请点击添加按钮开始该过程",
  addTask: "添加任务",
  backlog: "待办",
  doing: "进行中",
  done: "已完成",
  creationDate: "创建日期",
  deliveryDate: "交付日期",
  closeDate: "关闭日期",
  category: "类别",
  priority: "优先级",
  location: "位置",
  startTask: "开始任务",
  closeTask: "关闭任务",
  days: "天",
  tasksRecycleBin: "任务回收站",
  editTask: "编辑任务",
  addCategory: "添加类别",
  // ADD - guide me steps
  step1_addtask_title: "1. 添加任务",
  step1_addtask_text: "点击此处添加新任务",
  step2_addtask_title: "2. 标题",
  step2_addtask_text: "编写能描述您的新任务的标题",
  step3_addtask_title: "3. 截止日期",
  step3_addtask_text: "选择截止日期作为完成任务的最后期限",
  step4_addtask_title: "4. 状态",
  step4_addtask_text1_1: "选择状态",
  step4_addtask_text1_2: "选择“开放”立即发布任务。",
  step4_addtask_text2_1: "选择",
  step4_addtask_text2_2: "选择“草稿”状态以保存任务的草稿而不发布。",
  step5_addtask_title: "5. 隐私",
  step5_addtask_text: "点击此处使其对所有人可见或仅对参与人员可见",
  step5_addtask_text_1: "选择任务隐私类型",
  step5_addtask_text_2: "任何有权访问模块的人都将看到您的任务",
  step5_addtask_text_3: "只有参与者才能看到此任务",
  step6_addtask_title: "6. 分配人员",
  step6_addtask_text: "选择需要完成任务的人员",
  step7_addtask_title: "7. 分发列表",
  step7_addtask_text: "选择要通知此任务的人员",
  step8_addtask_title: "8. 描述",
  step8_addtask_text: "编写任务的描述",
  step9_addtask_title: "9. 上传文件",
  step9_addtask_text: "点击此处或拖放文件，如图像或文档",
  step10_addtask_title: "10. 类别",
  step10_addtask_text: "选择任务的类别",
  step11_addtask_title: "11. 位置",
  step11_addtask_text: "选择任务需要完成的项目位置",
  step12_addtask_title: "12. 优先级",
  step12_addtask_text: "选择高、中、低优先级之间",
  step13_addtask_title: "13. 发布",
  step13_addtask_text: "点击此处公开任务，或根据情况将其保存为草稿",
  stepFinal_addtask: "您已学会如何创建新任务",
  // VIEW - guide me steps
  step1_viewtask_title: "1. 查看任务",
  step1_viewtask_text: "点击任务以查看其内容。",
  step2_viewtask_title: "2. 参与人员",
  step2_viewtask_text: "在此部分，您可以查看指派人员和创建者。",
  step3_viewtask_title: "3. 任务数据",
  step3_viewtask_text:
    "在此部分，您可以查看任务的主要数据，如截止日期、状态、优先级等。",
  step4_viewtask_title: "4. 描述",
  step4_viewtask_text: "在这里，您可以查看任务所有者创建的描述。",
  step6_viewtask_title: "6. 回复",
  step6_viewtask_text:
    "对主要描述进行回复并附加文件。不同的回复将显示在描述下方。",
  step7_viewtask_title: "7. 评论",
  step7_viewtask_text:
    "对已作出的回应进行评论，必要时附加文件。评论将显示在回应消息下方。",
  step8_viewtask_title: "8. 开始/完成任务",
  step8_viewtask_text:
    "点击此处将任务标记为已开始；如果任务已经开始，请点击完成任务。",
  stepFinal_viewtask: "您已学会如何查看任务",
  // EDIT - guide me steps
  step1_edittask_title: "1. 编辑任务",
  step1_edittask_text: "点击三个点以打开不同的选项：点击“编辑”。",
  step2_edittask_title: "2. 信息",
  step2_edittask_text: "在此部分，您可以编辑任务的标题、截止日期、状态和隐私。",
  step3_edittask_title: "3. 描述和附加文件",
  step3_edittask_text: "在此部分，您可以编辑任务的描述并删除附加文件。",
  step4_edittask_title: "4. 附加信息",
  step4_edittask_text: "在此部分，您可以编辑其他信息，如类别、优先级和位置。",
  step4_edittask_text1_1: "选择状态",
  step4_edittask_text1_2: "选择“开放”以发布待回应的任务。",
  step4_edittask_text2_1: "选择",
  step4_edittask_text2_2: "选择“草稿”状态以保存任务的草稿而不发布。",
  step5_edittask_title: "5. 分配人员",
  step5_edittask_text: "添加或删除任务的指派人员",
  step5_edittask_text_1: "选择任务隐私类型",
  step5_edittask_text_2: "任何有权访问模块的人都将看到您的任务",
  step5_edittask_text_3: "只有参与者才能看到此任务",
  step6_edittask_title: "6. 分发列表",
  step6_edittask_text: "添加或删除分发列表中的人员",
  step7_edittask_title: "7. 保存更改",
  step7_edittask_text: "点击此处保存并更新对任务所做的更改",
  stepFinal_edittask: "您已学会如何编辑任务",
  // RECYCLE - guide me steps
  step1_recycletask_title: "2. 回收站任务",
  step1_recycletask_text: "在这里，您可以查看已从项目中删除的任务。",
  step2_recycletask_title: "3. 选择任务",
  step2_recycletask_text: "选择要恢复的任务。",
  step3_recycletask_title: "4. 恢复任务",
  step3_recycletask_text: "点击此处恢复所选任务。",
  stepFinal_recycletask: "您已学会如何与回收站交互",
  // EXPORT - guide me steps
  step1_exporttask_title: "点击三个点以打开不同的选项：",
  step1_exporttask_text1_1: "导出为PDF",
  step1_exporttask_text1_2: "点击此处将所选任务导出为PDF文件。",
  step1_exporttask_text2_1: "通过电子邮件发送",
  step1_exporttask_text2_2: "点击此处将所选任务通过电子邮件发送。",
  stepFinal_exporttask: "您已学会如何导出任务",
  // SETTINGS - guide me steps
  step1_settingstask_title: "2. 任务设置",
  step1_settingstask_text: "点击此处修改设置",
  step3_settingstask_title: "3. 分发列表",
  step3_settingstask_text: "选择默认的分发列表人员",
  step4_settingstask_title: "4. 保存设置",
  step4_settingstask_text: "点击此处保存设置",
  stepFinal_settingstask: "您已学会如何与任务设置交互",
  // other
  obligatory: "必须",
  public: "公开",
  private: "私密",
  congratulations: "恭喜！",
  rfi_title_module: "RFIs",
  no_rfis_title: "当前没有创建RFI",
  no_rfis_text: "如果您想添加一个，请点击添加RFI按钮开始该过程",
  meeting: "会议",
  workflow: "工作流程",
  guideMe: "引导",
  support: "支持",
  closeTaskMessage: "您确定要关闭此任务吗？",
  subtasks: "子任务",
  addSubtask: "添加子任务",
  noSubtasks: "没有创建子任务",
  inList: "在列表中",
};
