import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { ContactSupport } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import GroupsIcon from "@mui/icons-material/Groups";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import { Add, Edit, Visibility } from "@mui/icons-material";

import { selectLocale } from "../../features/preferences/preferencesSlice";
import Directory from "./../../assets/workflow/ProjectDirectory.png";
import Directorio from "./../../assets/workflow/DirectorioProyecto.png";
import { useGetUsersFromEnterpriseQuery } from "../../features/enterprise/enterpriseApiSlice";
import { useProjectDirectorySteps } from "./components/steps/projectDirectory";
import { useGuideMeModal } from "./hooks/useGuideMeModal";
// Support Menu
import { SupportMenu } from "./components/SupportMenu";
import { SupportMenuModalMetting } from "./components/modals/SupportMenuModalMetting";
import { SupportMenuItemHeader } from "./components/SupportMenuItemHeader";
import { SupportContent } from "./components/SupportContent";
import { SupportListItem } from "./components/SupportListItem";
import { SupportMenuModalWorkflow } from "./components/modals/SupportMenuModalWorkflow";
import { SupportMenuModalGuideMe } from "./components/modals/SupportMenuModalGuideMe";
import { setCurrentGuideMe } from "../../features/project/projectSlice";

const SupportButtonDirectory = () => {
  const dispatch = useDispatch();

  const { idEnterprise } = useParams();
  const { t: translation } = useTranslation("projectDirectory");
  const [recordsLength, setRecordsLength] = useState(0);
  const locale = useSelector(selectLocale);
  const [imgWorkflow] = useState(locale === "en" ? Directory : Directorio);

  const { data: records, isSuccess } = useGetUsersFromEnterpriseQuery(
    { idEnterprise },
    { skip: !idEnterprise },
  );

  const [{ addSteps, editSteps, viewSteps }] = useProjectDirectorySteps();

  // Support button (make component)
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [anchorSupp, setAnchorSupp] = useState(null);
  //For open
  const handleClick = (event) => {
    setAnchorSupp(event.currentTarget);
    setMenuIsOpen(true);
  };
  //For close
  const handleClose = () => {
    setAnchorSupp(null);
    setMenuIsOpen(false);
  };
  // End support button

  // Meeting modal
  const [meetingModalIsOpened, setMeetingModalIsOpened] = useState(false);
  const openMeetingModal = () => setMeetingModalIsOpened(true);
  const closeMeetingModal = () => setMeetingModalIsOpened(false);
  // Workflow modal
  const [workflowModalIsOpened, setWorkflowModalIsOpened] = useState(false);
  const openWorkflowModal = () => setWorkflowModalIsOpened(true);
  const closeWorkflowModal = () => setWorkflowModalIsOpened(false);
  // guide me modal
  const [guideModalIsOpened, setGuideModalIsOpened] = useState(false);
  const openGuideModal = () => setGuideModalIsOpened(true);
  const closeGuideModal = () => setGuideModalIsOpened(false);

  const [{ handleGuideMe }] = useGuideMeModal({
    addSteps,
    editSteps,
    viewSteps,
  });

  useEffect(() => {
    if (records && isSuccess) {
      setRecordsLength(records.length);
    }
  }, [records, isSuccess]);

  return (
    <div>
      {/* Button */}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={translation("support")}
      >
        <IconButton
          id="support-button"
          aria-controls={menuIsOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuIsOpen ? "true" : undefined}
          sx={{ color: "#007FFF" }}
          onClick={handleClick}
        >
          <ContactSupport />
        </IconButton>
      </Tooltip>

      <SupportMenu anchor={anchorSupp} open={menuIsOpen} onClose={handleClose}>
        {/* Meeting */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openMeetingModal();
          }}
        >
          <SupportMenuItemHeader title={translation("meeting")}>
            <GroupsIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalMetting
          open={meetingModalIsOpened}
          onClose={closeMeetingModal}
        />
        {/* Workflow */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openWorkflowModal();
          }}
        >
          <SupportMenuItemHeader title={translation("workflow")}>
            <AppRegistrationRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalWorkflow
          open={workflowModalIsOpened}
          diagramImageUrl={imgWorkflow}
          onClose={closeWorkflowModal}
        />

        {/* Guide Me */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openGuideModal();
          }}
        >
          <SupportMenuItemHeader title={translation("guideMe")}>
            <TouchAppRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalGuideMe
          title={`${translation("guideMe")} ${translation("moduleName")}`}
          open={guideModalIsOpened}
          onClose={closeGuideModal}
        >
          <SupportContent>
            {/* ADD */}
            <SupportListItem
              can="add"
              moduleName={"directory"}
              onClick={() => {
                handleGuideMe("add");
                dispatch(setCurrentGuideMe("directory-users-add"));
                closeGuideModal();
              }}
            >
              <Add sx={{ marginRight: 1 }} />
              {`${translation("addButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* VIEW */}
            <SupportListItem
              can="view"
              moduleName={"directory"}
              onClick={() => {
                handleGuideMe("view");
                dispatch(setCurrentGuideMe("directory-users-view"));
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <Visibility sx={{ marginRight: 1 }} />
              {`${translation("viewButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* EDIT */}
            <SupportListItem
              can="edit"
              moduleName={"directory"}
              onClick={() => {
                handleGuideMe("edit");
                dispatch(setCurrentGuideMe("directory-users-edit"));
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <Edit sx={{ marginRight: 1 }} />
              {`${translation("editButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
          </SupportContent>
        </SupportMenuModalGuideMe>
      </SupportMenu>
    </div>
  );
};

export { SupportButtonDirectory };
