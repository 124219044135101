import React, { useEffect, useState } from "react";
// MUI Components
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// MUI Icons
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
// Assets
// Hooks
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Tooltip } from "@mui/material";
import logo from "../../assets/logo-box.png";
// Redux: reducers
import { useGetUserQuery } from "../../features/enterprise/enterpriseApiSlice";
import {
  useGetProjectUsersQuery,
  useGetUserRoleQuery,
} from "../../features/project/projectApiSlice";
import {
  selectCurrentProject,
  setCurrentProject,
  setCurrentProjectUsers,
  setCurrentRole,
} from "../../features/project/projectSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";

import {
  useGetVisiblesMutation,
  useGetOrderMutation,
} from "../../features/project/projectSettingsApiSlice";
import {
  setVisibles,
  setOrder,
} from "../../features/project/projectSettingsSlice";

function ProjectCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: tGeneral } = useTranslation("general");

  const { data: enterpriseUser, isSuccess } = useGetUserQuery();

  const currentProject = useSelector(selectCurrentProject);
  const currentUser = useSelector(selectCurrentUser);
  const { data: projectUsers } = useGetProjectUsersQuery(props?.project?.id);

  const [idCardProject, setIdProjectCard] = useState(null);

  const {
    data: roleData,
    isSuccessLocations,
    refetch,
  } = useGetUserRoleQuery(
    {
      idUser: currentUser?.id,
      idProject: idCardProject,
    },
    { skip: idCardProject === null },
  );

  const setProjectUsers = async () => {
    if (projectUsers.length > 0) {
      const usersList = projectUsers
        .filter(function (obj) {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.user.id,
          name: `${user.user.firstName} ${user.user.lastName}`,
          urlAvatar: user.user.urlAvatar,
        }));
      dispatch(setCurrentProjectUsers(usersList));
    }
  };

  const getRole = async () => {
    if (roleData) {
      dispatch(setCurrentRole(roleData[0].role));
    }
  };

  const [getVisibleModules, { isSuccessVisibles }] = useGetVisiblesMutation();

  // const visible_modules = useSelector(selectVisibleConfig);

  const [modulesV, setModulesV] = React.useState([
    { id: "1", name: "Drawings", visible: true },
    { id: "2", name: "Punch List", visible: true },
    { id: "3", name: "Directory", visible: true },
    { id: "4", name: "RFIs", visible: true },
    { id: "5", name: "Submittals", visible: true },
    { id: "6", name: "Deliveries", visible: true },
    { id: "7", name: "Hours", visible: true },
    { id: "8", name: "Meetings", visible: true },
    { id: "9", name: "Photos", visible: true },
    { id: "10", name: "Tasks", visible: true },
    { id: "11", name: "Inspections", visible: true },
    { id: "12", name: "Issues", visible: true },
    { id: "13", name: "Daily Reports", visible: true },
    { id: "14", name: "Documents", visible: true },
    { id: "15", name: "Delays", visible: true },
    { id: "16", name: "Announcements", visible: true },
    { id: "17", name: "KPIs", visible: true },
  ]);

  const getVisiblesFromDatabase = async (project) => {
    try {
      const body = {
        idProject: project?.id,
        idUser: enterpriseUser?.id,
      };
      const response = await getVisibleModules(body).unwrap();

      if (response) {
        if (response[0].visibleModules.visibleModules.length > 0) {
          dispatch(setVisibles(response[0].visibleModules.visibleModules));
        } else {
          dispatch(setVisibles(modulesV));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [getOrderModules, { isSuccessOrder }] = useGetOrderMutation();

  const [modulesO, setModulesO] = React.useState([
    { id: "1", name: "Drawings", order: 0 },
    { id: "2", name: "Punch List", order: 1 },
    { id: "3", name: "Directory", order: 2 },
    { id: "4", name: "RFIs", order: 3 },
    { id: "5", name: "Submittals", order: 4 },
    { id: "6", name: "Deliveries", order: 5 },
    { id: "7", name: "Hours", order: 6 },
    { id: "8", name: "Meetings", order: 7 },
    { id: "9", name: "Photos", order: 8 },
    { id: "10", name: "Tasks", order: 9 },
    { id: "11", name: "Inspections", order: 9.1 },
    { id: "12", name: "Issues", order: 11 },
    { id: "13", name: "Daily Reports", order: 12 },
    { id: "14", name: "Documents", order: 13 },
    { id: "15", name: "Delays", order: 14 },
    { id: "16", name: "Announcements", order: 15 },
    { id: "17", name: "KPIs", order: 16 },
  ]);

  const getOrderFromDatabase = async (project) => {
    try {
      const body = {
        idProject: project?.id,
        idUser: enterpriseUser?.id,
      };
      const response = await getOrderModules(body).unwrap();

      if (response) {
        if (response[0].orderModules.orderModules.length > 0) {
          dispatch(setOrder(response[0].orderModules.orderModules));
        } else {
          dispatch(setOrder(modulesO));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  // const idProject = useParams();
  // const { data: project } = useGetProjectAdminPanelInfoQuery(idProject);

  useEffect(() => {
    if (props?.project) {
      setIdProjectCard(props?.project?.id);
    }
  }, [props]);

  return (
    <>
      <Card
        onClick={() => {
          // dispatch(setCurrentProject(project));
          dispatch(setCurrentProject(props?.project));
          getVisiblesFromDatabase(props?.project);
          getOrderFromDatabase(props?.project);
          getRole();
          // TODO: implement ability
          setProjectUsers();

          navigate(`../project/${props?.project.id}/dashboard`);
        }}
        sx={{
          // TODO: in dark mode, hover should make card color clearer to increase elevation
          borderRadius: 5,
          boxShadow: 3,
          ":hover": {
            transform: "scale3d(1.01, 1.01, 1)",
            boxShadow: 5,
            transition: "transform 0.25s ease-in-out",
          },
        }}
      >
        <CardContent>
          {/* <Box component="div" sx={{ position: "relative", float: "right" }}>
            <Chip size="small" label="0" sx={{ fontWeight: '600' }} />
          </Box> */}

          {/* Card content */}
          <Grid
            container
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            {/* Managers */}
            <Grid item xs={12} md={8} sx={{ marginBottom: 1 }}>
              {/* Card Title */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                {/* title */}
                <Tooltip title={props?.project?.projectName}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    noWrap
                    textOverflow="ellipsis"
                  >
                    {props?.project?.projectName}
                  </Typography>
                </Tooltip>

                {/* bActive */}
                <Chip
                  size="small"
                  label={tGeneral(
                    props?.project?.bActive ? "active" : "inactive",
                  )}
                  sx={{
                    fontWeight: "600",
                    marginLeft: 1,
                    backgroundColor: props?.project?.bActive
                      ? "#44b663"
                      : "#b1b7c0",
                    fontSize: 13,
                    color: "white",
                  }}
                />

                {/* notification number */}
                {/* <Chip
                  size="small"
                  label="0"
                  sx={{ fontWeight: "600", marginLeft: 1 }}
                /> */}
              </Box>

              <Typography fontSize="0.8rem" marginBottom={1}>
                <span style={{ fontWeight: "600" }}>
                  {tGeneral("manager")}:{" "}
                </span>
                {`${props?.project?.constructionManager || tGeneral("noManager")}`}
              </Typography>

              <Typography fontSize="0.8rem" marginBottom={1}>
                <span style={{ fontWeight: "600" }}>
                  {tGeneral("constructionDirector")}:{" "}
                </span>
                {`${props?.project?.constructionDirector || tGeneral("noConstructionDirector")}`}
              </Typography>

              <Typography fontSize="0.8rem" marginBottom={1}>
                <span style={{ fontWeight: "600" }}>
                  {tGeneral("architect")}:{" "}
                </span>
                {`${props?.project?.architect || tGeneral("noArchitect")}`}
              </Typography>

              <Typography fontSize="0.8rem" marginBottom={1}>
                <span style={{ fontWeight: "600" }}>
                  {tGeneral("projectEngineer")}:{" "}
                </span>
                {`${props?.project?.projectEngineer || tGeneral("noProjectEngineer")}`}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              sx={{
                height: 160,
                overflow: "hidden",
                alignContent: "center",
                borderRadius: 3,
              }}
            >
              {/* <Box component="img" src={props?.project?.image || logo} /> */}
              <img
                src={props?.project?.image || logo}
                alt="project image"
                style={{ objectFit: "cover", height: "100%", width: "100%" }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* <CardActions sx={{ justifyContent: "flex-end" }}>
        <IconButton
          size="small"
          sx={{
            position: "absolute",
            marginRight: 0.8,
            marginBottom: 9,
            backgroundColor: "#ffc42c",
            zIndex: 1,
          }}
        >
          <DownloadRoundedIcon style={{ color: "black", fontSize: 16 }} />
        </IconButton>
      </CardActions> */}
    </>
  );
}

export default ProjectCard;
