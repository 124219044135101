import * as React from "react";

import { useSelector } from "react-redux";

// Material UI Components
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

import { Delete } from "@mui/icons-material";
import { selectTheme } from "../../features/preferences/preferencesSlice";

// Custom Components
import UserAvatar from "./UserAvatar";

function BubbleAvatarDeletable(props) {
  const darkMode = useSelector(selectTheme);

  const { user } = props;
  const chipColor = props.color ? "#f7e4b2" : "transparent";
  let avatar;
  avatar = (
    <UserAvatar
      sx={{ m: 10 }}
      key={user.id}
      name={user.name}
      size="Small"
      url={user?.urlAvatar}
    />
  );

  return (
    <Box padding={props.isList ? 0 : 0.5}>
      {props.editable ? (
        <Chip
          sx={{
            width: "100%",
            height: 40,
            bgcolor: darkMode ? "" : "#f7e4b2",
            fontSize: "0.875rem",
            justifyContent: "left",
            mt: 1,
            padding: 2,
            borderRadius: "23px",
          }}
          avatar={avatar}
          label={user.name}
          deleteIcon={<Delete style={{ color: "red", marginLeft: "auto" }} />}
          onDelete={(e) => {
            if (props.byObject) {
              // For return id user
              props.setValues(user.id);
            } else {
              props.setValues(user.name);
            }
          }}
        />
      ) : (
        <Chip
          sx={{
            width: "100%",
            height: 40,
            bgcolor: chipColor,
            fontSize: "0.875rem",
            justifyContent: "space-between",
            padding: props.isList ? 0 : 2,
            borderRadius: "23px",
          }}
          avatar={avatar}
          label={user.name}
        />
      )}
    </Box>
  );
}

export default BubbleAvatarDeletable;
