import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetLocationsQuery } from "../../../features/locations/locationApiSlice";
import {
  useGetAllProjectAlbumsQuery,
  useUpdateImageMutation,
} from "../../../features/project/imageApiSlice";
import { selectCurrentProject } from "../../../features/project/projectSlice";
import { PopUpAlert } from "../../PopUpAlert";
import { LocationSelect } from "../../shared";
import { UploadPhotoLocationSelect } from "./UploadPhoto/UploadPhotoLocationSelect";

const tierLabels = {
  tier1: "Tier 1",
  tier2: "Tier 2",
  tier3: "Tier 3",
};

function MovePhoto({ info, image, ...modalProps }) {
  const { t: tPhotos } = useTranslation("photos");
  const { t: tGeneral } = useTranslation("general");
  const [selectedValue, setSelectedValue] = React.useState();
  const currentProject = useSelector(selectCurrentProject);
  const { data: albums, isLoading: isAlbumsLoading } =
    useGetAllProjectAlbumsQuery({
      projectId: currentProject?.id,
    });
  const { data: locations, isLoading: isLocationsLoading } =
    useGetLocationsQuery(currentProject?.id, {
      skip: !currentProject?.id,
      refetchOnMountOrArgChange: true,
    });

  const [updateImage, { isLoading: isUpdateLoading, isSuccess, isError }] =
    useUpdateImageMutation();

  const handleUpdateImage = async () => {
    const body = isAlbum
      ? { idAlbum: selectedValue }
      : { location: selectedValue };
    body.idProject = currentProject?.id;
    if (image.idAlbum) {
      body.idPastAlbum = image.idAlbum;
    }
    await updateImage({ id: image.id, ...body }).unwrap();
  };

  const isAlbum = info?.type === "album";

  useEffect(() => {
    if (info) {
      setSelectedValue(info.value);
    }
  }, [info]);

  React.useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        "Done!",
        isAlbum
          ? tPhotos("movePhotoAlbumSuccess")
          : tPhotos("movePhotoLocationSuccess"),
      );
      modalProps.onClose();
    }
    if (isError) {
      PopUpAlert("error", "Error!", tGeneral("errorMessage"));
      modalProps.onClose();
    }
  }, [isSuccess, isError, isAlbum, modalProps, tPhotos, tGeneral]);

  const mapMenuItems = (item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name ?? item.locationName}
    </MenuItem>
  );
  return (
    <Modal
      {...modalProps}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "570px",
          height: "auto",
          padding: "40px",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="1.5625rem" fontWeight="bold">
            {isAlbum
              ? tPhotos("movePhotoAlbumTitle")
              : tPhotos("movePhotoLocationTitle")}
          </Typography>
          <IconButton onClick={modalProps.onClose}>
            <Close />
          </IconButton>
        </Box>
        <Typography fontSize="1.375rem">
          {isAlbum
            ? tPhotos("movePhotoAlbumSubtitle")
            : tPhotos("movePhotoLocationSubtitle")}
        </Typography>
        <Divider sx={{ mt: "15px", mb: "24px" }} />
        {isAlbum ? (
          <FormControl fullWidth sx={{ mb: "20px" }}>
            <InputLabel id="album-select-label">
              {isAlbum ? tPhotos("album") : tGeneral("location")}
            </InputLabel>
            {isAlbumsLoading || isLocationsLoading ? (
              <CircularProgress color="primary" />
            ) : (
              <Select
                labelId="album-select-label"
                id="album-select"
                label={isAlbum ? tPhotos("album") : tGeneral("location")}
                defaultValue={info?.value}
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                {isAlbum
                  ? albums?.results.map(mapMenuItems)
                  : Object.keys(locations ?? {})?.map((tier) => [
                      <ListSubheader>{tierLabels[tier]}</ListSubheader>,
                      locations[tier].map(mapMenuItems),
                    ])}
              </Select>
            )}
          </FormControl>
        ) : (
          <UploadPhotoLocationSelect
            onChange={(value) => setSelectedValue(value)}
            selectedLocation={selectedValue}
          />
        )}

        <Box display="flex" justifyContent="space-around" columnGap="16px">
          <Button
            variant="contained"
            fullWidth
            sx={{
              py: "16px",
              border: "1px solid black",
              backgroundColor: "white",
              color: "#000",
              "&:hover": {
                backgroundColor: "#000",
                color: "white",
              },
            }}
            onClick={modalProps.onClose}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            onClick={handleUpdateImage}
            disabled={
              !selectedValue || isUpdateLoading || selectedValue === info?.value
            }
            variant="contained"
            fullWidth
            sx={{ py: "16px" }}
          >
            {tGeneral("confirm")}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { MovePhoto };
