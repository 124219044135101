import { tour } from "./tour";

export const dailyReports = {
  ...tour,
  moduleElement: "Relatórios",
  dailyReports: "Relatórios Diários",
  reports: "Relatórios",
  emptyReportsTitle: "Nenhum relatório criado neste momento",
  emptyReportsContent:
    "Se você deseja adicionar um, convidamos você a clicar no botão adicionar relatório para iniciar o processo",
  emptyInactiveReportsTitle: "Não há relatórios na lixeira no momento",
  emptyInactiveReportsContent:
    "Uma vez que você exclui um relatório, ele aparecerá aqui",
  addReport: "Adicionar Relatório",
  addDailyReport: "Adicionar Relatório Diário",
  editReport: "Editar Relatório",
  dueDate: "Data de Vencimento",
  newReport: "Novo relatório",
  creators: "Criadores",
  assigned: "Atribuído",
  distributionList: "Lista de Distribuição",
  private: "Privado",
  num: "Número",
  title: "Título",
  status: "Status",
  closedDate: "Data de Fechamento",
  actions: "Ações",
  detail: "Detalhe",
  exportPdf: "Exportar PDF",
  exportCvs: "Exportar CVS",
  fetchReportIssue: "Houve um problema ao carregar este relatório",
  retry: "Tentar Novamente",
  createdDate: "Data de Criação",
  atHour: "às",
  lastSeen: "Última Visualização",
  lastEdit: "Última Edição",
  approveReport: "Aprovar Relatório",
  dailyReportsRecycleBin: "Lixeira (Relatórios)",
  confirmDelete:
    "Você tem certeza de que deseja excluir o(s) relatório(s) diário(s) selecionado(s)? Ao realizar esta ação, ela não poderá ser revertida",
  successRecovered: "Relatório Diário recuperado com sucesso",
  errorRecovered: "Não é possível restaurar o Relatório Diário",
  successDeleted: "Relatório Diário excluído com sucesso",
  errorDeleted: "Não é possível excluir o Relatório Diário",
  approvedReport: "Relatório aprovado com sucesso",
  errorApprovedReport: "Não é possível aprovar o relatório",
  deleteReport: "Excluir Relatório",
  report: "Relatório",
  addDescription: "Adicionar uma descrição",
  // other
  obligatory: "Obrigatório",
  public: "Público",
  congratulations: "Parabéns!",
  rfi_title_module: "RFIs",
  no_rfis_title: "Nenhum RFI foi criado neste momento",
  no_rfis_text:
    "Se você deseja adicionar um, convidamos você a clicar no botão adicionar RFI para iniciar o processo",
  meeting: "Reunião",
  workflow: "Fluxo de Trabalho",
  guideMe: "Guie-me",
  support: "Suporte",
  days: "Dias",
  rfi_recycle_bin: "Lixeira de RFI",
  // add steps
  step1_addDR_title: "1. Adicionar relatório",
  step1_addDR_text: "Clique aqui para adicionar um novo relatório",
  step2_addDR_title: "2. Título",
  step2_addDR_text: "Escreva um título que possa descrever seu novo relatório",
  step3_addDR_title: "3. Data de Vencimento",
  step3_addDR_text:
    "Escolha uma data de vencimento como prazo para receber a aprovação do responsável",
  step4_addDR_title: "4. Status",
  step4_addDR_text1_1: "Escolha o status",
  step4_addDR_text1_2: "ABERTO para publicar o relatório como ativo.",
  step4_addDR_text2_1: "Escolha o status",
  step4_addDR_text2_2:
    "RASCUNHO para manter um rascunho do relatório sem publicá-lo.",
  step5_addDR_title: "5. Privacidade",
  step5_addDR_text_1: "Escolha o tipo de privacidade do relatório",
  step5_addDR_text_2: "Qualquer pessoa com acesso ao módulo verá seu relatório",
  step5_addDR_text_3: "Apenas os participantes poderão ver este relatório",
  step6_addDR_title: "6. Criadores",
  step6_addDR_text:
    "Escolha os usuários que terão as mesmas permissões que você",
  step7_addDR_title: "7. Responsáveis",
  step7_addDR_text: "Selecione as pessoas que aprovarão o relatório.",
  step8_addDR_title: "8. Lista de Distribuição",
  step8_addDR_text:
    "Selecione as pessoas que você deseja notificar sobre este relatório",
  step9_addDR_title: "9. Categoria",
  step9_addDR_text1_1: "Escolha a categoria",
  step9_addDR_text1_2: "Básica para criar vários relatórios.",
  step9_addDR_text2:
    "Categoria de Progresso do Trabalho para criar relatórios com medidas de progresso e custo.",
  step9_addDR_text3: "Categoria de Problema para criar apenas um relatório.",
  step9_addDR_text4:
    "Categoria de Atraso para criar um relatório com dias e horas de atraso.",
  step10_addDR_title: "10. Adicionar Novo Relatório",
  step10_addDR_text: "Clique aqui para iniciar um novo relatório",
  step11_addDR_title: "11. Selecionar a localização",
  step11_addDR_text:
    "Clique aqui para selecionar ou adicionar uma localização que será relatada",
  step12_addDR_title: "12. Descrição",
  step12_addDR_text: "Escreva a descrição do progresso do trabalho",
  step13_addDR_title: "13. Anexar fotos ou arquivos",
  step13_addDR_text: "Adicione fotos ou arquivos à descrição",
  step14_addDR_title: "14. Adicionar",
  step14_addDR_text: "Clique aqui para publicar o relatório",
  stepFinal_addDR: "Você aprendeu como criar um relatório",
  // VIEW - guide me steps
  step1_viewDR_title: "1. Visualizar Relatório",
  step1_viewDR_text: "Clique aqui para visualizar um relatório",
  step2_viewDR_title: "2. Categoria",
  step2_viewDR_text: "Visualize a categoria à qual o relatório pertence",
  step3_viewDR_title: "3. Informações Gerais do Relatório",
  step3_viewDR_text: "Mostra as informações completas deste relatório",
  step4_viewDR_title: "{{stepNumber}}. Descrição do relatório por localização",
  step4_viewDR_text: "Mostra as informações completas deste relatório e fotos",
  step5_viewDR_title: "{{stepNumber}}. Comentários",
  step5_viewDR_text: "Você pode comentar sobre as localizações relatadas",
  step7_viewDR_title: "{{stepNumber}}. Aprovar",
  step7_viewDR_text: "Aprovar o relatório",
  stepFinal_viewDR: "Você aprendeu como visualizar um relatório",
  step_view_reports_description_title: "{{stepNumber}}. Descrição",
  step_view_reports_description_text:
    "Visualize a descrição completa do relatório",
  step_view_report_progress_title: "{{stepNumber}}. Atraso e Custo",
  step_view_report_progress_text:
    "Observe as medidas de progresso e custo do relatório",
  // EDIT - guide me steps
  step1_editDR_title: "1. Editar relatório",
  step1_editDR_text: "Clique aqui para editar um relatório",
  step9_editDR_title: "{{stepNumber}}. Adicionar Novo Relatório",
  step9_editDR_text: "Clique aqui para iniciar um novo relatório",
  step10_editDR_title: "{{stepNumber}}. Editar relatórios criados",
  step10_editDR_text:
    "Você pode visualizar e editar as informações sobre o relatório",
  step11_editDR_title: "{{stepNumber}}. Arquivos e informações",
  step11_editDR_text: "Editar ou adicionar arquivos aos relatórios criados",
  step13_editDR_title: "{{stepNumber}}. Salvar",
  step13_editDR_text: "Clique aqui para salvar o relatório modificado",
  stepFinal_editDR: "Você aprendeu como editar um relatório",
  step11_edit_report_progress_title: "{{stepNumber}}. Avanço e custo",
  step11_edit_report_progress_text:
    "Editar ou adicionar as medidas de progresso e custo do relatório",
  step10_edit_report_problem: "Modificar a descrição e arquivos do relatório",
  step9_edit_report_delay_title: "9. Atraso",
  step9_edit_report_delay_text:
    "Editar o número de dias ou horas em que o relatório está atrasado",
  step10_edit_report_delay: "Modificar a descrição e arquivos do relatório",
  // RECYCLE - guide me steps
  step1_recycleDR_title: "1. Lixeira de Relatórios",
  step1_recycleDR_text: "Clique aqui para abrir a lixeira",
  step2_recycleDR_title: "2. Menu da Lixeira",
  step2_recycleDR_text: "Este é o menu da lixeira",
  step3_recycleDR_title: "3. Visualizar um relatório",
  step3_recycleDR_text: "Você pode visualizar o relatório completo",
  step4_recycleDR_title: "4. Restaurar relatório",
  step4_recycleDR_text: "Selecione um ou vários relatórios para restaurar",
  step5_recycleDR_title: "5. Restaurar relatórios selecionados",
  step5_recycleDR_text:
    "Clique aqui para restaurar os relatórios selecionados.",
  stepFinal_recycleDR: "Você aprendeu como interagir com a lixeira",
  // EXPORT - guide me steps
  step1_exportDR_title: "1. Exportar PDF",
  step1_exportDR_text: "Clique aqui para exportar o relatório em formato PDF",
  step2_exportDR_title: "2. Enviar Email",
  step2_exportDR_text: "Clique aqui para compartilhar este relatório por email",
  step3_exportDR_title: "3. Exportar CSV",
  step3_exportDR_text:
    "Selecione alguns ou filtre todos os relatórios para exportar em um arquivo CSV",
  step4_exportDR_title: "4. Exportar PDFs",
  step4_exportDR_text:
    "Selecione alguns ou filtre todos os relatórios para exportar em um arquivo PDF",
  step5_exportDR_title: "5. Tela dentro de um relatório",
  step5_exportDR_text:
    "Você pode exportar em PDF a partir da visualização detalhada",
  stepFinal_exportDR: "Você aprendeu como exportar um relatório",
  // SETTINGS - guide me steps
  step1_settingsDR_title: "2. Configurações do Relatório",
  step1_settingsDR_text: "Clique aqui para modificar as configurações",
  step2_settingsDR_title: "3. Lista de Distribuição",
  step2_settingsDR_text:
    "Modifique aqui os usuários que pertencem à lista de distribuição globalmente",
  step3_settingsDR_title: "4. Salvar configurações",
  step3_settingsDR_text: "Clique aqui para salvar as configurações",
  stepFinal_settingsDR:
    "Você aprendeu como interagir com as configurações do relatório",
  due: "Data de Vencimento",
  closed: "Fechado",
  basic: "Básico",
  workProgress: "Progresso do Trabalho",
  problem: "Problema",
  delay: "Atraso",
  advance: "Avanço",
  unit: "Unidade",
  addUnit: "Adicionar unidade",
  unitAdded: "Unidade adicionada com sucesso",
  risk: "Risco",
  highRisk: "Alto risco",
  mediumRisk: "Risco médio",
  lowRisk: "Baixo risco",
  workerName: "Nome do Trabalhador",
  jobType: "Tipo de Trabalho",
  workedHours: "Horas Trabalhadas",
  assistants: "Assistentes",
  manpower: "Mão de Obra",
  subcontractorMessage:
    "Hoje, {{date}}, compareceram um total de {{total}} pessoas. Favor verificar.",
  job: "Trabalho",
};
