export const analytics = {
  projectProgress: "Project Progress",
  hi: "Hi",
  realProgress: "Real Progress",
  welcomeBack: "Welcome Back",
  moduleStatistics: "Module Statistics",
  onTime: "On Time",
  overdue: "Overdue",
  constructionPercentage: "Construction progress",
  completed: "Completed",
  thisWeek: "This week",
  workForce: "Work force",
  hours: "Hours",
  completedTasks: "Completed tasks",
  analytics: "Analytics",
  addWidgets: "Add your widgets to display",
  projectStatistics: "Project statistics",
  rfis: "RFIs",
  tasks: "Tasks",
  submittals: "Submittals",
  drawingsProgress: "Drawings Progress",
  drawingsCount_one: "Drawing",
  drawingsCount_other: "Drawings",
  inspections: "Inspections",
  inspectionCount_one: "Inspection",
  inspectionCount_other: "Inspections",
  delayed: "Delayed",
  closed: "Closed",
  open: "open",
  pending: "Pending",
  inProgress: "In progress",
  done: "Done",
  revision: "Revision",
  total: "Total",
  selectWidget: "Select the widget of your choice",
  widgetDisplayed: "The selected widget will be displayed on your Dashboard",
  somethingWentWrong: "Something went wrong",
};
