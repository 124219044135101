import { HelpOutline, RemoveRedEye } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useGetBimsByProjectQuery } from "../../../features/project/modules/bim";
import { StyledMaterialReactTable, TooltipIconButton } from "../../shared";
import { BimViewer } from "./BimViewer";

function BimTable() {
  const { t: tGeneral } = useTranslation("general");
  const { t: tBim } = useTranslation("bim");
  const { idProject, idBim } = useParams();

  const navigate = useNavigate();

  const { data: bimData, isLoading } = useGetBimsByProjectQuery(idProject, {
    skip: !idProject,
  });

  const columns = [
    {
      header: tGeneral("name"),
      accessorKey: "name",
      Cell: ({ cell }) => <Typography>{cell.getValue()}</Typography>,
      enableSorting: false,
      size: 100,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: tGeneral("progress"),
      Header: () => (
        <Box display="flex" columnGap="5px">
          {tGeneral("progress")}{" "}
          <Tooltip title={tBim("progressHelp")}>
            <HelpOutline />
          </Tooltip>
        </Box>
      ),
      accessorKey: "progress",
      Cell: ({ cell }) => (
        <Box width="200px" height="20px" position="relative">
          <Box
            width={`${cell.getValue()}%`}
            height="20px"
            sx={{
              backgroundColor:
                Number(cell.getValue()) < 100 ? "#ffc900" : "#5ce381",
              borderRadius: "1rem",
            }}
            position="absolute"
            left={0}
            zIndex={-1}
          />
          <Typography textAlign="center">{cell.getValue()}%</Typography>
        </Box>
      ),
      enableSorting: false,
      size: 50,
    },
  ];
  return (
    <>
      <StyledMaterialReactTable
        columns={columns}
        isLoading={isLoading}
        data={bimData ?? []}
        renderRowActions={({ row }) => (
          <Box display="flex" justifyContent="center">
            <TooltipIconButton
              icon={<RemoveRedEye sx={{ color: "black" }} />}
              onClick={() => navigate(`./${row.original?.id}`)}
              label={tGeneral("view")}
              disabled={Number(row.original?.progress) < 100}
            />
          </Box>
        )}
      />
      {idBim && (
        <BimViewer isOpen={Boolean(idBim)} onClose={() => navigate(-1)} />
      )}
    </>
  );
}

export { BimTable };
