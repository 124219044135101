import {
  Add,
  ContactSupport,
  PictureAsPdf,
  Restore,
  Settings,
  Visibility,
} from "@mui/icons-material";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded"; // workflow
import GroupsIcon from "@mui/icons-material/Groups"; // meeting
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded"; // guide me
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Imagenes from "../../assets/workflow/Imagenes.png";
import Photos from "../../assets/workflow/Photos.png";
import { setCurrentGuideMe } from "../../features/project/projectSlice";
import { selectCurrentProject } from "../../features/project/projectSlice";
import { selectLocale } from "../../features/preferences/preferencesSlice";
import { useGetAllProjectImagesQuery } from "../../features/project/imageApiSlice";
import { setSampleImage } from "../../features/project/modules/rfiSlice";
import { usePhotosSteps } from "./components/steps/photos";
import { useGuideMeModal } from "./hooks/useGuideMeModal";
import { SupportContent } from "./components/SupportContent";
import { SupportListItem } from "./components/SupportListItem";
import { SupportMenu } from "./components/SupportMenu";
import { SupportMenuItemHeader } from "./components/SupportMenuItemHeader";
import { SupportMenuModalGuideMe } from "./components/modals/SupportMenuModalGuideMe";
import { SupportMenuModalMetting } from "./components/modals/SupportMenuModalMetting";
import { SupportMenuModalWorkflow } from "./components/modals/SupportMenuModalWorkflow";

function SupportButtonPhotos() {
  const dispatch = useDispatch();

  const { t: translation } = useTranslation("photos");
  const currentProject = useSelector(selectCurrentProject);
  const [recordsLength, setRecordsLength] = useState(0);
  const locale = useSelector(selectLocale);
  const [imgWorkflow] = useState(locale === "en" ? Photos : Imagenes);

  const { data: records, isSuccess } = useGetAllProjectImagesQuery({
    projectId: currentProject?.id,
  });

  const [
    {
      addPhotoSteps,
      addAlbumSteps,
      viewSteps,
      recycleSteps,
      exportSteps,
      settingsSteps,
    },
  ] = usePhotosSteps();

  // Support button (make component)
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [anchorSupp, setAnchorSupp] = useState(null);
  // For open
  const handleClick = (event) => {
    setAnchorSupp(event.currentTarget);
    setMenuIsOpen(true);
  };
  // For close
  const handleClose = () => {
    setAnchorSupp(null);
    setMenuIsOpen(false);
  };
  // End support button

  // Meeting modal
  const [meetingModalIsOpened, setMeetingModalIsOpened] = useState(false);
  const openMeetingModal = () => setMeetingModalIsOpened(true);
  const closeMeetingModal = () => setMeetingModalIsOpened(false);
  // Workflow modal
  const [workflowModalIsOpened, setWorkflowModalIsOpened] = useState(false);
  const openWorkflowModal = () => setWorkflowModalIsOpened(true);
  const closeWorkflowModal = () => setWorkflowModalIsOpened(false);
  // guide me modal
  const [guideModalIsOpened, setGuideModalIsOpened] = useState(false);
  const openGuideModal = () => setGuideModalIsOpened(true);
  const closeGuideModal = () => setGuideModalIsOpened(false);

  // Tutorial Guide Me
  const [{ handleGuideMe }] = useGuideMeModal({
    addSteps: addPhotoSteps,
    editSteps: addAlbumSteps,
    viewSteps,
    recycleSteps,
    exportSteps,
    settingsSteps,
  });

  useEffect(() => {
    if (records && isSuccess) {
      setRecordsLength(records.total);
      if (records.total > 0) {
        let firstImage = records.results[0];
        let updatedImage = Object.assign({}, firstImage, {
          src: firstImage.url,
        });
        dispatch(setSampleImage(updatedImage));
      }
    }
  }, [records, isSuccess]);

  return (
    <div>
      {/* Button */}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={translation("support")}
      >
        <IconButton
          id="support-button"
          aria-controls={menuIsOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuIsOpen ? "true" : undefined}
          sx={{ color: "#007FFF" }}
          onClick={handleClick}
        >
          <ContactSupport />
        </IconButton>
      </Tooltip>

      <SupportMenu anchor={anchorSupp} open={menuIsOpen} onClose={handleClose}>
        {/* Meeting */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openMeetingModal();
          }}
        >
          <SupportMenuItemHeader title={translation("meeting")}>
            <GroupsIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalMetting
          open={meetingModalIsOpened}
          onClose={closeMeetingModal}
        />
        {/* Workflow */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openWorkflowModal();
          }}
        >
          <SupportMenuItemHeader title={translation("workflow")}>
            <AppRegistrationRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalWorkflow
          open={workflowModalIsOpened}
          diagramImageUrl={imgWorkflow}
          onClose={closeWorkflowModal}
        />

        {/* Guide Me */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openGuideModal();
          }}
        >
          <SupportMenuItemHeader title={translation("guideMe")}>
            <TouchAppRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalGuideMe
          title={`${translation("guideMe")} ${translation("moduleElement")}`}
          open={guideModalIsOpened}
          onClose={closeGuideModal}
        >
          <SupportContent>
            {/* ADD */}
            <SupportListItem
              can="add"
              moduleName={"photos"}
              onClick={() => {
                handleGuideMe("add");
                dispatch(setCurrentGuideMe("photos-add"));
                closeGuideModal();
              }}
            >
              <Add sx={{ marginRight: 1 }} />
              {`${translation("addButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* EDIT */}
            <SupportListItem
              moduleName={"photos"}
              onClick={() => {
                handleGuideMe("edit");
                dispatch(setCurrentGuideMe("photos-edit"));
                closeGuideModal();
              }}
              disabled={recordsLength == 0}
            >
              <Add sx={{ marginRight: 1 }} />
              {`${translation("editButtonTour")}`}
            </SupportListItem>
            {/* VIEW */}
            <SupportListItem
              moduleName={"photos"}
              onClick={() => {
                handleGuideMe("view");
                dispatch(setCurrentGuideMe("photos-view"));
                closeGuideModal();
              }}
              disabled={recordsLength == 0}
            >
              <Visibility sx={{ marginRight: 1 }} />
              {`${translation("viewButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* RECYCLE BIN */}
            <SupportListItem
              moduleName={"photos"}
              onClick={() => {
                handleGuideMe("recycle");
                dispatch(setCurrentGuideMe("photos-recycle"));
                closeGuideModal();
              }}
              disabled={recordsLength == 0}
            >
              <Restore sx={{ marginRight: 1 }} />
              {`${translation("recycleButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* EXPORT */}
            <SupportListItem
              moduleName={"photos"}
              onClick={() => {
                handleGuideMe("export");
                dispatch(setCurrentGuideMe("photos-export"));
                closeGuideModal();
              }}
              disabled={recordsLength == 0}
            >
              <PictureAsPdf sx={{ marginRight: 1 }} />
              {`${translation("exportButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* SETTINGS */}
            <SupportListItem
              can="settings"
              moduleName={"photos"}
              disabled={recordsLength === 0}
              onClick={() => {
                handleGuideMe("settings");
                dispatch(setCurrentGuideMe("photos-settings"));
                closeGuideModal();
              }}
            >
              <Settings sx={{ marginRight: 1 }} />
              {`${translation("settingsButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
          </SupportContent>
        </SupportMenuModalGuideMe>
      </SupportMenu>
    </div>
  );
}

export { SupportButtonPhotos };
