// @ts-check
/* eslint react/prop-types: 0 */

import { Dialog, DialogContent, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Swal from "sweetalert2";
import { selectCurrentProject } from "../../../../features/project/projectSlice";
import TransitionComponent from "../TransitionComponent";

export default function Export(props) {
  const { client, open, setOpen } = props;

  const project = useSelector(selectCurrentProject);

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  useEffect(() => {
    const download = async () => {
      try {
        // eslint-disable-next-line react/prop-types
        const response = await client.request({
          method: "POST",
          url: "/export",
        });
        const byteString = atob(response.data);
        const byteNumbers = new Array(byteString.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < byteString.length; i++) {
          byteNumbers[i] = byteString.charCodeAt(i);
        }
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(byteNumbers)], { type: "application/octet-stream" }),
        );
        const a = document.createElement("a");
        a.download = `${project.projectName}.xlsx`;
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        const Toast = Swal.mixin({
          position: "center",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          toast: true,
        });
        Toast.fire({
          icon: "success",
          title: tProjectSchedule("Success"),
        });
      } catch (error) {
        const Toast = Swal.mixin({
          position: "center",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          toast: true,
        });
        Toast.fire({
          icon: "error",
          title: tProjectSchedule("Error"),
        });
        // eslint-disable-next-line no-console
        console.error(error);
      }
      setOpen(false);
    };

    if (open) {
      download();
    }
  }, [client, open, project.projectName, setOpen, tProjectSchedule]);

  return (
    <Dialog
      fullWidth
      onClose={() => setOpen(false)}
      open={open}
      PaperProps={{
        className: "project-schedule",
      }}
      scroll="body"
      // @ts-ignore
      TransitionComponent={TransitionComponent}
    >
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Typography
          fontSize="md"
          fontWeight="bold"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {tProjectSchedule("Export to Excel")}
        </Typography>
        <CircularProgress size={20} />
      </DialogContent>
    </Dialog>
  );
}
