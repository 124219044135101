import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
// MUI
import { Box, CircularProgress } from "@mui/material";
// Icons

// Custom imports
import { PopUpAlert } from "../../../components/PopUpAlert";
import { EmptyState, TitleWidgets } from "../../../components/shared";
import {
  useGetInactiveDailyReportsQuery,
  useRestoreDailyReportMutation,
} from "../../../features/project/modules/dailyReports/dailyReportsApiSlice";
import { setNeedReload } from "../../../features/project/modules/dailyReports/dailyReportsSlice";
import { selectCurrentProject } from "../../../features/project/projectSlice";
import { useDistributionList } from "../../../hooks";
import { DailyReportsTable } from "./components/DailyReportsTable";
import dailyReportsUtil from "./utils/dailyReports";

function DailyReportsRecycle() {
  // Local state
  const [selectedReports, setSelectedReports] = useState({});
  // Selectors
  const currentProject = useSelector(selectCurrentProject);
  // Queries & mutations
  const {
    data: dailyReports,
    isLoading: dailyReportsLoading,
    isSuccess: dailyReportsSuccess,
    refetch,
  } = useGetInactiveDailyReportsQuery(currentProject?.id);

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tGeneral } = useTranslation("general");
  const { projectUsers } = useDistributionList(currentProject?.id);

  const inactiveReports = useMemo(() => {
    if (dailyReports?.length > 0 && projectUsers) {
      return dailyReports.map((dr) => ({
        ...dr,
        assignedUsers: dr.assignedToUsers,
        dueDate: dr?.dueDate?.split("T")?.[0],
        closeDate: dr?.closeDate?.split("T")?.[0],
      }));
    }
    return [];
  }, [dailyReports, projectUsers]);

  // Search
  const [searchValue, setSearchValue] = useState("");
  const customSearchFunction = (value) => {
    setSearchValue(value);
  };

  // Handle Restore
  const [
    restoreDailyReport,
    {
      isSuccess: isRestoreDailyReportSuccess,
      isError: isRestoreDailyReportError,
      isLoading: isRestoreDailyReportLoading,
    },
  ] = useRestoreDailyReportMutation();
  useEffect(() => {
    if (isRestoreDailyReportSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        `${tDailyReports("reports")}(s) ${tGeneral("recoveredSuccessfully")}`,
      );
    }
  }, [isRestoreDailyReportSuccess]);
  useEffect(() => {
    if (isRestoreDailyReportError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  }, [isRestoreDailyReportError]);
  const handleRestore = async () => {
    await restoreDailyReport({
      ids: Object.keys(selectedReports),
    }).unwrap();
    dispatch(setNeedReload(true));
    setSelectedReports({});
    refetch();
  };

  return (
    <>
      <TitleWidgets
        title={tDailyReports("dailyReportsRecycleBin")}
        customActions={[]}
        searchEnabled
        customSearchFunction={customSearchFunction}
        backEnabled
        customBackFunctionEnabled
        customBackFunction={() => {
          dispatch(setNeedReload(true));
          navigate(-1);
        }}
        supportDisabled
        isRecycle
        restoreDisabled={!Object.keys(selectedReports).length}
        onRestore={handleRestore}
        isRestoreLoading={isRestoreDailyReportLoading}
      />

      {/* Loading */}
      {dailyReportsLoading && (
        <Box display="flex" justifyContent="center" width="100%" marginTop={10}>
          <CircularProgress size={60} />
        </Box>
      )}
      {/* No reports */}
      {dailyReportsSuccess && inactiveReports?.length === 0 && (
        <EmptyState
          title={tDailyReports("emptyInactiveReportsTitle")}
          content={tDailyReports("emptyInactiveReportsContent")}
          module="dailyReports"
        />
      )}
      {/* Reports available */}
      {dailyReportsSuccess && inactiveReports?.length > 0 && (
        <Box sx={{ width: "100%" }}>
          <DailyReportsTable
            reports={inactiveReports}
            inactive
            selectedReports={selectedReports}
            setSelectedReports={setSelectedReports}
          />
        </Box>
      )}
    </>
  );
}

export default DailyReportsRecycle;
