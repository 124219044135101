import { datadogRum } from "@datadog/browser-rum";
import { Edit, Email, Summarize } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import jsPDF from "jspdf";
import "jspdf-autotable";
import clone from "just-clone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";

import { useAbility } from "@casl/react";
import moment from "moment";
import { PopUpAlert } from "../../components/PopUpAlert";
import { InspectionsFilters } from "../../components/projectDashboard/Inspections";
import {
  AssignedUsersTooltip,
  ModalWithAction,
  StyledMaterialReactTable,
  TitleWidgets,
  TooltipIconButton,
} from "../../components/shared";
import { ModalFileViewer } from "../../components/shared/ModalFileViewer";
import SendToEmail from "../../components/shared/SendToEmail";
import { useAppTourContext } from "../../components/supportButton/context/context";
import { DISTRIBUTION_LIST_NAMES } from "../../constants";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import {
  useDeleteAllInspectionsMutation,
  useGetActiveInspectionsQuery,
  useGetInspectionsStatusMutation,
} from "../../features/project/modules/inspections/apiSlice";
import {
  selectSettingsInspectionPopupOpen,
  setCurrentInspection,
  setSampleInspection,
} from "../../features/project/modules/inspections/slice";
import {
  useGetProjectConfigurationQuery,
  useGetUserRoleQuery,
  useUpdateProjectConfigMutation,
} from "../../features/project/projectApiSlice";
import {
  selectCurrentGuideMe,
  setCurrentProjectConfig,
} from "../../features/project/projectSlice";
import { useSendToEmailMutation } from "../../features/ses/sesApiSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
import Can from "../../utils/can";
import CaslContext from "../../utils/caslContext";

function Inspections() {
  const darkMode = useSelector(selectTheme);
  const { idProject, idEnterprise } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tInspections } = useTranslation("inspections");
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tStatus } = useTranslation("status");

  function formatDate(d) {
    const months = [
      tGeneral("january"),
      tGeneral("february"),
      tGeneral("march"),
      tGeneral("april"),
      tGeneral("may"),
      tGeneral("june"),
      tGeneral("july"),
      tGeneral("august"),
      tGeneral("september"),
      tGeneral("october"),
      tGeneral("november"),
      tGeneral("december"),
    ];
    const date = new Date(d);
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  }

  const statusProps = {
    pending: {
      label: tGeneral("open_action").toUpperCase(),
      styles: {
        backgroundColor: "#5ce381",
        color: "white",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    open_past_due: {
      label: tGeneral("open_action").toUpperCase(),
      styles: {
        backgroundColor: "#e15452",
        color: "white",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    closed: {
      label: tGeneral("closed").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#c2c3c3",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    draft: {
      label: tGeneral("draft").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "orange",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    in_revision: {
      label: tGeneral("in_revision").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#03cafc",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    stillOpen: {
      label: tGeneral("still_open").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#ffc900",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    draft_closed_date: {
      label: tGeneral("draft").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#ffc900",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    notDefined: {
      label: tGeneral("not_defined").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#2196f3",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    open: {
      label: tGeneral("open").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#ffc900",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    rejected: {
      styles: {
        color: "white",
        backgroundColor: "red",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
  };

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(null);
  const settingsInspectionPopupOpen = useSelector(
    selectSettingsInspectionPopupOpen,
  );
  const { data: projectConfiguration } =
    useGetProjectConfigurationQuery(idProject);
  const [updateConfig, { isSuccess: isSuccesUpdateConfig }] =
    useUpdateProjectConfigMutation();

  const [deleteAllInspections, { isSuccess: isSuccessDeleteAll }] =
    useDeleteAllInspectionsMutation();
  const [data, setData] = useState([]);
  const currentUser = useSelector(selectCurrentUser);

  const [userList, setUserList] = useState([]);
  const [deleteIds, setDeleteIds] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [searchValue, setSearchValue] = useState(true);
  const [filters, setFilters] = useState({});

  // Filters
  const [paramsInspections, setParamsInspections] = useState(null);

  // Send by email
  const [sendingInspections, setSendingInspections] = useState(null);
  const [
    sendMail,
    {
      isLoading: isSendMailLoading,
      isSuccess: isSendEmailSuccess,
      isError: isSendEmailError,
      error: sendEmailError,
    },
  ] = useSendToEmailMutation();

  const {
    data: inspections,
    isSuccess,
    isLoading: isLoadingInspection,
  } = useGetActiveInspectionsQuery(
    {
      idProject,
      ...filters,
      location: filters?.location?.map((location) => location.id),
      ...(filters?.createdOn !== null &&
        filters?.createdOn !== undefined && {
          startDate: filters?.createdOn[0],
        }),
      ...(filters?.createdOn !== null &&
        filters?.createdOn !== undefined && { endDate: filters?.createdOn[1] }),
      createdOn: null,
    },
    {
      skip: Object.keys(filters) === 0 && !idProject,
    },
  );

  const [getInspections, { isSuccess: isSuccessInspection }] =
    useGetInspectionsStatusMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dateNow = new Date();

  const [pdfUrl, setPdfUrl] = React.useState(null);

  const getNames = (ids) => {
    return userList.filter((v) => ids && ids.includes(v.id)).map((v) => v.name);
  };

  const getUserInfo = (names) => {
    return userList
      .filter((v) => names && names.includes(v.name))
      .map((v) => {
        return {
          id: v.id,
          name: v.name,
          urlAvatar: v.urlAvatar,
        };
      });
  };

  const avatarGroup = (rowData, userList) => {
    return userList
      .filter((v) => rowData.includes(v.id))
      .map((v) => {
        return {
          id: v.id,
          name: v.name,
          urlAvatar: v.urlAvatar,
        };
      });
  };

  const graph = (inspection) => {
    const inspectionRow = inspection;
    dispatch(setCurrentInspection(inspectionRow));
    navigate(`./${inspection.id}/graph`);
  };

  const seeDetails = (inspection) => {
    const inspectionRow = inspection;
    dispatch(setCurrentInspection(inspectionRow));
    navigate(`./${inspection.id}`);
  };

  const customSearchFunction = (query) => {
    setSearchValue(query);
    const dataTemp = inspections
      .filter(function (obj) {
        return (
          obj.number === query ||
          obj.title?.toLowerCase().includes(query.toLowerCase()) ||
          query.toLowerCase().includes(obj.title?.toLowerCase())
        );
      })
      .map((inspection) => ({
        ...inspection,
        id: inspection.id,
        bPrivate: inspection.bPrivate,
        action: "all", // get user permissions
        num: inspection.number,
        deliveryDate: inspection.deliveryDate,
        closedDate: formatDate(inspection.closedDate),
        assignedUsers: avatarGroup(inspection.assignedTo, userList),
        createdDate: formatDate(inspection.createDate),
      }));
    setData(dataTemp);
  };

  const columns = [
    // Num
    {
      accessorKey: "num",
      header: "#",
      // defaultSort: "desc",
      Cell: ({ cell, row }) => `${cell.getValue()}.${row.original?.revision}`,
      size: 40,
    },

    // Title
    {
      accessorKey: "title",
      header: tGeneral("title"),
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <Typography noWrap textOverflow="ellipsis">
            {cell.getValue()}
          </Typography>
        </Tooltip>
      ),
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      size: 100,
    },

    // Status
    {
      accessorKey: "status",
      header: tGeneral("status"),
      Cell: ({ cell, row }) => (
        <Chip
          sx={
            statusProps[cell.getValue()]
              ? statusProps[cell.getValue()].styles
              : statusProps.notDefined.styles
          }
          label={tStatus(cell.getValue()).toUpperCase()}
        />
      ),
      size: 40,
    },

    // Assigned
    {
      accessorKey: "assignedToUsers",
      header: tGeneral("assigned"),
      Cell: ({ cell }) => <AssignedUsersTooltip users={cell.getValue()} />,
      size: 60,
    },

    // Delivery Date
    {
      accessorKey: "deliveryDate",
      header: tDailyReports("due"),
      Cell: ({ cell }) => (
        <Typography>
          {moment.utc(cell.getValue()).format("DD/MM/YYYY")}
        </Typography>
      ),
      size: 80,
    },

    // Close Date
    {
      accessorKey: "closedDate",
      header: tDailyReports("closed"),
      Cell: ({ cell, row }) => {
        // eslint-disable-next-line no-nested-ternary
        if (
          new Date(row.original?.deliveryDate) < dateNow &&
          row.original?.status !== "closed" &&
          !row.original?.status === "draft"
        ) {
          return (
            <Chip
              sx={[statusProps.open_past_due.styles, { width: "100px" }]}
              label={tStatus(row?.original?.status).toUpperCase()}
            />
          );
        }
        if (row.original?.status === "closed") {
          return cell.getValue();
        }
        return (
          <Chip
            sx={[
              statusProps[row.original?.status]
                ? statusProps[row.original?.status].styles
                : statusProps?.notDefined.styles,
              { width: "100px" },
            ]}
            label={tStatus(row.original?.status).toUpperCase()}
          />
        );
      },
      size: 80,
    },
  ];

  const { data: roleData, isSuccessLocations } = useGetUserRoleQuery({
    idUser: currentUser?.id,
    idProject,
  });

  useEffect(() => {
    setLoadingData(true);
    if (paramsInspections === null) {
      setParamsInspections({ idProject });
    }

    if (projectConfiguration) {
      dispatch(setCurrentProjectConfig(projectConfiguration));
    }

    if (isSuccess && inspections) {
      const dataTemp = inspections.map((inspection) => ({
        ...inspection,
        id: inspection.id,
        bPrivate: inspection.bPrivate,
        action: "all", // get user permissions
        num: inspection.number,
        deliveryDate: inspection.deliveryDate,
        closedDate: formatDate(inspection.closedDate),
        createdDate: formatDate(inspection.createDate),
      }));
      setData(dataTemp);

      // for guide me tours
      const newData = clone(dataTemp[0]);
      dispatch(setSampleInspection(newData));

      setLoadingData(false);
    }
  }, [
    isSuccess,
    inspections,
    isSuccesUpdateConfig,
    projectConfiguration,
    roleData,
  ]);

  useEffect(() => {
    if (settingsInspectionPopupOpen === true) {
      // open modal
      setOpen(true);
    } else {
      // close modal
      setOpen(false);
    }
  }, [settingsInspectionPopupOpen]);

  useEffect(() => {
    if (isSendEmailSuccess) {
      PopUpAlert("success", tGeneral("sent"), tGeneral("sentMessage"));
      setSendingInspections(null);
    }
    if (isSendEmailError) {
      PopUpAlert("error", tGeneral("error"), sendEmailError.message);
      setSendingInspections(null);
    }
  }, [isSendEmailError, isSendEmailSuccess, sendEmailError, tGeneral]);

  function showAlert(message, type) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }

  // Delete Inspection
  const deleteInspection = async (e) => {
    try {
      const body = {
        ids: Object.keys(deleteIds),
        idProject,
      };
      const response = await deleteAllInspections(body).unwrap();
      if (response) {
        setOpenDelete(false);
        setDeleteIds({});
        showAlert(tGeneral("deletedSuccessfully"), "success");
      }
    } catch (err) {
      console.error(err);
      showAlert(tGeneral("cantDelete"), "error");
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Delete - Error",
          stack: err.stack ? err.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
  };

  const handleSubmit = async (e) => {};

  const openInspectionPDF = async (e) => {
    try {
      const body = {
        idProject,
        status: "open",
      };
      const response = await getInspections(body).unwrap();
      if (response) {
        showAlert("Inspection pdf successfully", "success");
      }
    } catch (err) {
      console.error(err);
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Pdf - Error",
          stack: err.stack ? err.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
  };

  const closeInspectionPDF = async (e) => {
    try {
      const body = {
        idProject,
        status: "closed",
      };
      const response = await getInspections(body).unwrap();
      if (response) {
        showAlert("Inspection pdf successfully", "success");
      }
    } catch (err) {
      console.error(err);
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Pdf - Error",
          stack: err.stack ? err.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
  };

  const allInspectionPDF = async (e) => {
    try {
      const body = {
        idProject,
      };
      const response = await getInspections(body).unwrap();

      const newDoc = new jsPDF();
      newDoc.autoTable({
        theme: "striped",
        columns: columns.map((col) => ({ ...col, dataKey: col.field })),
        // body: (response),
        body: data,
      });

      newDoc.save("inspection_Table.pdf");

      if (response) {
        showAlert("Inspection pdf successfully", "success");
      }
    } catch (err) {
      console.error(err);
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspection pdf failed",
          stack: err.stack ? err.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
  };

  const isMobile = useMediaQuery("(max-width:820px)");

  const handleSendEmailClick = (rowData) => {
    setSendingInspections([rowData]);
  };

  const handleSendEmail = async (
    selectedInspection,
    message,
    subject,
    recipients,
    cc,
  ) => {
    const promises = selectedInspection.map(async (inspection) => {
      // const pdfLink = await exportInspection({ inspection });
      // return pdfLink.data;
    });

    const links = await Promise.all(promises);
    const body = {
      message,
      subject,
      pdfLink: links.join("\r"),
    };
    if (recipients?.length > 0) {
      body.recipients = recipients;
    }
    if (cc.length > 0) {
      body.cc = cc;
    }
    sendMail(body);
  };

  const ability = useAbility(CaslContext);

  const {
    state: { tourActive, stepIndex, run },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  useEffect(() => {
    if (tourActive && stepIndex === 0 && !run) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, run, currentGuideMe]);

  return (
    <>
      <TitleWidgets
        backEnabled={false}
        customSearchFunction={customSearchFunction}
        moduleName="inspections"
        searchEnabled
        supportDisabled
        title={tInspections("title")}
        showDelete={
          Object.keys(deleteIds).length > 0 &&
          ability.can("delete", "inspections")
        }
        onDelete={() => setOpenDelete(true)}
        customActions={[
          {
            label: `${tGeneral("templates")}`,
            icon: <DescriptionIcon />,
            onClick: () => {
              navigate("../inspections/templates");
            },
            dataTour: "add-inspection-templates-2",
          },
        ]}
        addFunction={() => {
          dispatch(setCurrentInspection(null));
          navigate("../inspections/form");
        }}
        distributionListName={DISTRIBUTION_LIST_NAMES.inspections}
        enableSupport
        addNameTour="add-inspection-1"
        showExports={false}
      />
      <Can I="view" a="inspections">
        <Box>
          <>
            <StyledMaterialReactTable
              enableRowSelection
              isLoading={loadingData || isLoadingInspection}
              filtersComponent={
                <InspectionsFilters
                  filters={filters}
                  setFilters={(val) => {
                    setFilters(val);
                  }}
                />
              }
              selectedRows={deleteIds}
              setSelectedRows={setDeleteIds}
              renderRowActions={({ row }) => (
                <Can I="view" a="inspections">
                  {row.original?.status !== "draft" ? (
                    <ButtonGroup
                      sx={{ width: "100%", justifyContent: "space-around" }}
                    >
                      {(ability.can("superAdmin", "inspections") ||
                        (ability.can("edit", "inspections") &&
                          row.original.creators.indexOf(currentUser.id) >
                            -1)) && (
                        <TooltipIconButton
                          color="black"
                          icon={<Edit />}
                          label={tGeneral("edit")}
                          disabled={
                            row?.original?.status === "open" ||
                            (ability.cannot("superAdmin", "inspections") &&
                              row.original.creators.indexOf(currentUser.id) < 0)
                          }
                          onClick={() => {
                            const inspectionRow = row.original;
                            inspectionRow.assignedTo = getNames(
                              row.original?.assignedTo,
                            );
                            inspectionRow.distributionList = getNames(
                              row.original?.distributionList,
                            );
                            inspectionRow.assignedInfo = getUserInfo(
                              row.original?.assignedTo,
                            );
                            inspectionRow.distributionInfo = getUserInfo(
                              row.original?.distributionList,
                            );
                            dispatch(setCurrentInspection(inspectionRow));
                            navigate("../inspections/form");
                          }}
                        />
                      )}
                      <Tooltip title={tGeneral("history")}>
                        <IconButton
                          type="submit"
                          size="small"
                          onClick={() => graph(row.original)}
                          sx={{
                            color: darkMode ? "white" : "black",
                            ":hover": {
                              color: "#FBCB04",
                            },
                            m: [0, 0, 0, 0],
                          }}
                        >
                          <Summarize />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={tGeneral("seeDetails")}>
                        <IconButton
                          type="submit"
                          size="small"
                          onClick={() => {
                            seeDetails(row.original);
                          }}
                          sx={{
                            color: darkMode ? "white" : "black",
                            ":hover": {
                              color: "#FBCB04",
                            },
                            m: [0, 0, 0, 0],
                          }}
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={tGeneral("email")}>
                        <IconButton
                          type="submit"
                          size="small"
                          onClick={() => {
                            handleSendEmailClick(row.original);
                          }}
                          sx={{
                            color: darkMode ? "white" : "black",
                            ":hover": {
                              color: "#FBCB04",
                            },
                            m: [0, 0, 0, 0],
                          }}
                        >
                          <Email />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  ) : (
                    <Button
                      sx={{
                        width: "90%",
                        color: "black",
                        backgroundColor: "white",
                        borderColor: "black",
                        border: 1,
                        fontSize: "1.125rem",
                        ":hover": {
                          color: "white",
                          backgroundColor: "#b4b4b4",
                        },
                        padding: "12px 0",
                      }}
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        const inspectionRow = row.original;
                        inspectionRow.assignedTo = getNames(
                          row.original?.assignedTo,
                        );
                        inspectionRow.distributionList = getNames(
                          row.original?.distributionList,
                        );
                        inspectionRow.assignedInfo = getUserInfo(
                          row.original?.assignedTo,
                        );
                        inspectionRow.distributionInfo = getUserInfo(
                          row.original?.distributionList,
                        );
                        dispatch(setCurrentInspection(inspectionRow));
                        navigate("../inspections/form");
                      }}
                    >
                      {tGeneral("edit").toUpperCase()}
                    </Button>
                  )}
                </Can>
              )}
              // column definition
              columns={columns}
              // actual data
              data={data}
              emptyStateMessage={tGeneral("noElements", {
                moduleName: tInspections("title").toLowerCase(),
              })}
            />
            <ModalWithAction
              title={tGeneral("delete")}
              content={tInspections("deleteInspection")}
              actionLabel={tGeneral("delete")}
              onClose={() => setOpenDelete(null)}
              isOpen={openDelete}
              action={deleteInspection}
            />
          </>
        </Box>
      </Can>
      <Can I="none" a="inspections">
        {tGeneral("noPermission")}
      </Can>
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
      {Boolean(sendingInspections) && (
        <SendToEmail
          onClose={() => setSendingInspections(null)}
          isOpen={Boolean(sendingInspections)}
          title={
            sendingInspections?.length > 1
              ? `${tGeneral("inspections")} (${sendingInspections?.length})`
              : sendingInspections[0]?.title
          }
          onSend={(message, subject, recipients, cc) => {
            handleSendEmail(
              sendingInspections,
              message,
              subject,
              recipients,
              cc,
            );
          }}
          isLoading={isSendMailLoading}
        />
      )}
    </>
  );
}

export default Inspections;
