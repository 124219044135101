import React, { forwardRef } from "react";
// MUI Components
import { Box, IconButton } from "@mui/material";
import { useState } from "react";
//instalar materialTable https://material-table.com/#/docs/install
import {
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@mui/icons-material";
import MaterialTable from "material-table";

//graficas
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { useSelector } from "react-redux";
import {
  useGetUsersFromEnterpriseQuery,
  useGetTutorialsQuery,
} from "../../features/enterprise/enterpriseApiSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
import { selectCurrentEnterprise } from "../../features/enterprise/enterpriseSlice";

// Validators
// Alert
import { useTour } from "@reactour/tour";
import { KpiColumns, KpiSteps } from "../../constants/kpi.js";
import { useTranslation } from "react-i18next";

function Kpi() {
  const { t: tGeneral } = useTranslation("general");

  const currentUser = useSelector(selectCurrentUser);
  const currentEnterprise = useSelector(selectCurrentEnterprise);

  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  let dataBody = null;

  const { setCurrentStep, currentStep, isOpen, setSteps, setIsOpen } =
    useTour();

  const { data: enterprisesUsers, isLoading } = useGetUsersFromEnterpriseQuery({
    idEnterprise: currentEnterprise.id,
  });

  const { data: KpiTutorial } = useGetTutorialsQuery(currentUser.id);

  React.useEffect(() => {
    if (enterprisesUsers) {
      setData(
        enterprisesUsers.map((user) => ({
          id: user.id,
          idUser: user.idUser,
          lastName: user.lastName,
          firstName: user.firstName,
          email: user.email,
          enterpriseName: user.enterpriseName,
          phone: "test",
        })),
      );
    }

    if (KpiTutorial) {
      // if (isSuccess) {
      KpiTutorial.Kpi ? setIsOpen(false) : setIsOpen(true);
    }
  }, [enterprisesUsers, KpiTutorial]);

  React.useLayoutEffect(() => {
    setSteps(KpiSteps);
  }, [setSteps]);

  //para que se visualicen los iconos https://material-table.com/#/docs/install leer documentacion
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Export: forwardRef((props, ref) => (
      <IconButton
        className="Kpi-export"
        onClick={() => {
          if (isOpen) {
            setTimeout(() => {
              setCurrentStep(currentStep + 1);
            }, 500);
          }
        }}
      >
        <SaveAlt {...props} ref={ref} />
      </IconButton>
    )),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Inactivity",
      },
    },
    layout: {
      padding: 20,
    },
  };

  const labels = [
    "Enterprise 1",
    "Enterprise 2",
    "Enterprise 3",
    "Enterprise 4",
    "Enterprise 5",
    "Enterprise 6",
    "Enterprise 7",
    "Enterprise 8",
    "Enterprise 9",
    "Enterprise 10",
    "Enterprise 11",
    "Enterprise 12",
    "Enterprise 13",
    "Enterprise 14",
    "Enterprise 15",
    "Enterprise 16",
    "Enterprise 17",
    "Enterprise 18",
    "Enterprise 19",
    "Enterprise 20",
    "Enterprise 21",
    "Enterprise 22",
    "Enterprise 23",
    "Enterprise 24",
    "Enterprise 25",
    "Enterprise 26",
    "Enterprise 27",
    "Enterprise 28",
    "Enterprise 29",
    "Enterprise 30",
    "Enterprise 31",
    "Enterprise 32",
    "Enterprise 33",
    "Enterprise 34",
    "Enterprise 35",
    "Enterprise 36",
    "Enterprise 37",
    "Enterprise 38",
    "Enterprise 39",
    "Enterprise 40",
    "Enterprise 41",
    "Enterprise 42",
    "Enterprise 43",
    "Enterprise 44",
    "Enterprise 45",
    "Enterprise 46",
    "Enterprise 47",
    "Enterprise 48",
    "Enterprise 49",
    "Enterprise 50",
  ];

  const data_ = {
    labels,
    datasets: [
      {
        label: "actual period",
        data: labels.map(() => Math.floor(Math.random() * (1000 - 0 + 1)) + 0),
        backgroundColor: "rgba(0, 0, 128, 0.7)",
      },
      {
        label: "last period",
        data: labels.map(() => Math.floor(Math.random() * (1000 - 0 + 1)) + 0),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  return (
    <Box sx={{ height: 500, width: "100%" }} className="Kpi-table">
      <MaterialTable
        icons={tableIcons}
        columns={KpiColumns}
        data={data}
        localization={tableLocalization}
        title="Kpi"
        options={{
          actionsColumnIndex: -1,
          sorting: true,
          exportButton: true,
        }}
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection
        disableSelectionOnClick
        isLoading={isLoading}
      />
      <div>
        <Bar options={options} data={data_} />;
      </div>
    </Box>
  );
}

export default Kpi;
