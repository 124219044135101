import { Check, Save } from "@mui/icons-material";
import { Box, Checkbox, TextField } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { PopUpAlert } from "../../components/PopUpAlert";
import {
  StyledMaterialReactTable,
  TooltipIconButton,
} from "../../components/shared";
import { TitleWidgets } from "../../components/shared/TitleWidgets";
import {
  useAddRoleMutation,
  useGetRoleQuery,
  useUpdateRoleMutation,
} from "../../features/enterprise/role";

function AddRoles() {
  const { t: tGeneral } = useTranslation("general");
  const { idRole, idEnterprise } = useParams();

  const navigate = useNavigate();

  const [
    addRole,
    {
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      isError: isAddError,
      error: addError,
    },
  ] = useAddRoleMutation();
  const [
    updateRole,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateRoleMutation();

  const [name, setName] = useState("");

  const [role, setRole] = useState({
    adminPanel: 0,
    announcements: 0,
    bim: 0,
    dailyReports: 0,
    dashboard: 0,
    directory: 0,
    documents: 0,
    drawings: 0,
    inspections: 0,
    meetings: 0,
    photos: 0,
    projectNetwork: 0,
    projectSchedule: 0,
    rfis: 0,
    submittals: 0,
    tasks: 0,
  });

  const handleChange = (key, value) => {
    setRole((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      header: tGeneral("module"),
      accessorKey: "key",
      Cell: ({ cell }) => tGeneral(cell.getValue()),
      size: 100,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: tGeneral("superAdmin"),
      accessorKey: "value",
      Cell: ({ cell, row }) => (
        <Checkbox
          checked={cell.getValue() === 3}
          onChange={() => handleChange(row.original.key, 3)}
        />
      ),
      size: 50,
    },
    {
      header: tGeneral("admin"),
      accessorKey: "value",
      Cell: ({ cell, row }) => (
        <Checkbox
          checked={cell.getValue() === 2}
          onChange={() => handleChange(row.original.key, 2)}
        />
      ),
      size: 50,
    },
    {
      header: tGeneral("viewOnly"),
      accessorKey: "value",
      Cell: ({ cell, row }) => (
        <Checkbox
          checked={cell.getValue() === 1}
          onChange={() => handleChange(row.original.key, 1)}
        />
      ),
      size: 50,
    },
    {
      header: tGeneral("none"),
      accessorKey: "value",
      Cell: ({ cell, row }) => (
        <Checkbox
          checked={cell.getValue() === 0}
          onChange={() => handleChange(row.original.key, 0)}
        />
      ),
      size: 50,
    },
  ];

  const tableData = useMemo(() => {
    return Object.keys(role).map((key) => ({
      key,
      value: role[key],
    }));
  }, [role]);

  const { data } = useGetRoleQuery(idRole, { skip: !idRole });

  useEffect(() => {
    if (data) {
      const { id, name, ...rest } = data;
      setRole(rest);
      setName(name);
    }
  }, [data]);

  const handleSubmitRole = () => {
    if (idRole) {
      updateRole({ idRole, name, ...role });
    } else {
      addRole({ name, idEnterprise, ...role });
    }
  };

  useEffect(() => {
    if (isAddSuccess) {
      PopUpAlert(
        "success",
        tGeneral("role"),
        tGeneral("roleCreatedSuccessfully"),
      );
      navigate("./../roles");
    }
    if (isAddError) {
      PopUpAlert("error", tGeneral("error"), addError.message);
    }
  }, [isAddSuccess, isAddError, addError, tGeneral]);

  useEffect(() => {
    if (isUpdateSuccess) {
      PopUpAlert(
        "success",
        tGeneral("role"),
        tGeneral("roleEditedSuccessfully"),
      );
      navigate("./../../roles");
    }
    if (isUpdateError) {
      PopUpAlert("error", tGeneral("error"), updateError.message);
    }
  }, [isUpdateSuccess, isUpdateError, updateError, tGeneral]);

  const isAll = useCallback(
    (value) => {
      return Object.values(role).every((v) => v === value);
    },
    [role],
  );

  const handleChangeAll = (value, checked) => {
    const newKeys = Object.keys(role);
    if (!checked) {
      setRole(
        newKeys.reduce((acc, key) => {
          acc[key] = 0;
          return acc;
        }, {}),
      );
    } else {
      setRole(
        newKeys.reduce((acc, key) => {
          acc[key] = value;
          return acc;
        }, {}),
      );
    }
  };

  return (
    <Box>
      <TitleWidgets
        title={idRole ? tGeneral("editRole") : tGeneral("addRole")}
        showMore
        showAdd={false}
        backEnabled
        showMoreOptions={false}
        customElement={
          <Box display="flex">
            <TooltipIconButton
              label={idRole ? tGeneral("save") : tGeneral("add")}
              icon={idRole ? <Save /> : <Check />}
              onClick={handleSubmitRole}
              isLoading={isAddLoading || isUpdateLoading}
              disabled={isAddLoading || isUpdateLoading}
            />
          </Box>
        }
      />
      <Box p="1rem 1rem 0 1rem">
        <TextField
          label={tGeneral("name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          size="small"
          sx={{ width: "30%" }}
        />
        <Box display="flex">
          <Box flex={2} />
          <Box flex={1} textAlign="center">
            <Checkbox
              checked={isAll(3)}
              onChange={(e) => handleChangeAll(3, e.target.checked)}
            />
          </Box>
          <Box flex={1} textAlign="center">
            <Checkbox
              checked={isAll(2)}
              onChange={(e) => handleChangeAll(2, e.target.checked)}
            />
          </Box>
          <Box flex={1} textAlign="center">
            <Checkbox
              checked={isAll(1)}
              onChange={(e) => handleChangeAll(1, e.target.checked)}
            />
          </Box>
          <Box flex={1} textAlign="center">
            <Checkbox
              checked={isAll(0)}
              onChange={(e) => handleChangeAll(0, e.target.checked)}
            />
          </Box>
        </Box>
        <StyledMaterialReactTable
          data={tableData}
          columns={columns}
          enableRowActions={false}
          enablePagination={false}
        />
      </Box>
    </Box>
  );
}

export { AddRoles };
