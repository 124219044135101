import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import {
  Add,
  CircleSharp,
  Close,
  DescriptionOutlined,
  InfoOutlined,
  Lock,
  LockOpen,
  Subject,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Switch,
  TextField,
  Typography,
  Select,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { PopUpAlert } from "../../components/PopUpAlert";
import {
  CreateFormUsersSide,
  FilePreviewHorizontalScroll,
  FormHeader,
} from "../../components/shared";
import { DISTRIBUTION_LIST_NAMES } from "../../constants";
import { useGetDrawingsListQuery } from "../../features/drawings";
import { useUploadFilesMutation } from "../../features/files";
import {
  useCreateRfiMutation,
  useEditRfiMutation,
  useGetRfiByIdQuery,
} from "../../features/project/modules/rfiApiSlice";
import {
  useGetProjectConfigRfiQuery,
  useGetProjectUsersQuery,
} from "../../features/project/projectApiSlice";
import { useGetSpecificationsQuery } from "../../features/project/specificationApiSlice";
import { SpecificationModal } from "../../components/shared/SpecificationSelect/SpecificationModal";
import { useAppTourContext } from "../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../features/project/projectSlice";
import { useGetProjectDirectoryUsersQuery } from "../../features/project/modules/directory/projectDirectoryApiSlice";

const label = { inputProps: { "aria-label": "Color switch demo" } };

const rfiDaysName = "rfi_delay_days";

function RfiForm({ isModal, onCreate, onClose, idDrawing }) {
  // Translations
  const { t: tRfis } = useTranslation("rfis");
  const { t: tGeneral } = useTranslation("general");
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const navigate = useNavigate();

  const [addSpecification, setAddSpecification] = useState(false);

  const { idRfi, idProject } = useParams();
  const { data: rfi } = useGetRfiByIdQuery(idRfi, {
    skip: !idRfi,
  });

  const { data: drawings } = useGetDrawingsListQuery(idProject);

  const { data: configData } = useGetProjectConfigRfiQuery({
    idProject,
    distributionListName: DISTRIBUTION_LIST_NAMES.rfis,
    rfiDaysName,
  });

  const [
    createRfi,
    {
      data: createdRfi,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateRfiMutation();
  const [uploadFiles, { isLoading: isFilesLoading }] = useUploadFilesMutation();
  const [
    updateRfi,
    {
      isLaoding: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useEditRfiMutation();

  const { data: specificationsData } = useGetSpecificationsQuery(idProject);

  const { data: projectUsersData } = useGetProjectDirectoryUsersQuery(idProject)
  const validationSchema = yup.object({
    title: yup.string("Enter a valid title").required("This field is required"),
    dueDate: yup
      .string("Enter a valid date")
      .required("This field is required"),
    assignedTo: yup.array().of(yup.string()).min(1, "This field is required"),
    question: yup
      .string("Enter a valid title")
      .required("This field is required"),
  });
  const formContext = useForm({
    defaultValues: {
      bPrivate: false,
      title: "",
      dueDate: null,
      assignedTo: [],
      company: "",
      drawing: "",
      question: "",
      files: [],
      distributionList: [],
      delayHours: null,
      delayDays: null,
      cost: null,
      currency: null,
      specification: null,
      status: "pending",
      receivedFrom: "",
    },
    disabled: isCreateLoading || isUpdateLoading || isFilesLoading,
    resolver: yupResolver(validationSchema),
  });

  const { register, getValues, control, watch, setValue, handleSubmit, reset } =
    formContext;

  const projectUsers = useMemo(
    () => (projectUsersData ? projectUsersData : []),
    [projectUsersData],
  );

  const specifications = useMemo(
    () => specificationsData ?? [],
    [specificationsData],
  );

  useEffect(() => {
    if (isUpdateSuccess) {
      PopUpAlert(
        "success",
        tGeneral("updated"),
        tGeneral("updatedSuccessfully"),
      );

      navigate("../../rfi");
    }
    if (isUpdateError) {
      PopUpAlert("error", "Error", updateError.message);
    }
  }, [isUpdateSuccess, isUpdateError, updateError, tGeneral, navigate]);

  useEffect(() => {
    if (isCreateSuccess && createdRfi) {
      PopUpAlert(
        "success",
        tGeneral("created"),
        tGeneral("createdSuccessfully"),
      );
      if (isModal) {
        onCreate(createdRfi.id);
        onClose();
      } else {
        navigate("../rfi");
      }
    }
    if (isCreateError) {
      PopUpAlert("error", "Error", createError.message);
    }
  }, [
    isCreateSuccess,
    isCreateError,
    createError,
    tGeneral,
    navigate,
    createdRfi,
    isModal,
    onCreate,
    onClose,
  ]);

  useEffect(() => {
    if (configData && !idRfi) {
      setValue(
        "distributionList",
        configData.users.map((user) => user.id),
      );
      const newDate = moment(new Date())
        .add(configData.delayDays, "days")
        .startOf("day");
      setValue("dueDate", newDate);
    }
  }, [configData, idRfi, setValue]);

  useEffect(() => {
    if (rfi) {
      const {
        assignedTo,
        distributionList,
        question: { question, files },
        dueDate,
        bPrivate,
        specification,
        status,
        cost,
        currency,
        delayDays,
        delayHours,
        title,
        receivedFrom,
        drawing,
      } = rfi;
      reset({
        assignedTo: assignedTo.map((user) => user.id),
        distributionList: distributionList.map((user) => user.id),
        question,
        files,
        dueDate: moment(dueDate).utc(),
        bPrivate,
        specification: specification ? specification : "",
        status,
        cost: cost > 0 ? cost : null,
        currency,
        delayDays: delayDays > 0 ? delayDays : null,
        delayHours: delayHours > 0 ? delayHours : null,
        title,
        receivedFrom: receivedFrom ? receivedFrom.id : "",
        drawing: drawing.length > 0 ? drawing[0].id : "",
      });
    }
  }, [rfi, reset]);
  const [deletedFiles, setDeletedFiles] = useState([]);

  useEffect(() => {
    if (idDrawing && drawings) {
      setValue("drawing", idDrawing, { shouldValidate: true });
    }
  }, [idDrawing, drawings, setValue]);

  const statusOptions = [
    {
      value: "pending",
      label: tGeneral("open_action").toUpperCase(),
      color: "#5ce381",
    },
    {
      value: "draft",
      label: tGeneral("draft").toUpperCase(),
      color: "#ffc900",
    },
    {
      value: "closed",
      label: tGeneral("closed").toUpperCase(),
      color: "#707070",
    },
  ];

  const onSubmit = async ({ files, ...formData }) => {
    if (formData.assignedTo.length === 0) return;
    const body = {
      ...formData,
      idProject,
      bDraft: formData.status === "draft",
    };
    if (files.length > 0) {
      const idQuestion = idRfi ? rfi.question.id : uuidv4();
      const filesFormData = new FormData();
      files.forEach((file) => {
        filesFormData.append("files", file);
      });
      await uploadFiles({
        idObject: idQuestion,
        moduleName: "rfis",
        body: filesFormData,
      });
      body.idQuestion = idQuestion;
    }
    if (idRfi) {
      body.id = idRfi;
      body.deletedFiles = deletedFiles;
      body.idQuestion = rfi.question.id;
      body.idChat = rfi.question.id;
      updateRfi(body);
    } else {
      createRfi(body);
    }
  };

  const handleDeleteQuestionFile = (file, i) => {
    const files = getValues("files");
    if (file.id) {
      setDeletedFiles([...deletedFiles, file.id]);
    }
    files.splice(i, 1);
    setValue("files", [...files]);
  };

  const bPrivate = watch("bPrivate");
  const files = watch("files");
  const price = watch("price");
  const currency = watch("currency");
  const delayHours = watch("delayHours");
  const delayDays = watch("delayDays");

  const handleAddFiles = (newFiles) => setValue("files", newFiles);

  const handleToggleCost = (e) => {
    const {
      target: { checked },
    } = e;
    if (checked) {
      setValue("price", 0);
      setValue("currency", "mxn");
    } else {
      setValue("price", null);
      setValue("currency", null);
    }
  };

  const handleToggleDelay = (e) => {
    const {
      target: { checked },
    } = e;

    if (checked) {
      setValue("delayDays", 0);
      setValue("delayHours", 0);
    } else {
      setValue("delayDays", null);
      setValue("delayHours", null);
    }
  };

  const bCost = price !== null && currency !== null;
  const bDelay = delayHours !== null && delayDays !== null;

  const {
    setState,
    state: { tourActive, stepIndex },
  } = useAppTourContext();

  /* useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setState({ run: true, stepIndex: 1 });
      }, 500);
    }
  }); */

  /* useEffect(() => {
    return () => {
      setState({ run: false, tourActive: false, stepIndex: 0 });
    };
  }, []); */

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 1 &&
      (currentGuideMe === "rfis-add" || currentGuideMe === "rfi-edit")
    ) {
      setState({ run: true });
    }
  }, [tourActive, stepIndex, currentGuideMe]);

  const getSelectedReceivedFrom = (idUser) => {
    const selectedUser = projectUsers.find((user) => user.id === idUser);
    if (!selectedUser) return "";

    return (
      <Box display="flex" flexDirection="row" alignItems="center" sx={{ maxHeight: "20px" }}>
        <Avatar src={selectedUser.urlAvatar} sx={{ width: "30px", height: "30px", fontSize: 14 }}>
          {selectedUser.firstName[0]}
          {selectedUser.lastName[0]}
        </Avatar>
        <Typography ml="10px">
          {selectedUser.firstName} {selectedUser.lastName}
        </Typography>
      </Box>
    );
  };

  return (
    <FormProvider {...formContext}>
      <Box width="100%" component="form" onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          onBack={() => {
            if (isModal) {
              onClose();
            } else {
              navigate(idRfi ? "./../.." : "./..");
            }
          }}
          isEditing={Boolean(idRfi)}
          isSubmit
          title={idRfi ? `${tGeneral("edit")} RFI` : `${tGeneral("add")} RFI`}
          dataTourNameButton="add-rfi-15"
          backIcon={isModal ? <Close /> : null}
        />
        <Box
          display="flex"
          width="100%"
          columnGap="10px"
          height="88vh"
          p="10px"
          overflow="hidden"
        >
          <Box
            flex={2}
            display="flex"
            flexDirection="column"
            rowGap="10px"
            pt="10px"
            overflow="hidden"
          >
            <Box display="flex" columnGap="10px">
              <Controller
                control={control}
                name="title"
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    label={tGeneral("title")}
                    placeholder={tGeneral("title")}
                    {...field}
                    data-tour="add-rfi-2"
                  />
                )}
              />
              <Box data-tour="add-rfi-3">
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="dueDate"
                  render={({ field }) => (
                    <DatePicker
                      label={tGeneral("due_date")}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          required
                          label={tGeneral("due_date")}
                          {...params}
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box display="flex" columnGap="10px">
              <FormControl fullWidth>
                <InputLabel>{tGeneral("status")}</InputLabel>
                <Select
                  placeholder={tGeneral("status")}
                  label={tGeneral("status")}
                  {...register("status")}
                  value={watch("status")}
                  data-tour="add-rfi-4"
                  MenuProps={{
                    disableScrollLock: true,
                    MenuListProps: {
                      "data-tour": "highlighted-add-rfi-4",
                    },
                  }}
                >
                  {statusOptions
                    .filter(({ value }) => idRfi || value !== "closed")
                    .map(({ value, label, color }) => (
                      <MenuItem value={value} key={value}>
                        <Box display="flex" alignItems="center">
                          <CircleSharp sx={{ color }} />
                          {label}
                        </Box>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Card sx={{ width: "100%", height: "100%" }}>
                <CardActions
                  sx={{ justifyContent: "space-between" }}
                  data-tour="add-rfi-5"
                >
                  <Box>
                    <IconButton title="Back">
                      {bPrivate ? <Lock /> : <LockOpen />}
                    </IconButton>
                    {bPrivate ? tGeneral("private") : tGeneral("public")}
                  </Box>
                  <Switch
                    {...label}
                    {...register("bPrivate")}
                    color="primary"
                  />
                </CardActions>
              </Card>
            </Box>
            <Divider />
            <Box
              flex={1}
              sx={{ overflowY: "auto", width: "100%", overflowX: "hidden" }}
            >
              <Box data-tour="add-rfi-7">
                <Box
                  sx={{ alignItems: "center", display: "flex", mb: "10px" }}
                  columnGap="10px"
                >
                  <Subject />
                  {tGeneral("question")}
                </Box>
                <Controller
                  name="question"
                  fullWidth
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      rows={6}
                      fullWidth
                      multiline
                      required
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box data-tour="add-rfi-8">
                <Box
                  display="flex"
                  alignItems="center"
                  mt="10px"
                  columnGap="10px"
                >
                  <DescriptionOutlined />
                  <Typography>{tGeneral("files")}</Typography>
                </Box>
                <FilePreviewHorizontalScroll
                  onAddPhotos={handleAddFiles}
                  onDelete={handleDeleteQuestionFile}
                  files={files}
                />
              </Box>
              <Divider />
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                  p: "5px",
                  height: "auto",
                }}
                data-tour="edit-rfi-8"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  mt="10px"
                  columnGap="10px"
                >
                  <InfoOutlined />
                  <Typography>{tGeneral("additionalInformation")}</Typography>
                </Box>
                <Box display="flex" columnGap="10px">
                  <FormControl fullWidth data-tour="add-rfi-9">
                    <InputLabel>{tGeneral("receivedFrom")}</InputLabel>
                    <Select
                      label={tGeneral("receivedFrom")}
                      {...register("receivedFrom")}
                      value={watch("receivedFrom")}
                      MenuProps={{ sx: { maxHeight: "300px" } }}
                      renderValue={getSelectedReceivedFrom}
                    >
                      {projectUsers.map((user) => (
                        <MenuItem
                          value={user.id}
                          key={user.id}
                          sx={{ display: "flex" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (user.id === watch("receivedFrom")) {
                              setValue("receivedFrom", "");
                            } else {
                              setValue("receivedFrom", user.id);
                            }
                          }}
                        >
                          <Avatar src={user.urlAvatar}>
                            {user.firstName[0]}
                            {user.lastName[0]}
                          </Avatar>
                          <Typography ml="10px">
                            {user.firstName} {user.lastName}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    disabled
                    label={tGeneral("company")}
                    placeholder={tGeneral("company")}
                    value={
                      watch("receivedFrom")
                        ? projectUsers.find(
                            (projectUser) => projectUser.id === watch("receivedFrom"),
                          )?.companyName
                        : ""
                    }
                  />
                </Box>
                <Box display="flex" columnGap="10px">
                  <FormControl fullWidth data-tour="add-rfi-10">
                    <InputLabel>{tGeneral("specification")}</InputLabel>
                    <Select
                      label={tGeneral("specification")}
                      {...register("specification")}
                      value={watch("specification")}
                      MenuProps={{ sx: { maxHeight: "300px" } }}
                    >
                      {specifications.map((spec) => (
                        <MenuItem
                          value={spec.id}
                          key={spec.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (spec.id === watch("specification")) {
                              setValue("specification", "");
                            } else {
                              setValue("specification", spec.id);
                            }
                          }}
                        >
                          {spec.name}
                        </MenuItem>
                      ))}
                      <Box display="flex">
                        <Button
                          variant="text"
                          startIcon={<Add />}
                          fullWidth
                          style={{
                            justifyContent: "flex-start",
                            paddingLeft: "3%",
                            color: "black",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setAddSpecification(true);
                          }}
                        >
                          {tGeneral("addSpecification")}
                        </Button>
                      </Box>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth data-tour="add-rfi-11">
                    <InputLabel># {tGeneral("drawing")}</InputLabel>
                    <Select
                      label={`# ${tGeneral("drawing")}`}
                      {...register("drawing")}
                      value={watch("drawing")}
                      MenuProps={{ sx: { maxHeight: "300px" } }}
                    >
                      {drawings?.map((drawing) => (
                        <MenuItem
                          value={drawing.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (drawing.id === watch("drawing")) {
                              setValue("drawing", "");
                            } else {
                              setValue("drawing", drawing.id);
                            }
                          }}
                        >
                          {drawing.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" columnGap="10px">
                  <Card
                    sx={{ height: bCost ? "auto" : "52px", flex: 1, p: "5px" }}
                    data-tour="add-rfi-12"
                  >
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={bCost}
                            onChange={handleToggleCost}
                          />
                        }
                        label={tGeneral("cost")}
                      />
                    </Box>
                    <Box display="flex" columnGap="10px">
                      {bCost && (
                        <Controller
                          control={control}
                          name="cost"
                          rules={{ min: 0 }}
                          render={({ field }) => (
                            <TextField
                              data-tour="observe-add-rfi-12"
                              label={tGeneral("price")}
                              placeholder={tGeneral("price")}
                              fullWidth
                              type="number"
                              {...field}
                            />
                          )}
                        />
                      )}
                      {bCost && (
                        <FormControl fullWidth>
                          <InputLabel>{tGeneral("currency")}</InputLabel>
                          <Select
                            {...register("currency")}
                            fullWidth
                            label={tGeneral("currency")}
                            value={currency}
                            data-tour="observe-add-rfi-12"
                          >
                            <MenuItem value="mxn">{tGeneral("mxn")}</MenuItem>
                            <MenuItem value="usd">{tGeneral("usd")}</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Box>
                  </Card>
                  <Card
                    sx={{ height: bDelay ? "auto" : "52px", flex: 1, p: "5px" }}
                    data-tour="add-rfi-13"
                  >
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={bDelay}
                            onChange={handleToggleDelay}
                          />
                        }
                        label={tGeneral("delay")}
                      />
                    </Box>
                    <Box display="flex" columnGap="10px">
                      {bDelay && (
                        <Controller
                          control={control}
                          name="delayDays"
                          rules={{ min: 0 }}
                          render={({ field }) => (
                            <TextField
                              label={tRfis("days")}
                              placeholder={tRfis("days")}
                              fullWidth
                              type="number"
                              data-tour="observe-add-rfi-13"
                              {...field}
                            />
                          )}
                        />
                      )}
                      {bDelay && (
                        <Controller
                          control={control}
                          name="delayHours"
                          rules={{ min: 0 }}
                          render={({ field }) => (
                            <TextField
                              label={`${tGeneral("hour")}s`}
                              placeholder={`${tGeneral("hour")}s`}
                              fullWidth
                              type="number"
                              data-tour="observe-add-rfi-13"
                              {...field}
                            />
                          )}
                        />
                      )}
                    </Box>
                  </Card>
                </Box>
              </Card>
            </Box>
          </Box>
          <CreateFormUsersSide
            distributionListName={DISTRIBUTION_LIST_NAMES.rfis}
            lists={[
              {
                label: tGeneral("assigned"),
                name: "assignedTo",
                dataTourName: "add-rfi-6",
              },
            ]}
            distributionListDataTour="add-rfi-14"
          />
        </Box>
        <Modal
          open={isUpdateLoading || isCreateLoading || isFilesLoading}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size="100px" color="primary" />
        </Modal>
        <SpecificationModal
          isOpen={addSpecification}
          onClose={(e) => {
            setAddSpecification(false);
          }}
        />
      </Box>
    </FormProvider>
  );
}

export default RfiForm;
