import {
  Attachment,
  Close,
  ExpandLess,
  ExpandMore,
  FolderOutlined,
  Lock,
  OpenInNew,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ReactComponent as IconFolder } from "../../../../assets/documents/folder.svg";
import { ManagePermissionsModal } from "../../../../components/shared";
import {
  useEditDocumentMutation,
  useGetDocumentQuery,
} from "../../../../features/project/modules/documents/documentsApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
  selectCurrentRole,
} from "../../../../features/project/projectSlice";
import { ChangeHistoryModal } from "./Modals/ChangeHistoryModal";
import { EmailsHistoryModal } from "./Modals/EmailsHistoryModal";
import { useEditProjectFolderMutation } from "../../../../features/project/modules/documents/projectFolderApiSlice";
import { useAppTourContext } from "../../../../components/supportButton/context/context";
import { useEffect } from "react";
import { useLayoutEffect } from "react";

export default function InfoDrawer(props) {
  const { idDocument } = useParams();
  const { closeInfoDrawer, path } = props;
  const { t: tGeneral } = useTranslation("general");
  const { t: tDocuments } = useTranslation("documents");
  const [generalCollapse, setGeneralCollapse] = useState(true);
  const [permissionsCollapse, setPermissionsCollapse] = useState(true);
  const currentProject = useSelector(selectCurrentProject);
  const currentRole = useSelector(selectCurrentRole);
  const handleClick = (type) => {
    if (type === "generalCollapse") setGeneralCollapse(!generalCollapse);
    if (type === "permissionsCollapse")
      setPermissionsCollapse(!permissionsCollapse);
  };

  const { data: currentDocument, isLoading } = useGetDocumentQuery(idDocument, {
    skip: idDocument === undefined,
  });

  const [updateDocument, { isUpdateDocumentLoading }] =
    useEditDocumentMutation();
  const [updateFolder, { isUpdateFolderLoading }] =
    useEditProjectFolderMutation();

  function formatDateMsg(simpleDate) {
    const date = new Date(simpleDate);

    const months = [
      tGeneral("january"),
      tGeneral("february"),
      tGeneral("march"),
      tGeneral("april"),
      tGeneral("may"),
      tGeneral("june"),
      tGeneral("july"),
      tGeneral("august"),
      tGeneral("september"),
      tGeneral("october"),
      tGeneral("november"),
      tGeneral("december"),
    ];

    let hrs = date.getHours();
    let ampm = "";
    if (hrs < 11) {
      ampm = "AM";
    } else {
      if (hrs !== 12) {
        hrs -= 12;
      }
      ampm = "PM";
    }

    let mins = date.getMinutes();
    if (mins < 10) mins = `0${mins}`;
    if (hrs < 10) hrs = `0${hrs}`;

    const dateBirthFormat =
      `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}` +
      " | " +
      `${hrs}:${mins} ${ampm}`;

    return dateBirthFormat;
  }

  const [isEmailsHistoryOpen, setIsEmailsHistoryOpen] = useState(false);
  const [isChangeHistoryOpen, setIsChangeHistoryOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPermissionsOpen, setIsPermissionsOpen] = useState(false);

  const handleOpenEmailsHistory = () => {
    setSelectedFile(currentDocument);
    setIsEmailsHistoryOpen(true);
  };
  const handleOpenChangeHistory = () => {
    setSelectedFile(currentDocument);
    setIsChangeHistoryOpen(true);
  };

  const handleCloseModals = () => {
    setIsEmailsHistoryOpen(false);
    setIsChangeHistoryOpen(false);
    setSelectedFile(null);
  };

  const open = Boolean(idDocument);

  const handleSavePermissions = async (data) => {
    const body = { ...data, id: currentDocument.id };
    if (currentDocument.isFolder) {
      await updateFolder(body);
    } else {
      await updateDocument(body);
    }
    setIsPermissionsOpen(false);
  };

  const {
    state: { tourActive, run, stepIndex, steps },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  useLayoutEffect(() => {
    if (
      tourActive &&
      open &&
      currentGuideMe === "docs-file-info" &&
      !run &&
      stepIndex === 2 &&
      !isLoading &&
      currentDocument
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [
    tourActive,
    open,
    currentGuideMe,
    run,
    steps,
    stepIndex,
    isLoading,
    currentDocument,
  ]);

  useEffect(() => {
    if (
      tourActive &&
      !isEmailsHistoryOpen &&
      currentGuideMe === "docs-file-info" &&
      stepIndex === 5 &&
      !isLoading &&
      currentDocument
    ) {
      handleOpenEmailsHistory();
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    } else {
      setIsEmailsHistoryOpen(false);
    }
  }, [tourActive, steps, stepIndex, isLoading, currentDocument]);

  return (
    <React.Fragment key="info">
      <Drawer
        anchor="right"
        open={open}
        onClose={(e) => closeInfoDrawer()}
        sx={{
          // width: "500px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "600px",
            boxSizing: "border-box",
          },
        }}
      >
        {isLoading || !currentDocument ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ m: "20px" }}>
            <IconButton onClick={(e) => closeInfoDrawer()} sx={{ mb: 2 }}>
              <Close />
            </IconButton>
            <Box sx={{ display: "flex", m: 2 }}>
              <SvgIcon sx={{ mr: 1, width: 30, height: "auto" }}>
                {currentDocument.isFolder ? <IconFolder /> : <Attachment />}
              </SvgIcon>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {currentDocument.name}
              </Typography>
            </Box>
            <Divider sx={{ m: 2 }} />
            {/* generalCollapse */}
            <ListItemButton
              onClick={() => handleClick("generalCollapse")}
              disableRipple
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {tGeneral("generalInformation")}
                  </Typography>
                }
              />
              {generalCollapse ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={generalCollapse} timeout="auto" unmountOnExit>
              <Box sx={{ m: 2 }}>
                <Box style={{ marginBottom: "15px" }}>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {tGeneral("title")}
                  </Typography>
                  <Typography variant="body">{currentDocument.name}</Typography>
                </Box>
                <Box style={{ marginBottom: "15px" }}>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {tGeneral("createdOn")}
                  </Typography>
                  <Typography variant="body">
                    {formatDateMsg(currentDocument.createDate)}
                  </Typography>
                </Box>
                <Box style={{ marginBottom: "15px" }}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold", marginRight: "10px" }}
                  >
                    {tGeneral("location")}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <FolderOutlined sx={{ mr: 1 }} /> /
                    {path?.length > 0 &&
                      path?.map((dir) => (
                        <Box display="flex" alignItems="center">
                          <Typography>{dir.name}</Typography> /
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
            </Collapse>
            <Divider sx={{ m: 2 }} />
            {/* permissionsCollapse */}
            <span data-tour="docs-file-info-3">
              <ListItemButton
                onClick={() => handleClick("permissionsCollapse")}
                disableRipple
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      {tGeneral("permissions")}
                    </Typography>
                  }
                />
                {permissionsCollapse ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={permissionsCollapse} timeout="auto" unmountOnExit>
                <Box sx={{ m: 2 }}>
                  {currentRole?.documents === 2 ||
                  currentRole?.documents === 3 ? (
                    <>
                      <Button
                        onClick={() => setIsPermissionsOpen(true)}
                        variant="outlined"
                        sx={{ p: 1, mb: "10px" }}
                      >
                        {tGeneral("managePermissions")}
                      </Button>
                      <br />
                    </>
                  ) : null}

                  <Typography variant="body">
                    {currentDocument.bPrivate ? (
                      <Box sx={{ display: "flex", mt: 1 }}>
                        <Lock />
                        {tDocuments("folderIsPrivate")}
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", mt: 1 }}>
                        <span>{tDocuments("folderIsPublic")}: </span>
                        <strong>{` ${currentProject.projectName}`}</strong>
                      </Box>
                    )}
                  </Typography>
                </Box>
              </Collapse>
            </span>
            <Divider sx={{ m: 2 }} />
            {/* Change History */}
            <Button
              variant="text"
              sx={{
                display: "flex",
                m: 2,
                textTransform: "none",
                padding: 0,
                color: "#000",
              }}
              onClick={handleOpenChangeHistory}
              data-tour="docs-file-info-4"
            >
              <SvgIcon sx={{ mr: 1 }}>
                <OpenInNew />
              </SvgIcon>
              <Typography fontSize="1.125rem" sx={{ fontWeight: "bold" }}>
                {tGeneral("changeHistory")}
              </Typography>
            </Button>
            <Divider sx={{ m: 2 }} />
            {/* Email */}
            <Button
              variant="text"
              sx={{
                display: "flex",
                m: 2,
                textTransform: "none",
                padding: 0,
                color: "#000",
              }}
              onClick={handleOpenEmailsHistory}
              data-tour="docs-file-info-5"
            >
              <SvgIcon sx={{ mr: 1 }}>
                <OpenInNew />
              </SvgIcon>
              <Typography fontSize="1.125rem" sx={{ fontWeight: "bold" }}>
                {tGeneral("email")}
              </Typography>
            </Button>
          </Box>
        )}
      </Drawer>
      <EmailsHistoryModal
        isOpen={isEmailsHistoryOpen}
        onClose={handleCloseModals}
        file={selectedFile}
      />
      <ChangeHistoryModal
        isOpen={isChangeHistoryOpen}
        onClose={handleCloseModals}
        file={selectedFile}
      />
      {isPermissionsOpen && (
        <ManagePermissionsModal
          isOpen={isPermissionsOpen}
          currentCompanies={currentDocument.allowedCompanies}
          currentUsers={currentDocument.allowedUsers}
          onClose={() => setIsPermissionsOpen(false)}
          onSave={handleSavePermissions}
          isPrivate={currentDocument.bPrivate}
          isLoading={isUpdateFolderLoading || isUpdateDocumentLoading}
        />
      )}
    </React.Fragment>
  );
}
