import { Apartment } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import UserAvatar from "../../../../../components/userAvatars/UserAvatar";
import { MessageBox } from "../../../../../components/question/MessageBox";
import { MessageReply } from "../../../../../components/question/MessageReply";
import { useCommentObjectMutation } from "../../../../../features/message/messageApiSlice";

function SubcontractorDetailsTable({ detail }) {
  const { t: tDailyReports } = useTranslation("dailyReports");
  return (
    <Box
      border="0.8px solid #E6B60C"
      borderRadius="0.5rem"
      sx={{ backgroundColor: "#FEFDE8" }}
      p="0.5rem 1rem"
      display="flex"
      flexDirection="column"
      rowGap="0.5rem"
    >
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
        <Typography>{tDailyReports("job")}</Typography>
        <Typography>{tDailyReports("assistants")}</Typography>
        <Typography>{tDailyReports("workedHours")}</Typography>
      </Box>
      <Divider />
      {detail.map((d) => (
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
          <Typography>{d.jobType.name}</Typography>
          <Typography>{d.assistants > 0 ? d.assistants : d.name}</Typography>
          <Typography>{d.hours}</Typography>
        </Box>
      ))}
    </Box>
  );
}

function SubcontractorDetails({ manpower, showComments = true }) {
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tGeneral } = useTranslation("general");

  const [commentReport] = useCommentObjectMutation();

  const handleCommentReport = (comment) => {
    commentReport({
      idObject: manpower.id,
      comment,
      files: [],
      module: "dailyReports",
      objectName: "comment",
    });
  };

  return (
    <Box
      flex={3}
      p="0.5rem 1rem"
      display="flex"
      flexDirection="column"
      rowGap="0.5rem"
      overflow="hidden"
      sx={{ maxHeight: "40vh", overflowY: "auto" }}
    >
      <Box display="flex" py="0.5rem">
        <Apartment sx={{ color: "#E6B60C" }} />
        <Typography>{manpower.subcontractor.name}</Typography>
      </Box>
      <Typography>
        {tDailyReports("subcontractorMessage", {
          date: moment(manpower.createdDate).format("dddd D"),
          total: manpower.detail.reduce((acc, m) => {
            return acc + (m.assistants > 0 ? m.assistants : 1);
          }, 0),
        })}
      </Typography>
      <SubcontractorDetailsTable detail={manpower.detail} />
      {showComments && (
        <MessageReply
          showConnection={false}
          placeholder={`${tGeneral("newComment")}`}
          reply={handleCommentReport}
          enableFiles={false}
        />
      )}
      {showComments && (
        <Box>
          {manpower.comments?.map(
            ({
              comment: {
                user: { firstName, lastName, urlAvatar },
                createdDate,
                comment,
                files,
                id,
                bActive = true,
              },
            }) => (
              <Box display="flex">
                <UserAvatar url={urlAvatar} bActive={bActive} />
                <MessageBox
                  createdBy={`${firstName} ${lastName}`}
                  createdDate={createdDate}
                  text={comment}
                  files={files}
                  key={id}
                  bgColor="#FFC42D45"
                  width="80%"
                />
              </Box>
            ),
          )}
        </Box>
      )}
    </Box>
  );
}

export { SubcontractorDetails };
