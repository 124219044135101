import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authSlice from "./features/auth/authSlice";
import enterpriseSlice from "./features/enterprise/enterpriseSlice";
import projectSlice from "./features/project/projectSlice";
import preferencesSlice from "./features/preferences/preferencesSlice";
import userSettingsSlice from "./features/userSettings/userSettingsSlice";
import projectSettingsSlice from "./features/project/projectSettingsSlice";
import messageSlice from "./features/message/messageSlice";
import rfiSlice from "./features/project/modules/rfiSlice";
import submittalSlice from "./features/project/modules/submittals/submittalSlice";
import tasksSlice from "./features/project/modules/tasks/tasksSlice";
import inspectionsSlice from "./features/project/modules/inspections/slice";
import inspectionsTemplateSlice from "./features/project/modules/inspectionsTemplates/slice";
import announcementSlice from "./features/project/modules/announcements/announcementSlice";
import meetingSlice from "./features/project/modules/meetings/meetingSlice";
import projectFolderSlice from "./features/project/modules/documents/projectFolderSlice";
import documentsSlice from "./features/project/modules/documents/documentsSlice";
import dailyReportsSlice from "./features/project/modules/dailyReports/dailyReportsSlice";
import projectDirectorySlice from "./features/project/modules/directory/projectDirectorySlice";
import drawingsSlice from "./features/drawings/drawingsSlice";
import analyticsSlice from "./features/project/analyticsSlice";
// import s3Slice from "./features/s3/s3Slice";


export default () => {
  const store = configureStore({
    reducer: {
      // Don't persist RTK Query states & reducers
      [apiSlice.reducerPath]: apiSlice.reducer,
      auth: persistReducer({ key: "auth", storage: sessionStorage }, authSlice),
      enterprise: persistReducer(
        { key: "enterprise", storage: sessionStorage },
        enterpriseSlice,
      ),
      project: persistReducer(
        { key: "project", storage: sessionStorage },
        projectSlice,
      ),
      preferences: persistReducer(
        { key: "preferences", storage: sessionStorage },
        preferencesSlice,
      ),
      userSettings: persistReducer(
        { key: "userSettings", storage: sessionStorage },
        userSettingsSlice,
      ),
      projectSettings: persistReducer(
        { key: "projectSettings", storage: sessionStorage },
        projectSettingsSlice,
      ),
      message: persistReducer(
        { key: "message", storage: sessionStorage },
        messageSlice,
      ),
      rfi: persistReducer({ key: "rfi", storage: sessionStorage }, rfiSlice),
      submittal: persistReducer(
        { key: "submittal", storage: sessionStorage },
        submittalSlice,
      ),
      tasks: persistReducer(
        { key: "tasks", storage: sessionStorage },
        tasksSlice,
      ),
      inspections: persistReducer(
        { key: "inspections", storage: sessionStorage },
        inspectionsSlice,
      ),
      inspectionsTemplates: persistReducer(
        { key: "inspectionsTemplates", storage: sessionStorage },
        inspectionsTemplateSlice,
      ),
      announcement: persistReducer(
        { key: "announcement", storage: sessionStorage },
        announcementSlice,
      ),
      meeting: persistReducer(
        { key: "meeting", storage: sessionStorage },
        meetingSlice,
      ),
      projectFolder: persistReducer(
        { key: "projectFolder", storage: sessionStorage },
        projectFolderSlice,
      ),
      documents: persistReducer(
        { key: "documents", storage: sessionStorage },
        documentsSlice,
      ),
      dailyReports: persistReducer(
        { key: "dailyReports", storage: sessionStorage },
        dailyReportsSlice,
      ),
      projectDirectory: persistReducer(
        { key: "projectDirectory", storage: sessionStorage },
        projectDirectorySlice,
      ),
      drawings: persistReducer(
        { key: "drawings", storage: sessionStorage },
        drawingsSlice,
      ),
      analytics: analyticsSlice, // Add the analytics slice here
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore middleware validation for redux-persist actions
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(apiSlice.middleware),
  });
  const persistor = persistStore(store);
  return { store, persistor };
};
