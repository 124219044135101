import { apiSlice } from "../../api/apiSlice";

export const s3ApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPresignedUrl: builder.query({
      query: () => "/s3/getPresignedUrl",
      method: "GET",
    }),
    createPresignedUrl: builder.mutation({
      query: (body) => ({
        url: "/s3/createPresignedUrl",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    createPresignedUrlWithPath: builder.mutation({
      query: (body) => ({
        url: "/s3/createPresignedUrlWithPath",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetPresignedUrlQuery,
  useCreatePresignedUrlMutation,
  useCreatePresignedUrlWithPathMutation,
} = s3ApiSlice;
