import { tour } from "./tour";

export const tasks = {
  // general tour
  ...tour,
  moduleElement: "Tasks",
  // ADD - guide me steps
  task: "Task",
  tasks: "Tasks",
  high: "High",
  medium: "Medium",
  low: "Low",
  taskDeletedSuccessfully: "Task(s) deleted successfully",
  taskRestoredSuccessfully: "Task(s) restored successfully",
  noTasksCreated: "No tasks created at this moment",
  noTasksContent:
    "If you want to add one, we invite you to click the add button to start the process",
  addTask: "Add task",
  backlog: "Backlog",
  doing: "Doing",
  done: "Done",
  creationDate: "Creation date",
  deliveryDate: "Delivery date",
  closeDate: "Close date",
  category: "Category",
  priority: "Priority",
  location: "Location",
  startTask: "Start task",
  closeTask: "Close task",
  days: "Days",
  tasksRecycleBin: "Tasks recycle bin",
  editTask: "Edit task",
  addCategory: "Add category",
  // ADD - guide me steps
  step1_addtask_title: "1. Add task",
  step1_addtask_text: "Click here to add a new task",
  step2_addtask_title: "2. Title",
  step2_addtask_text: "Write a title that can describe your new task",
  step3_addtask_title: "3. Due Date",
  step3_addtask_text: "Choose the due date as the deadline to finish the task",
  step4_addtask_title: "4. Status",
  step4_addtask_text1_1: "Choose the status",
  step4_addtask_text1_2: "OPEN to Publish the task rigth away.",
  step4_addtask_text2_1: "Choose the",
  step4_addtask_text2_2:
    "DRAFT status to keep a draft of the task without publishing it.",
  step5_addtask_title: "5. Privacy",
  step5_addtask_text:
    "Click here to make it visible for everyone or for just people the people involved",
  step5_addtask_text_1: "Choose the type of task privacy",
  step5_addtask_text_2: "Anyone with access to the module will see your task",
  step5_addtask_text_3: "Only participants will be able to see this task",
  step6_addtask_title: "6. Assignes",
  step6_addtask_text: "Select the people whom need to finish the task",
  step7_addtask_title: "7. Distribution list",
  step7_addtask_text: "Select the people you want to notify about this task",
  step8_addtask_title: "8. Description",
  step8_addtask_text: "Write a description about what is the task about",
  step9_addtask_title: "9. Upload files",
  step9_addtask_text:
    "Click here or drag and drop files such as images or documents",
  step10_addtask_title: "10. Category",
  step10_addtask_text: "Choose the category of the task",
  step11_addtask_title: "11. Location",
  step11_addtask_text:
    "Select the location within the project where the task needs to be made",
  step12_addtask_title: "12. Priority",
  step12_addtask_text: "Select between hig, medium and low priority",
  step13_addtask_title: "13. Publish",
  step13_addtask_text:
    "Click here to make public the task, or to save it as a draft if it applies",
  stepFinal_addtask: "You have learned how to create a new task",
  // VIEW - guide me steps
  step1_viewtask_title: "1. View task",
  step1_viewtask_text: "Click on a task to see its content.",
  step2_viewtask_title: "2. Involved",
  step2_viewtask_text:
    "In this section, you can see who the assignees and creator are.",
  step3_viewtask_title: "3. Task Data",
  step3_viewtask_text:
    "In this section, you can see the main data of the task such as due dates, status, priority, etc.",
  step4_viewtask_title: "4. Description",
  step4_viewtask_text:
    "Here you can see the description of the task created by its owner.",
  step6_viewtask_title: "6. Reply",
  step6_viewtask_text:
    "Write a reply to the main description and attach files to it. Different replies will be displayed below the description.",
  step7_viewtask_title: "7. Comment",
  step7_viewtask_text:
    "Leave a comment on a response that has been made and attach files if necessary. Comments will be displayed below the responded message.",
  step8_viewtask_title: "8. Start/finish task",
  step8_viewtask_text:
    "Click here to mark the task as started; if it has already been started, click to finish the task.",
  stepFinal_viewtask: "You have learned how to view a task",
  // EDIT - guide me steps
  step1_edittask_title: "1. Edit task",
  step1_edittask_text:
    "Click on the three dots to open different options: Click on 'Edit'.",
  step2_edittask_title: "2. Information",
  step2_edittask_text:
    "In this section, you can edit the title, due date, status, and privacy of the task.",
  step3_edittask_title: "3. Description and attached files",
  step3_edittask_text:
    "In this section, you can edit the task description and delete attached files.",
  step4_edittask_title: "4. Additional information",
  step4_edittask_text:
    "In this section, you can edit additional information such as category, priority, and location.",
  step4_edittask_text1_1: "Choose the status",
  step4_edittask_text1_2: "OPEN to Publish the task pending a response.",
  step4_edittask_text2_1: "Choose the",
  step4_edittask_text2_2:
    "DRAFT status to keep a draft of the task without publishing it.",
  step5_edittask_title: "5. Assigned",
  step5_edittask_text: "Add or remove task assignees",
  step5_edittask_text_1: "Choose the type of task privacy",
  step5_edittask_text_2: "Anyone with access to the module will see your task",
  step5_edittask_text_3: "Only participants will be able to see this task",
  step6_edittask_title: "6. Distribution List",
  step6_edittask_text: "Add or remove people to the distribution list",
  step7_edittask_title: "7. Save changes",
  step7_edittask_text:
    "Click here to save and update the changes made to the task",
  stepFinal_edittask: "You have learned how to edit a task",
  // RECYCLE - guide me steps
  step1_recycletask_title: "2. Recycle Bin tasks",
  step1_recycletask_text:
    "Here you can see the tasks that have been deleted from the project.",
  step2_recycletask_title: "3. Select tasks",
  step2_recycletask_text: "Select the tasks that you want to restore.",
  step3_recycletask_title: "4. Restore tasks",
  step3_recycletask_text: "Click here to restore the selected tasks.",
  stepFinal_recycletask:
    "You have learned how to interact with the recycle bin",
  // EXPORT - guide me steps
  step1_exporttask_title: "Click on the three dots to open different options:",
  step1_exporttask_text1_1: "Export to PDF",
  step1_exporttask_text1_2:
    "Click here to export the selected task to a PDF file.",
  step1_exporttask_text2_1: "Send by email",
  step1_exporttask_text2_2: "Click here to send the selected task by email.",
  stepFinal_exporttask: "You have learned how to export a task",
  // SETTINGS - guide me steps
  step1_settingstask_title: "2. Task settings",
  step1_settingstask_text: "Click here to modify the settings",
  /*step2_settingstask_title: "3. Days",
  step2_settingstask_text:
    "Select the minimum number of days for delivering a task by default.",*/
  step3_settingstask_title: "3. Distribution List",
  step3_settingstask_text:
    "Select who are the default people for the distribution list of created tasks.",
  step4_settingstask_title: "4. Save settings",
  step4_settingstask_text: "Click here to save the settings",
  stepFinal_settingstask:
    "You have learned how to interact with the task settings",
  // other
  obligatory: "Obligatory",
  public: "Public",
  private: "Private",
  congratulations: "Congratulations!",
  rfi_title_module: "RFIs",
  no_rfis_title: "No RFIs have been created at the moment",
  no_rfis_text:
    "If you want to add one, we invite you to click the add RFI button to start the process",
  meeting: "Meeting",
  workflow: "Workflow",
  guideMe: "Guide me",
  support: "Support",
  days: "Days",
  closeTaskMessage: "Are you sure you want to close this task?",
  subtasks: "Subtasks",
  addSubtask: "Add a subtask",
  noSubtasks: "No subtasks created",
  inList: "In list",
};
