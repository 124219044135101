import { tour } from "./tour";

const inspections = {
  // general tour
  ...tour,
  moduleElement: "Inspeções",
  subModuleElement: "Modelos",
  // ADD - guide me steps
  step1_inspectionsAdd_title: "1. Adicionar.",
  step1_inspectionsAdd_text: "Clique no ícone + para adicionar um novo modelo.",
  step2_inspectionsAdd_title: "2. Título.",
  step2_inspectionsAdd_text: "Insira o título da inspeção.",
  step3_inspectionsAdd_title: "3. Data de entrega.",
  step3_inspectionsAdd_text: "Escolha a data de entrega da inspeção.",
  step4_inspectionsAdd_title: "4. Status.",
  step4_inspectionsAdd_text_1_1: "Escolha o status",
  step4_inspectionsAdd_text_1_2: "Em revisão para inspeções nesse status.",
  step4_inspectionsAdd_text_2_1: "Escolha o status",
  step4_inspectionsAdd_text_2_2:
    "Status RASCUNHO para manter um rascunho da inspeção.",
  step5_inspectionsAdd_title: "5. Privacidade.",
  step5_inspectionsAdd_text: "Escolha o tipo de privacidade da inspeção",
  step5_inspectionsAdd_text_1:
    "Qualquer pessoa com acesso ao módulo verá a inspeção.",
  step5_inspectionsAdd_text_2:
    "Apenas os participantes poderão ver esta inspeção.",
  step6_inspectionsAdd_title: "6. Localização.",
  step6_inspectionsAdd_text: "Selecione a localização da inspeção.",
  step7_inspectionsAdd_title: "7. Escolha o modelo.",
  step7_inspectionsAdd_text: "Selecione o modelo a ser usado pela inspeção.",
  step8_inspectionsAdd_title: "8. Criadores.",
  step8_inspectionsAdd_text:
    "Escolha os usuários que terão as mesmas permissões que você",
  step9_inspectionsAdd_title: "9. Atribuído.",
  step9_inspectionsAdd_text: "Selecione as pessoas que aprovarão a inspeção.",
  step10_inspectionsAdd_title: "10. Lista de distribuição.",
  step10_inspectionsAdd_text:
    "Selecione as pessoas que você deseja notificar sobre esta inspeção.",
  step11_inspectionsAdd_title: "11. Criar.",
  step11_inspectionsAdd_text: "Clique aqui para criar a inspeção.",
  step_final_inspectionsAdd: "Você aprendeu como criar uma inspeção.",
  // ADD TEMPLATES - guide me steps
  step1_inspectionTemplatesAdd_title: "1. Menu do Módulo de Inspeção.",
  step1_inspectionTemplatesAdd_text: "Clique aqui para ver as opções.",
  step2_inspectionTemplatesAdd_title: "2. Modelos.",
  step2_inspectionTemplatesAdd_text:
    "Clique aqui para abrir a seção de modelos.",
  step3_inspectionTemplatesAdd_title: "3. Adicionar.",
  step3_inspectionTemplatesAdd_text: "Clique no ícone +",
  step4_inspectionTemplatesAdd_title: "4. Título.",
  step4_inspectionTemplatesAdd_text: "Insira o título do modelo de inspeção",
  step5_inspectionTemplatesAdd_title: "5. Código.",
  step5_inspectionTemplatesAdd_text: "Insira o código do modelo de inspeção.",
  step6_inspectionTemplatesAdd_title: "6. Categoria.",
  step6_inspectionTemplatesAdd_text: "Selecione a categoria.",
  step7_inspectionTemplatesAdd_title: "7. Descrição.",
  step7_inspectionTemplatesAdd_text: "Insira a descrição.",
  step8_inspectionTemplatesAdd_title: "8. Lista de inspeções",
  step8_inspectionTemplatesAdd_text:
    "Adicione um novo item a ser inspecionado clicando em Adicionar seção.",
  step8_1_inspectionTemplatesAdd_title: "8.1 Título.",
  step8_1_inspectionTemplatesAdd_text: "Insira o título da seção.",
  step8_1_1_inspectionTemplatesAdd_title: "8.1.1 Adicionar elemento.",
  step8_1_1_inspectionTemplatesAdd_text:
    "Clique para adicionar um elemento a ser inspecionado.",
  step8_1_2_inspectionTemplatesAdd_title: "8.1.2 Título.",
  step8_1_2_inspectionTemplatesAdd_text: "Insira o título.",
  step8_1_3_inspectionTemplatesAdd_title: "8.1.3 Descrição.",
  step8_1_3_inspectionTemplatesAdd_text: "Insira a descrição do elemento.",
  step9_inspectionTemplatesAdd_title: "9. Adicionar.",
  step9_inspectionTemplatesAdd_text: "Clique para adicionar o elemento.",
  step10_inspectionTemplatesAdd_title: "10. Salvar.",
  step10_inspectionTemplatesAdd_text: "Clique para salvar o modelo.",
  step_final_inspectionTemplatesAdd: "Você aprendeu como criar um modelo.",

  add: "Adicionar Inspeção",
  edit: "Editar Inspeção",
  guideMe: "Guie-me",
  inspection_recycle_bin: "Inspeções - Lixeira",
  inspections: "Inspeções",
  meeting: "Reunião",
  no_inspections_text:
    "Se você deseja adicionar uma, convidamos você a clicar no botão Adicionar Inspeção para iniciar o processo",
  no_inspections_title: "Nenhuma inspeção foi criada no momento",
  title: "Inspeções",
  workflow: "Fluxo de trabalho",
  itemsInspected: "Itens Inspecionados",
  pass: "Aprovado",
  fail: "Reprovado",
  na: "N/A",
  sections: "Seções",
  items: "Itens",
  createTask: "Criar Tarefa",
  add_button_tour: "Criar uma nova inspeção",
  select_template: "Selecionar Modelo",
  add_section: "Adicionar Seção",
  no_sections: "Não há seções nesta inspeção.",
  approve: "Aprovar",
  checkAndSign: "Verificar e Assinar",
  createREvision: "Criar revisão",
  observation: "Observação",
  addItem: "Adicionar item",
  deleteInspection: "Tem certeza de que deseja excluir esta inspeção?",
};

export { inspections };
