import { ContactSupport, Edit } from "@mui/icons-material";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import GroupsIcon from "@mui/icons-material/Groups";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { selectLocale } from "../../features/preferences/preferencesSlice";
import { useGetActiveInspectionsTemplatesQuery } from "../../features/project/modules/inspectionsTemplates/apiSlice";
import {
  selectCurrentProject,
  setCurrentGuideMe,
} from "../../features/project/projectSlice";
import InspectionsTemplatesEn from "../../assets/workflow/InspectionsTemplates.png";
import InspectionsTemplatesEs from "../../assets/workflow/InspectionsTemplates.png";

import { SupportMenu } from "./components/SupportMenu";
import { SupportMenuModalMetting } from "./components/modals/SupportMenuModalMetting";
import { SupportMenuItemHeader } from "./components/SupportMenuItemHeader";
import { SupportContent } from "./components/SupportContent";
import { SupportListItem } from "./components/SupportListItem";
import { SupportMenuModalWorkflow } from "./components/modals/SupportMenuModalWorkflow";
import { SupportMenuModalGuideMe } from "./components/modals/SupportMenuModalGuideMe";
import { useGuideMeModal } from "./hooks/useGuideMeModal";
import { useInspectionTemplatesSteps } from "./components/steps/inspectionsTemplate";

function SupportButtonInspectionsTemplates() {
  const dispatch = useDispatch();

  const { t: translation } = useTranslation("inspectionsTemplates");
  const currentProject = useSelector(selectCurrentProject);
  const [recordsLength, setRecordsLength] = useState(0);
  const locale = useSelector(selectLocale);
  const [imgWorkflow] = useState(
    locale == "en" ? InspectionsTemplatesEn : InspectionsTemplatesEs,
  );
  const { data: records, isSuccess } = useGetActiveInspectionsTemplatesQuery({
    idProject: currentProject.id,
  });

  const [{ editSteps }] = useInspectionTemplatesSteps();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [anchorSupp, setAnchorSupp] = useState(null);
  //For open
  const handleClick = (event) => {
    setAnchorSupp(event.currentTarget);
    setMenuIsOpen(true);
  };
  //For close
  const handleClose = () => {
    setAnchorSupp(null);
    setMenuIsOpen(false);
  };
  // End support button

  // Meeting modal
  const [meetingModalIsOpened, setMeetingModalIsOpened] = useState(false);
  const openMeetingModal = () => setMeetingModalIsOpened(true);
  const closeMeetingModal = () => setMeetingModalIsOpened(false);
  // Workflow modal
  const [workflowModalIsOpened, setWorkflowModalIsOpened] = useState(false);
  const openWorkflowModal = () => setWorkflowModalIsOpened(true);
  const closeWorkflowModal = () => setWorkflowModalIsOpened(false);
  // guide me modal
  const [guideModalIsOpened, setGuideModalIsOpened] = useState(false);
  const openGuideModal = () => setGuideModalIsOpened(true);
  const closeGuideModal = () => setGuideModalIsOpened(false);

  // Tutorial Guide Me
  const [{ handleGuideMe }] = useGuideMeModal({
    editSteps,
  });

  useEffect(() => {
    if (records && isSuccess) {
      setRecordsLength(records.length);
    }
  }, [records, isSuccess]);

  return (
    <div>
      {/* Button */}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={translation("support")}
      >
        <IconButton
          id="support-button"
          aria-controls={menuIsOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuIsOpen ? "true" : undefined}
          sx={{ color: "#007FFF" }}
          onClick={handleClick}
        >
          <ContactSupport />
        </IconButton>
      </Tooltip>

      {/**
       * It could seem weird but Menu and MenuItem do not support Fragments. This was an effort to refactor the code.
       * https://stackoverflow.com/questions/75083605/mui-the-menu-component-doesnt-accept-a-fragment-as-a-child-consider-providing
       * */}
      <SupportMenu anchor={anchorSupp} open={menuIsOpen} onClose={handleClose}>
        {/* Meeting */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openMeetingModal();
          }}
        >
          <SupportMenuItemHeader title={translation("meeting")}>
            <GroupsIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalMetting
          open={meetingModalIsOpened}
          onClose={closeMeetingModal}
        />

        {/* Workflow */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openWorkflowModal();
          }}
        >
          <SupportMenuItemHeader title={translation("workflow")}>
            <AppRegistrationRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalWorkflow
          open={workflowModalIsOpened}
          diagramImageUrl={imgWorkflow}
          onClose={closeWorkflowModal}
        />

        {/* Guide Me */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openGuideModal();
          }}
        >
          <SupportMenuItemHeader title={translation("guideMe")}>
            <TouchAppRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalGuideMe
          title={`${translation("guideMe")} ${translation("moduleElement")}`}
          open={guideModalIsOpened}
          onClose={closeGuideModal}
        >
          <SupportContent>
            {/* ADD */}
            <SupportListItem
              can="add"
              moduleName={"inspectionsTemplates"}
              onClick={() => {
                handleGuideMe("edit");
                dispatch(setCurrentGuideMe("inspections-templates-edit"));
                closeGuideModal();
              }}
            >
              <Edit sx={{ marginRight: 1 }} />
              {`${translation("viewButtonTour")}/${translation(
                "editButtonTour",
              )} ${translation("subModuleElement")}`}
            </SupportListItem>
          </SupportContent>
        </SupportMenuModalGuideMe>
      </SupportMenu>
    </div>
  );
}

export { SupportButtonInspectionsTemplates };
