// Third-party library imports
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Relative imports
import { RFI, Submittals, Tasks, Inspections, Drawings } from "./ModuleWidgets";
import { selectAllWidgets } from "../../../features/project/analyticsSlice";

const AddWidgetModal = ({ open, onClose, onAddWidget }) => {
  const { t: tAnalytics } = useTranslation("analytics");

  const widgets = useSelector(selectAllWidgets);

  // Helper function to check if a widget type is rendered
  const isWidgetRendered = (type) => {
    return Object.values(widgets).some((widget) => widget.id === type);
  };

  const handleAddWidget = (type, size) => {
    if (!isWidgetRendered(type)) {
      onAddWidget(type, size); // Pass the hardcoded size here
      onClose();
    }
  };

  const getOverlayStyle = (isRendered) => ({
    ...disabledWidgetsStyle,
    opacity: isRendered ? 0.7 : 0,
    cursor: isRendered ? "not-allowed" : "pointer",
  });

  const widgetsStyle = {
    cursor: "pointer",
    textAlign: "center",
    height: "266px",
    boxShadow: "1px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Adjust to ensure all elements fit
    alignItems: "center",
    padding: "16px", // Added padding for better spacing
  };

  const disabledWidgetsStyle = {
    ...widgetsStyle,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bgcolor: "#ddd",
    zIndex: 1000, // ensure this box is on top of other elements
  };

  const contentContainerStyles = {
    padding: "0px 8px",
    width: "100%",
    height: "92%",
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          width: "85%",
          height: "85%",
          maxWidth: "none",
          maxHeight: "none",
          position: "relative",
          borderRadius: "16px",
        },
      }}
    >
      {/* Container Box with padding */}
      <Box padding="32px 48px">
        {/* Title */}
        <Typography
          variant="h4"
          sx={{
            fontSize: "34px",
            fontStyle: "normal",
            fontWeight: "700",
            letterSpacing: "0.085px",
            marginBottom: "8px",
          }}
        >
          {tAnalytics("selectWidget")}
        </Typography>
        {/* Description */}
        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            letterSpacing: "0.04px",
            marginBottom: "40px",
          }}
        >
          {tAnalytics("widgetDisplayed")}
        </Typography>
        <DialogContent sx={{ padding: "2px" }}>
          <Grid container spacing={2} sx={{ padding: "8px" }}>
            <Grid item xs={3}>
              <Box
                sx={{ position: "relative" }}
                onClick={() => handleAddWidget("tasks", "sm")}
              >
                {/* overlay for disabled widgets */}
                <Box sx={getOverlayStyle(isWidgetRendered("tasks"))} />
                {/* Tasks Widget Box */}
                <Box sx={widgetsStyle}>
                  <Box sx={contentContainerStyles}>
                    <Tasks size="sm" isMock={true} />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                sx={{ position: "relative" }}
                onClick={() => handleAddWidget("submittals", "sm")}
              >
                {/* overlay for disabled widgets */}
                <Box sx={getOverlayStyle(isWidgetRendered("submittals"))} />
                {/* Submittals Widget Box */}
                <Box sx={widgetsStyle}>
                  <Box sx={contentContainerStyles}>
                    <Submittals size="sm" isMock={true} />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                sx={{ position: "relative" }}
                onClick={() => handleAddWidget("rfi", "sm")}
              >
                {/* overlay for disabled widgets */}
                <Box sx={getOverlayStyle(isWidgetRendered("rfi"))} />
                {/* RFI Widget Box */}
                <Box sx={widgetsStyle}>
                  <Box sx={contentContainerStyles}>
                    <RFI size="sm" isMock={true} />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{ position: "relative" }}
                onClick={() => handleAddWidget("inspections", "md")}
              >
                {/* overlay for disabled widgets */}
                <Box sx={getOverlayStyle(isWidgetRendered("inspections"))} />
                {/* Inspections Widget Box */}
                <Box sx={widgetsStyle}>
                  <Box sx={contentContainerStyles}>
                    <Inspections size="md" isMock={true} />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{ position: "relative" }}
                onClick={() => handleAddWidget("drawings", "md")}
              >
                {/* overlay for disabled widgets */}
                <Box sx={getOverlayStyle(isWidgetRendered("drawings"))} />
                {/* Drawings Widget Box */}
                <Box sx={widgetsStyle}>
                  <Box sx={contentContainerStyles}>
                    <Drawings size="sm" isMock={true} />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: "24px", right: "24px" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default AddWidgetModal;
