import { MoreVert } from "@mui/icons-material";
import { Avatar, Box, Card, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  useDeleteNotificationMutation,
  useMarkAsReadMutation,
} from "../../../features/notificationCenter/notificationCenterApiSlice";
import { TooltipIconButton } from "../TooltipIconButton";

function NotificationCard({ notification, closeDrawer }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tNotificationActions } = useTranslation("notificationActions");
  const { t: tNotificationCenter } = useTranslation("notificationCenter");
  const navigate = useNavigate();

  const [markAsRead] = useMarkAsReadMutation();
  const [deleteNotification] = useDeleteNotificationMutation();

  const [anchorEl, setAnchorEl] = useState(null);

  const getNameInitials = () => {
    if (!notification) return "";

    const splittedName = notification.createdByName.split(" ");
    return `${splittedName[0][0]}${splittedName[1][0]}`;
  };

  const getTimeDifference = () => {
    const notificationDate = new Date(notification.createdDate);
    const currentDate = new Date();
    const timeDifferenceInMilliseconds = currentDate - notificationDate;
    let timeDifference = Math.floor(timeDifferenceInMilliseconds / 1000);
    let timeLabel = timeDifference > 1 ? "seconds" : "second";
    if (timeDifference > 60) {
      timeDifference = Math.floor(timeDifference / 60);
      timeLabel = timeDifference > 1 ? "minutes" : "minute";
    }
    if (timeDifference > 60) {
      timeDifference = Math.floor(timeDifference / 60);
      timeLabel = timeDifference > 1 ? "hours" : "hour";
    }

    if (timeDifference > 23 && timeLabel.match(/hour/)) {
      timeDifference = Math.floor(timeDifference / 24);
      timeLabel = timeDifference > 1 ? "days" : "day";
    }

    return tGeneral("timeAgo", {
      time: timeDifference,
      unit: tGeneral(timeLabel).toLowerCase(),
    });
  };

  const navigateTo = () => {
    if (!notification.read) {
      markAsRead(notification.id);
    }
    navigate(
      `./../../enterprise/${notification.idEnterprise}/project/${notification.idProject}/${notification.moduleRoute}/${notification.idObject}`,
      { relative: "path" },
    );
    closeDrawer();
  };

  const handleOpenMoreMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteNotification(notification.id);
    setAnchorEl(null);
  };

  const handleMarkAsRead = (e) => {
    e.stopPropagation();
    markAsRead(notification.id);
    setAnchorEl(null);
  };

  return (
    <Box display="flex" mb="0.5rem">
      <Card
        sx={{
          display: "flex",
          borderRadius: "0.5rem",
          flex: 1,
          cursor: "pointer",
          backgroundColor: notification.read ? "#FAFAFA" : "#FDF9C4",
          p: "0.5rem 1rem",
        }}
        component="div"
        onClick={navigateTo}
      >
        <Box flex={1} display="flex" flexDirection="column" rowGap="0.5rem">
          <Box display="flex" sx={{ borderRadius: "8px" }} columnGap="0.5rem">
            <Typography
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              fontWeight="500"
              color="#C68D08"
            >
              {tGeneral(notification.tableName)}: {notification.title}
            </Typography>
            {notification.number && (
              <Typography fontSize="0.875rem" color="#8E8E8E">
                #{notification.number}
              </Typography>
            )}
          </Box>
          <Box display="flex" columnGap="8px" alignItems="center">
            <Avatar
              sx={{ width: "1.5rem", height: "1.5rem" }}
              src={notification.createdByUrlAvatar}
            >
              {getNameInitials()}
            </Avatar>
            <Typography fontWeight="bold">
              {notification.createdByName}
            </Typography>
            <Typography>
              {tNotificationActions(notification.action, {
                object: tNotificationActions(notification.tableName),
              })}
            </Typography>
          </Box>
          <Box display="flex" columnGap="0.5rem">
            <Typography fontSize="0.75rem" color="#1F1F1F">
              {getTimeDifference()}
            </Typography>
            <Typography fontSize="0.75rem" color="#1F1F1F">
              {notification.projectName}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <TooltipIconButton
            icon={<MoreVert />}
            color="#8E8E8E"
            label={tGeneral("more")}
            onClick={handleOpenMoreMenu}
          />
          <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={(e) => {
              e.stopPropagation();
              setAnchorEl(null);
            }}
          >
            <MenuItem onClick={handleDelete}>{tGeneral("delete")}</MenuItem>
            <MenuItem onClick={handleMarkAsRead}>
              {tNotificationCenter("markAsRead")}
            </MenuItem>
          </Menu>
        </Box>
      </Card>
    </Box>
  );
}

export { NotificationCard };
