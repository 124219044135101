import { ArrowBack, ArrowForward, Close, Download } from "@mui/icons-material";
import { Box, Card, IconButton, Modal } from "@mui/material";
import WebViewer from "@pdftron/webviewer";
import { useEffect, useMemo, useRef, useState } from "react";
import { isVideo } from "../../utils/video";
import ReactPlayer from "react-player";

/**
  @params
  - isOpen: Boolean
  - onClose: () => void;
  - files: Array[string] "Array of files urls"
  - initialIndex: Number "Initial index for the carousel. Default is 0"
*/
function ModalCarouselFileViewer({
  isOpen,
  onClose,
  files = [],
  initialIndex = 0,
}) {
  const viewerRef = useRef(null);
  const [instance, setInstance] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(initialIndex);
  }, [initialIndex]);

  const currentFile = useMemo(() => {
    if (files.length > 0) {
      return files[currentIndex];
    }
    return null;
  }, [files, currentIndex]);

  const isImg = useMemo(() => {
    if (currentFile) {
      return Boolean(currentFile.extension.match(/png|jpg|jpeg|svg|avif/));
    }
    return false;
  }, [currentFile]);

  const isVideoFile = useMemo(() => {
    if (currentFile) {
      const bVideo = isVideo({
        type: currentFile.extension
      });
      return bVideo;
    }
    return false;
  }, [currentFile]);

  useEffect(() => {
    if (viewerRef.current && isModalOpen && currentFile && !isImg && !isVideoFile) {
      WebViewer(
        {
          licenseKey: process.env.REACT_APP_APRYSE_LICENSE_KEY,
          path: "/pdftron",
          showToolbarControl: true,
          showSideWindowControl: false,
          disabledElements: [
            "textSelection",
            "panToolButton",
            "textAnnotationButton",
            "freeTextAnnotationButton",
            "stickyAnnotationButton",
            "calloutAnnotationButton",
            "stampAnnotationButton",
            "measurementToolGroupButton",
            "toolsHeader",
            "leftPanelButton",
            "viewControlsButton",
            "zoomOverlayButton",
            "selectToolButton",
            "ribbons",
            "searchButton",
            "toggleNotesButton",
          ],
        },
        viewerRef.current,
      )
        .then((newInstance) => {
          setInstance(newInstance);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [viewerRef, isModalOpen, currentFile, isImg, isVideoFile]);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const handleNextFile = () => {
    setCurrentIndex((prev) => prev + 1);
  };

  const handlePrevFile = () => {
    setCurrentIndex((prev) => prev - 1);
  };

  useEffect(() => {
    if (currentFile && instance && !isImg && !isVideoFile) {
      instance.loadDocument(currentFile.url, {
        extension: currentFile.extension,
      });
    }
  }, [currentFile, instance, isImg, isVideoFile]);

  const download = () => {
    const el = document.createElement("a");
    el.setAttribute("href", currentFile.url);
    el.setAttribute("download", currentFile.name);
    el.setAttribute("target", "_blank");
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  return (
    <Modal open={isOpen} onClose={onClose} sx={{ display: "flex", flex: 1 }}>
      <Box display="flex" flex={1}>
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          justifyContent="flex-end"
          mr="10px"
        >
          <IconButton onClick={handlePrevFile} disabled={currentIndex === 0}>
            <ArrowBack
              sx={{ backgroundColor: "white", borderRadius: "20px" }}
            />
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flex={2}
        >
          <Card
            sx={{
              p: "1%",
              height: "90%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            {isImg ? (
              <img
                src={currentFile.url}
                alt={currentFile.name}
                style={{
                  maxWidth: "100%",
                  objectFit: "contain",
                  margin: "auto",
                  maxHeight: "100%",
                }}
              />
            ) : isVideoFile ? (
              <ReactPlayer
                url={currentFile?.url}
                controls
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            ) : (
              <Box
                ref={viewerRef}
                className="webviewer"
                sx={{ height: "100%", width: "100%" }}
              />
            )}

            {isImg && (
              <IconButton
                onClick={download}
                sx={{ position: "absolute", right: "50px", top: "10px" }}
              >
                <Download color="primary" />
              </IconButton>
            )}
            <IconButton
              sx={{ position: "absolute", right: "10px", top: "10px" }}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </Card>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          justifyContent="flex-start"
          ml="10px"
        >
          <IconButton
            onClick={handleNextFile}
            disabled={currentIndex === files.length - 1}
          >
            <ArrowForward
              sx={{ backgroundColor: "white", borderRadius: "20px" }}
            />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
}

export { ModalCarouselFileViewer };
