// eslint-disable-next-line import/prefer-default-export
export const projectSchedule = {
  "% Complete": "Completado",
  "$ Project Cost": "$ Costo del Proyecto",
  "Add New": "Añadir Nuevo",
  "All Colors": "Todos los Colores",
  "All Dates": "Todas las Fechas",
  "Are you sure you want to delete the activity": "¿Está seguro de querer eliminar la actividad?",
  "Are you sure you want to delete the group": "¿Está seguro de querer eliminar el grupo?",
  "Are you sure you want to delete the milestone": "¿Está seguro de querer eliminar el hito?",
  "Are you sure?": "¿Está seguro?",
  "Choose color": "Elige color",
  "Copy Link": "Copiar enlace",
  "Create Activity": "Crear Actividad",
  "Create Group": "Crear Grupo",
  "Create Milestone": "Crear Hito",
  "Create Roadblock": "Crear Bloqueo",
  "Daily Reports": "Reportes",
  "Date Format:": "Formato de Fecha:",
  "Default Color": "Color Predeterminado",
  "Delete Activity": "Eliminar Actividad",
  "Delete Group": "Eliminar Grupo",
  "Delete Milestone": "Eliminar Hito",
  "Dependent On": "Dependiente De",
  "Depends On": "Depende De",
  "Due Date": "Fecha de Vencimiento",
  "End Date": "Fecha de Fin",
  "Expand All Groups": "Expandir Todos los Grupos",
  "Export to Excel": "Exportar a Excel",
  "Hide Completed": "Ocultar Completados",
  "Import from Excel": "Importar de Excel",
  "Import or export": "Importar o exportar",
  "Invite people": "Invitar personas",
  "No other activity depends on this activity.": "Ninguna otra actividad depende de esta.",
  "Project Cost": "Costo del Proyecto",
  "Project Schedule": "Programa de Obra",
  "Real Duration": "Duración Real",
  "Real End Date": "Fecha de Fin Real",
  "Real End": "Final Real",
  "Real Start Date": "Fecha de Inicio Real",
  "Real Start": "Inicio Real",
  "Real Times": "Tiempos Reales",
  "Scheduled Duration": "Duración Programada",
  "Scheduled End Date": "Fecha de Fin Programada",
  "Scheduled End": "Final Programado",
  "Scheduled Start Date": "Fecha de Inicio Programada",
  "Scheduled Start": "Inicio Programado",
  "Scheduled Times": "Tiempos Programados",
  "Select a type": "Seleccione un tipo",
  "Select Assignees": "Seleccionar Asignados",
  "Select Colors": "Seleccionar Colores",
  "Show Completed": "Mostrar Completados",
  "Start Date": "Fecha de Inicio",
  "Submitting...": "Enviando...",
  "There are no comments in this activity.": "No hay comentarios en esta actividad.",
  "There are no comments in this group.": "No hay comentarios en este grupo.",
  "There are no dependents/depedencies of this activity.": "No hay dependientes/dependencias de esta actividad.",
  "There are no histories in this group.": "No hay historiales en este grupo.",
  "There are no items in this module.": "No hay elementos en este módulo.",
  "There are no more daily reports available.": "No hay más reportes disponibles.",
  "There are no more documents available.": "No hay más documentos disponibles.",
  "There are no more drawings available.": "No hay más planos disponibles.",
  "There are no more inspections available.": "No hay más inspecciones disponibles.",
  "There are no more meetings available.": "No hay más reuniones disponibles.",
  "There are no more RFIs available.": "No hay más RFIs disponibles.",
  "There are no more tasks available.": "No hay más tareas disponibles.",
  "There is history in this activity.": "Hay historial en esta actividad.",
  "There is history in this group.": "Hay historial en este grupo.",
  "This activity does noes depend on any other activity.": "Esta actividad no depende de ninguna otra actividad.",
  "This feature is under construction.": "Esta función está en construcción.",
  "Update Roadblock": "Actualizar Obstáculo",
  "You have marked this milestone as completed.": "Ha marcado este hito como completado.",
  "You have marked this milestone as not completed.": "Ha marcado este hito como no completado.",
  "You have not invited anyone yet.": "Aún no has invitado a nadie.",
  Activities: "Actividades",
  Activity: "Actividad",
  Assigned: "Asignado",
  Assignees: "Asignados",
  Budget: "Presupuesto",
  Cancel: "Cancelar",
  Chart: "Gráfico",
  CLOSE: "CERRADO",
  Close: "Cerrar",
  Colors: "Colores",
  Comments: "Comentarios",
  Cost: "Costo",
  Create: "Crear",
  Day: "Día",
  Dependencies: "Dependencias",
  Description: "Descripción",
  Difference: "Diferencia",
  Documents: "Documentos",
  Drawings: "Planos",
  Duration: "Duración",
  Email: "Correo electrónico",
  Error: "Error",
  Everyone: "Todos",
  Expenses: "Gastos",
  Files: "Archivos",
  Grid: "Cuadrícula",
  History: "Historia",
  Inspections: "Inspecciones",
  Invite: "Invitar",
  Meetings: "Reuniones",
  Milestone: "Hito",
  Name: "Nombre",
  No: "No",
  OPEN: "ABIERTO",
  Permissions: "Permisos",
  Predecessor: "Predecesor",
  Progress: "Progreso",
  RESCHEDULE: "REPROGRAMADO",
  RFIs: "RFIs",
  Roadblocks: "Obstáculos",
  Select: "Seleccionar",
  Send: "Guardar",
  Share: "Compartir",
  Status: "Estado",
  Success: "Éxito",
  Successor: "Sucesor",
  Tasks: "Tareas",
  Title: "Título",
  to: "a",
  Total: "Total",
  Update: "Actualizar",
  Week: "Semana",
  Yes: "Sí",
  "DOWNLOAD PDF": "DESCARGAR PDF",
  "Additional Columns": "Columnas Adicionales",
  ACTIVITY_CREATED: "La actividad fue creada",
  ACTIVITY_UPDATED_ASSIGNEES_CREATED: 'Se añadió el asignado "{{ name }}"',
  ACTIVITY_UPDATED_ASSIGNEES_REMOVED: 'Se eliminó el asignado "{{ name }}"',
  ACTIVITY_UPDATED_COLOR: 'El color cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_DESCRIPTION: 'La descripción cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_ID_ACTIVITY_UPDATED: 'El predecesor cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_PROGRESS: 'El progreso cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_CREATED: 'Se añadió la línea de gasto "{{ title }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_REMOVED: 'Se eliminó la línea de gasto "{{ title }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_AMOUNT:
    'El monto cambió de "{{ old }}" a "{{ new }}" en la línea de gasto "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_FILE:
    'El archivo cambió de "{{ old }}" a "{{ new }}" en la línea de gasto "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_TITLE:
    'El título cambió de "{{ old }}" a "{{ new }}" en la línea de gasto "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_BUDGET: 'El presupuesto cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_REAL_TIMES_END: 'El fin real cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_REAL_TIMES_START: 'El inicio real cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_END: 'El fin programado cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_START: 'El inicio programado cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_TITLE: 'El título cambió de "{{ old }}" a "{{ new }}"',
  DAILY_REPORT_CREATED: 'Se añadió el informe diario "{{ title }}"',
  DAILY_REPORT_REMOVED: 'Se eliminó el informe diario "{{ title }}"',
  DOCUMENT_CREATED: 'Se añadió el documento "{{ name }}"',
  DOCUMENT_REMOVED: 'Se eliminó el documento "{{ name }}"',
  DRAWING_CREATED: 'Se añadió el dibujo "{{ name }}"',
  DRAWING_REMOVED: 'Se eliminó el dibujo "{{ name }}"',
  INSPECTION_CREATED: 'Se añadió la inspección "{{ title }}"',
  INSPECTION_REMOVED: 'Se eliminó la inspección "{{ title }}"',
  MEETING_CREATED: 'Se añadió la reunión "{{ title }}"',
  MEETING_REMOVED: 'Se eliminó la reunión "{{ title }}"',
  RFI_CREATED: 'Se añadió el RFI "{{ title }}"',
  RFI_REMOVED: 'Se eliminó el RFI "{{ title }}"',
  ROADBLOCK_CREATED: 'Se creó el obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_ASSIGNEES_CREATED: 'Se añadió el asignado "{{ name }}" al obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_ASSIGNEES_REMOVED: 'Se eliminó el asignado "{{ name }}" del obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_DATE: 'La fecha cambió de "{{ old }}" a "{{ new }}" en el obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_DESCRIPTION:
    'La descripción cambió de "{{ old }}" a "{{ new }}" en el obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_STATUS: 'El estado cambió de "{{ old }}" a "{{ new }}" en el obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_TYPE: 'El tipo cambió de "{{ old }}" a "{{ new }}" en el obstáculo "{{ description }}"',
  TASK_CREATED: 'Se añadió la tarea "{{ title }}"',
  TASK_REMOVED: 'Se eliminó la tarea "{{ title }}"',
};
