import { Close, Lock, LockOpen } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useGetProjectByIdQuery } from "../../../../features/project/projectApiSlice";
import { reportCategories } from "../../../../constants";

function ModalModulePendings({ isOpen, onClose, data }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");

  const { data: project } = useGetProjectByIdQuery(idProject, {
    skip: !idProject || !isOpen,
  });

  const moduleNames = {
    rfi: "RFIs",
    reports: tGeneral("reports"),
    tasks: tGeneral("tasks"),
    submittal: tGeneral("submittals"),
    vobos: "VoBos",
    delays: "Delays",
    punchList: "Punch List",
    meetings: tGeneral("meetings"),
    deliveries: "Deliveries",
    issues: "Issues",
  };
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(5);
  const objects = useMemo(() => {
    if (data) {
      const { data: objects } = { ...data };
      const sortedData = objects
        ?.slice()
        .sort((a, b) => Number(b.number) - Number(a.number));
      return sortedData;
    }
    return [];
  }, [data]);

  const columns = useMemo(() => {
    const cols = [
      {
        headerName: tGeneral("privacy"),
        field: "bPrivate",
        renderCell: (params) => (params.value ? <Lock /> : <LockOpen />),
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
      },
      {
        headerName: tGeneral("number"),
        field: "number",
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (params) => (
          <Button
            variant="text"
            color="info"
            sx={{ textDecoration: "underline" }}
            onClick={() =>
              navigate(
                `../${
                  data?.moduleName === "reports"
                    ? "dailyReports"
                    : data?.moduleName
                }/${params.row.id}`,
              )
            }
          >
            {params.value}
          </Button>
        ),
      },
      {
        headerName: tGeneral("title"),
        field: "title",
        flex: 4,
        sortable: false,
      },
      {
        headerName: tGeneral("status"),
        field: "status",
        renderCell: (params) =>
          moment(new Date(params.row.dueDate)).diff(new Date()) > 0 ? (
            <Chip
              sx={{ color: "white", width: "70%" }}
              label={tGeneral("onTime")}
              color="primary"
            />
          ) : (
            <Chip
              label={tGeneral("overdue")}
              sx={{
                backgroundColor: "#E05353",
                color: "white",
                width: "70%",
              }}
            />
          ),
        flex: 2,
        headerAlign: "center",
        align: "center",
        sortable: false,
      },
      {
        headerName: tGeneral("assigned"),
        field: "assignedTo",
        flex: 2,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (params) => (
          <AvatarGroup max={3}>
            {params?.value?.map((user) => (
              <Tooltip title={`${user.firstName} ${user.lastName}`}>
                <Avatar
                  src={user.urlAvatar}
                  title={`${user.firstName} ${user.lastName}`}
                />
              </Tooltip>
            ))}
          </AvatarGroup>
        ),
      },
      {
        headerName:
          data?.moduleName === "meetings"
            ? tGeneral("date")
            : tGeneral("due_date"),
        field: "dueDate",
        renderCell: (params) =>
          moment.utc(new Date(params.value)).format("D MMMM YYYY"),
        flex: 2,
        headerAlign: "center",
        align: "center",
        sortable: false,
      },
    ];
    if (data?.moduleName === "reports") {
      cols.push({
        headerName: tGeneral("category"),
        renderCell: (params) => (
          <Chip
            label={tDailyReports(
              reportCategories[(params?.value ?? 1) - 1]?.label,
            )}
            sx={{
              color: "white",
              backgroundColor:
                reportCategories[(params?.value ?? 1) - 1]?.color,
              width: "100%",
            }}
          />
        ),
        field: "category",
        flex: 2,
        headerAlign: "center",
        align: "center",
        sortable: false,
      });
    }
    return cols;
  }, [data, navigate, tDailyReports, tGeneral]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{ width: "60%", height: "550px", p: "36px", borderRadius: "20px" }}
      >
        <Box display="flex" justifyContent="space-between" mb="22px">
          <Typography fontSize="1.5625rem" fontWeight="bold">
            {moduleNames[data?.moduleName]}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <DataGrid
          sx={{
            height: "90%",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              fontSize: "1rem",
            },
            "& .MuiDataGrid-cell": {
              color: "black",
              fontSize: "1rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          }}
          rows={objects}
          columns={columns}
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          onPageSizeChange={(value) => setPageSize(value)}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          hideFooterSelectedRowCount
        />
      </Card>
    </Modal>
  );
}

export { ModalModulePendings };
