import { Send, Upload } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectTheme } from "../../features/preferences/preferencesSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
import { FilePreviewHorizontalScroll } from "../shared";
import Voicenote from "../Voicenote";

function MessageReply({
  reply,
  placeholder,
  isLoading,
  showConnection = true,
  enableFiles = true,
  disabled = false,
  requiredText = false,
}) {
  const fileRef = useRef();
  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const user = useSelector(selectCurrentUser);
  const darkMode = useSelector(selectTheme);

  // Translations
  const { t: tRfis } = useTranslation("rfis");
  const { t: tGeneral } = useTranslation("general");

  const handleOnReply = () => {
    reply(message, selectedFiles);
    setMessage("");
    setSelectedFiles([]);
    if (fileRef.current) {
      fileRef.current.value = null;
    }
  };

  const resetMessage = () => {
    setMessage("");
    setSelectedFiles([]);
  };

  const handleOnDeleteFile = (file) => {
    const tempFiles = [...selectedFiles];
    setSelectedFiles(tempFiles.filter((f) => f.name !== file.name));
  };

  return (
    <Box>
      {selectedFiles.length > 0 && (
        <FilePreviewHorizontalScroll
          files={selectedFiles}
          onAddPhotos={(files) => setSelectedFiles(files)}
          onDelete={handleOnDeleteFile}
        />
      )}
      <Box
        display="flex"
        pt="25px"
        flex={1}
        position="relative"
        alignItems="center"
        mb="30px"
      >
        {showConnection && (
          <Box
            sx={{
              width: "30px",
              border: "1px solid #F2F2F2",
              position: "absolute",
              top: 50,
              left: -21,
            }}
          />
        )}
        <Avatar
          sx={{ backgroundColor: "white", p: "2px" }}
          title={`${user?.firstName} ${user?.lastName}`}
          src={user?.urlAvatar}
        />
        <FormControl
          fullWidth
          sx={{
            ml: "10px",
            minHeight: "55px",
          }}
        >
          <OutlinedInput
            disabled={isLoading || disabled}
            placeholder={placeholder}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            multiline
            maxRows={5}
            endAdornment={
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {enableFiles && (
                  <>
                    <Voicenote
                      submitVoicenote={true}
                      handleVoicenote={(message, files) =>
                        reply(message, files)
                      }
                      resetMessage={() => resetMessage()}
                    />
                    <IconButton
                      disabled={isLoading || disabled}
                      onClick={() => fileRef?.current?.click()}
                    >
                      <input
                        type="file"
                        multiple
                        style={{ display: "none" }}
                        ref={fileRef}
                        onChange={(e) =>
                          setSelectedFiles(Array.from(e.currentTarget.files))
                        }
                      />
                      <Upload />
                    </IconButton>
                  </>
                )}
                {isLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  <Button
                    onClick={() => {
                      if (requiredText) {
                        if (message.length > 0) handleOnReply();
                      } else {
                        handleOnReply();
                      }
                    }}
                    disabled={isLoading || disabled}
                  >
                    {tGeneral("send")} <Send />
                  </Button>
                )}
              </Box>
            }
            sx={{
              paddingY: 1,
              paddingX: 2,
              backgroundColor: darkMode ? "black" : "white",
            }}
          />
        </FormControl>
      </Box>
    </Box>
  );
}

export { MessageReply };
