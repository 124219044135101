import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import {
  useCreateAlbumMutation,
  useUpdateAlbumMutation,
} from "../../../features/project/imageApiSlice";
import { selectCurrentProject } from "../../../features/project/projectSlice";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function CreateAlbum({ isOpen, onClose, currentAlbum }) {
  const { t: tPhotos } = useTranslation("photos");
  const { t: tGeneral } = useTranslation("general");
  const [album, setAlbum] = React.useState();
  const [
    createAlbum,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateAlbumMutation();

  const [
    updateAlbum,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateAlbumMutation();

  const currentProject = useSelector(selectCurrentProject);

  React.useEffect(() => {
    if (currentAlbum) {
      setAlbum(currentAlbum.name);
    }
  }, [currentAlbum, isOpen]);

  React.useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      Swal.fire({
        icon: "success",
        title: `${tPhotos("album")} ${
          currentAlbum ? tGeneral("updated") : tGeneral("created")
        }`,
        showConfirmButton: false,
        timer: 2000,
      });
      onClose();
    }
    if (isCreateError || isUpdateError) {
      Swal.fire({
        icon: "error",
        title: currentAlbum ? updateError.message : createError.message,
        showConfirmButton: false,
        timer: 2000,
      });
      onClose();
    }
  }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

  const onAction = async () => {
    if (currentAlbum) {
      await updateAlbum({ name: album, id: currentAlbum.id }).unwrap();
    } else {
      await createAlbum({
        name: album,
        idProject: currentProject.id,
        bPrivate: false,
      }).unwrap();
    }
    if (isCreateSuccess || isUpdateSuccess) {
      onClose();
    }
    if (isCreateError || isUpdateError) {
      console.error(createError ?? updateError);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          width: "30%",
          height: "auto",
          borderRadius: "20px",
          p: "25px 40px 40px 40px",
        }}
      >
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <Typography fontSize="1.562rem" fontWeight="bold">
            {currentAlbum ? tGeneral("rename") : tGeneral("new")}{" "}
            {tPhotos("album")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Box mt="40px" data-tour="add-album-2">
          <TextField
            onChange={(event) => {
              setAlbum(event.target.value);
            }}
            value={album}
            required
            fullWidth
            variant="outlined"
            label={tPhotos("albumName")}
            InputLabelProps={{ sx: { color: "black" } }}
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" mt="20px">
          <Button
            fullWidth
            color="secondary"
            sx={{
              marginRight: "20px",
              py: "1rem",
              border: "1px solid black",
              fontSize: "1rem",
            }}
            onClick={onClose}
            variant="outlined"
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            fullWidth
            disabled={!album || isCreateLoading || isUpdateLoading}
            variant="contained"
            color="primary"
            onClick={onAction}
            sx={{ py: "1rem", fontSize: "1rem" }}
            data-tour="add-album-3"
          >
            {currentAlbum ? tGeneral("save") : tGeneral("add")}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { CreateAlbum };
