import { Box, CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useGetUserQuery } from "../userSettings/userSettingsApiSlice";
import { selectCurrentToken } from "./authSlice";

function RequireAuth() {
  const accessToken = useSelector(selectCurrentToken);
  const { data, isLoading } = useGetUserQuery({ skip: !accessToken });
  const location = useLocation();

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (!accessToken) {
    return <Navigate to="login" state={{ from: location }} replace />;
  }

  if (data && !data.onboardingDone) {
    return <Navigate to="onboarding" state={{ from: location }} replace />;
  }

  return <Outlet />;
}
export default RequireAuth;
