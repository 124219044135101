// React imports
import { useEffect, useRef } from "react";

// Third-party library imports
import { DndContext, DragOverlay, pointerWithin } from "@dnd-kit/core";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Project-specific absolute imports
import Can from "../../../utils/can";

// Relative imports
import DroppableBox from "./DroppableBox";
import Widget from "./Widget";
import { useSelector, useDispatch } from "react-redux";
import {
  setWidgets,
  deleteWidget,
  setEditing,
  setActiveWidget,
  setDraggedWidget,
  selectAllWidgets,
  selectActiveWidgetId,
  selectIsEditing,
  selectDraggedWidget,
} from "../../../features/project/analyticsSlice";

const WidgetContainer = () => {
  const { t: tAnalytics } = useTranslation("analytics");
  const dispatch = useDispatch();

  // State to store the widgets and their positions
  const widgets = useSelector(selectAllWidgets);
  // State to store the ID of the active widget
  const activeWidgetId = useSelector(selectActiveWidgetId);
  // State to toggle the edit mode
  const isEditing = useSelector(selectIsEditing);
  // State to store the data of the dragged item
  const draggedWidget = useSelector(selectDraggedWidget);

  // Handle the drop of a widget
  const handleDrop = (rowIndex, colIndex) => {
    if (!draggedWidget) return;

    // Mapping for widget sizes to their column spans
    const sizeMapping = {
      sm: { cols: 1 },
      md: { cols: 2 },
      lg: { cols: 4 },
    };

    const { cols } = sizeMapping[draggedWidget.size];
    let targetIndex = `${rowIndex}-${colIndex}`;

    // Check if the widget exceeds the available columns
    if (colIndex + cols > 4) {
      restoreDraggedItem();
      return;
    }

    const occupiedIndices = [];

    // Check if the target cells are occupied by other widgets
    for (let c = 0; c < cols; c++) {
      const currentIndex = `${rowIndex}-${colIndex + c}`;
      if (
        widgets[currentIndex] &&
        widgets[currentIndex].id !== draggedWidget.id
      ) {
        restoreDraggedItem();
        return;
      }
      occupiedIndices.push(currentIndex);
    }

    const newWidgets = { ...widgets };

    // Update the new widget positions
    occupiedIndices.forEach((idx) => {
      newWidgets[idx] = { ...draggedWidget, mainIndex: targetIndex };
    });

    dispatch(setWidgets(newWidgets));
    dispatch(setDraggedWidget(null));
  };

  // Restore the dragged item to its original position
  const restoreDraggedItem = () => {
    if (!draggedWidget) return;

    // Mapping for widget sizes to their column spans
    const sizeMapping = {
      sm: { cols: 1 },
      md: { cols: 2 },
      lg: { cols: 4 },
    };

    const { cols } = sizeMapping[draggedWidget.size];
    const mainIndex = draggedWidget.mainIndex;

    const [rowIndex, colIndex] = mainIndex.split("-").map(Number);

    const occupiedIndices = [];

    // Calculate the indices that were originally occupied by the dragged item
    for (let c = 0; c < cols; c++) {
      const currentIndex = `${rowIndex}-${colIndex + c}`;
      occupiedIndices.push(currentIndex);
    }

    const newWidgets = { ...widgets };

    // Restore the dragged item to its original position
    occupiedIndices.forEach((idx) => {
      newWidgets[idx] = { ...draggedWidget, mainIndex: mainIndex };
    });

    dispatch(setWidgets(newWidgets));
    dispatch(setDraggedWidget(null));
  };

  // Toggle edit mode
  const handleEditClick = () => {
    dispatch(setEditing(!isEditing));
    dispatch(setActiveWidget(null)); // Deactivate widget when editing mode is toggled
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        dispatch(setActiveWidget(null));
      }
    };

    if (activeWidgetId !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeWidgetId, dispatch]);

  return (
    <DndContext
      collisionDetection={pointerWithin}
      // Handle the start of dragging
      onDragStart={(event) => {
        const { active } = event;
        const item = active.data.current;
        dispatch(setDraggedWidget(item));

        // Remove the dragged item from its original position
        dispatch(deleteWidget(item.id));
      }}
      onDragEnd={(event) => {
        // Extract the `over` property from the event object
        const { over } = event;

        // Check if the dragged item is dropped over a valid droppable area
        if (over) {
          // Extract the row and column indices from the `over.id`
          const [rowIndex, colIndex] = over.id.split("-").map(Number);
          // Call the handleDrop function with the extracted indices
          handleDrop(rowIndex, colIndex);
        } else {
          // If the item is not dropped over a valid area, restore it to its original position
          restoreDraggedItem();
        }
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          flexGrow: 1,
          padding: 2,
          backgroundColor: "rgba(255, 255, 255, 0.4)",
          position: "relative",
          borderRadius: "15px",
          margin: "1%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center", // Align children at the top
            height: "auto", // Ensure height is auto to hug contents
            mb: "16px",
          }}
        >
          <Typography
            sx={{ marginRight: "8px", fontWeight: 700, fontSize: "20px" }}
          >
            {tAnalytics("projectStatistics")}
          </Typography>
          <Can I="delete" a="analytics">
            <IconButton
              onClick={handleEditClick}
              sx={{
                color: isEditing ? "#F6D336" : "gray",
                padding: "0", // Remove default padding
                marginTop: "0", // Ensure it starts at the top
              }}
              disabled={Object.keys(widgets).length === 0} // Disable button if widgets state is empty
            >
              <EditIcon />
            </IconButton>
          </Can>
        </Box>
        <Grid container spacing={2}>
          {Array.from({ length: 2 }).map((_, rowIndex) => (
            <Grid item xs={12} key={rowIndex}>
              <Grid container spacing={2}>
                {[...Array(4)].map((_, colIndex) => (
                  <Grid item xs={3} key={colIndex}>
                    <DroppableBox
                      key={`${rowIndex}-${colIndex}`}
                      rowIndex={rowIndex}
                      colIndex={colIndex}
                      handleDrop={handleDrop}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* Drag overlay to show the widget being dragged */}
      <DragOverlay
        style={{ zIndex: 1500 }} // Ensure the DragOverlay is above other components
      >
        {draggedWidget ? (
          <Widget
            id={draggedWidget.id}
            type={draggedWidget.type}
            size={draggedWidget.size}
            draggedStyle={{ width: "100%" }} // Ensure proper size while dragging
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default WidgetContainer;
