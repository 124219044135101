import {
  Checklist,
  Close,
  Done,
  EditNote,
  PlayArrow,
  RemoveRedEye,
} from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";

import Alert from "../../../assets/alert.svg";
import Calendar from "../../../assets/calendar.png";
import Categories from "../../../assets/categories.png";
import Delivery from "../../../assets/delivery.svg";
import Star from "../../../assets/star.svg";
import Location from "../../../assets/your-location.png";
import {
  useGetTaskByIdQuery,
  useSetDoingTaskMutation,
  useSetDoneTaskMutation,
} from "../../../features/project/modules/tasks/tasksApiSlice";
import {
  selectSelectedTasks,
  setEditingTask,
} from "../../../features/project/modules/tasks/tasksSlice";
import { Question } from "../../question";

import { useExportTaskMutation } from "../../../features/exportPdf";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import CaslContext from "../../../utils/caslContext";
import {
  DetailsHeader,
  ModalLastEdit,
  ModalSeenBy,
  ModalWithAction,
  RelatedUsersDetailsCard,
  TaskDetailsSkeletonLoading,
} from "../../shared";
import { ModalFileViewer } from "../../shared/ModalFileViewer";
import SubtaskDetail from "./Subtasks/SubtaskDetail";
import { useAppTourContext } from "../../supportButton/context/context";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import pdf from "../../../utils/pdf";

function TaskDetails() {
  // Translation
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");
  const { idEnterprise, idProject, idTask } = useParams();
  const [closingTask, setClosingTask] = useState(null);
  const [checkingSubtask, setCheckingSubtask] = useState(false);
  const [isLastSeenOpen, setIsLastSeenOpen] = useState(false);
  const [isLastEditOpen, setIsLastEditOpen] = useState(false);

  const ability = useContext(CaslContext);

  function formatDate(simpleDate) {
    const date = new Date(simpleDate);

    const month = [
      tGeneral("january"),
      tGeneral("february"),
      tGeneral("march"),
      tGeneral("april"),
      tGeneral("may"),
      tGeneral("june"),
      tGeneral("july"),
      tGeneral("august"),
      tGeneral("september"),
      tGeneral("october"),
      tGeneral("november"),
      tGeneral("december"),
    ];

    const dateBirthFormat = `${date.getDate()} ${
      month[date.getMonth()]
    } ${date.getFullYear()}`;

    return dateBirthFormat;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [setDoingTask] = useSetDoingTaskMutation();
  const [setDoneTask] = useSetDoneTaskMutation();

  const {
    data: task,
    isLoading: isTaskLoading,
    isFetching: isTaskFetching,
    isSuccess: getTaskByIdIsSuccess,
  } = useGetTaskByIdQuery(idTask, {
    skip: !idTask,
  });

  const handleClose = useCallback(
    (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setClosingTask(null);
      const newUrl = location.pathname.replace(`/${idTask}`, "");
      navigate(newUrl, { replace: true });
    },
    [idTask, location, navigate],
  );

  const handleActionClick = useCallback(() => {
    switch (task?.status) {
      case "backlog":
        setDoingTask({ id: task?.id });
        handleClose();
        break;
      case "doing":
        setClosingTask(task.id);
        break;
      case "done":
        dispatch(setEditingTask(task));
        navigate(location.pathname.replace(`${idTask}`, "form"), {
          replace: true,
        });
        break;

      default:
        break;
    }
  }, [dispatch, handleClose, task, idTask, location, navigate, setDoingTask]);

  const features = [
    {
      id: 1,
      title: tTasks("creationDate"),
      value: formatDate(`${task?.createDate.split("T")[0]}T10:00:00.000Z`),
      icon: Calendar,
    },
    {
      id: 2,
      title: tTasks("deliveryDate"),
      value: formatDate(`${task?.dueDate.split("T")[0]}T10:00:00.000Z`),
      icon: Delivery,
    },
    {
      id: 3,
      title: tTasks("closeDate"),
      value: task?.closedDate
        ? formatDate(`${task?.dueDate.split("T")[0]}T10:00:00.000Z`)
        : tGeneral("still_open"),
      icon: Star,
    },
    {
      id: 4,
      title: tTasks("category"),
      value: task?.category || "N/A",
      icon: Categories,
    },
    {
      id: 5,
      title: tTasks("priority"),
      value: task?.priority ? tTasks(task?.priority) : "N/A",
      icon: Alert,
    },
    {
      id: 6,
      title: tGeneral("location"),
      value: task?.location || "N/A",
      icon: Location,
    },
  ];
  const [pdfUrl, setPdfUrl] = React.useState(null);

  const user = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const selectedTasks = useSelector(selectSelectedTasks);

  const exportPdfDocument = async () => {
    await pdf({
      data: {},
      token,
      url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/tasks/${idTask}`,
      download: `${task.title}.pdf`,
    });
  };

  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();

  const showButton = useMemo(() => {
    if (task && user) {
      return (
        task.status === "doing" &&
        (task.createdBy === user.id || ability.can("superAdmin", "tasks"))
      );
    }
    return false;
  }, [task, user, ability]);

  const showStartButton = useMemo(() => {
    if (task && user) {
      return (
        task.status === "backlog" &&
        (task.assignedTo.indexOf(user.id) > -1 ||
          ability.can("superAdmin", "tasks"))
      );
    }
    return false;
  }, [task, user, ability.rules]);

  const disableChat = useMemo(() => {
    if (task && user) {
      let isDisabled = task.status !== "doing";

      if (!isDisabled) {
        isDisabled =
          task.assignedTo.indexOf(user.id) < 0 &&
          task.createdBy !== user.id &&
          task.distributionList.indexOf(user.id) < 0 &&
          ability.cannot("superAdmin", "tasks");
      }

      return isDisabled;
    }
    return false;
  }, [task, user, ability.rules]);

  const disableChecklist = useMemo(() => {
    if (task && user) {
      return (
        task.status !== "doing" ||
        (task.assignedTo.indexOf(user.id) < 0 && task.createdBy !== user.id)
      );
    }
    return false;
  }, [task, user]);

  const getMainActionObject = useMemo(() => {
    if (!task?.bActive) return null;
    if (showStartButton) {
      return {
        icon: <PlayArrow />,
        label: tTasks("startTask"),
        onClick: handleActionClick,
      };
    }
    if (showButton) {
      return {
        icon: <Done data-tour="view-tasks-7" />,
        label:
          task?.status === "doing"
            ? tTasks("closeTask").toUpperCase()
            : tGeneral("edit").toUpperCase(),
        onClick: handleActionClick,
      };
    }
    return null;
  }, [task, showStartButton, showButton, tGeneral, tTasks, handleActionClick]);

  useLayoutEffect(() => {
    if (
      task &&
      getTaskByIdIsSuccess &&
      tourActive &&
      stepIndex === 1 &&
      currentGuideMe === "tasks-view"
    ) {
      if (selectedTasks.includes(task?.id)) {
        setTimeout(() => {
          setState({ run: true });
        });
      }
    }
  }, [
    tourActive,
    stepIndex,
    currentGuideMe,
    steps,
    selectedTasks,
    task,
    getTaskByIdIsSuccess,
    setState,
  ]);

  return (
    <Drawer
      open={Boolean(idTask)}
      anchor="right"
      disableScrollLock
      onClose={handleClose}
      PaperProps={{ sx: { width: "40%" } }}
    >
      {isTaskLoading || (isTaskFetching && !checkingSubtask) ? (
        <TaskDetailsSkeletonLoading />
      ) : (
        <Box display="flex" flexDirection="column" overflow="hidden">
          {/* First row: close icon & actions */}
          <DetailsHeader
            number={task?.taskNumber}
            status={task?.status}
            bPrivate={task?.bPrivate}
            title={task?.title}
            mainAction={getMainActionObject}
            showMainAction
            titleBreakLine
            backIcon={<Close />}
            onBack={handleClose}
            actions={[
              {
                onClick: () => setIsLastEditOpen(true),
                icon: <EditNote />,
                label: tGeneral("lastEdit"),
              },
              {
                onClick: () => setIsLastSeenOpen(true),
                icon: <RemoveRedEye />,
                label: tGeneral("lastSeen"),
              },
              {
                icon: <PictureAsPdfIcon />,
                label: tGeneral("exportPdf"),
                onClick: () => {
                  exportPdfDocument();
                },
              },
            ]}
          />
          {/* Column (backlog, doing, done) */}
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            columnGap={1}
            px="10px"
          >
            <Typography fontSize="16px">{tTasks("inList")}:</Typography>
            <Typography
              fontSize="16px"
              sx={{
                textDecoration: "underline",
              }}
            >
              {tTasks(task?.status)}
            </Typography>
          </Box>
          <Box px="10px" flex={1} sx={{ overflowY: "auto" }} pt="10px">
            {/* Creator & assignees */}
            <RelatedUsersDetailsCard
              lists={[
                { users: [task?.createdByUser], label: tGeneral("creator") },
                { users: task?.assignedToUsers, label: tGeneral("assignees") },
              ]}
            />
            {/* Info grid */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 2,
                marginTop: 2,
              }}
              data-tour="view-tasks-3"
            >
              {features.map((feature) => (
                <Paper
                  key={feature.id}
                  elevation={2}
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 2,
                  }}
                >
                  <img
                    src={feature.icon}
                    alt={feature.title}
                    style={{ height: 40 }}
                  />
                  <Typography
                    fontSize="14px"
                    textAlign="center"
                    color="#707070"
                  >
                    {feature.title}
                  </Typography>
                  {feature.value !== "STILL OPEN" && (
                    <Typography
                      fontSize="16px"
                      textAlign="center"
                      fontWeight="medium"
                    >
                      {feature.value}
                    </Typography>
                  )}
                  {feature.value === "STILL OPEN" && (
                    <Typography
                      fontSize="16px"
                      textAlign="center"
                      fontWeight="medium"
                      color="white"
                      bgcolor="#FFC42D"
                      px={1}
                      py="4px"
                      borderRadius={10}
                    >
                      {feature.value}
                    </Typography>
                  )}
                </Paper>
              ))}
            </Box>
            {/* Subtasks */}
            <Box display="flex" flexDirection="column" marginTop={3}>
              <Box display="flex" alignItems="center" columnGap={1}>
                <Checklist />
                <Typography fontSize="1.125rem" fontWeight="bold">
                  {tTasks("subtasks")}
                </Typography>
              </Box>
              <SubtaskDetail
                subtasks={task?.subtasks}
                disable={disableChecklist || isTaskFetching}
                onCheck={(checkValue) => {
                  setCheckingSubtask(checkValue);
                }}
              />
            </Box>
            {task?.idQuestion && (
              <Box marginTop={3} data-tour="view-tasks-4">
                <Question
                  object={task}
                  title={tGeneral("description")}
                  moduleName="tasks"
                  disabled={disableChat}
                  requiredText
                  showTitleIcon
                />
              </Box>
            )}
          </Box>
          {Boolean(pdfUrl) && (
            <ModalFileViewer
              file={{ url: pdfUrl }}
              isOpen={Boolean(pdfUrl)}
              onClose={() => setPdfUrl(null)}
            />
          )}
        </Box>
      )}
      <ModalWithAction
        actionLabel={tGeneral("accept")}
        action={() => {
          setDoneTask({ id: closingTask });
          handleClose();
        }}
        isOpen={Boolean(closingTask)}
        onClose={() => setClosingTask(null)}
        title={tTasks("closeTask")}
        content={tTasks("closeTaskMessage")}
      />
      <ModalLastEdit
        idObject={idTask}
        isOpen={isLastEditOpen}
        onClose={() => setIsLastEditOpen(false)}
      />
      <ModalSeenBy
        idObject={idTask}
        isOpen={isLastSeenOpen}
        onClose={() => setIsLastSeenOpen(false)}
      />
    </Drawer>
  );
}

export default TaskDetails;
