import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import MaterialTable from "material-table";
import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetTodoListQuery,
  useRestoreProjectTodosMutation,
} from "../../../../features/project/projectApiSlice";

function ModalTodoRecycleBin({ isOpen, onClose, idProject }) {
  const { t: tGeneral } = useTranslation("general");
  const columns = [
    {
      title: tGeneral("date"),
      field: "limitDate",
      render: (rowData) => moment(rowData.limitDate).format("D MMMM YYYY"),
    },
    { title: tGeneral("title"), field: "name" },
  ];
  const [selectedTodos, setSelectedTodos] = useState([]);
  const { data } = useGetTodoListQuery({ idProject, bActive: false });
  const [restoreTodos] = useRestoreProjectTodosMutation();

  const todos = useMemo(() => {
    if (data) {
      return data.map((todo) => ({ ...todo }));
    }
    return [];
  }, [data]);

  const handleRestoreTodos = async () => {
    await restoreTodos({ ids: selectedTodos });
  };

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "50%",
          height: "550px",
          p: "32px 40px",
          borderRadius: "20px",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize="1.5625rem" fontWeight="bold">
            {tGeneral("trashCan")} ({tGeneral("todoList")})
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="row-reverse" my="16px">
          <Button
            disabled={!selectedTodos.length}
            variant="contained"
            sx={{ p: "15px 40px" }}
            onClick={handleRestoreTodos}
          >
            {tGeneral("restore")}
          </Button>
        </Box>
        <MaterialTable
          columns={columns}
          components={{ Toolbar: () => null }}
          options={{
            selection: true,
            search: false,
            showTitle: false,
          }}
          data={todos}
          localization={tableLocalization}
          onSelectionChange={(rows) =>
            setSelectedTodos(rows.map((row) => row.id))
          }
        />
      </Card>
    </Modal>
  );
}

export { ModalTodoRecycleBin };
