import { Delete, Task } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentGuideMe } from "../../features/project/projectSlice";
import Can from "../../utils/can";
import { StyledMaterialReactTable, TooltipIconButton } from "../shared";
import { useAppTourContext } from "../supportButton/context/context";
import { DrawingsReviewFilters } from "./DrawingsReviewFilters";
import { ModalApproveDrawing } from "./ModalApproveDrawing";

function DrawingsToCheck({
  data,
  selected,
  onSelectionChange,
  onDelete,
  isLoading,
  filters,
  setFilters,
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const [pageSize, setPageSize] = useState(10);
  const [revision, setRevision] = useState(null);
  const drawings = useMemo(() => {
    if (data) {
      return data.map((drawing) => {
        const splittedLink = drawing.pdfFile.split("/");
        const fileName = decodeURI(
          splittedLink[splittedLink.length - 1].replace(/.pdf|.PDF/, ""),
        );
        return { ...drawing, fileName };
      });
    }
    return [];
  }, [data]);

  const columns = [
    {
      header: tGeneral("name"),
      accessorKey: "fileName",
      size: 300,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
  ];

  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 2 &&
      currentGuideMe === "drawings-review-and-publish"
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe]);

  useEffect(() => {
    if (
      drawings &&
      tourActive &&
      (stepIndex === 3 || stepIndex === 4) &&
      currentGuideMe === "drawings-review-and-publish"
    ) {
      if (drawings.length > 0) {
        setRevision(drawings[0]);
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }

    if (
      drawings &&
      tourActive &&
      (stepIndex === 5 || stepIndex === 2) &&
      currentGuideMe === "drawings-review-and-publish"
    ) {
      setRevision(null);
    }
  }, [drawings, tourActive, stepIndex, steps, currentGuideMe]);

  return (
    <Box>
      <StyledMaterialReactTable
        data={drawings}
        columns={columns}
        enableRowSelection
        selectedRows={selected}
        setSelectedRows={onSelectionChange}
        isLoading={isLoading}
        filtersComponent={
          <DrawingsReviewFilters
            filters={filters}
            onChange={(val) => setFilters(val)}
          />
        }
        renderRowActions={({ row }) => {
          return (
            <Box display="flex">
              <TooltipIconButton
                icon={<Task />}
                label={tDrawings("approve")}
                onClick={() => setRevision(row.original)}
                color="black"
                data-tour={
                  row.index === 0 ? "review-and-publish-drawings-3" : ""
                }
              />
              <Can I="delete" a="drawings">
                <TooltipIconButton
                  icon={<Delete color="red" />}
                  label={tGeneral("delete")}
                  onClick={() => onDelete(row.id)}
                  color="red"
                />
              </Can>
            </Box>
          );
        }}
      />
      {Boolean(revision) && (
        <ModalApproveDrawing
          isOpen={Boolean(revision)}
          onClose={() => setRevision(null)}
          revision={revision}
        />
      )}
    </Box>
  );
}

export { DrawingsToCheck };
