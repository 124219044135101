import { tour } from "./tour";

export const drawings = {
  // general tour
  ...tour,
  moduleElement: "도면",
  exportButtonTour: "다운로드",
  reviewAndPublishButtonTour: "검토 및 게시",
  // ADD - guide me steps
  step1_addDrawing_title: "1. 추가",
  step1_addDrawing_text: "새 도면을 추가하려면 + 아이콘을 클릭하세요.",
  step2_addDrawing_title: "2. 파일 추가",
  step2_addDrawing_text: "파일을 첨부하거나 점선 상자 안에 끌어다 놓으세요.",
  step3_addDrawing_title: "3. 세트 이름",
  step3_addDrawing_text: "세트 이름을 선택하거나 입력하세요.",
  step4_addDrawing_title: "4. 도면 날짜",
  step4_addDrawing_text: "도면의 날짜를 선택하세요.",
  step5_addDrawing_title: "5. 도면 수령 날짜",
  step5_addDrawing_text: "도면의 수령 날짜를 선택하세요.",
  step6_addDrawing_title: "6. 저장",
  step6_addDrawing_text: "도면을 저장하려면 여기를 클릭하세요.",
  step_final_addDrawing: "도면 추가 방법을 배웠습니다.",
  // REVIEW & PUBLISH - guide me steps
  step1_reviewAndPublishDrawing_title: "1. 추가 옵션",
  step1_reviewAndPublishDrawing_text:
    "더 많은 옵션을 보려면 여기를 클릭하세요.",
  step2_reviewAndPublishDrawing_title: "2. 도면 검토",
  step2_reviewAndPublishDrawing_text:
    "검토 대기 도면 섹션을 열려면 여기를 클릭하세요.",
  step3_reviewAndPublishDrawing_title: "3. 승인",
  step3_reviewAndPublishDrawing_text:
    "도면의 정보를 확인하려면 여기를 클릭하세요.",
  step4_reviewAndPublishDrawing_title: "4. 정보 수정",
  step4_reviewAndPublishDrawing_text: "필요한 변경 사항을 수행하세요.",
  step5_reviewAndPublishDrawing_title: "5. 승인",
  step5_reviewAndPublishDrawing_text: "도면을 승인하려면 여기를 클릭하세요.",
  step6_reviewAndPublishDrawing_title: "6. 게시 탭",
  step6_reviewAndPublishDrawing_text:
    "게시 대기 도면 목록을 보려면 여기를 클릭하세요.",
  step7_reviewAndPublishDrawing_title: "7. 도면 선택",
  step7_reviewAndPublishDrawing_text: "게시할 도면을 선택하세요.",
  step8_reviewAndPublishDrawing_title: "8. 게시",
  step8_reviewAndPublishDrawing_text:
    "선택한 도면을 게시하려면 여기를 클릭하세요.",
  step_final_reviewAndPublishDrawing: "도면 검토 및 게시 방법을 배웠습니다.",
  // VIEW - guide me steps
  step1_viewDrawing_title: "1. 보기",
  step1_viewDrawing_text: "도면을 보려면 여기를 클릭하세요.",
  step2_viewDrawing_title: "2. 리비전",
  step2_viewDrawing_text: "리뷰 번호를 선택하세요.",
  step3_viewDrawing_title: "3. 도면",
  step3_viewDrawing_text: "도면을 변경해야 하는 경우 선택하세요.",
  step4_viewDrawing_title: "4. 주석",
  step4_viewDrawing_text: "도면에 주석을 추가하려면 여기를 클릭하세요.",
  step5_viewDrawing_title: "5. 주석 표시/숨기기",
  step5_viewDrawing_text: "주석을 표시하거나 숨기려면 여기를 클릭하세요.",
  step_final_viewDrawing: "도면 보기 방법을 배웠습니다.",
  // EDIT - guide me steps
  step1_editDrawing_title: "1. 편집",
  step1_editDrawing_text: "도면을 편집하려면 여기를 클릭하세요.",
  step2_editDrawing_title: "2. 도면 이름",
  step2_editDrawing_text: "도면 이름을 편집하세요.",
  step3_editDrawing_title: "3. 세트 이름",
  step3_editDrawing_text: "세트 이름을 선택하거나 편집하세요.",
  step4_editDrawing_title: "4. 항목",
  step4_editDrawing_text: "항목을 선택하세요.",
  step5_editDrawing_title: "5. 공개 여부",
  step5_editDrawing_text: "도면을 모두에게 표시할지 여부를 선택하세요.",
  step6_editDrawing_title: "6. 공개 수준",
  step6_editDrawing_text: "활성화되면 특정 회사 및 개인의 액세스가 제한됩니다.",
  step7_editDrawing_title: "7. 저장",
  step7_editDrawing_text: "변경 사항을 저장하려면 여기를 클릭하세요.",
  step_final_editDrawing: "도면 편집 방법을 배웠습니다.",
  // RECYCLE - guide me steps
  step1_recycleDrawing_title: "1. 추가 옵션",
  step1_recycleDrawing_text: "더 많은 옵션을 보려면 여기를 클릭하세요.",
  step2_recycleDrawing_title: "2. 도면 휴지통",
  step2_recycleDrawing_text: "도면 휴지통을 시각화하려면 여기를 클릭하세요.",
  step3_recycleDrawing_title: "3. 도면 선택",
  step3_recycleDrawing_text: "복원할 도면을 선택하세요.",
  step4_recycleDrawing_title: "4. 복원",
  step4_recycleDrawing_text: "선택한 도면을 복원하려면 여기를 클릭하세요.",
  step_final_recycleDrawing: "도면 복원 방법을 배웠습니다.",
  // DOWNLOAD - guide me steps
  step1_exportDrawing_title: "1. 추가 작업",
  step1_exportDrawing_text:
    "더 많은 작업을 위해 3 점을 클릭하여 메뉴를 엽니다.",
  step2_exportDrawing_title: "2. 다운로드",
  step2_exportDrawing_text: "도면을 다운로드하려면 여기를 클릭하세요.",
  step3_exportDrawing_title: "3. 대체 다운로드",
  step3_exportDrawing_text:
    "리비전 목록을 표시하려면 #Rev 열 위에 커서를 위치시키세요.",
  step4_exportDrawing_title: "4. 리비전 다운로드",
  step4_exportDrawing_text: "다운로드할 파일을 클릭하세요.",
  step_final_exportDrawing: "도면 다운로드 방법을 배웠습니다.",
  // SETTINGS - guide me steps
  step1_settingsDrawing_title: "1. 추가 옵션",
  step1_settingsDrawing_text: "더 많은 옵션을 보려면 여기를 클릭하세요.",
  step2_settingsDrawing_title: "2. 설정",
  step2_settingsDrawing_text: "설정 모달을 엽니다.",
  step3_settingsDrawing_title: "3. 배포 목록",
  step3_settingsDrawing_text:
    "도면 모듈에 기본 액세스 권한이 있는 사람을 추가하거나 제거하세요.",
  step4_settingsDrawing_title: "4. 항목",
  step4_settingsDrawing_text: "항목 탭에 액세스하려면 여기를 클릭하세요.",
  step5_settingsDrawing_title: "5. 항목 추가",
  step5_settingsDrawing_text: "추가할 항목의 이름을 입력하세요.",
  step_final_settingsDrawing: "도면 모듈을 구성하는 방법을 배웠습니다.",

  addDrawing: "도면 추가",
  reviewDrawings: "도면 검토",
  drawingsToCheck: "검토 대기",
  drawingsToPublish: "게시 대기",
  discipline: "항목",
  disciplines: "항목",
  addNewSet: "새 세트 추가",
  setName: "세트 이름",
  drawingDate: "도면 날짜",
  deletingDrawingMessage:
    "이 도면을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.",
  deleteDrawing: "도면 삭제",
  drawingsDeletedMessage: "도면이 성공적으로 삭제되었습니다.",
  drawingsPublishedMessage: "도면이 성공적으로 게시되었습니다.",
  drawingsRestoredMessage: "도면이 성공적으로 복원되었습니다.",
  recycleBin: "도면 휴지통",
  drawingName: "도면 이름",
  deleteDrawingsMessage: "이 도면을 삭제하시겠습니까?",
  deleteLastRevision: "마지막 리비전 삭제",
  revision: "리비전",
  noDrawingsCreated: "생성된 도면이 없습니다.",
  addDrawingsValidation: "도면을 추가하려면 추가 버튼을 클릭하세요.",
  line: "선",
  rectangle: "사각형",
  ellipse: "타원",
  arrow: "화살표",
  polyline: "다각선",
  annotationPublishedMessage: "주석이 성공적으로 게시되었습니다.",
  freetext: "텍스트",
  annotationAssignedMessage: "주석이 성공적으로 할당되었습니다.",
  annotationLinkedMessage: "주석이 성공적으로 연결되었습니다.",
  approveDrawing: "도면 승인",
  drawingId: "도면 ID",
  approve: "승인",
  publishAnnotations: "주석 게시",
  deleteAnnotations: "주석 삭제",
  deleteAnnotationsContent: "주석 ${1}개를 삭제하시겠습니까?",
  annotationDeletedMessage: "주석이 성공적으로 삭제되었습니다.",
  drawingsAddedTitle: "도면 세트 추가됨",
  drawingsAddedContent:
    "도면이 성공적으로 추가되었습니다. 시스템에 업로드되면 알려드리겠습니다.",
  reviseDrawings: "도면 수정",
  area: "면적",
  calibrate: "보정",
  distance: "거리",
  includeAnnotations: "주석 포함",
  excludeAnnotations: "주석 제외",
  editDrawing: "도면 편집",
  addDiscipline: "항목 추가",
  editDrawingId: "도면 ID 편집",
  editedDrawingMessage: "도면이 성공적으로 업데이트되었습니다.",
  compareWith: "비교 대상",
  selectDrawgingsToCompare: "비교할 도면 선택",
  revisionsDeletedSuccessfully: "리비전이 성공적으로 삭제되었습니다.",
  links: "링크",
  showAnnotations: "주석 표시",
  hideAnnotations: "주석 숨기기",
  measure: "측정",
  generated: "생성됨",
  comparisionGeneratedSuccessfully: "비교가 성공적으로 생성되었습니다.",
  setCalibration: "보정 조정",
  calibrateDescription:
    "이 평면의 모든 측정은 아래에 지정된 보정을 참조합니다.",
  calibrationNote: "참고: 이 측정값은 정확하지 않습니다.",
  calibrateInstructions: "보정하려면 평면에서 두 점을 선택하십시오",
  fileSizeLimit: "최대 파일 크기: 10GB (총합)",
  ploadingQtyDrawings: "{{qty}}개의 도면을 업로드 중입니다",
  uploadDrawings: "도면 업로드",
  comment: "댓글 달기",
  commentDrawing: "도면에 댓글 추가하기",
  commentPlaceholder: "도면에 대한 댓글을 작성하세요",
  drawingViewer: "도면 뷰어",
};
