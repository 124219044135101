import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editingTask: null,
  selected: [],
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setEditingTask: (state, action) => {
      state.editingTask = action.payload;
    },
    addSelectedTask: (state, action) => {
      if (!state.selected.includes(action.payload)) {
        state.selected = [...state.selected, action.payload];
      }
    },
    removeSelectedTask: (state, action) => {
      state.selected = state.selected.filter((task) => task !== action.payload);
    },
    resetSelectedTasks: (state) => {
      state.selected = [];
    },
    resetTasks: () => initialState,
  },
});

export const selectEditingTask = (state) => state.tasks.editingTask;
export const selectSelectedTasks = (state) => state.tasks.selected;

export const {
  setEditingTask,
  addSelectedTask,
  removeSelectedTask,
  resetSelectedTasks,
  resetTasks,
} = tasksSlice.actions;

export default tasksSlice.reducer;
