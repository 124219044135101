import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import WarningIcon from "../../assets/icons/modals/warning.png";
import DeleteIcon from "../../assets/icons/delete.png";

function ModalWithAction({
  title,
  content,
  actionLabel,
  action,
  isOpen,
  onClose,
  icon = "warning",
  isLoading = false,
  showCancel = true,
}) {
  const { t: tGeneral } = useTranslation("general");
  const iconSrc = () => {
    switch (icon) {
      case "warning":
        return WarningIcon;
      case "delete":
        return DeleteIcon;
      default:
        return WarningIcon;
    }
  };
  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={isOpen}
      onClose={onClose}
    >
      <Card
        sx={{
          width: "30%",
          height: "auto",
          borderRadius: "15px",
          p: "36px",
          rowGap: "27px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box textAlign="center">
          <img src={iconSrc()} alt="icon" width="150px" height="150px" />
        </Box>
        <Typography fontSize="1.5625rem" textAlign="center" fontWeight="bold">
          {title}
        </Typography>
        <Typography fontSize="1.125rem" textAlign="center">
          {content}
        </Typography>
        <Box display="flex" justifyContent="space-evenly" columnGap="16px">
          {showCancel && (
            <Button
              variant="outlined"
              fullWidth
              color="inherit"
              onClick={onClose}
              sx={{ py: "16px" }}
              disabled={isLoading}
            >
              {tGeneral("cancel")}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={action}
            sx={{
              textTransform: "none",
              borderRadius: "1rem",
              fontSize: "1rem",
              px: "2rem",
              py: "0.25rem",
              background: "linear-gradient(45deg, #FBCB04, #E5E900)",
            }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress color="primary" /> : actionLabel}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalWithAction };
