import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadingFiles: null,
};

const drawingsSlice = createSlice({
  name: "drawings",
  initialState,
  reducers: {
    setUploadingFiles: (state, action) => {
      state.uploadingFiles = action.payload;
    },
  },
});

export const selectUploadingFiles = (state) => state.drawings.uploadingFiles;

export const { setUploadingFiles } = drawingsSlice.actions;

export default drawingsSlice.reducer;
