import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
// MUI
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
// Icons
import {
  Add,
  ArrowBack,
  ChevronRight,
  Clear,
  Delete,
  Restore,
  Search,
  Settings,
} from "@mui/icons-material";
import Can from "../../../utils/can";
// Custom imports
import LoadingOverlay from "../../../components/LoadingOverlay";
import { PopUpAlert } from "../../../components/PopUpAlert";
import SupportButtonTasks from "../../../components/supportButton/SupportButtonTasks";
import TaskBoard from "../../../components/projectDashboard/Tasks/TaskBoard";
import TaskSettingsModal from "../../../components/projectDashboard/Tasks/TaskSettingsModal";
import {
  EmptyState,
  TasksSkeletonLoading,
  TitleWidgets,
} from "../../../components/shared";
import BubbleAvatarDeletable from "../../../components/userAvatars/BubbleAvatarDeletable";
import { statusOptions } from "../../../constants/projectDashboard/selectOptions";
import { useGetLocationsQuery } from "../../../features/locations/locationApiSlice";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import {
  useDeleteTaskMutation,
  useGetTasksQuery,
} from "../../../features/project/modules/tasks/tasksApiSlice";
import {
  addSelectedTask,
  resetSelectedTasks,
  selectSelectedTasks,
  setEditingTask,
} from "../../../features/project/modules/tasks/tasksSlice";
import {
  useGetProjectConfigurationQuery,
  useUpdateProjectConfigMutation,
} from "../../../features/project/projectApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
  selectCurrentRole,
} from "../../../features/project/projectSlice";
import { useDistributionList } from "../../../hooks";
import { selectSettingsTasksPopupOpen } from "../../../features/project/modules/rfiSlice";

import { useExportTasksMutation } from "../../../features/exportPdf";
import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import TaskDetails from "../../../components/projectDashboard/Tasks/TaskDetails";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants";
import CaslContext from "../../../utils/caslContext";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { SearchUserFilter } from "../../../components/shared/SearchUserFilter";
import { SearchBasicFilter } from "../../../components/shared/SearchBasicFilter";
import { SearchLocationFilter } from "../../../components/shared/SearchLocationFilter";
import { DateRangeFilter } from "../../../components/shared/DateRangeFilter";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Tasks() {
  // Translation
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");

  const { idProject } = useParams();

  const ability = useContext(CaslContext);
  // Local state
  const [openAddFilter, setOpenAddFilter] = useState(false);
  // Filters
  const [userList, setUserList] = useState([]);
  const [currentFilter, setCurrentFilter] = useState();
  const [assignedList, setAssignedList] = useState([]);
  const [creatorList, setCreatorList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [createdOn, setCreatedOn] = useState([]);

  const [currentTier, setCurrentTier] = useState(0);
  const [superLocation, setSuperLocation] = useState([]);

  // Selectors
  const currentProject = useSelector(selectCurrentProject);
  const selectedTasks = useSelector(selectSelectedTasks);
  const darkMode = useSelector(selectTheme);

  // Search
  const [searchValue, setSearchValue] = useState("");

  // Queries & mutations
  const {
    data: tasks,
    isLoading: tasksLoading,
    isSuccess: tasksSuccess,
  } = useGetTasksQuery({ idProject, bActive: true });
  const { data: locations } = useGetLocationsQuery(idProject, {
    skip: !idProject,
    refetchOnMountOrArgChange: true,
  });
  const { data: projectConfig } = useGetProjectConfigurationQuery(idProject);
  const [
    updateConfig,
    { isSuccess: isUpdateConfigSuccess, isError: isUpdateConfigError },
  ] = useUpdateProjectConfigMutation();
  const [
    deleteTask,
    {
      isSuccess: isDeleteTaskSuccess,
      isError: isDeleteTaskError,
      isLoading: isDeleteTaskLoading,
    },
  ] = useDeleteTaskMutation();

  // Hooks
  const {
    getSelectedUsers,
    projectUsers,
    handleCloseDistributionList,
    handleOpenDistributionList,
    isDistributionListOpen,
  } = useDistributionList(idProject);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterOptions = [
    {
      value: "assigned",
      label: tGeneral("assignees"),
    },
    {
      value: "creator",
      label: tGeneral("createdBy"),
    },
    {
      value: "status",
      label: tGeneral("status"),
    },
    {
      value: "category",
      label: tGeneral("category"),
    },
    {
      value: "priority",
      label: tGeneral("priority"),
    },
    {
      value: "location",
      label: tGeneral("location"),
    },
    {
      value: "createdOn",
      label: tGeneral("createdOn"),
    },
  ];

  const priorityOptions = [
    {
      value: "high",
      label: tTasks("high"),
    },
    {
      value: "medium",
      label: tTasks("medium"),
    },
    {
      value: "low",
      label: tTasks("low"),
    },
  ];

  const styles = {
    secondaryFilter: {
      ml: 1,
      minWidth: "200px",
      verticalAlign: "center",
    },
    inputTitle: {
      backgroundColor: darkMode ? "" : "transparent",
      borderRadius: 2,
    },
    selectInput: {
      backgroundColor: darkMode ? "" : "#FFF5DB",
    },
  };

  // Filters
  const filterTasks = (value, filter) => {
    switch (filter) {
      case "assigned":
        setAssignedList(value);
        if (currentFilter === "assigned") setCurrentFilter();
        break;
      case "creator":
        setCreatorList(value);
        if (currentFilter === "creator") setCurrentFilter();
        break;
      case "status":
        setStatusList(value);
        if (currentFilter === "status") setCurrentFilter();
        break;
      case "category":
        setCategoryList(value);
        if (currentFilter === "category") setCurrentFilter();
        break;
      case "priority":
        setPriorityList(value);
        if (currentFilter === "priority") setCurrentFilter();
        break;
      case "location":
        setLocationList(value);
        if (currentFilter === "location") setCurrentFilter();
        break;
      case "createdOn":
        setCreatedOn(value);
        if (currentFilter === "createdOn") setCurrentFilter();
        break;
    }
  };

  const handleDeleteTasks = async () => {
    await deleteTask({
      ids: selectedTasks,
    }).unwrap();
  };

  function getTaskStatus(status, isDraft) {
    let value = "open";
    if (status === "backlog" && isDraft) {
      value = "draft";
    }
    if (status === "done") {
      value = "close";
    }

    return value;
  }

  const handleNextTier = (location) => {
    if (currentTier < tiers.length) {
      setSuperLocation((prev) => [...prev, location]);
      setCurrentTier((prev) => prev + 1);
    }
  };

  const handlePrevTier = () => {
    const lastId = superLocation[currentTier - 1].id;
    setSuperLocation((prev) => prev.filter((loc) => loc.id !== lastId));
    setCurrentTier((prev) => prev - 1);
  };

  const filterBySuperLocation = (location) => {
    if (superLocation.length === 0) {
      return true;
    }
    return superLocation[currentTier - 1].id === location.superLocation;
  };

  useEffect(() => {
    // Reset selected tasks on component unmount
    return () => {
      dispatch(resetSelectedTasks());
    };
  }, []);

  useEffect(() => {
    if (isUpdateConfigSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tGeneral("projectConfigurationUpdateSuccess"),
      );
      handleCloseDistributionList();
    }
    if (isUpdateConfigError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      handleCloseDistributionList();
    }
  }, [isUpdateConfigSuccess, isUpdateConfigError]);

  useEffect(() => {
    if (isDeleteTaskSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tTasks("taskDeletedSuccessfully"),
      );
      dispatch(resetSelectedTasks());
    }
  }, [isDeleteTaskSuccess]);

  useEffect(() => {
    if (isDeleteTaskError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      dispatch(resetSelectedTasks());
    }
  }, [isDeleteTaskError]);

  useEffect(() => {
    if (projectUsers) {
      const usersList = projectUsers
        .filter(function (obj) {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.user.id,
          name: `${user.user.firstName} ${user.user.lastName}`,
          urlAvatar: user.user.urlAvatar,
          // idUser: user.user.id,
        }));
      setUserList(usersList);
    }
  }, [projectUsers]);

  const tiers = useMemo(() => {
    if (locations) {
      return Object.keys(locations);
    }
    return [];
  }, [locations]);

  const categories = useMemo(() => {
    if (projectConfig?.taskCategoryList) {
      return projectConfig?.taskCategoryList.map((category, index) => ({
        id: index,
        label: category,
      }));
    }
    return [];
  }, [projectConfig?.taskCategoryList]);

  const activeTasks = tasks?.filter((task) => task?.bActive === true);
  const [pdfUrl, setPdfUrl] = React.useState(null);

  const {
    state: { tourActive, stepIndex, run, steps },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 0 &&
      !run &&
      (currentGuideMe === "tasks-add" ||
        currentGuideMe === "tasks-view" ||
        currentGuideMe === "tasks-edit")
    ) {
      setState({ run: true });
    }
  }, [tourActive, stepIndex, currentGuideMe, run, setState]);

  useEffect(() => {
    if (
      tasks &&
      tasksSuccess &&
      tourActive &&
      stepIndex === 0 &&
      (currentGuideMe === "tasks-view" || currentGuideMe === "tasks-edit")
    ) {
      if (tasks.length > 0) {
        const task = tasks.find((task) => !task.bDraft && task.bActive);
        dispatch(addSelectedTask(task?.id));
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }

    if (
      tourActive &&
      stepIndex === steps.length - 1 &&
      (currentGuideMe === "tasks-view" || currentGuideMe === "tasks-edit")
    ) {
      dispatch(resetSelectedTasks());
      dispatch(resetSelectedTasks());
    }
  }, [
    tourActive,
    stepIndex,
    currentGuideMe,
    steps,
    tasks,
    tasksSuccess,
    dispatch,
    setState,
  ]);

  if (tasksLoading) {
    return <TasksSkeletonLoading />;
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {/* Title, searchbar & buttons */}
      <TitleWidgets
        title={tTasks("tasks")}
        distributionListName={DISTRIBUTION_LIST_NAMES.tasks}
        moduleName="tasks"
        showDelete={selectedTasks.length > 0 && ability.can("delete", "tasks")}
        onDelete={handleDeleteTasks}
        enableSupport
        addNameTour="add-tasks-1"
        showExports={false}
      />
      {/* Filters & delete button */}
      {tasksSuccess && activeTasks?.length > 0 && (
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          my="10px"
          px="10px"
        >
          {/* Filter dropdown & chips */}
          <Box
            display="flex"
            flex={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <FormControl sx={{ minWidth: "15%" }} size="small">
              <InputLabel>{tGeneral("addFilter")}</InputLabel>
              <Select
                open={openAddFilter}
                onClose={() => {
                  setOpenAddFilter(false);
                }}
                onOpen={() => {
                  setOpenAddFilter(true);
                }}
                label={tGeneral("addFilter")}
                MenuProps={{ disableScrollLock: true }}
                defaultValue=""
                value={currentFilter === undefined ? "" : currentFilter}
                onChange={(e) => {
                  setCurrentFilter(e.target.value);
                }}
              >
                {filterOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Assigned Select */}
            {(currentFilter === "assigned" || assignedList.length > 0) && (
              <SearchUserFilter
                title={tGeneral("assignees")}
                userList={userList}
                selectedUsersList={userList.filter(
                  (user) =>
                    assignedList.findIndex(
                      (assignedUser) => assignedUser === user.id,
                    ) > -1,
                )}
                selectedUsersFormatted={assignedList}
                handleUpdateUsersList={(list) => {
                  filterTasks(
                    list.length > 0 ? list.map((user) => user.id) : [],
                    "assigned",
                  );
                }}
                selectProps={{
                  ml: 1,
                  width: "200px",
                }}
              />
            )}
            {/* Creator Select */}
            {(currentFilter === "creator" || creatorList.length > 0) && (
              <SearchUserFilter
                title={tGeneral("createdBy")}
                userList={userList}
                selectedUsersList={userList.filter(
                  (user) =>
                    creatorList.findIndex(
                      (creatorUser) => creatorUser === user.id,
                    ) > -1,
                )}
                selectedUsersFormatted={creatorList}
                handleUpdateUsersList={(list) => {
                  filterTasks(
                    list.length > 0 ? list.map((user) => user.id) : [],
                    "creator",
                  );
                }}
                selectProps={{
                  ml: 1,
                  width: "200px",
                }}
              />
            )}
            {/* Status Select */}
            {(currentFilter === "status" || statusList.length > 0) && (
              <FormControl size="small" sx={styles.secondaryFilter}>
                <InputLabel id="userTag1" sx={styles.inputTitle}>
                  {statusList.length > 0 ? "" : tGeneral("status")}
                </InputLabel>
                <Select
                  labelId="statusField"
                  id="demo-multiple-checkbox1"
                  multiple
                  onChange={(e) => {
                    filterTasks(e.target.value, "status");
                  }}
                  value={statusList}
                  startAdornment={
                    statusList.length > 0 ? (
                      <IconButton
                        onClick={(e) => {
                          filterTasks([], "status");
                        }}
                      >
                        <Clear />
                      </IconButton>
                    ) : (
                      ""
                    )
                  }
                  input={
                    <OutlinedInput
                      label={statusList.length > 0 ? "" : tGeneral("status")}
                    />
                  }
                  renderValue={(selected) =>
                    statusList.length > 0
                      ? `${tGeneral("status")} (${statusList.length})`
                      : `${tGeneral("status")}`
                  }
                  MenuProps={MenuProps}
                  sx={styles.selectInput}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={statusList.indexOf(option.value) > -1}
                        sx={{
                          color: "black",
                          ":checked": {
                            color: "#FBCB04",
                          },
                          borderColor:
                            statusList.indexOf(option.value) > -1
                              ? "#f7e4b2"
                              : "black",
                        }}
                      />
                      {option.label == "OPEN"
                        ? tGeneral("open_action").toUpperCase()
                        : option.label == "DRAFT"
                          ? tGeneral("draft").toUpperCase()
                          : option.label == "CLOSED"
                            ? tGeneral("closed").toUpperCase()
                            : "Other"}
                      {/* {option.label} */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {/* Category Select */}
            {(currentFilter === "category" || categoryList.length > 0) && (
              <SearchBasicFilter
                title={tGeneral("category")}
                itemList={categories}
                selectedElementsList={categoryList}
                handleUpdateElementsList={(list) => {
                  filterTasks(list.length > 0 ? list : [], "category");
                }}
                selectProps={{
                  ml: 1,
                  width: "200px",
                }}
              />
            )}
            {/* Priority Select */}
            {(currentFilter === "priority" || priorityList.length > 0) && (
              <FormControl size="small" sx={styles.secondaryFilter}>
                <InputLabel id="userTag1" sx={styles.inputTitle}>
                  {priorityList.length > 0 ? "" : tGeneral("priority")}
                </InputLabel>
                <Select
                  labelId="priorityField"
                  id="demo-multiple-checkbox1"
                  multiple
                  onChange={(e) => {
                    filterTasks(e.target.value, "priority");
                  }}
                  value={priorityList}
                  startAdornment={
                    priorityList.length > 0 ? (
                      <IconButton
                        onClick={(e) => {
                          filterTasks([], "priority");
                        }}
                      >
                        <Clear />
                      </IconButton>
                    ) : (
                      ""
                    )
                  }
                  input={
                    <OutlinedInput
                      label={
                        priorityList.length > 0 ? "" : tGeneral("priority")
                      }
                    />
                  }
                  renderValue={(selected) =>
                    priorityList.length > 0
                      ? `${tGeneral("priority")} (${priorityList.length})`
                      : `${tGeneral("priority")}`
                  }
                  MenuProps={MenuProps}
                  sx={styles.selectInput}
                >
                  {priorityOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={priorityList.indexOf(option.value) > -1}
                        sx={{
                          color: "black",
                          ":checked": {
                            color: "#FBCB04",
                          },
                          borderColor:
                            priorityList.indexOf(option.value) > -1
                              ? "#f7e4b2"
                              : "black",
                        }}
                      />
                      <Box display="flex" alignItems="center">
                        {option.label}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {/* Location Select */}
            {(currentFilter === "location" || locationList.length > 0) && (
              <SearchLocationFilter
                title={tGeneral("location")}
                selectedLocations={locationList}
                onChange={(list) => {
                  filterTasks(list.length > 0 ? list : [], "location");
                }}
                selectProps={{
                  ml: 1,
                  width: "200px",
                }}
              />
            )}
            {/* Date Range */}
            {(currentFilter === "createdOn" || createdOn?.length > 0) && (
              <DateRangeFilter
                onChange={(stringDates) => {
                  filterTasks(stringDates, "createdOn");
                }}
                filterProps={{
                  minWidth: "200px",
                  verticalAlign: "center",
                  ml: 1,
                }}
              />
            )}
          </Box>
        </Box>
      )}
      {/* Tasks available */}
      {tasksSuccess && activeTasks?.length > 0 && (
        <TaskBoard
          tasks={activeTasks.filter(function (obj) {
            return (
              obj.title.toLowerCase().includes(searchValue.toLowerCase()) &&
              // Assignees filter
              (assignedList?.length > 0
                ? assignedList.filter((idUser) =>
                    obj.assignedTo.includes(idUser),
                  ).length > 0
                : obj) &&
              // Creator filter
              (creatorList?.length > 0
                ? creatorList.includes(obj.createdBy)
                : obj) &&
              // Status filter
              (statusList?.length > 0
                ? statusList.includes(getTaskStatus(obj.status, obj.bDraft))
                : obj) &&
              // Category filter
              (categoryList?.length > 0
                ? categoryList
                    .map((category) => category.label)
                    .includes(obj.category)
                : obj) &&
              // Priority filter
              (priorityList?.length > 0
                ? priorityList.includes(obj.priority)
                : obj) &&
              // Location filter
              (locationList?.length > 0
                ? locationList
                    .map((location) => location.id)
                    .includes(obj.locationId)
                : obj) &&
              (createdOn?.length > 0
                ? new Date(createdOn[0]).getTime() <=
                    new Date(obj.createDate).getTime() &&
                  new Date(obj.createDate).getTime() <=
                    new Date(createdOn[1]).getTime()
                : obj)
            );
          })}
          users={projectUsers}
          locations={locations}
        />
      )}
      {/* No tasks */}
      {tasksSuccess && activeTasks?.length === 0 && (
        <EmptyState
          title={tTasks("noTasksCreated")}
          content={tTasks("noTasksContent")}
          module="tasks"
        />
      )}

      {/* TODO: Refactor useDistributionList to be more self-contained */}
      {isDistributionListOpen && (
        <TaskSettingsModal
          isOpen={isDistributionListOpen}
          onClose={handleCloseDistributionList}
          users={projectUsers}
          days={projectConfig?.taskDays}
          selectedUsers={getSelectedUsers("taskDistributionList")}
          updateConfig={async (distributionList, days) => {
            await updateConfig({
              idProject,
              taskDistributionList: distributionList,
              taskDays: days,
            }).unwrap();
          }}
        />
      )}
      <LoadingOverlay open={isDeleteTaskLoading} />
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
      <TaskDetails />
    </Box>
  );
}

export default Tasks;
