import { announcements } from "./announcements";
import { general } from "./general";
import { photos } from "./photos";
import { projectDirectory } from "./projectDirectory";
import { dailyReports } from "./dailyReports";
import { meetings } from "./meetings";
import { rfis } from "./rfis";
import { onboarding } from "./onboarding";
import { enterprise } from "./enterprise";
import { drawings } from "./drawings";
import { plan } from "./plan";
import { payment } from "./payment";
import { documents } from "./documents";
import { tasks } from "./tasks";
import { inspections } from "./inspections";
import { inspectionsTemplates } from "./inspectionsTemplates";
import { login } from "./login";
import { message } from "./message";
import { contacts } from "./contacts";
import { validation } from "./validation";
import { weather } from "./weather";
import { preferences } from "./preferences";
import { status } from "./status";
import { tour } from "./tour";
import { projectSchedule } from "./projectSchedule";
import { error } from "./error";
import { notificationCenter } from "./notificationCenter";
import { notificationActions } from "./notificationActions";
import { submittals } from "./submittals";
import { bim } from "./bim";
import { roles } from "./roles";
import { projectNetwork } from "./projectNetwork";
import { drawingsChecklist } from "./checklist";
import { analytics } from "./analytics";
import { materialTable } from "./materialTable";

export const es = {
  announcements,
  photos,
  general,
  projectDirectory,
  dailyReports,
  meetings,
  rfis,
  onboarding,
  enterprise,
  drawings,
  plan,
  payment,
  documents,
  tasks,
  inspections,
  inspectionsTemplates,
  login,
  weather,
  message,
  contacts,
  validation,
  preferences,
  status,
  tour,
  projectSchedule,
  error,
  notificationCenter,
  notificationActions,
  submittals,
  bim,
  roles,
  projectNetwork,
  drawingsChecklist,
  analytics,
  materialTable,
};
