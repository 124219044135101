import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Redux: selectors
import {
  useGetUserQuery,
  useUpdateUserInfoMutation,
} from "../../features/enterprise/enterpriseApiSlice";
import { useCreatePresignedUrlWithPathMutation } from "../../features/s3/s3ApiSlice";
import { useCreateAvatarMutation } from "../../features/userSettings/userSettingsApiSlice";
import {
  selectCurrentUser,
  setCurrentUser,
} from "../../features/userSettings/userSettingsSlice";
// MUI Components
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  ImageList,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
// React Component
// Custom Components
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PopUpAlert } from "../../components/PopUpAlert";
import UserAvatar from "../../components/userAvatars/UserAvatar";

// Helpers
import { formatDatePicker } from "../../services/helpers/dateFormat";

import { Edit, PersonPin } from "@mui/icons-material";
import { ImageListItem } from "@mui/material";
import moment from "moment";

const avatars = [
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-01.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-02.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-03.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-04.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-05.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-06.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-07.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-08.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-09.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-10.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-11.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-12.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-13.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-14.png",
  "https://buildpeer-images.s3.us-east-2.amazonaws.com/defaultAvatars/AVATAR-15.png",
];

function ProfileEdit() {
  // hooks
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [dateBirth, setDateBirth] = useState();
  const [gender, setGender] = useState("Other");
  const [inProcess, setInProcess] = useState(false);
  const [imageChange, setImageChange] = useState(null);
  const [newImageUrl, setNewImageUrl] = useState(null);
  const [popOverRef, setPopOverRef] = useState(null);
  const { t: tGeneral } = useTranslation("general");

  const isMobile = useMediaQuery("(max-width:900px)");
  const sm_measurement = useMediaQuery("(max-width:1300px)");
  const md_measurement = useMediaQuery("(max-width:1400px)");

  // Redux
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const { data: enterprisesUsers, isLoading } = useGetUserQuery();

  const [updateUserInfo, { isLoading: isSubmitLoading }] =
    useUpdateUserInfoMutation();

  const [createAvatar, { isLoading: isCreateLoading }] =
    useCreateAvatarMutation();

  const [createPresignedUrl, { isLoading: isUrlLoading }] =
    useCreatePresignedUrlWithPathMutation();

  useEffect(() => {
    if (enterprisesUsers) {
      setFirstName(enterprisesUsers.firstName);
      setLastName(enterprisesUsers.lastName);
      setDateBirth(moment.utc(enterprisesUsers.dateBirth).format("YYYY-MM-DD"));
      setGender(enterprisesUsers.gender);
      setEmail(enterprisesUsers.email);
      setPhone(enterprisesUsers.phone);
    }
  }, [enterprisesUsers]);

  const handleSubmit = async (e) => {
    setInProcess(true);
    e.preventDefault();
    try {
      if (imageChange !== null) {
        await handleSendToBucket(imageChange);
      } else if (newImageUrl) {
        await createAvatar({ idUser: user.id, imageUrl: newImageUrl });
      }
      const dueDateFormat = new Date(dateBirth + "T06:00:00.000Z");
      const response = await updateUserInfo({
        idUser: enterprisesUsers.id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        dateBirth: dueDateFormat,
        gender: gender,
      }).unwrap();

      if (response) {
        dispatch(setCurrentUser(response));
        PopUpAlert("success", tGeneral("success"), tGeneral("userUpdate"));
        navigate(-1);
      }
    } catch (err) {
      let message =
        err?.status === 400 ? err?.data.validation.body["message"] : err?.data;
      PopUpAlert("error", tGeneral("error"), message);
    }
    setInProcess(false);
  };

  async function handleSendToBucket(files) {
    const file = files[0];

    let body = {
      name: file.name,
      path: "Avatars",
    };

    const presignedUrl = await createPresignedUrl(body).unwrap();

    await fetch(presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: file,
    });

    const imageUrl = presignedUrl.split("?")[0];

    try {
      const response = await createAvatar({
        idUser: user.id,
        imageUrl: imageUrl,
      }).unwrap();
    } catch (error) {
      PopUpAlert("error", tGeneral("avatarUpdateError"));
    }
  }

  const handleNewFileUrl = (files) => {
    setImageChange(files);
    if (FileReader && files && files.length) {
      const fr = new FileReader();
      fr.onload = () => {
        setNewImageUrl(fr.result);
      };
      fr.readAsDataURL(files[0]);
    }
  };

  const handleClick = (e) => {
    setPopOverRef(e.currentTarget);
  };

  const handleClosePopOver = () => {
    setPopOverRef(null);
  };

  const open = Boolean(popOverRef);
  const id = open ? "avatar-popover" : undefined;

  return (
    <>
      <Box width="100%">
        <Typography fontSize="36px" align={"left"} fontWeight="bold">
          {tGeneral("profile")}
        </Typography>
        <Box display="flex" width="100%" flexWrap="wrap" mt={3}>
          <Box
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            display="flex"
            width={sm_measurement ? "100%" : "20%"}
            position="relative"
          >
            <Tooltip title={tGeneral("edit")} placement="top">
              <Button
                variant="contained"
                color="primary"
                component="label"
                aria-label="upload picture"
                sx={{
                  minWidth: 0,
                  padding: "18px",
                  position: "absolute",
                  right: isMobile ? "20%" : sm_measurement ? "30%" : 10,
                  top: 10,
                  zIndex: 10,
                }}
              >
                <Edit />
                <input
                  accept="image/*"
                  hidden
                  type="file"
                  onChange={(e) => handleNewFileUrl(e.target.files)}
                />
              </Button>
            </Tooltip>
            <Button
              sx={{
                position: "absolute",
                left: isMobile ? "20%" : sm_measurement ? "30%" : 10,
                top: 10,
                backgroundColor: "white",
                border: "1px solid black",
                padding: "18px",
                minWidth: 0,
                zIndex: 10,
              }}
              aria-describedby={id}
              onClick={handleClick}
              onMouseEnter={handleClick}
            >
              <PersonPin sx={{ color: "black" }} />
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={popOverRef}
              onClose={handleClosePopOver}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <Typography
                paddingLeft={"15px"}
                paddingTop={"15px"}
                fontSize="12px"
                align={"left"}
                fontWeight="bold"
              >
                {tGeneral("selectAvatar")}
              </Typography>
              <ImageList
                sx={{ width: "320px", paddingX: "5%" }}
                cols={5}
                rowHeight="60px"
              >
                {avatars.map((image) => (
                  <ImageListItem
                    key={image}
                    component={Button}
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      margin: 0,
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      setNewImageUrl(image);
                      setImageChange(null);
                      handleClosePopOver();
                    }}
                  >
                    <img src={image} width="50px" height="50px" />
                  </ImageListItem>
                ))}
              </ImageList>
            </Popover>
            <Card
              sx={{
                backgroundColor: "white",
                padding: "0",
                borderRadius: "100%",
              }}
            >
              <UserAvatar
                name={
                  enterprisesUsers?.firstName + " " + enterprisesUsers?.lastName
                }
                width={md_measurement ? "200px" : "220px"}
                height={md_measurement ? "200px" : "220px"}
                url={newImageUrl ?? enterprisesUsers?.urlAvatar}
              />
            </Card>
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            flex={1}
            gap="40px"
            justifyContent="center"
          >
            <TextField
              margin="dense"
              variant="outlined"
              type="text"
              label={tGeneral("firstName")}
              value={enterprisesUsers ? firstName : ""}
              onChange={(e) => setFirstName(e.target.value)}
              sx={{ width: isMobile ? "100%" : "45%" }}
            />
            <TextField
              margin="dense"
              variant="outlined"
              type="text"
              label={tGeneral("lastName")}
              value={enterprisesUsers ? lastName : ""}
              onChange={(e) => setLastName(e.target.value)}
              sx={{ width: isMobile ? "100%" : "45%" }}
            />
            <TextField
              margin="dense"
              variant="outlined"
              type={"date"}
              label={tGeneral("birthDate")}
              value={enterprisesUsers ? dateBirth : ""}
              onChange={(e) => setDateBirth(e.target.value)}
              sx={{ width: isMobile ? "100%" : "45%" }}
              inp
            />
            <TextField
              margin="dense"
              variant="outlined"
              type="text"
              label={tGeneral("gender")}
              id="gender"
              select
              value={enterprisesUsers ? gender : ""}
              onChange={(e) => setGender(e.target.value)}
              sx={{ width: isMobile ? "100%" : "45%" }}
            >
              <MenuItem value="Male">{tGeneral("male")}</MenuItem>
              <MenuItem value="Female">{tGeneral("female")}</MenuItem>
              <MenuItem value="Other">{tGeneral("other")}</MenuItem>
            </TextField>
            <Box sx={{ width: isMobile ? "100%" : "45%" }}>
              <Typography
                fontSize={12}
                align={"left"}
                fontWeight="bold"
                sx={{ ml: 1.5 }}
              >
                {tGeneral("email")}
              </Typography>
              <Typography
                fontSize={16}
                align={"left"}
                sx={{
                  mt: 0.5,
                  ml: 1.5,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {enterprisesUsers ? email : ""}
              </Typography>
            </Box>
            <TextField
              margin="dense"
              variant="outlined"
              type="text"
              label={tGeneral("phone")}
              value={enterprisesUsers ? phone : ""}
              onChange={(e) => setPhone(e.target.value)}
              sx={{ width: isMobile ? "100%" : "45%" }}
            />
          </Box>
        </Box>
      </Box>
      <Grid container item xs={12} justifyContent="flex-end">
        {/* Button Save*/}
        <Grid item xs={12} sx={{ m: 1 }}>
          <Divider sx={{ my: "20px" }} />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              onClick={(e) => {
                navigate(-1);
              }}
              sx={{
                width: isMobile ? "100%" : "18%",
                m: 1,
                borderRadius: 1,
                color: "black",
                backgroundColor: "white",
                borderColor: "black",
                border: 1,
                ":hover": {
                  color: "white",
                  backgroundColor: "#b4b4b4",
                },
              }}
            >
              {tGeneral("cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={(e) => {
                handleSubmit(e);
              }}
              sx={{
                width: isMobile ? "100%" : "18%",
                borderRadius: 1,
                m: 1,
              }}
              disabled={inProcess}
            >
              {tGeneral(inProcess ? "inProcess" : "save")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ProfileEdit;
