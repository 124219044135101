import { apiSlice } from "../../api/apiSlice";

const imageApiQuery = "/project/images";

const objectToQueryString = (object) => {
  const queryString = Object.keys(object)
    .map((key) => {
      let value = object[key];
      if (typeof value === "object") {
        value = value.join(",");
      }
      if (!value) {
        return "";
      }
      return `${key}=${value}`;
    })
    .filter((param) => param.length)
    .join("&");
  return queryString.length ? `?${queryString}` : "";
};

const imageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProjectImages: builder.query({
      query: ({
        projectId,
        locations,
        uploadedBy,
        idAlbum,
        perPage,
        currentPage,
        bActive = true,
        groupBy = "month",
        startDate,
        endDate,
        startDateCreated,
        endDateCreated,
      }) => ({
        url: `${imageApiQuery}/getAllProjectImagesMobile/${projectId}/get?bActive=${bActive}${
          uploadedBy ? `&uploadedBy=${uploadedBy}` : ""}${locations ? `&locations=${locations}` : ""}${startDate ? `&startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}${startDateCreated ? `&startDateCreated=${startDateCreated}` : ""}${endDateCreated ? `&endDateCreated=${endDateCreated}` : ""}${idAlbum ? `&idAlbum=${idAlbum}` : ""}&page=${currentPage}&limit=${perPage}&groupBy=${groupBy}`,
        method: "GET",
        keepUnusedDataFor: 10,
      }),
      providesTags: ["Images"],
    }),
    getAllProjectAlbums: builder.query({
      query: ({ uploadedBy, locations, projectId, bActive = true }) => ({
        url: `${imageApiQuery}/getAllProjectAlbums/${projectId}/get?bActive=${bActive}${
          uploadedBy ? `&uploadedBy=${uploadedBy}` : ""
        }${locations ? `&locations=${locations}` : ""}&page=0&limit=100`,
        method: "GET",
        keepUnusedDataFor: 10,
      }),
      providesTags: ["Albums"],
    }),
    getAlbum: builder.query({
      query: ({ idAlbum, idProject, ...objectQuery }) => ({
        url: `${imageApiQuery}/getAlbum/${idProject}/${idAlbum}${objectToQueryString(
          objectQuery,
        )}`,
        method: "GET",
        keepUnusedDataFor: 10,
      }),
      providesTags: ["Albums"],
    }),
    deleteImage: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/deleteImages`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Images", "Albums"],
    }),
    permanentDeleteImage: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/imagePermanentDelete`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Images"],
    }),
    permanentDeleteAlbum: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/albumPermanentDelete`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Albums"],
    }),
    createBucketImage: builder.mutation({
      query: ({ url, body }) => ({
        url,
        method: "PUT",
        body,
      }),
    }),
    createImage: builder.mutation({
      async queryFn({ file, body }, _api, _extra, fetchBaseQuery) {
        const { data: url, error: presignedUrlError } = await fetchBaseQuery(
          "/s3/getPresignedUrl",
        );
        if (presignedUrlError) {
          return { error: presignedUrlError };
        }
        await fetch(url, {
          method: "PUT",
          body: file,
        });
        body.url = url.split("?")[0];
        const { data, error: createError } = await fetchBaseQuery({
          url: `${imageApiQuery}/createImage`,
          method: "POST",
          body,
        });
        if (createError) {
          return { error: createError };
        }
        return { data };
      },
      invalidatesTags: ["Images", "Albums"],
    }),
    updateSeenBy: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/updateSeenBy`,
        method: "PUT",
        body,
      }),
    }),
    createAlbum: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/createAlbum`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Albums"],
    }),
    updateAlbum: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/updateAlbum`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Albums"],
    }),
    addImageToAlbum: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/addImageToAlbum`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Albums"],
    }),
    removeImageFromAlbum: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/removeImageFromAlbum`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Albums"],
    }),
    restoreImages: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/restoreImages`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Images"],
    }),
    deleteAlbums: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/deleteAlbums`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Albums"],
    }),
    restoreAlbums: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/restoreAlbums`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Albums"],
    }),
    setAlbumCover: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/updateFrontpageImageAlbum`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Albums"],
    }),
    updateImage: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/editImage`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Albums", "Images"],
    }),
    getChat: builder.query({
      query: (idImage) => ({
        url: `${imageApiQuery}/chat/${idImage}`,
        method: "GET",
      }),
      providesTags: ["ImageChat"],
    }),
    commentImage: builder.mutation({
      query: (body) => ({
        url: "/message/commentObject",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ImageChat"],
    }),
    uploadImage: builder.mutation({
      query: (body) => ({
        url: `${imageApiQuery}/upload`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Images", "Albums"],
    }),
    getImage: builder.query({
      query: (id) => `${imageApiQuery}/${id}`,
      providesTags: (_result, _error, id) => ["Images", { id }],
    }),
  }),
});

export const {
  useAddImageToAlbumMutation,
  useCreateAlbumMutation,
  useUpdateAlbumMutation,
  useGetAllProjectAlbumsQuery,
  useGetAllProjectImagesQuery,
  useDeleteImageMutation,
  useCreateImageMutation,
  useUpdateSeenByMutation,
  useCreateBucketImageMutation,
  useRestoreImagesMutation,
  useGetAlbumQuery,
  useSetAlbumCoverMutation,
  useRestoreAlbumsMutation,
  useDeleteAlbumsMutation,
  useRemoveImageFromAlbumMutation,
  useUpdateImageMutation,
  usePermanentDeleteAlbumMutation,
  usePermanentDeleteImageMutation,
  useCommentImageMutation,
  useGetChatQuery,
  useUploadImageMutation,
  useGetImageQuery,
} = imageApiSlice;
