import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { InlineWidget } from "react-calendly";

const SupportMenuModalMetting = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
          }}
        >
          <CloseIcon
            style={{
              backgroundColor: "red",
              borderRadius: "50%",
              padding: 1,
              color: "white",
            }}
          />
        </IconButton>
        <InlineWidget
          url="https://calendly.com/buildpeersupport"
          styles={{
            height: "100vh",
          }}
        />
      </Box>
    </Modal>
  );
};

export { SupportMenuModalMetting };
