import { Box, Card, Divider, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FilePreviewHorizontalScroll } from "../../../../components/shared";

function DelayReport({ delayReport, updateReport, category }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");

  return (
    <Box display="flex" width="100%">
      <Card sx={{ width: "98%", padding: 2, mb: 2 }}>
        <Typography fontWeight="bold">
          {tDailyReports("delay").toUpperCase()}
        </Typography>
        <Box display="flex" flex={1} width="100%" columnGap={2} mt={2} data-tour="edit-report-delay-9">
          <TextField
            fullWidth
            required
            label={tGeneral("days")}
            onClick={(e) => e.stopPropagation()}
            value={delayReport.delayDays}
            type="number"
            onFocus={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              updateReport({
                ...delayReport,
                delayDays: e.target.value,
              });
            }}
            inputProps={{
              style: {
                paddingRight: 5,
              },
            }}
          />
          <TextField
            fullWidth
            required
            label={tGeneral("hours")}
            onClick={(e) => e.stopPropagation()}
            value={delayReport.delayHours}
            type="number"
            onFocus={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              updateReport({
                ...delayReport,
                delayHours: e.target.value,
              });
            }}
            inputProps={{
              style: {
                paddingRight: 5,
              },
            }}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Typography fontWeight="bold">
          {tGeneral("files").toUpperCase()}
        </Typography>
        <Card sx={{ width: "100%", p: 2, my: 2 }} data-tour="edit-report-delay-10">
          <FilePreviewHorizontalScroll
            files={delayReport.files}
            onAddPhotos={(newFiles) => {
              updateReport({
                ...delayReport,
                files: newFiles,
              });
            }}
            onDelete={(file, i) => {
              let currentDelayReport = { ...delayReport };
              let newFiles = [...currentDelayReport.files];
              newFiles.splice(i, 1);
              currentDelayReport.files = newFiles;
              if (file.id) {
                currentDelayReport.deletedFiles = [
                  ...(currentDelayReport.deletedFiles || []),
                  file.id,
                ];
              }
              updateReport(currentDelayReport);
            }}
          />
          <TextField
            rows={6}
            fullWidth
            multiline
            required
            label={tGeneral("description")}
            value={delayReport.description}
            onChange={(e) => {
              updateReport({
                ...delayReport,
                description: e.target.value,
              });
            }}
            sx={{
              mt: 1,
            }}
          />
        </Card>
      </Card>
    </Box>
  );
}

export { DelayReport };
