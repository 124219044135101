import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppTourContext } from "../../../../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../../../../features/project/projectSlice";
import { DailyReportsTable } from "../DailyReportsTable";

function CategoryAccordion({
  filters,
  title,
  icon,
  color,
  category,
  counter = 0,
  reports = [],
  isLoading,
  selectedReports,
  setSelectedReports,
  dataTour,
}) {
  const [expanded, setExpanded] = useState(false);

  const {
    state: { tourActive, stepIndex, run },
    setState,
  } = useAppTourContext();

  const currentGuideMe = useSelector(selectCurrentGuideMe);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 1 &&
      dataTour &&
      (currentGuideMe === "daily-reports-view" ||
        currentGuideMe === "daily-reports-edit")
    ) {
      setExpanded(true);
      setTimeout(() => {
        setState({ run: true });
      }, 1000);
    }
  }, [currentGuideMe, setState, stepIndex, tourActive, dataTour]);

  return (
    <Box
      display="flex"
      sx={{
        backgroundColor: "white",
        p: "8px",
        borderRadius: "0.5rem",
        columnGap: "0.5rem",
      }}
    >
      <Box>
        <Box
          sx={{
            width: "0.5rem",
            borderRadius: "0.25rem",
            backgroundColor: color,
            height: "100%",
          }}
        />
      </Box>
      <Box flex={1} data-tour={dataTour}>
        <Accordion
          sx={{ boxShadow: "none" }}
          slotProps={{ transition: { unmountOnExit: true } }}
          expanded={expanded}
          onChange={() => setExpanded((prev) => !prev)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box display="flex" columnGap="0.5rem" alignItems="center">
              {icon}
              <Typography fontSize="0.875rem" fontWeight="500">
                {title} ({counter})
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <DailyReportsTable
              reports={reports}
              isLoading={isLoading}
              category={category}
              selectedReports={selectedReports}
              setSelectedReports={setSelectedReports}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

export { CategoryAccordion };
