import {
  Comment,
  MoreHoriz,
  RadioButtonChecked,
  RadioButtonUnchecked,
  RemoveRedEye,
} from "@mui/icons-material";
import { Box, Checkbox, Menu, Tooltip, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  TooltipIconButton,
  UpdateTextFieldModal,
} from "../../../../../components/shared";
import {
  useAssignDisciplineMutation,
  useAssignIdAuxMutation,
  useAssignSetMutation,
  useEditDisciplineMutation,
  useUpdateDrawingMutation,
  useUpdateSetMutation,
} from "../../../../../features/drawings";
import { AutocompleteCell } from "./AutocompleteCell";
import { EditNameCell } from "./EditNameCell";

function DrawingRow({
  drawing,
  isSelected,
  onSelect,
  sets,
  disciplines,
  idAuxs,
  onEdit,
  onDelete,
  onView,
  onComment,
}) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const [anchorEl, setAnchorEl] = useState(null);
  const [editing, setEditing] = useState(null);

  const fileName = useMemo(() => {
    if (drawing && drawing.pdfFile) {
      const urlSplitted = drawing.pdfFile.split("/");
      return decodeURIComponent(urlSplitted[urlSplitted.length - 1]);
    }
    return "";
  }, [drawing]);

  const handleOpenMore = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const [assingIdAux, { isLoading: isIdAuxLoading }] = useAssignIdAuxMutation();
  const [assignSet, { isLoading: isSetLoading }] = useAssignSetMutation();
  const [assignDiscipline, { isLoading: isDisciplineLoading }] =
    useAssignDisciplineMutation();
  const [updateDrawing, { isLoading: isUpdateDrawingLoading }] =
    useUpdateDrawingMutation();
  const [updateDiscipline, { isLoading: isUpdateDisciplineLoading }] =
    useEditDisciplineMutation();
  const [updateSet, { isLoading: isUpdateSetLoading }] = useUpdateSetMutation();

  const handleAssignIdAux = (idDrawing, idAux) => {
    assingIdAux({
      idProject,
      idDrawing,
      idAux,
      name: drawing.drawingId ? drawing.name : fileName.replace(".pdf", ""),
      idRevision: drawing.id,
    });
  };

  const handleAssignDiscipline = (idDiscipline, name) => {
    assignDiscipline({
      idProject,
      idDiscipline,
      name,
      idDrawing: drawing.drawingId,
    });
  };

  const handleAssignSet = (idSet, name) => {
    assignSet({
      idProject,
      idSet,
      name,
      idRevision: drawing.id,
    });
  };

  const handleUpdate = async (newValue) => {
    if (editing.type === "idAux") {
      await updateDrawing({ idDrawing: editing.idObject, idAux: newValue });
    } else if (editing.type === "set") {
      await updateSet({ id: editing.idObject, name: newValue });
    } else if (editing.type === "discipline") {
      await updateDiscipline({ id: editing.idObject, name: newValue });
    }
    setEditing(null);
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="1rem 4fr 3fr 2fr 1fr 2fr 2fr 100px"
      width="100%"
      alignItems="center"
      columnGap="1rem"
      p="0.5rem"
    >
      <Checkbox
        checked={isSelected}
        onChange={() => onSelect(drawing.id)}
        icon={<RadioButtonUnchecked />}
        checkedIcon={<RadioButtonChecked />}
      />
      <Tooltip title={fileName} sx={{ flex: 1 }}>
        <Typography
          noWrap
          sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
        >
          {fileName}
        </Typography>
      </Tooltip>
      <AutocompleteCell
        options={idAuxs?.map((idAux) => ({
          value: idAux.id,
          label: idAux.idAux,
        }))}
        onChange={(idDrawing) => handleAssignIdAux(idDrawing, null)}
        onCreate={(idAux) => handleAssignIdAux(null, idAux)}
        isLoading={isIdAuxLoading}
        value={drawing.drawingId}
        onEdit={(idDrawing, idAux) =>
          setEditing({
            type: "idAux",
            value: idAux,
            idObject: idDrawing,
            title: "ID Aux",
            label: "ID Aux",
          })
        }
      />
      <EditNameCell
        idDrawing={drawing.drawingId}
        value={drawing.drawingId ? drawing.name : fileName.replace(".pdf", "")}
      />
      <Typography textAlign="center">
        {drawing.drawingId ? drawing.number : ""}
      </Typography>
      <AutocompleteCell
        options={sets?.map((set) => ({ value: set.id, label: set.name }))}
        onChange={(idSet) => handleAssignSet(idSet, null)}
        onCreate={(name) => handleAssignSet(null, name)}
        isLoading={isSetLoading}
        value={drawing.idSet}
        onEdit={(idSet, name) =>
          setEditing({
            type: "set",
            idObject: idSet,
            value: name,
            title: tDrawings("setName"),
            label: tDrawings("setName"),
          })
        }
      />
      <AutocompleteCell
        options={disciplines?.map((discipline) => ({
          label: discipline.name,
          value: discipline.id,
        }))}
        onChange={(idDiscipline) => handleAssignDiscipline(idDiscipline, null)}
        onCreate={(name) => handleAssignDiscipline(null, name)}
        isLoading={isDisciplineLoading}
        value={drawing.idDiscipline}
        onEdit={(idDiscipline, name) =>
          setEditing({
            type: "discipline",
            idObject: idDiscipline,
            value: name,
            title: tDrawings("discipline"),
            label: tDrawings("discipline"),
          })
        }
      />
      <Box textAlign="center">
        <TooltipIconButton
          icon={<MoreHoriz />}
          color="black"
          onClick={handleOpenMore}
          label={tGeneral("more")}
        />
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Box display="flex">
            <TooltipIconButton
              icon={<Comment />}
              color="black"
              label={tGeneral("comment")}
              onClick={() => onComment(drawing.drawingId, drawing.comment)}
            />
            <TooltipIconButton
              icon={<RemoveRedEye />}
              color="black"
              label={tGeneral("view")}
              onClick={() => onView(drawing.id)}
            />
          </Box>
        </Menu>
      </Box>
      <UpdateTextFieldModal
        isOpen={Boolean(editing)}
        onClose={() => setEditing(null)}
        title={editing?.title}
        label={editing?.label}
        value={editing?.value}
        onUpdate={(newValue) => handleUpdate(newValue)}
        isLoading={
          isUpdateDrawingLoading ||
          isUpdateDisciplineLoading ||
          isUpdateSetLoading
        }
      />
    </Box>
  );
}

export { DrawingRow };
