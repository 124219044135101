const status = {
  open: "열림",
  closed: "닫힘",
  close: "닫힘",
  in_revision: "진행 중",
  draft: "초안",
  pending: "보류 중",
  doing: "열림",
  backlog: "열림",
  rejected: "거부됨",
  done: "완료",
  inactive: "비활성화",
};

export { status };
