import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import JSZip from "jszip";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DWGIcon from "../../assets/files/dwg.png";
import PDFIcon from "../../assets/files/pdf.png";

const TYPES = { dwg: "dwg", pdf: "pdf" };

function ModalDownload({ isOpen, onClose, revisions, drawingName }) {
  const { t: tGeneral } = useTranslation("general");
  const [omittedItems, setOmittedItems] = useState([]);
  const [selectedRevisions, setSelectedRevisions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleOmitItem = (id, type) => {
    const newOmittedItems = [...omittedItems];
    const index = newOmittedItems.findIndex(
      (item) => item.id === id && item.type === type,
    );
    if (index > -1) {
      newOmittedItems.splice(index, 1);
    } else {
      newOmittedItems.push({ id, type });
    }
    setOmittedItems(newOmittedItems);
  };

  const isOmitted = (id, type) =>
    omittedItems.findIndex((item) => item.id === id && item.type === type) > -1;

  const columns = [
    {
      field: "number",
      headerName: "#Rev",
      flex: 1,
    },
    {
      field: "files",
      headerName: "Files",
      renderCell: (params) => (
        <Box display="flex">
          {params.row.drawingFile && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleOmitItem(params.id, TYPES.dwg);
              }}
            >
              <img
                src={DWGIcon}
                alt="DWG File"
                width="30px"
                style={{
                  opacity: isOmitted(params.id, TYPES.dwg) ? "0.5" : "1",
                }}
              />
            </IconButton>
          )}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleOmitItem(params.id, TYPES.pdf);
            }}
            style={{
              opacity: isOmitted(params.id, TYPES.pdf) ? "0.5" : "1",
            }}
          >
            <img src={PDFIcon} alt="PDF File" width="30px" />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleDownload = async () => {
    setIsLoading(true);
    const selectedRevs = revisions.filter(
      (rev) => selectedRevisions.findIndex((selRev) => selRev === rev.id) > -1,
    );
    const downloadingItems = selectedRevs.reduce((prev, curr) => {
      const items = { ...prev };
      if (!items[`Rev-${curr.number}`]) {
        items[`Rev-${curr.number}`] = [];
      }
      if (
        omittedItems.findIndex(
          (omitted) => omitted.id === curr.id && omitted.type === TYPES.pdf,
        ) < 0
      ) {
        items[`Rev-${curr.number}`].push(curr.pdfFile);
      }
      if (
        omittedItems.findIndex(
          (omitted) => omitted.id === curr.id && omitted.type === TYPES.dwg,
        ) < 0 &&
        curr.drawingFile
      ) {
        items[`Rev-${curr.number}`].push(curr.drawingFile);
      }

      return items;
    }, {});
    const downloadKeys = Object.keys(downloadingItems);
    if (
      downloadKeys.length === 1 &&
      downloadingItems[downloadKeys[0]].length === 1
    ) {
      const link = document.createElement("a");

      const xhr = new XMLHttpRequest();
      xhr.open("GET", downloadingItems[downloadKeys[0]][0], true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const objectUrl = URL.createObjectURL(blob);

          link.href = objectUrl;
          link.setAttribute("download", `${drawingName} - ${downloadKeys[0]}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);

          URL.revokeObjectURL(objectUrl);
        }
      };
      xhr.send();
      setIsLoading(false);
      return;
    }
    const zip = new JSZip();
    await Promise.all(
      downloadKeys.map(async (folder) => {
        await Promise.all(
          downloadingItems[folder].map(async (url) => {
            const urlWithCacheBuster = `${url}?cache_buster=${new Date().getTime()}`;
            const res = await fetch(urlWithCacheBuster, { cache: "no-store" });
            const data = await res.blob();
            const extensionFile = url.split(".").pop();
            zip.file(
              `${folder}/${drawingName} - ${folder}.${extensionFile}`,
              data,
            );
          }),
        );
      }),
    );

    zip.generateAsync({ type: "blob" }).then((content) => {
      const downloadLink = document.createElement("a");
      downloadLink.download = `${drawingName}.zip`;
      downloadLink.href = URL.createObjectURL(content);
      downloadLink.click();
      setIsLoading(false);
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          p: "30px 40px",
          minHeight: "450px",
          width: "30%",
          borderRadius: "20px",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="1.563rem" fontWeight="600">
            {tGeneral("download")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ my: "20px" }} />
        <DataGrid
          columns={columns}
          rows={revisions}
          sx={{ "& .MuiDataGrid-footerContainer": { display: "none" } }}
          autoHeight
          checkboxSelection
          onRowSelectionModelChange={(model) => setSelectedRevisions(model)}
          disableRowSelectionOnClick
        />
        <Box display="flex" columnGap="16px" mt="24px">
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            sx={{ py: "16px" }}
            onClick={onClose}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            disabled={selectedRevisions.length === 0 || isLoading}
            variant="contained"
            fullWidth
            sx={{ py: "16px" }}
            onClick={handleDownload}
          >
            {isLoading ? (
              <CircularProgress color="primary" />
            ) : (
              tGeneral("download")
            )}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalDownload };
