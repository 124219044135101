import { tour } from "./tour";

export const tasks = {
  // general tour
  ...tour,
  moduleElement: "Tareas",
  // ADD - guide me steps

  task: "Tarea",
  tasks: "Tareas",
  high: "Alto",
  medium: "Medio",
  low: "Bajo",
  taskDeletedSuccessfully: "Tarea(s) eliminada con éxito",
  taskRestoredSuccessfully: "Tarea(s) restaurada con éxito",
  noTasksCreated: "No hay tareas creadas en este momento",
  noTasksContent:
    "Si desea agregar una, lo invitamos a hacer clic en el botón de Agregar Tarea para iniciar el proceso",
  addTask: "Agregar tarea",
  backlog: "Pendiente",
  doing: "En proceso",
  done: "Hecho",
  creationDate: "Fecha de creación",
  deliveryDate: "Fecha de entrega",
  closeDate: "Fecha de cierre",
  category: "Categoría",
  priority: "Prioridad",
  startTask: "Iniciar tarea",
  closeTask: "Cerrar tarea",
  days: "Días de respuesta",
  tasksRecycleBin: "Papelera de tareas",
  editTask: "Editar tarea",
  addCategory: "Agregar categoría",
  // ADD - guide me steps
  step1_addtask_title: "1. Agregar tarea",
  step1_addtask_text: "Haz clic aquí para agregar una nueva tarea.",
  step2_addtask_title: "2. Título",
  step2_addtask_text: "Escribe un título que pueda describir tu nueva tarea.",
  step3_addtask_title: "3. Fecha de entrega",
  step3_addtask_text: "Elige una fecha límite para finalizar la tarea.",
  step4_addtask_title: "4. Estatus",
  step4_addtask_text1_1: "Elige el estatus",
  step4_addtask_text1_2: "ABIERTO para publicar la tarea inmediatamente.",
  step4_addtask_text2_1: "Elige el estatus",
  step4_addtask_text2_2:
    "BORRADOR para guardar un borrador de la tarea sin publicarla.",
  step5_addtask_title: "5. Privacidad",
  step5_addtask_text:
    "Haga clic aquí para que sea visible para todos o solo para las personas involucradas",
  step5_addtask_text_1: "Elige el tipo de privacidad del RFI",
  step5_addtask_text_2: "Cualquier persona con acceso al módulo verá tu RFI",
  step5_addtask_text_3: "Solo los participantes podrán ver este RFI",
  step6_addtask_title: "6. Asignado",
  step6_addtask_text:
    "Selecciona a las personas que necesitan terminar la tarea.",
  step7_addtask_title: "7. Lista de distribución",
  step7_addtask_text:
    "Selecciona las personas a las que deseas notificar acerca de esta tarea.",
  step8_addtask_title: "8. Descripción",
  step8_addtask_text: "Escribe una descripción de lo que trata la tarea.",
  step9_addtask_title: "9. Subir archivos",
  step9_addtask_text:
    "Haz clic aquí o arrastra y suelta archivos como imágenes o documentos.",
  step10_addtask_title: "10. Categoría",
  step10_addtask_text: "Selecciona la categoría de la tarea.",
  step11_addtask_title: "11. Ubicación",
  step11_addtask_text:
    "Selecciona la ubicación dentro del proyecto donde se necesita realizar la tarea.",
  step12_addtask_title: "12. Prioridad",
  step12_addtask_text: "Selecciona entre alta, media o baja prioridad.",
  step13_addtask_title: "13. Publicar tarea",
  step13_addtask_text:
    "Haga clic aquí para publicar la tarea o guárdela como borrador si aún no está lista para publicarse.",
  stepFinal_addtask: "Haz aprendido cómo crear una nueva tarea",
  // VIEW - guide me steps
  step1_viewtask_title: "1. Ver tarea",
  step1_viewtask_text: "Haz click sobre una tarea para ver su contenido",
  step2_viewtask_title: "2. Involucrados",
  step2_viewtask_text:
    "En esta sección podrás veré quienes son los asignados y el creador",
  step3_viewtask_title: "3. Datos de la tarea",
  step3_viewtask_text:
    "En esta sección podrás ver los datos principales de la tarea, como fechas de entrega, estatus, prioridad, etc.",
  step4_viewtask_title: "4. Descripción",
  step4_viewtask_text:
    "Aquí podrás ver la descripción de la tarea creada por el dueño de la misma",
  step6_viewtask_title: "6. Responder",
  step6_viewtask_text:
    "Escribir una respuesta a la descripción principal y adjunta archivos a la misma , las diferentes respuestas se colocarán por debajo de de la descripción",
  step7_viewtask_title: "7. Comentar",
  step7_viewtask_text:
    "Comenta a una respuesta que se haya hecho y adjunta archivos. Los comentario se colocaran por debajo de la respuesta comentada",
  step8_viewtask_title: "8. Empezar/terminar tarea",
  step8_viewtask_text:
    "Haz click aquí para marcar la tarea como empezada; si ya ha sido empezada, haz click para terminar la tarea",
  stepFinal_viewtask: "Haz aprendido cómo ver un tarea",
  // EDIT - guide me steps
  step1_edittask_title: "1. Editar tarea",
  step1_edittask_text:
    "Haz click sobre los tres puntos para abrir las diferentes opciones: Haz click sobre editar",
  step2_edittask_title: "2. Información",
  step2_edittask_text:
    "En esta sección puedes editar el titulo, la fecha de entrega, el estatus y la privacidad",
  step3_edittask_title: "3. Descripción y Archivos Adjuntos",
  step3_edittask_text:
    "En esta sección puedes editar la descripción de la tarea y eliminar archivos adjuntos",
  step4_edittask_title: "4. Información adicional",
  step4_edittask_text:
    "En esta sección puedes editar la información adicional, como la categoría, la prioridad y la ubicación",
  step4_edittask_text1_1: "Elige el estatus",
  step4_edittask_text1_2:
    "ABIERTO para publicar el tarea pendiente de una respuesta.",
  step4_edittask_text2_1: "Elige el estatus",
  step4_edittask_text2_2:
    "BORRADOR para mantener un borrador del tarea sin publicarlo.",
  step5_edittask_title: "5. Asignados",
  step5_edittask_text: "Agrega o elimina personas asignadas a la tarea.",
  step5_edittask_text_1: "Elige el tipo de privacidad de tarea",
  step5_edittask_text_2: "Cualquier persona con acceso al módulo verá tu tarea",
  step5_edittask_text_3: "Solo los participantes podrán ver este tarea",
  step6_edittask_title: "6. Lista de distribución",
  step6_edittask_text: "Agrega o elimina personas a la lista de distribución",
  step7_edittask_title: "7. Guarda los cambios",
  step7_edittask_text:
    "Haz click aquí para guardar y actualizar los cambios hechos a la tarea",
  stepFinal_edittask: "Haz aprendido a editar una tarea",
  // RECYCLE - guide me steps
  step1_recycletask_title: "2. Papelera de tareas",
  step1_recycletask_text: "Haz clic aquí para abrir el menu de reciclaje",
  step2_recycletask_title: "3. Selecciona tareas",
  step2_recycletask_text: "Selecciona las tareas que se quieran restaurar",
  step3_recycletask_title: "4. Restaurar tareas",
  step3_recycletask_text:
    "Haz click aquí para restaurar las tareas seleccionadas",
  stepFinal_recycletask:
    "Haz aprendido cómo interactuar con la papelera de tareas",
  // EXPORT - guide me steps
  step1_exporttask_title:
    "Haz click sobre los tres puntos para abrir las diferentes opciones:",
  step1_exporttask_text1_1: "Exportar a PDF",
  step1_exporttask_text1_2:
    "Haz click aquí para exportar la tarea seleccionada a un archivo PDF",
  step1_exporttask_text2_1: "Mandar por correo",
  step1_exporttask_text2_2:
    "Haz click aqui para remitir la tarea por medio de correo electrónico",
  stepFinal_exporttask: "Haz aprendido a exportar una tarea",
  // SETTINGS - guide me steps
  step1_settingstask_title: "2. Ajustes",
  step1_settingstask_text:
    "Haz click que para abrir el menu de ajustes del modulo",
  /*step2_settingstask_title: "3. Días",
  step2_settingstask_text:
    "Selecciona cuanto es el límite mínimo de días para entregar una tarea de manera predeterminada",*/
  step3_settingstask_title: "3. Lista de distribución",
  step3_settingstask_text:
    "Selecciona quienes son las personas predeterminadas par la lista de distribución de las tareas creadas",
  step4_settingstask_title: "4. Guardar",
  step4_settingstask_text: "Haz click aquí para guardar los cambios realizados",
  stepFinal_settingstask:
    "Haz aprendido cómo interactuar con la configuración de tareas",
  // other
  obligatory: "Obligatorio",
  public: "Público",
  private: "Privado",
  congratulations: "¡Felicidades!",
  rfi_title_module: "RFIs (Dudas)",
  no_rfis_title: "No se han creado RFIs (Dudas) hasta el momento",
  no_rfis_text:
    "Si deseas agregar uno, te invitamos a dar click en el botón de añadir para comenzar con el proceso",
  meeting: "Soporte",
  workflow: "Flujo",
  guideMe: "Guíame",
  support: "Soporte",
  closeTaskMessage: "¿Estás seguro de cerrar esta tarea?",
  subtasks: "Subtareas",
  addSubtask: "Agregar subtarea",
  noSubtasks: "No hay subtareas creadas",
  inList: "En lista",
};
