import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Search } from "@mui/icons-material";
import { useGetProjectUsersQuery } from "../../../features/project/projectApiSlice";
import { FilterSelect } from "../../shared";
import { SearchUserFilter } from "../../shared/SearchUserFilter";
import { DateRangeFilter } from "../../shared/DateRangeFilter";

function RfiFilters({ onChange, disableSearch = false }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { data } = useGetProjectUsersQuery(idProject);

  const timerIdRef = useRef(null);

  const [assignees, setAssignees] = useState(null);
  const [creator, setCreator] = useState(null);
  const [status, setStatus] = useState(null);
  const [privacy, setPrivacy] = useState(null);
  const [search, setSearch] = useState("");
  const [createdOn, setCreatedOn] = useState(null);

  const projectUsers = useMemo(
    () =>
      data
        ? data.map((user) => ({
            id: user.user.id,
            name: `${user.user.firstName} ${user.user.lastName}`,
            urlAvatar: user.user.urlAvatar,
          }))
        : [],
    [data],
  );

  const filterOptions = [
    {
      value: "assignees",
      label: tGeneral("assignees"),
    },
    {
      value: "creator",
      label: tGeneral("createdBy"),
    },
    {
      value: "status",
      label: tGeneral("status"),
    },
    {
      value: "privacy",
      label: tGeneral("privacy"),
    },
    {
      value: "createdOn",
      label: tGeneral("createdOn"),
    },
  ];

  const privacyOptions = [
    {
      value: "private",
      label: tGeneral("private").toUpperCase(),
    },
    {
      value: "public",
      label: tGeneral("public").toUpperCase(),
    },
  ];

  const handleAddFilter = (e) => {
    const {
      target: { value },
    } = e;
    if (value === "assignees" && assignees === null) {
      setAssignees([]);
    }
    if (value === "creator" && creator === null) {
      setCreator([]);
    }
    if (value === "status" && status === null) {
      setStatus([]);
    }
    if (value === "privacy" && privacy === null) {
      setPrivacy([]);
    }
    if (value === "createdOn" && createdOn === null) {
      setCreatedOn([]);
    }
  };

  const statusOptions = [
    {
      value: "pending",
      label: tGeneral("open_action").toUpperCase(),
      color: "#5ce381",
    },
    {
      value: "draft",
      label: tGeneral("draft").toUpperCase(),
      color: "#ffc900",
    },
    {
      value: "closed",
      label: tGeneral("closed").toUpperCase(),
      color: "#707070",
    },
  ];

  const handleChange = (val, filter) => {
    const newVal = { creator, assignees, status, privacy, createdOn };
    if (filter === "creator") {
      setCreator(val);
    }
    if (filter === "assignees") {
      setAssignees(val);
    }
    if (filter === "status") {
      setStatus(val);
    }
    if (filter === "privacy") {
      setPrivacy(val);
    }
    if (filter === "createdOn") {
      setCreatedOn(val);
    }
    newVal[filter] = val;
    onChange(newVal);
  };

  const handleSearchChange = (e) => {
    const {
      target: { value },
    } = e;
    setSearch(value);
    clearTimeout(timerIdRef.current);
    timerIdRef.current = setTimeout(() => {
      handleChange(value, "search");
    }, 500);
  };

  return (
    <Box display="flex" width="100%" columnGap="10px" p="10px">
      <Box display="flex" flex={1} columnGap="10px">
        <FormControl size="small">
          <InputLabel id="add-filter-select-label" aria shrink={false}>
            {tGeneral("addFilter")}
          </InputLabel>
          <Select
            value=""
            onChange={handleAddFilter}
            label={tGeneral("filters")}
            placeholder={tGeneral("filters")}
            sx={{ minWidth: "180px" }}
          >
            {filterOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {assignees !== null && (
          <SearchUserFilter
            title={tGeneral("assignees")}
            userList={projectUsers}
            selectedUsersList={projectUsers.filter(
              (user) =>
                assignees.findIndex(
                  (assignedUser) => assignedUser === user.id,
                ) > -1,
            )}
            selectedUsersFormatted={assignees}
            handleUpdateUsersList={(list) => {
              handleChange(list.length > 0 ? list.map((user) => user.id) : null, "assignees");
            }}
            selectProps={{
              ml: 1,
              width: "200px",
            }}
          />
        )}
        {creator !== null && (
          <SearchUserFilter
            title={tGeneral("createdBy")}
            userList={projectUsers}
            selectedUsersList={projectUsers.filter(
              (user) =>
                creator.findIndex(
                  (creatorUser) => creatorUser === user.id,
                ) > -1,
            )}
            selectedUsersFormatted={creator}
            handleUpdateUsersList={(list) => {
              handleChange(list.length > 0 ? list.map((user) => user.id) : null, "creator");
            }}
            selectProps={{
              ml: 1,
              width: "200px",
            }}
          />
        )}
        {status !== null && (
          <FilterSelect
            label={tGeneral("status")}
            renderText={tGeneral("status")}
            selectedObjects={status}
            onChange={(val) => handleChange(val, "status")}
            options={statusOptions}
            size="small"
          />
        )}
        {privacy !== null && (
          <FilterSelect
            label={tGeneral("privacy")}
            renderText={tGeneral("privacy")}
            selectedObjects={privacy}
            onChange={(val) => handleChange(val, "privacy")}
            options={privacyOptions}
            size="small"
          />
        )}
        {createdOn !== null && (
          <DateRangeFilter
            onChange={(stringDates) => {
              handleChange(stringDates, "createdOn");
            }}
          />
        )}
      </Box>
      {!disableSearch && (
        <TextField
          sx={{ justifyItems: "flex-end" }}
          size="small"
          value={search}
          InputProps={{ startAdornment: <Search /> }}
          placeholder={tGeneral("search")}
          onChange={handleSearchChange}
        />
      )}
    </Box>
  );
}

export { RfiFilters };
