import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setAccessToken } from "../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://api.app.buildpeer.com/",
  // baseUrl: process.env.REACT_APP_BACKEND_URL,
  // Send back our httpOnly secure cookie
  credentials: "same-origin",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
  /* credentialsRecoverPassword: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.recoverPasswordToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  }, */
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.originalStatus === 403 || result?.error?.status === 403) {
    const refreshResult = await baseQuery(
      {
        url: "/auth/token",
        method: "POST",
      },
      api,
      extraOptions,
    );
    if (refreshResult?.data) {
      api.dispatch(setAccessToken(refreshResult.data.accessToken));
      api.getState("auth");
      result = await baseQuery(args, api, extraOptions);
    } else {
      await baseQuery(
        {
          url: "auth/logout",
          method: "POST",
        },
        api,
        extraOptions,
      );
      api.dispatch(logOut());
    }
  }
  return result;
};

// Creating api
export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Enterprises",
    "Projects",
    "Albums",
    "Images",
    "RFIs",
    "Tasks",
    "Announcements",
    "Specifications",
    "CostCodes",
    "Message",
    "ProjectConfig",
    "Submittals",
    "Locations",
    "AdminPanel",
    "VisibleModules",
    "User",
    "TodoList",
    "Dashboard",
    "Meetings",
    "Documents",
    "ProjectFolder",
    "DailyReports",
    "ImageChat",
    "Drawings",
    "Disciplines",
    "Sets",
    "DailyReportsChat",
    "Annotations",
    "NotificationsProject",
    "Contacts",
    "DistributionList",
    "UserPreferences",
    "ProjectDirectoryUsers",
    "Companies",
    "Inspections",
    "Notifications",
    "Types",
    "BIM",
    "Units",
    "ProjectNetwork",
    "ProjectNetworkCodes",
    "DrawingsChecklist",
    "JobTypes",
    "Subcontractors",
    "Manpower",
    "Roles",
    "InspectionsTemplates",
  ],
  endpoints: (builder) => ({}),
});
