import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGetProjectUsersQuery } from "../../../features/project/projectApiSlice";
import {
  selectCurrentProject,
  selectCurrentRole,
  setCurrentProjectUsers,
} from "../../../features/project/projectSlice";
import { Announcements } from "./Announcements";
import { ModulePendings } from "./ModulePendings";
import { Pendings } from "./Pendings";
import { TodoList } from "./TodoList/TodoList";
import { Weather } from "./Weather";
import Can from "../../../utils/can";

function Dashboard() {
  const { t: tGeneral } = useTranslation("general");
  const currentProject = useSelector(selectCurrentProject);
  const { data: projectUsers, isSuccess } = useGetProjectUsersQuery(
    currentProject?.id,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess && projectUsers.length > 0) {
      const usersList = projectUsers
        .filter(function (obj) {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.user.id,
          name: `${user.user.firstName} ${user.user.lastName}`,
          urlAvatar: user.user.urlAvatar,
        }));
      dispatch(setCurrentProjectUsers(usersList));
    }
  }, [isSuccess, projectUsers, dispatch]);

  return (
    <Box
      sx={{ width: "100%", height: "88vh" }}
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        mb="12px"
        borderBottom="1px solid #FFC42D"
        minHeight="60px"
        alignItems="center"
      >
        <Typography ml="10px" variant="h4">
          {tGeneral("dashboard")}
        </Typography>
        {/* <SupportButton sx={{ height: "100%" }} /> */}
      </Box>

      <Box display="flex" columnGap="25px" height="100%" px="10px">
        {/* module pendings */}
        <Can I="superAdmin" a="dashboard">
          <Box flex={1} rowGap="10px" sx={{ overflowY: "auto" }}>
            <Typography pl="12px" fontSize="1.375rem">
              Resumen
            </Typography>
            <ModulePendings />
          </Box>
        </Can>

        <Box display="flex" flexDirection="column" flex={1} rowGap="22px">
          {/* pendings */}
          <Pendings />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          overflow="hidden"
          rowGap="10px"
        >
          <Announcements />
          <Weather />
          <TodoList />
        </Box>
      </Box>
    </Box>
  );
}

export { Dashboard };
