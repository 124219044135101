export const analytics = {
  projectProgress: "项目进度",
  hi: "你好",
  realProgress: "实际进度",
  welcomeBack: "欢迎回来",
  moduleStatistics: "模块统计",
  onTime: "准时",
  overdue: "逾期",
  constructionPercentage: "建设百分比",
  completed: "已完成",
  thisWeek: "本周",
  workForce: "劳动力",
  hours: "小时",
  completedTasks: "已完成的任务",
  analytics: "分析",
  addWidgets: "添加您的小部件以显示",
  projectStatistics: "项目统计",
  rfis: "RFIs",
  tasks: "任务",
  submittals: "提交",
  drawingsProgress: "图纸进度",
  drawingsCount: "图纸",
  inspections: "检查",
  inspectionCount: "检查",
  delayed: "延迟",
  closed: "已关闭",
  open: "打开",
  pending: "待处理",
  inProgress: "进行中",
  done: "已完成",
  revision: "修订",
  total: "总计",
  selectWidget: "选择您选择的小部件",
  widgetDisplayed: "所选小部件将显示在您的仪表板上",
  somethingWentWrong: "出了点问题",
};
