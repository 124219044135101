// React imports
import { useState } from "react";

// Third-party library imports
import AddIcon from "@mui/icons-material/Add";
import { Box, Fab, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDroppable } from "@dnd-kit/core";

// Project-specific absolute imports
import Can from "../../../utils/can";

// Relative imports
import AddWidgetModal from "./AddWidgetModal";
import Widget from "./Widget";
import { useSelector, useDispatch } from "react-redux";
import {
  addNewWidget,
  setEditing,
  selectAllWidgets,
} from "../../../features/project/analyticsSlice"; // adjust the path as necessary

const DroppableBox = ({ rowIndex, colIndex }) => {
  const { t: tAnalytics } = useTranslation("analytics");
  const dispatch = useDispatch();
  const widgets = useSelector(selectAllWidgets);

  // Set up the droppable area
  const { setNodeRef, isOver } = useDroppable({
    id: `${rowIndex}-${colIndex}`,
  });

  // State to control the dialog visibility
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Handle opening the dialog
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    dispatch(setEditing(false)); // Set isEditing to false
  };

  // Handle closing the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  // Handle adding a widget of the selected type and size
  const handleAddWidget = (type, size) => {
    dispatch(addNewWidget({ type, size, rowIndex, colIndex }));
    handleCloseDialog();
  };

  const widget = widgets[`${rowIndex}-${colIndex}`];
  const isWidgetMain = widget && widget.mainIndex === `${rowIndex}-${colIndex}`;

  return (
    <Box
      ref={setNodeRef}
      sx={{
        height: "266px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        background: isOver ? "#f0f0f0" : "white",
        borderRadius: "16px",
        padding: "8px",
        boxSizing: "border-box",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      {isWidgetMain ? (
        <Widget
          id={widget.id}
          size={widget.size}
          mainIndex={widget.mainIndex}
          type={widget.type}
        />
      ) : !widget ? (
        // Render the add widget button if there is no widget in the droppable area
        <Can I="add" a="analytics">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              zIndex: 1,
            }}
          >
            <Fab
              sx={{
                backgroundColor: "#F5F5F5",
                color: "#8E8E8E",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "#FBF18B",
                },
              }}
              aria-label="add"
              onClick={handleOpenDialog}
            >
              <AddIcon sx={{ fontSize: "48px" }} />
            </Fab>
            <Typography
              sx={{
                color: "#8E8E8E",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "22px",
              }}
            >
              {tAnalytics("addWidgets")}
            </Typography>
          </Box>
        </Can>
      ) : null}
      <AddWidgetModal
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onAddWidget={handleAddWidget}
      />
    </Box>
  );
};

export default DroppableBox;
