import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
// MUI Components
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Api Slice
import {
  useGetRfisQuery,
  useRecoverRfiMutation,
} from "../../features/project/modules/rfiApiSlice";
// React router

// Alert
import { PopUpAlert } from "../../components/PopUpAlert";
import { RFITable } from "../../components/projectDashboard/Rfis";
import { TitleWidgets } from "../../components/shared";
import { useAppTourContext } from "../../components/supportButton/context/context";
import { useSelector } from "react-redux";
import { selectCurrentGuideMe } from "../../features/project/projectSlice";

function RfiRecycle() {
  const { idProject } = useParams();

  const [selectedRfis, setSelectedRfis] = useState({});
  // Translations
  const { t: tGeneral } = useTranslation("general");
  const { t: tRfis } = useTranslation("rfis");
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  const { data: rfisData, refetch } = useGetRfisQuery({
    idProject,
    params: { bActive: false },
  });

  const rfis = useMemo(() => rfisData ?? [], [rfisData]);

  const [
    recoverRfis,
    {
      isSuccess: isRecoverSuccess,
      isError: isRecoverError,
      isLoading: isRestoreLoading,
    },
  ] = useRecoverRfiMutation();

  // Restore rfi
  const restoreRfi = () => {
    const body = { ids: Object.keys(selectedRfis) };
    recoverRfis(body);
    setSelectedRfis({});
    refetch();
  };

  useEffect(() => {
    if (isRecoverSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        `RFI ${tGeneral("recoveredSuccessfully")}`,
      );
    }
    if (isRecoverError) {
      PopUpAlert("error", "Error", tGeneral("cantRestore"));
    }
  }, [tGeneral, isRecoverSuccess, isRecoverError]);

  useLayoutEffect(() => {
    if (tourActive && stepIndex === 2 && currentGuideMe === "rfi-recycle") {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe]);

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <TitleWidgets
        title={tRfis("rfi_recycle_bin")}
        backEnabled
        isRecycle
        restoreDisabled={!Object.keys(selectedRfis).length}
        onRestore={restoreRfi}
        isRestoreLoading={isRestoreLoading}
      />
      <RFITable
        rfis={rfis}
        selectedRfis={selectedRfis}
        setSelectedRfis={setSelectedRfis}
        isRecycle
      />
    </Box>
  );
}

export default RfiRecycle;
