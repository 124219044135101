export const analytics = {
  projectProgress: "Progresso do projeto",
  hi: "Oi",
  realProgress: "Progresso real",
  welcomeBack: "Bem-vindo",
  moduleStatistics: "Estatísticas do módulo",
  onTime: "No Tempo",
  overdue: "Atrasado",
  constructionPercentage: "Porcentagem de construção",
  completed: "Concluído",
  thisWeek: "Esta semana",
  workForce: "Força de trabalho",
  hours: "Horas",
  completedTasks: "Tarefas concluídas",
  analytics: "Analítica",
  addWidgets: "Adicione seus widgets para exibir",
  projectStatistics: "Estatísticas do projeto",
  rfis: "RFIs",
  tasks: "Tarefas",
  submittals: "Submissões",
  drawingsProgress: "Progresso dos Desenhos",
  drawingsCount_one: "Desenho",
  drawingsCount_other: "Desenhos",
  inspections: "Inspeções",
  inspectionCount_one: "Inspeção",
  inspectionCount_other: "Inspeções",
  delayed: "Atrasado",
  closed: "Fechado",
  open: "Aberto",
  pending: "Pendente",
  inProgress: "Em Progresso",
  done: "Concluído",
  revision: "Revisão",
  total: "Total",
  selectWidget: "Selecione o widget de sua escolha",
  widgetDisplayed: "O widget selecionado será exibido em seu painel",
  somethingWentWrong: "Algo deu errado",
};
