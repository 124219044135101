import { datadogRum } from "@datadog/browser-rum";
import {
  Check,
  EditNote,
  PictureAsPdf,
  RemoveRedEye,
} from "@mui/icons-material";
import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Dollar from "../../assets/rfi/dollar.png";
import Find from "../../assets/rfi/find.svg";
import Plan from "../../assets/rfi/plan.svg";
import Time from "../../assets/rfi/time.svg";
import {
  useCloseRfiMutation,
  useGetRfiByIdQuery,
} from "../../features/project/modules/rfiApiSlice";
import { capitalizeFirstLetterOfSentence } from "../../services/helpers/capitalizeFirstLetterOfSentence";

import RfiUserDetail from "../../components/projectDashboard/RfiUserDetail";
import BubbleAvatarDeletable from "../../components/userAvatars/BubbleAvatarDeletable";

// Redux
import { useGetProjectUsersQuery } from "../../features/project/projectApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
} from "../../features/project/projectSlice";
import { useGetSpecificationsQuery } from "../../features/project/specificationApiSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
import { selectCurrentToken } from "../../features/auth/authSlice";
// React router

// Jspdf
import "jspdf-autotable";

// Alert
import { Question } from "../../components/question";
import {
  DetailsHeader,
  ModalLastEdit,
  ModalSeenBy,
} from "../../components/shared";
import { Topic } from "../../components/topic";
import { useAddUserLogMutation } from "../../features/log/logApiSlice";

import { ModalFileViewer } from "../../components/shared/ModalFileViewer";
import { useAppTourContext } from "../../components/supportButton/context/context";
import CaslContext from "../../utils/caslContext";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import pdf from "../../utils/pdf";

const styles = {
  sectionTitle: {
    textAlign: "left",
    fontWeight: "bold",
  },
  sectionText: {
    textAlign: "left",
  },
  box1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: "1",
  },
  // PropertyCard.value: {
  //   flex: "end",
  // }
};

// TODO: make class component
function PropertyCard({ value, label, img }) {
  return (
    <Card sx={{ minWidth: "160px", overflowY: "true", my: "1%" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {img}
        <Typography fontSize="14px" style={{ marginTop: 5 }} color="#707070">
          {label}
        </Typography>
        <Typography fontSize="18px" fontWeight="medium">
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}

function RfiViewDetails() {
  const [pdfUrl, setPdfUrl] = React.useState(null);
  const ability = useContext(CaslContext);

  const navigate = useNavigate();
  const [closeCurrentRfi] = useCloseRfiMutation();
  const { idEnterprise, idProject, idRfi } = useParams();
  const { data: projectUsers } = useGetProjectUsersQuery(idProject);
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const { data: projectSpecs } = useGetSpecificationsQuery(idProject);
  const [addLog] = useAddUserLogMutation();

  const currentProject = useSelector(selectCurrentProject);
  const { t: tGeneral } = useTranslation("general");

  const currentUser = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);
  const [specification, setSpecification] = useState();
  const [inProgress, setInProgress] = useState(false);
  const [openLastSeen, setOpenLastSeen] = useState(false);
  const [openLastEdit, setOpenLastEdit] = useState(false);
  // TODO: make helper function
  const goBack = () => {
    navigate(-1);
  };
  const { data: currentRfi } = useGetRfiByIdQuery(idRfi);

  const exportPdfDocument = async (rfi) => {
    await pdf({
      data: {},
      token,
      url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/rfis/${rfi.id}`,
      download: `${rfi.title}.pdf`,
    });
  };

  function showAlert(message, type) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }

  // TODO: section in-component methods together (CRUD)
  const closeRfi = async () => {
    const dateNow = new Date();

    const body = {
      idRfi: currentRfi?.id,
      closeDate: dateNow,
    };

    try {
      const response = await closeCurrentRfi(body).unwrap();

      if (response) {
        showAlert(`RFI ${tGeneral("closedSuccessfully")}`, "success");
        navigate(-1);
      }
    } catch (err) {
      showAlert(`${tGeneral("cantClose")} RFI`, "error");
      datadogRum.addError({
        message: "RFI's - Close - Error",
        stack: err.stack ? err.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: currentProject?.idEnterprise,
          module: "RFI's",
          project: currentProject?.id,
        },
        timestamp: Date.now(),
      });
    }
  };

  useEffect(() => {
    setInProgress(true);
    if (projectUsers && currentRfi && projectSpecs) {
      const tempDistList = projectUsers.filter((obj) =>
        currentRfi?.distributionList.includes(obj.id),
      );
      const distList = [];
      tempDistList.forEach((user) => {
        distList.push({
          ...user,
          name: `${user.user.firstName} ${user.user.lastName}`,
          urlAvatar: user.user.urlAvatar,
        });
      });
      if (currentRfi?.specification) {
        const currentSpec = projectSpecs.filter(
          (obj) => obj.id === currentRfi?.specification,
        )[0];
        setSpecification(`${currentSpec?.code} - ${currentSpec?.name}`);
      }
      setInProgress(false);
    }
  }, [projectUsers, currentRfi, projectSpecs]);

  useEffect(() => {
    const body = {
      idEnterprise,
      idProject,
      module: "rfi",
      action: "seen",
      idObject: idRfi,
      result: "ok",
    };
    addLog(body).unwrap();
  }, [addLog, idProject, idRfi, idEnterprise]);

  const showClose = useMemo(() => {
    if (currentRfi && ability && currentUser) {
      return (
        currentRfi?.status !== "closed" &&
        ((currentRfi?.createdBy.id === currentUser.id &&
          ability.can("close", "rfis")) ||
          ability.can("closeAll", "rfis"))
      );
    }
    return false;
  }, [currentRfi, ability, currentUser]);

  const canAnswer = useMemo(() => {
    if (currentRfi && currentUser) {
      return (
        !!currentRfi.assignedTo.find((user) => user.id === currentUser.id) ||
        currentRfi.createdBy.id === currentUser.id
      );
    }
    return false;
  }, [currentRfi, currentUser]);

  const canTopic = useMemo(() => {
    if (currentRfi && currentUser) {
      return (
        !ability.can("onlyView", "rfis") &&
        (!!currentRfi.assignedTo.find((user) => user.id === currentUser.id) ||
          !!currentRfi.distributionList.find(
            (user) => user.id === currentUser.id,
          ) ||
          currentRfi.createdBy.id === currentUser.id)
      );
    }
    return false;
  }, [currentRfi, currentUser, ability]);
  const isMobile = useMediaQuery("(max-width:820px)");

  const {
    setState,
    state: { tourActive, stepIndex },
  } = useAppTourContext();

  useLayoutEffect(() => {
    if (
      tourActive &&
      stepIndex === 1 &&
      currentGuideMe === "rfi-view" &&
      !inProgress
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, inProgress, setState]);

  function getCurrentStatus(status, bDraft) {
    let statusLabel = "open";
    if (status === "pending" && bDraft) statusLabel = "draft";
    if (status === "closed") statusLabel = "closed";

    return statusLabel;
  }

  if (inProgress) {
    return (
      <Box p="1rem 0.5rem">
        <SkeletonLoading />
      </Box>
    );
  }

  return (
    <Box>
      <Box width="100%">
        <Box display="flex" width="100%">
          <DetailsHeader
            bPrivate={currentRfi?.bPrivate}
            number={currentRfi?.number}
            title={currentRfi?.title}
            onBack={goBack}
            showMainAction={showClose}
            mainAction={{
              icon: <Check />,
              label: `${tGeneral("close")} RFI`,
              onClick: () => closeRfi(),
            }}
            mainActionDataTour="view-rfi-9"
            status={getCurrentStatus(currentRfi?.status, currentRfi?.bDraft)}
            actions={[
              {
                onClick: () => {
                  exportPdfDocument(currentRfi);
                },
                icon: <PictureAsPdf />,
                label: tGeneral("exportPdf"),
                dataTour: "export-pdf",
              },
              {
                onClick: () => setOpenLastEdit(true),
                icon: <EditNote />,
                label: tGeneral("lastEdit"),
                dataTour: "last-edit",
              },
              {
                onClick: () => setOpenLastSeen(true),
                icon: <RemoveRedEye />,
                label: tGeneral("lastSeen"),
                dataTour: "last-seen",
              },
            ]}
          />
          {isMobile ? (
            <Box
              display="flex"
              flex={1}
              sx={{ display: "flex", alignItems: "center", my: "1%" }}
            >
              {/* Title */}
              <Typography
                textAlign="center"
                flex={1}
                fontSize="16px"
                fontWeight="medium"
              >
                {capitalizeFirstLetterOfSentence(currentRfi?.title)}
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Divider />
      </Box>
      <Box
        sx={{ width: "100%", maxHeight: "75vh", overflow: "auto" }}
        data-tour="resize-view-rfi-2"
      >
        <Box data-tour="view-rfi-2" p="10px">
          {currentRfi && <RfiUserDetail currentRfi={currentRfi} />}
          <Divider />
          {/* SECTION #2 - ADDITIONAL INFORMATION */}
          <Box
            display="flex"
            my="20px"
            justifyContent="space-evenly"
            gap="1%"
            flexWrap="wrap"
          >
            {/* Delay */}
            <Box sx={{ flex: 1, maxWidth: isMobile ? "50%" : "auto" }}>
              <PropertyCard
                img={<img src={Time} alt="Retraso" style={{ height: 40 }} />}
                label={tGeneral("delay")}
                value={
                  // eslint-disable-next-line no-nested-ternary
                  currentRfi?.delayDays > 0
                    ? // eslint-disable-next-line no-nested-ternary
                      `${currentRfi?.delayDays} days ${
                        currentRfi?.delayHours > 0
                          ? `${currentRfi?.delayHours} hours`
                          : ""
                      }`
                    : currentRfi?.delayHours > 0
                      ? `${currentRfi?.delayHours} hours`
                      : "N/A"
                }
              />
            </Box>

            {/* Cost */}
            <Box sx={{ flex: 1, maxWidth: isMobile ? "50%" : "auto" }}>
              <PropertyCard
                img={<img src={Dollar} style={{ height: 40 }} alt="Costo" />}
                label={tGeneral("cost")}
                value={
                  currentRfi?.cost > 0
                    ? `$${currentRfi?.cost} ${currentRfi?.currency}`
                    : "N/A"
                }
              />
            </Box>

            {/* Drawing */}
            <Box sx={{ flex: 1, maxWidth: isMobile ? "50%" : "auto" }}>
              <PropertyCard
                img={<img src={Plan} style={{ height: 40 }} alt="Plano" />}
                label={tGeneral("drawing")}
                value={
                  currentRfi?.drawing?.length > 0
                    ? `${currentRfi?.drawing[0].idAux} - ${currentRfi?.drawing[0].name}`
                    : "N/A"
                }
              />
            </Box>

            {/* Specification */}
            <Box sx={{ flex: 1, maxWidth: isMobile ? "50%" : "auto" }}>
              <PropertyCard
                img={
                  <img src={Find} style={{ height: 40 }} alt="Especificación" />
                }
                label={tGeneral("specification")}
                value={specification ?? "N/A"}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        {/* SECTION #4 - CHAT */}
        <Box
          display="flex"
          flexDirection="column"
          mt="35px"
          width="100%"
          data-tour="view-rfi-3"
          p="10px"
        >
          <Question
            object={currentRfi}
            moduleName="rfis"
            disabled={currentRfi?.status === "closed" || !canAnswer}
            canMakeOfficial={
              currentRfi?.createdBy.id === currentUser.id &&
              !currentRfi?.hasOfficialAnswer
            }
          />
        </Box>
        <Divider />
        <Box data-tour="view-rfi-6" mt="35px" p="10px">
          <Topic
            idObject={currentRfi?.id}
            topics={currentRfi?.topics || []}
            moduleName="rfis"
            disabled={currentRfi?.status === "closed" || !canTopic}
          />
        </Box>

        {Boolean(pdfUrl) && (
          <ModalFileViewer
            file={{ url: pdfUrl }}
            isOpen={Boolean(pdfUrl)}
            onClose={() => setPdfUrl(null)}
          />
        )}
      </Box>
      <Divider />
      {/* SECTION #5 - DISTRIBUTION LIST */}
      <Box display="flex" flexDirection="column" px="10px" overflow="hidden">
        <Typography mt="20px" style={styles.sectionTitle}>
          {tGeneral("distributionList")}
        </Typography>

        <Box display="flex" mb="20px" flex={1} sx={{ overflowX: "auto" }}>
          {currentRfi?.distributionList?.length ? (
            currentRfi?.distributionList.map((member) => (
              <BubbleAvatarDeletable
                key={member.id}
                user={member}
                editable={false} // To hide delete button
                color // To show background color
                isList={false} // To add padding
              />
            ))
          ) : (
            <Typography fontSize="18px" fontWeight="medium">
              N/A
            </Typography>
          )}
        </Box>
      </Box>
      <ModalSeenBy
        isOpen={openLastSeen}
        onClose={() => setOpenLastSeen(false)}
        idObject={idRfi}
      />
      <ModalLastEdit
        isOpen={openLastEdit}
        onClose={() => setOpenLastEdit(false)}
        idObject={idRfi}
      />
    </Box>
  );
}

export { RfiViewDetails };
