import { useDispatch, useSelector } from "react-redux";

import { Box, Checkbox, Typography } from "@mui/material";
import {
  addSelectedTask,
  removeSelectedTask,
  selectSelectedTasks,
} from "../../../features/project/modules/tasks/tasksSlice";
import TaskCard from "./TaskCard";
import locationsUtil from "../../../utils/locations";
import { useTranslation } from "react-i18next";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import { useContext } from "react";
import CaslContext from "../../../utils/caslContext";

function TaskBoardColumn({
  title,
  tasks = [],
  users = [],
  locations = [],
  borders = false,
  droppableId,
}) {
  const dispatch = useDispatch();
  const selectedTasks = useSelector(selectSelectedTasks);
  const user = useSelector(selectCurrentUser);

  const ability = useContext(CaslContext);

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      // Select all tasks in the column
      tasks.forEach((task) => {
        dispatch(addSelectedTask(task?.id));
      });
    } else {
      // Unselect all tasks in the column
      tasks.forEach((task) => {
        dispatch(removeSelectedTask(task?.id));
      });
    }
  };

  // Translation
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");
  
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#FFF5DB' : 'transparent',
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 0,
    margin: `0 0 0 0`,
    background: 'transparent',

    // styles we need to apply on draggables
    ...draggableStyle
  });

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowX: "hidden",
        overflowY: "auto",
        height: "100%",
      }}
    >
      {/* Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          mb: 1,
        }}
      >
        <Box display="flex" alignItems={"center"}>
          <Checkbox
            checked={
              tasks?.length > 0 &&
              selectedTasks.length > 0 &&
              tasks?.every((task) => selectedTasks?.includes(task?.id))
            }
            onChange={handleCheckboxChange}
            size="small"
            color="secondary"
            disableRipple
            sx={{
              borderRadius: 0,
              width: "25px",
              height: "25px",
              mr: 1,
            }}
          />
          <Typography fontSize={"24px"} fontWeight={"medium"}>
            {title}
          </Typography>
        </Box>
        <Typography fontSize={"24px"} color={"#B0B7C1"}>
          {`#${tasks?.length || 0}`}
        </Typography>
      </Box>
      {/* Content */}
      <Box
        sx={{
          flex: 1,
          px: 1,
          pt: 1,
          ...(borders && {
            borderLeft: { xs: 0, md: "0.5px solid #B0B7C1" },
            borderRight: { xs: 0, md: "0.5px solid #B0B7C1" },
          }),
          overflow: "scroll",
        }}
      >
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {tasks.map((task, index) => (
                <Draggable
                  key={task.id}
                  draggableId={task.id}
                  index={index}
                  isDragDisabled={!task?.bActive ||
                    (task?.status === "backlog" && (task?.assignedTo.indexOf(user.id) < 0 && !ability.can("superAdmin", "tasks"))) ||
                    (task?.status === "doing" && (task?.createdBy !== user.id && !ability.can("superAdmin", "tasks"))) ||
                    task?.status === "done"
                  }
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <TaskCard
                        key={task?.id}
                        task={task}
                        title={task?.title}
                        status={task?.status}
                        number={task?.taskNumber}
                        priority={task?.priority}
                        assignedTo={task?.assignedTo?.map((userId) => {
                          const userObject = users?.find(
                            (userInstance) => userInstance?.user?.id === userId,
                          );
                          return userObject?.user;
                        })}
                        creator={
                          users?.find(
                            (userInstance) => userInstance?.user?.id === task?.createdBy,
                          )?.user
                        }
                        location={locationsUtil.getLocationNameFromId(
                          locations,
                          task?.locationId,
                        )}
                        createDate={task?.createDate}
                        dueDate={task?.dueDate}
                        closedDate={task?.closedDate}
                        isPrivate={task?.bPrivate}
                        isDraft={task?.bDraft}
                        subtasks={task?.subtasks}
                        PaperSxProps={{ mb: 2 }}
                        data-tour={selectedTasks?.includes(task?.id) ? "view-tasks-1" : ""}
                        isDragging={snapshot.isDragging}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </Box>
    </Box>
  );
}

export default TaskBoardColumn;
