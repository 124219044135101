import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from "@mui/material";
import { DateRange } from "react-date-range";
import { useTranslation } from "react-i18next";

function DateFilter({ value, onChange }) {
  const { t: tGeneral } = useTranslation("general");

  return (
    <Accordion variant="outlined" sx={{ border: "none" }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {tGeneral("date")}
      </AccordionSummary>
      <AccordionDetails>
        <DateRange
          ranges={value}
          onChange={(item) => onChange([item.selection])}
          color="#E6B60C"
          rangeColors={["#E6B60C"]}
          displayMode="dateRange"
        />
        <Box>
          <Button
            fullWidth
            variant="contained"
            sx={{ borderRadius: "1rem", textTransform: "none" }}
            onClick={() =>
              onChange([
                {
                  startDate: null,
                  endDate: null,
                  key: "selection",
                },
              ])
            }
          >
            {tGeneral("clearFilter")}
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export { DateFilter };
