import { datadogRum } from "@datadog/browser-rum";
import { Check, Close, Folder } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { PopUpAlert } from "../../../../../components/PopUpAlert";
import {
  CompanySelector,
  FilePreviewHorizontalScroll,
  TooltipIconButton,
} from "../../../../../components/shared";
import { useAppTourContext } from "../../../../../components/supportButton/context/context";
import { useGetEnterprisesQuery } from "../../../../../features/enterprise/enterpriseApiSlice";
import {
  selectCurrentEnterprise,
  setAllEnterprises,
} from "../../../../../features/enterprise/enterpriseSlice";
import { useCreateDocumentMutation } from "../../../../../features/project/modules/documents/documentsApiSlice";
import { useCreateProjectFolderMutation } from "../../../../../features/project/modules/documents/projectFolderApiSlice";
import {
  selectSelectedFolder,
  setNeedReload,
} from "../../../../../features/project/modules/documents/projectFolderSlice";
import { selectCurrentProject } from "../../../../../features/project/projectSlice";
import { useCreatePresignedUrlMutation } from "../../../../../features/s3/s3ApiSlice";
import { selectCurrentUser } from "../../../../../features/userSettings/userSettingsSlice";
import { setUploadingFiles } from "../../../../../features/drawings/drawingsSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function NewModal({ isOpen, onClose, action, path }) {
  const { idProject, idEnterprise } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tDocuments } = useTranslation("documents");

  const {
    state: { tourActive },
    setState,
  } = useAppTourContext();

  // Theme
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState({
    name: "",
    privacy: false,
  });
  // Current project vars
  const currentProject = useSelector(selectCurrentProject);
  const currentUser = useSelector(selectCurrentUser);
  const currentEnterprise = useSelector(selectCurrentEnterprise);
  // State vars
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [allowedCompanies, setAllowedCompanies] = useState([]);

  // Get Companies
  const {
    data: enterprises,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetEnterprisesQuery();
  const [cleanEnterprices, setCleanEnterprices] = useState([]);
  const [multipleFolders, setMultipleFolders] = useState(false);

  const handleChange = (event, name) => {
    if (name === "privacy") {
      setModalState({
        ...modalState,
        [event.target.name]: event.target.checked,
      });
    } else {
      setModalState({
        ...modalState,
        [name]: event.target.value,
      });
    }
  };

  useEffect(() => {
    if (isSuccess && enterprises.length > 0) {
      // Remove repeated enterprices
      setCleanEnterprices(
        enterprises.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id),
        ),
      );
      dispatch(setAllEnterprises(cleanEnterprices));
    }

    return () => {};
  }, [isSuccess, enterprises]);

  // Create folder paths
  const currentFolder = useSelector(selectSelectedFolder);
  // Create Folder
  const [
    createProjectFolder,
    {
      isSuccess: isCreateProjectFolderSuccess,
      isError: isCreateProjectFolderError,
      isLoading: isCreateProjectFolderLoading,
    },
  ] = useCreateProjectFolderMutation();
  useEffect(() => {
    if (isCreateProjectFolderSuccess && !multipleFolders) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDocuments("folderCreatedSuccessfully"),
      );
      setModalState({
        name: "",
        privacy: false,
      });
      onClose();
    }
  }, [isCreateProjectFolderSuccess]);
  useEffect(() => {
    if (isCreateProjectFolderError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  }, [isCreateProjectFolderError]);
  const handleCreateFolder = async () => {
    // TODO
    //  set allowed users
    //  set allowed companies
    //  set seenBy
    const folderPath = currentFolder ? `${currentFolder.name}/` : "/";
    const parentFolderId = path?.length ? path[path.length - 1].id : null;
    const body = {
      idProject: currentProject.id,
      name: modalState.name,
      size: 2,
      path: folderPath,
      seenBy: [currentUser.id],
      bActive: true,
      bPrivate: modalState.privacy,
      allowedUsers,
      allowedCompanies,
      favorite: false,
    };
    if (parentFolderId) {
      body.idParentFolder = parentFolderId;
    }
    await createProjectFolder(body).unwrap();
    dispatch(setNeedReload(true));
  };
  // Create File
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleCreateFile = async () => {
    if (selectedFiles.length > 0) {
      dispatch(
        setUploadingFiles(
          selectedFiles.map((file) => ({
            file,
            allowedCompanies,
            allowedUsers,
            idProjectFolder: path?.length ? path[path.length - 1].id : null,
            bPrivate: modalState.privacy,
            type: "document",
          })),
        ),
      );
    }
    onClose();
  };

  const handleDeleteFile = (_, i) => {
    setSelectedFiles([...selectedFiles].filter((_, index) => index !== i));
  };

  useLayoutEffect(() => {
    if (tourActive) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          width: "40%",
          height: modalState.privacy ? "90%" : "auto",
          borderRadius: "20px",
          p: "25px 40px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex" alignItems="center" mb="15px">
          <Typography variant="h6" fontWeight="bold" flex={1}>
            {action === "folder"
              ? tDocuments("addFolder")
              : tDocuments("addFile")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
          <TooltipIconButton
            icon={<Check />}
            disabled={
              (action === "folder" && modalState.name.length === 0) ||
              (action === "file" && selectedFiles.length <= 0) ||
              isCreateProjectFolderLoading
            }
            onClick={() => {
              if (action === "folder") {
                handleCreateFolder();
              }
              if (action === "file") {
                handleCreateFile();
              }
            }}
            label={tGeneral("add")}
            data-tour="docs-add-5"
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Folder /> /
          {path.length > 0 &&
            path?.map((dir, i) => (
              <Box key={dir.id} display="flex" alignItems="center">
                <Typography>{dir.name}</Typography>
                {i < path.length - 1 && "/"}
              </Box>
            ))}
        </Box>
        <Divider sx={{ my: "20px" }} />
        <Box flex={1} flexDirection="column" display="flex" overflow="scroll">
          {/* Name */}
          {action === "folder" && (
            <FormControl fullWidth>
              <TextField
                id={action === "folder" ? "folderName" : "fileName"}
                label={
                  action === "folder"
                    ? tDocuments("folderName")
                    : tDocuments("fileName")
                }
                variant="outlined"
                required
                value={modalState.name}
                onChange={(e) => handleChange(e, "name")}
                data-tour="docs-add-folder-3"
              />
            </FormControl>
          )}
          {/* File upload */}
          {action === "file" && (
            <Box>
              <FilePreviewHorizontalScroll
                files={selectedFiles}
                onAddPhotos={(val) => setSelectedFiles(val)}
                onDelete={handleDeleteFile}
                allowFolders
              />
            </Box>
          )}
          {/* Privacy */}
          <FormGroup>
            <Typography component="div">
              <Grid
                component="label"
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>{tGeneral("privacy")}</Grid>
                <Grid item>
                  <Switch
                    checked={modalState.privacy}
                    onChange={(e) => handleChange(e, "privacy")}
                    name="privacy"
                    data-tour="docs-add-4"
                  />
                </Grid>
              </Grid>
            </Typography>
          </FormGroup>
          {/* if private hide company and users selection */}
          {modalState.privacy && (
            <Box display="flex" flexDirection="column" flex={1}>
              <CompanySelector
                idEnterprise={idEnterprise}
                idProject={idProject}
                multiple
                selectedCompanies={allowedCompanies}
                selectedUsers={allowedUsers}
                onChangeCompanies={(ids) => setAllowedCompanies(ids)}
                onChangeUsers={(ids) => setAllowedUsers(ids)}
              />
            </Box>
          )}
        </Box>
      </Card>
    </Modal>
  );
}

export default NewModal;
