// Third-party library imports
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Icon imports
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import RefreshIcon from "@mui/icons-material/Refresh";

// Project-specific absolute imports
import { useGetProjectTasksQuery } from "../../../../features/project/analyticsApiSlice";
import { selectCurrentProject } from "../../../../features/project/projectSlice";

function Tasks({ size, isMock }) {
  const { t: tAnalytics } = useTranslation("analytics");
  const currentProject = useSelector(selectCurrentProject);

  const {
    data: tasksData,
    isError,
    isLoading,
    refetch,
  } = useGetProjectTasksQuery(currentProject?.id, {
    skip: isMock,
  });

  const handleRefetch = () => {
    refetch(); // Call refetch to reload data
  };

  // Function to transform tasksData into the desired format
  const chartData = [
    {
      status: tAnalytics("pending"),
      count: isMock ? 4 : tasksData?.pendingCount,
      color: "#F9DF3A",
    },
    {
      status: tAnalytics("inProgress"),
      count: isMock ? 2 : tasksData?.progressCount,
      color: "#93C8FF",
    },
    {
      status: tAnalytics("done"),
      count: isMock ? 8 : tasksData?.completedCount,
      color: "#CACACA",
    },
  ];

  let content;
  switch (size) {
    case "sm":
      content = (
        <Box
          sx={{
            position: "relative",
            height: { xs: "65%", lg: "80%" },
            width: "100%",
          }}
        >
          <ResponsiveBar
            data={chartData}
            keys={["count"]}
            indexBy="status"
            margin={{ top: 20, right: 0, bottom: 0, left: 0 }}
            padding={0.1} // Adjust padding for thicker bars
            groupMode="grouped"
            colors={({ data }) => data.color}
            borderRadius={8}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridY={false}
            enableLabel={false}
            legends={[]} // Remove legends from the chart
            maxValue={chartData.every((item) => item.count === 0) ? 1 : "auto"} // Only set maxValue if all counts are 0
            layers={[
              "bars",
              ({ bars, yScale }) =>
                bars.map(({ key, width, height, x, color }) => (
                  <g key={key}>
                    <rect
                      x={x}
                      y={yScale(0) - 2} // Adjust y position
                      width={width}
                      height={height > 0 ? 0 : 2} // Minimum height for visibility
                      fill={color}
                    />
                  </g>
                )),
            ]}
            tooltip={({ value }) => (
              <Box
                sx={{
                  padding: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "16px",
                  background: "rgba(255, 255, 255, 0.80)",
                  boxShadow: "0px 4px 12px 0px rgba(46, 43, 43, 0.10)",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#4B4B4B",
                  }}
                >
                  {value} {tAnalytics("tasks")}
                </Typography>
              </Box>
            )}
          />
          <Box
            sx={{
              height: "30%",
              width: "100%",
              display: "flex",
              flexWrap: { xs: "nowrap", lg: "wrap" },
              justifyContent: "flex-start",
              gap: "8px",
              padding: "8px",
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            {/* Custom Legends */}
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <CircleIcon sx={{ color: "#F9DF3A", fontSize: "10px" }} />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#8E8E8E",
                }}
              >
                {tAnalytics("pending")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <CircleIcon sx={{ color: "#93C8FF", fontSize: "10px" }} />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#8E8E8E",
                }}
              >
                {tAnalytics("inProgress")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <CircleIcon sx={{ color: "#CACACA", fontSize: "10px" }} />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#8E8E8E",
                }}
              >
                {tAnalytics("done")}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
      break;
    case "md":
    case "lg":
    default:
      content = <></>;
  }

  if (isLoading && !isError && !isMock)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (isError && !isLoading && !isMock)
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          sx={{
            color: "#8E8E8E",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
          }}
        >
          {tAnalytics("somethingWentWrong")}
        </Typography>
        <IconButton onClick={handleRefetch}>
          <RefreshIcon />
        </IconButton>
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", gap: "8px" }}>
        <TaskOutlinedIcon sx={{ color: "#FB3C75" }} />
        <Typography
          sx={{ fontSize: "16px", fontStyle: "normal", fontWeight: "500" }}
        >
          {tAnalytics("tasks")}
        </Typography>
      </Box>
      {content}
    </Box>
  );
}

export { Tasks };
