import * as React from "react";
import { PopUpAlert } from "../components/PopUpAlert";
import {
  useDeleteAlbumsMutation,
  useRestoreAlbumsMutation,
} from "../features/project/imageApiSlice";
import { useTranslation } from "react-i18next";

function useAlbumGallery(restore) {
  const { t: tPhotos } = useTranslation("photos");
  const { t: tGeneral } = useTranslation("general");

  const [selectedAlbums, setSelectedAlbums] = React.useState([]);
  const [isAlbumsOpen, setIsAlbumsOpen] = React.useState(false);
  const [
    deleteAlbums,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
    },
  ] = useDeleteAlbumsMutation();
  const [
    restoreAlbums,
    {
      isLoading: isRestoreLoading,
      isSuccess: isRestoreSuccess,
      isError: isRestoreError,
    },
  ] = useRestoreAlbumsMutation();

  const handleAction = async () => {
    if (restore) {
      await restoreAlbums({ ids: selectedAlbums });
    } else {
      await deleteAlbums({ ids: selectedAlbums });
    }
  };

  React.useEffect(() => {
    if (isDeleteSuccess || isRestoreSuccess) {
      PopUpAlert(
        "success",
        restore ? tGeneral("restored") : tGeneral("deleted"),
        `${tPhotos("album")} ${
          restore ? tGeneral("restored") : tGeneral("deleted")
        } ${tGeneral("successfully")}.`,
      );
      handleClose();
      handleClearAlbumGallery();
    }
    if (isDeleteError || isRestoreError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      handleClose();
      handleClearAlbumGallery();
    }
  }, [
    isDeleteSuccess,
    isDeleteError,
    isRestoreError,
    isRestoreSuccess,
    restore,
  ]);

  const handleSelectAlbum = (id) => {
    const index = selectedAlbums.indexOf(id);
    if (index > -1) {
      setSelectedAlbums((prev) => prev.filter((p) => p !== id));
    } else {
      setSelectedAlbums((prev) => [...prev, id]);
    }
  };
  const handleOpen = () => setIsAlbumsOpen(true);
  const handleClose = () => setIsAlbumsOpen(false);

  const handleClearAlbumGallery = () => {
    setSelectedAlbums([]);
  };

  return {
    selectedAlbums,
    isAlbumsOpen,
    isDeleteLoading,
    isRestoreLoading,
    handleOpen,
    handleClose,
    handleAction,
    handleSelectAlbum,
    handleClearAlbumGallery,
  };
}

export { useAlbumGallery };
