export const analytics = {
  projectProgress: "プロジェクト進捗",
  hi: "やあ",
  realProgress: "実際の進捗",
  welcomeBack: "お帰りなさい",
  moduleStatistics: "モジュールの統計",
  onTime: "時間通り",
  overdue: "遅延",
  constructionPercentage: "建設の割合",
  completed: "完了",
  thisWeek: "今週",
  workForce: "労働力",
  hours: "時間",
  completedTasks: "完了したタスク",
  analytics: "分析",
  addWidgets: "表示するウィジェットを追加",
  projectStatistics: "プロジェクトの統計",
  rfis: "RFIs",
  tasks: "タスク",
  submittals: "提出物",
  drawingsProgress: "図面の進捗",
  drawingsCount: "図面",
  inspections: "検査",
  inspectionCount: "検査",
  delayed: "遅延",
  closed: "閉じた",
  open: "開いている",
  pending: "保留中",
  inProgress: "進行中",
  done: "完了",
  revision: "修正",
  total: "合計",
  selectWidget: "お好みのウィジェットを選択してください",
  widgetDisplayed: "選択したウィジェットはダッシュボードに表示されます",
  somethingWentWrong: "問題が発生しました",
};
