export const analytics = {
  projectProgress: "Progreso del proyecto",
  hi: "Hola",
  realProgress: "Progreso real",
  welcomeBack: "Bienvenido",
  moduleStatistics: "Estadísticas de módulos",
  onTime: "A tiempo",
  overdue: "Atrasado",
  constructionPercentage: "Porcentaje de construcción",
  completed: "Completado",
  thisWeek: "Esta semana",
  workForce: "Fuerza laboral",
  hours: "Horas",
  completedTasks: "Tareas completadas",
  analytics: "Analíticas",
  addWidgets: "Agrega tus widgets para mostrar",
  projectStatistics: "Estadísticas de proyecto",
  rfis: "RFIs",
  tasks: "Tareas",
  submittals: "Submittals",
  drawingsProgress: "Avance general de Planos",
  drawingsCount_one: "Plano",
  drawingsCount_other: "Planos",
  inspections: "Inspecciones",
  inspectionCount_one: "Inspección",
  inspectionCount_other: "Inspecciones",
  delayed: "Atrasado",
  closed: "Cerrado",
  open: "Abierto",
  pending: "Pendiente",
  inProgress: "En progreso",
  done: "Hecho",
  revision: "Revisión",
  total: "Total",
  selectWidget: "Seleccione el widget de su elección",
  widgetDisplayed: "El widget seleccionado se mostrará en su tablero",
  somethingWentWrong: "Algo salió mal",
};
