import { useTheme } from "@emotion/react";
import {
  ArrowBackIos,
  ArrowForwardIos,
  BorderColor,
  Close,
  Delete,
  Download,
  ExpandMore,
  Image,
  InfoOutlined,
  LocationOn,
  MoreVert,
  SwapHoriz,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { selectLocale } from "../../../../features/preferences/preferencesSlice";
import {
  useDeleteImageMutation,
  useGetImageQuery,
  usePermanentDeleteImageMutation,
  useSetAlbumCoverMutation,
} from "../../../../features/project/imageApiSlice";
import { selectCurrentRole } from "../../../../features/project/projectSlice";
import Can from "../../../../utils/can";
import { PopUpAlert } from "../../../PopUpAlert";
import { ModalWithAction } from "../../../shared";
import { MovePhoto } from "../MovePhoto";
import { PhotoChat } from "./PhotoChat";

const colors = ["#000", "#f44336", "#0288d1", "#2e7d32"];

function InfoLabel({ label, value }) {
  return (
    <Box mt="4%">
      <Typography fontWeight="bold">{label}</Typography>
      <Typography>{value}</Typography>
    </Box>
  );
}

function SectionTitle({ title }) {
  return (
    <>
      <Divider sx={{ mt: "1%", p: 0 }} />
      <Box display="flex">
        <Typography
          color="black"
          bgcolor="#FBCB04"
          sx={{ borderBottomRightRadius: "15px", pr: "1%" }}
        >
          {title}
        </Typography>
      </Box>
    </>
  );
}

function PhotoViewer({
  isOpen,
  onClose,
  imageId,
  image,
  total,
  nextImage,
  previousImage,
  isRecycle = false,
}) {
  const { idPhoto } = useParams();
  const navigate = useNavigate();
  const { t: tPhotos } = useTranslation("photos");
  const { t: tGeneral } = useTranslation("general");
  const theme = useTheme();
  const [isEditing, setIsEditing] = React.useState(false);
  const [color, setColor] = React.useState("#000");
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isPermanentDeleteOpen, setIsPermanentDeleteOpen] =
    React.useState(false);
  const [movingValue, setMovingValue] = React.useState(null);
  const [isCover, setIsCover] = React.useState(false);
  const [menuElement, setMenuElement] = React.useState(null);

  const { data } = useGetImageQuery(image.id);

  const open = Boolean(menuElement);

  const locale = useSelector(selectLocale);
  const [datesLang, setDatesLang] = React.useState(
    locale === "en" ? "us-EN" : "es-MX",
  );

  const fileType = React.useMemo(() => {
    if (data) {
      return data.name.substring(data.name.lastIndexOf(".") + 1).toLowerCase();
    }
    return null;
  }, [data]);

  const isVideo = React.useMemo(() => {
    if (fileType) {
      return (
        ["flv", "avi", "mp4", "mov", "wmv", "avchd", "3gp", "mpeg"].indexOf(
          fileType,
        ) > -1
      );
    }

    return false;
  }, [fileType]);

  function formatDate(date) {
    return new Date(date).toLocaleDateString(datesLang, {
      weekday: "short",
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }

  const handleOpenMenu = (event) => {
    setMenuElement(event.currentTarget);
  };

  const handleClose = () => {
    setMenuElement(null);
  };

  const userRole = useSelector(selectCurrentRole);
  const [deleteImages, { isLoading: isDeleteLoading }] =
    useDeleteImageMutation();
  // For recycle bin
  const [
    permaDeleteImage,
    {
      isLoading: isPermanentDeleteLoading,
      isSuccess: isPermanentDeleteImageSuccess,
      isError: isPermanentDeleteImageError,
    },
  ] = usePermanentDeleteImageMutation();

  const [
    setAlbumCover,
    {
      isLoading: isAlbumCoverLoading,
      isError: isAlbumCoverError,
      isSuccess: isAlbumCoverSuccess,
    },
  ] = useSetAlbumCoverMutation();

  const handleClosePhoto = () => {
    if (idPhoto) {
      navigate("./..");
    }
    onClose();
  };

  const download = async () => {
    // Try 1
    // fetch(data.url)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const link = document.createElement("a");
    //     link.href = URL.createObjectURL(blob);
    //     link.download = data.name;
    //     link.style.display = "none";
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   });

    const link = document.createElement("a");
    const xhr = new XMLHttpRequest();
    xhr.open("GET", data.url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const objectUrl = URL.createObjectURL(blob);

        link.href = objectUrl;
        link.setAttribute("download", `${data.name}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        URL.revokeObjectURL(objectUrl);
      }
    };
    xhr.send();
  };

  const handleDeleteSelectedImages = async () => {
    await deleteImages({ ids: [image.id] }).unwrap();
    PopUpAlert("success", tPhotos("imagesDeletedSuccessfully"));
    handleClosePhoto();
  };

  const handlePermanentDelete = async () => {
    await permaDeleteImage({ ids: [image.id] }).unwrap();
  };

  const handleSetAlbumCover = async () => {
    await setAlbumCover({ id: image.idAlbum, frontpageImage: image.id });
    setIsCover(false);
  };

  React.useEffect(() => {
    if (isAlbumCoverSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tPhotos("imageCoverSetSuccessfully"),
      );
    }
    if (isAlbumCoverError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  }, [isAlbumCoverError, isAlbumCoverSuccess]);

  React.useEffect(() => {
    if (isPermanentDeleteImageSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        `${tPhotos("photos")} ${tGeneral("deletedPermanently")}`,
      );
      setIsPermanentDeleteOpen(false);
      handleClosePhoto();
    }
    if (isPermanentDeleteImageError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      setIsPermanentDeleteOpen(false);
      handleClosePhoto();
    }
  }, [isPermanentDeleteImageSuccess, isPermanentDeleteImageError]);

  const isMoving = Boolean(movingValue);
  return (
    <Modal open={isOpen} onClose={handleClosePhoto}>
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box display="flex" sx={{ backgroundColor: "#000" }}>
          {isEditing ? (
            <Box
              style={{ backgroundColor: "white" }}
              display="flex"
              alignItems="center"
              gap="20px"
              px="20px"
            >
              {colors.map((color) => (
                <IconButton onClick={() => setColor(color)}>
                  <BorderColor style={{ color }} />
                </IconButton>
              ))}
            </Box>
          ) : null}
        </Box>
        <Box display="flex" sx={{ height: "100%" }}>
          <Box flex={1} display="flex" alignItems="center">
            <Box sx={{ mx: "30px", minWidth: "42px" }}>
              {imageId > 0 && (
                <IconButton
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "50px",
                    color: "#fff",
                  }}
                  onClick={() => {
                    previousImage(imageId);
                    setIsEditing(false);
                  }}
                >
                  <ArrowBackIos />
                </IconButton>
              )}
            </Box>
            <Box display="flex" flex={1} justifyContent="center">
              {isVideo ? (
                <video
                  src={data?.url}
                  style={{ maxHeight: "80vh", maxWidth: "100%" }}
                  controls
                />
              ) : (
                <Box data-tour="view-photos-2">
                  <img
                    src={data?.url}
                    alt={data?.name}
                    style={{ maxHeight: "80vh", maxWidth: "100%" }}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ mx: "30px", minWidth: "42px" }}>
              {imageId !== total - 1 && (
                <IconButton
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "50px",
                    color: "#fff",
                  }}
                  onClick={() => {
                    nextImage(imageId);
                    setIsEditing(false);
                  }}
                >
                  <ArrowForwardIos />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#fff",
              width: "40%",
              maxWidth: "40%",
              [theme.breakpoints.down("lg")]: {
                maxWidth: "35%",
                width: "35%",
              },
              px: "20px",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <Box display="flex" my="20px">
              <IconButton sx={{ color: "#000" }} onClick={handleClosePhoto}>
                <Close />
              </IconButton>
              <Box flex={1} />
              {/* {allowedUser && (
                <IconButton
                  onClick={() => setIsEditing(!isEditing)}
                  sx={{ color: "#000" }}
                >
                  <Edit />
                </IconButton>
              )} */}
              <IconButton
                sx={{ color: "#000" }}
                onClick={download}
                data-tour="view-photos-5"
              >
                <Download />
              </IconButton>
              <Can I="delete" a="photos">
                <IconButton
                  sx={{ color: "#000" }}
                  onClick={() => {
                    if (isRecycle) {
                      setIsPermanentDeleteOpen(true);
                    } else {
                      setIsDeleting(true);
                    }
                  }}
                  data-tour="view-photos-6"
                >
                  <Delete color="error" />
                </IconButton>
              </Can>
              <Can I="photoOptions" a="photos">
                <IconButton
                  onClick={handleOpenMenu}
                  sx={{ color: "#000" }}
                  data-tour="view-photos-7"
                >
                  <MoreVert />
                </IconButton>
              </Can>
              {open && (
                <Menu
                  id="image-menu"
                  anchorEl={menuElement}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setMovingValue({ type: "album", value: data.idAlbum });
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <SwapHoriz />
                    </ListItemIcon>
                    <ListItemText>
                      {tPhotos("movePhotoAlbumTitle")}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setMovingValue({
                        type: "location",
                        value: data.location,
                      });
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <LocationOn />
                    </ListItemIcon>
                    <ListItemText>{tPhotos("setLocation")}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => setIsCover(true)}>
                    <ListItemIcon>
                      <Image />
                    </ListItemIcon>
                    <ListItemText>{tPhotos("setAlbumCover")}</ListItemText>
                  </MenuItem>
                </Menu>
              )}
            </Box>
            <Box data-tour="view-photos-3">
              <SectionTitle title={tGeneral("information")} />
              <Box mb="5%">
                {/* <InfoLabel
                  label={tGeneral("takenOn")}
                  value={image.dateTaken ? formatDate(image.dateTaken) : "---"}
                /> */}
                <InfoLabel
                  label={tGeneral("uploadedOn")}
                  value={moment(image.dateUploaded).format("ddd, LL")}
                />
                <InfoLabel
                  label={tGeneral("uploadedBy")}
                  value={image.createdBy}
                />
                <InfoLabel
                  label={tPhotos("album")}
                  value={data?.albumName ?? "---"}
                />
                <Accordion
                  elevation={0}
                  disableGutters
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                    paddingTop: 2,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                      borderBottom: "1px solid #d7d7d7",
                    }}
                  >
                    <Box display="flex" alignItems="center" columnGap={1}>
                      <InfoOutlined />
                      <Typography align="left" fontWeight="bold">
                        {tGeneral("additionalInformation")}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <InfoLabel
                    label={tGeneral("location")}
                    value={
                      <span>
                        {data?.latitude && data?.longitude ? (
                          <>
                            {tGeneral("location")}:{" "}
                            {parseFloat(data.latitude).toFixed(6)},{" "}
                            {parseFloat(data.longitude).toFixed(6)}
                            <br />
                          </>
                        ) : (
                          <>
                            {tGeneral("location")}: N/A
                            <br />
                          </>
                        )}
                        {data?.dateTaken ? (
                          <>
                            {tGeneral("date")}:{" "}
                            {moment.utc(data.dateTaken).format("ddd, LL")}
                            <br />
                          </>
                        ) : (
                          <>
                            {tGeneral("date")}: N/A
                            <br />
                          </>
                        )}
                        {data?.altitude ? (
                          <>
                            {tGeneral("altitude")}:{" "}
                            {parseFloat(data.altitude).toFixed(2)} meters
                          </>
                        ) : (
                          <>{tGeneral("altitude")}: N/A</>
                        )}
                      </span>
                    }
                  />
                  {data?.geolocationName && (
                    <InfoLabel
                      label={tPhotos("captureSite")}
                      value={data?.geolocationName}
                    />
                  )}
                  {data?.latitude && data?.longitude && (
                    <iframe
                      title="Image map"
                      width="100%"
                      height="350"
                      src={`https://www.openstreetmap.org/export/embed.html?bbox=${
                        Number(data.longitude) - 0.005
                      }%2C${Number(data.latitude) - 0.005}%2C${
                        Number(data.longitude) + 0.005
                      }%2C${
                        Number(data.latitude) + 0.005
                      }&layer=mapnik&marker=${data.latitude}%2C${
                        data.longitude
                      }`}
                      style={{ border: "1px solid black", marginTop: "10px" }}
                    />
                  )}
                </Accordion>
              </Box>
            </Box>
            <Box data-tour="view-photos-4">
              <SectionTitle title={tGeneral("comments")} />
              <Box my="5%">
                <PhotoChat idImage={image?.id} moduleName="photos" />
              </Box>
            </Box>
          </Box>
        </Box>
        <ModalWithAction
          isOpen={isDeleting}
          onClose={() => setIsDeleting(false)}
          title={tPhotos("deleteImagesTitle")}
          content={tPhotos("deleteImagesContent")}
          actionLabel={tGeneral("delete")}
          action={handleDeleteSelectedImages}
          isLoading={isDeleteLoading}
        />
        <ModalWithAction
          isOpen={isPermanentDeleteOpen}
          onClose={() => setIsPermanentDeleteOpen(false)}
          title={tGeneral("delete")}
          content={tPhotos("deletePermanentlyQuestion")}
          actionLabel={tGeneral("delete")}
          isLoading={isPermanentDeleteLoading}
          action={handlePermanentDelete}
        />
        <ModalWithAction
          isOpen={isCover}
          onClose={() => setIsCover(false)}
          title={tPhotos("setAlbumCover")}
          content={tPhotos("setAlbumCoverConfirm")}
          actionLabel={tGeneral("confirm")}
          action={handleSetAlbumCover}
          isLoading={isAlbumCoverLoading}
        />
        {isMoving && (
          <MovePhoto
            info={movingValue}
            open={isMoving}
            onClose={() => {
              setMovingValue(null);
              handleClosePhoto();
            }}
            image={data}
          />
        )}
      </Box>
    </Modal>
  );
}

export { PhotoViewer };
