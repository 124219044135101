import { Add } from "@mui/icons-material";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useGetAllProjectAlbumsQuery } from "../../../../features/project/imageApiSlice";
import { selectCurrentProject } from "../../../../features/project/projectSlice";
import { EmptyState } from "../../../shared";
import { AlbumCard } from "./AlbumCard";

function Albums({
  selectedUsers,
  selectedLocations,
  selectedAlbums,
  handleSelectAlbum,
  add,
  skip = false,
  restore = false,
  userRole,
}) {
  const { idPhoto } = useParams();
  const { t: tPhotos } = useTranslation("photos");
  const { t: tGeneral } = useTranslation("general");
  const currentProject = useSelector(selectCurrentProject);
  const [searchAlbum, setSearchAlbum] = React.useState("");
  const { data: retreivedAlbums, isLoading } = useGetAllProjectAlbumsQuery(
    {
      projectId: currentProject.id,
      bActive: !restore,
      uploadedBy: selectedUsers?.length
        ? selectedUsers?.map((user) => user.id)?.join(",")
        : "",
      locations:
        selectedLocations?.map((location) => location.id).join(",") ?? "",
    },
    { skip },
  );

  const albums = React.useMemo(() => {
    if (retreivedAlbums) {
      const albumFilter = new RegExp(searchAlbum, "g");
      return retreivedAlbums.results
        .map((album) => ({
          ...album,
          isSelected: false,
        }))
        .filter((album) => album.name.match(albumFilter) || searchAlbum === "");
    }
    return [];
  }, [retreivedAlbums, searchAlbum]);

  const navigate = useNavigate();

  const goToAlbumView = (idAlbum) => {
    if (idPhoto) {
      navigate(`./../albums/${idAlbum}`);
    } else {
      navigate(`./albums/${idAlbum}`);
    }
  };

  const renderResults = () => {
    if (retreivedAlbums?.results?.length) {
      return (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, 300px)",
            justifyContent: "space-around",
            gridGap: "20px",
            maxHeight: "74vh",
            overflow: "auto",
            py: "0.5rem",
          }}
        >
          {albums.map((album) => (
            <AlbumCard
              key={album.id}
              album={album}
              goAlbum={goToAlbumView}
              isSelected={selectedAlbums?.indexOf(album.id) > -1}
              selectAlbum={handleSelectAlbum}
            />
          ))}
        </Box>
      );
    }
    return (
      <EmptyState
        title={tPhotos("noAlbumsCreated")}
        content={
          userRole?.photos == 3 || userRole?.photos == 2
            ? tPhotos("addAlbumsValidation")
            : ""
        }
        module="photos"
        button={
          !restore && (userRole?.photos == 3 || userRole?.photos == 2) ? (
            <Button variant="contained" onClick={add}>
              <Add /> {tPhotos("addAlbum")}
            </Button>
          ) : (
            <></>
          )
        }
      />
    );
  };

  return (
    <Box>
      {isLoading ? (
        <Box margin="auto">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box>
          <Box display="flex" height="100%" mb="22px" px="10px" pt="10px">
            <TextField
              label={tGeneral("search")}
              variant="outlined"
              sx={{ minWidth: "500px" }}
              size="small"
              value={searchAlbum}
              onChange={(e) => setSearchAlbum(e.target.value)}
            />
          </Box>
          {renderResults()}
        </Box>
      )}
    </Box>
  );
}

export { Albums };
