import { AddCircleOutline } from "@mui/icons-material";
import { Box, Button, Card, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import DarkLogo from "../../assets/logo-dark.png";
import LightLogo from "../../assets/logo.png";
import { useGetEnterprisesQuery } from "../../features/enterprise/enterpriseApiSlice";
import {
  selectCurrentEnterprise,
  setCurrentEnterprise,
} from "../../features/enterprise/enterpriseSlice";
import {
  selectTheme,
  setLocale,
} from "../../features/preferences/preferencesSlice";

function EnterpriseSelector() {
  const { idEnterprise } = useParams();
  const { pathname } = useLocation();
  const isUser = pathname.match(/user/);
  const { t: tOnboarding } = useTranslation("onboarding");
  const currentEnterprise = useSelector(selectCurrentEnterprise);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSelecting, setIsSelecting] = useState(false);
  const { data } = useGetEnterprisesQuery();
  const darkMode = useSelector(selectTheme);
  useEffect(() => {
    if (!data || data?.length === 0) {
      return;
    }
    if ((!idEnterprise || idEnterprise === "undefined") && !currentEnterprise) {
      dispatch(setCurrentEnterprise(data[0]));
      navigate(`/enterprise/${data[0]?.id}/dashboard`, {
        replace: true,
      });
      return;
    }
    if (idEnterprise && idEnterprise !== "undefined" && !currentEnterprise) {
      const newCurrentEnterprise =
        data.find((enterprise) => enterprise.id === idEnterprise) ?? data[0];
      navigate(`/enterprise/${newCurrentEnterprise?.id}/dashboard`, {
        replace: true,
      });
      dispatch(setCurrentEnterprise(newCurrentEnterprise));
      return;
    }
    if (
      currentEnterprise &&
      (!idEnterprise || idEnterprise === "undefined") &&
      !isUser
    ) {
      navigate(`/enterprise/${currentEnterprise?.id}/dashboard`, {
        replace: true,
      });
    }
  }, [idEnterprise, data, navigate, dispatch, currentEnterprise, isUser]);

  useEffect(() => {
    if (data) {
      const body = data.find((enterprise) => enterprise.id === idEnterprise);
      dispatch(setCurrentEnterprise(body));
    }
  }, [data, dispatch, idEnterprise]);

  const sortedProjects = useMemo(() => {
    if (data && currentEnterprise) {
      return [...data].sort((a, _b) =>
        a.id === currentEnterprise.id ? -1 : 1,
      );
    }
    return [];
  }, [data, currentEnterprise]);

  const logoSrc = (enterprise) => {
    if (enterprise?.logo) {
      return enterprise.logo;
    }

    if (darkMode) {
      return DarkLogo;
    }

    return LightLogo;
  };

  const handleSelect = (enterprise) => {
    if (enterprise.id !== currentEnterprise.id) {
      dispatch(setCurrentEnterprise(enterprise));
      navigate(`/enterprise/${enterprise.id}/dashboard`, { replace: true });
    }
    setIsSelecting(false);
  };

  return (
    <Box sx={{ transition: "all 1s ease" }}>
      <Box
        component="div"
        mt="10px"
        p="5px"
        sx={{ cursor: "pointer" }}
        onClick={() => setIsSelecting(true)}
        display="flex"
        justifyContent="center"
      >
        <img
          style={{ maxWidth: "100%", maxHeight: "50px" }}
          src={logoSrc(currentEnterprise)}
          alt="asdad"
        />
      </Box>
      {isSelecting && (
        <Card
          sx={{
            position: "absolute",
            top: "1%",
            left: "3%",
            zIndex: 1000,
            height: "98vh",
            animation: "extend-vertical 1s ease",
            padding: "5px",
            overflowY: "auto",
          }}
        >
          {sortedProjects.map((enterprise) => (
            <Button
              key={enterprise.id}
              variant="text"
              mt="10px"
              onClick={() => handleSelect(enterprise)}
              size="small"
              sx={{
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#FFEBBC",
                },
                px: "50px",
                py: "9px",
                height: "65px",
                maxWidth: "316px",
                borderRadius: "21px",
                backgroundColor:
                  enterprise.id === currentEnterprise.id
                    ? "#FFEBBC"
                    : "initial",
              }}
              fullWidth
            >
              {enterprise.logo ? (
                <img
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  src={logoSrc(enterprise)}
                  alt="asdad"
                />
              ) : (
                <Typography fontWeight="bold" color="secondary">
                  {enterprise.name}
                </Typography>
              )}
            </Button>
          ))}
          {/* <Button
            variant="outlined"
            fullWidth
            sx={{ height: "55px", mt: "10px" }}
            color="secondary"
          >
            <AddCircleOutline /> {tOnboarding("createEnterprise")}
          </Button> */}
        </Card>
      )}
    </Box>
  );
}

export { EnterpriseSelector };
