import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  Add,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import {
  useDeleteSubmittalMutation,
  useGetSubmittalsQuery,
} from "../../../features/project/modules/submittals/submittalApiSlice";
import {
  selectCurrentProject,
} from "../../../features/project/projectSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import "jspdf-autotable";
import { useTranslation } from "react-i18next";

import NoRfis from "../../../assets/rfi/NoRfis.svg";
import { datadogRum } from "@datadog/browser-rum";
import { ModalWithAction, TitleWidgets } from "../../../components/shared";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants";
import Can from "../../../utils/can";
import { SubmittalsTable } from "../../../components/projectDashboard/Submittals/SubmittalsTable";
import { PopUpAlert } from "../../../components/PopUpAlert";
import CaslContext from "../../../utils/caslContext";
import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import { useExportSubmittalsMutation } from "../../../features/exportPdf";

const Submittals = () => {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tSubmittals } = useTranslation("submittals");

  const darkMode = useSelector(selectTheme);
  const currentProject = useSelector(selectCurrentProject);

  const ability = React.useContext(CaslContext);

  const [params, setParams] = useState({});
  const [selectedSubmittals, setSelectedSubmittals] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [removeSubmittal, { isLoading: isDeleteLoading }] = useDeleteSubmittalMutation();
  const {
    data,
    isLoading: isSubmittalsLoading,
    isFetching: isSubmittalsFetching,
  } = useGetSubmittalsQuery({
    idProject,
    params: {
      ...params,
      ...(params?.createdOn !== null &&
        params?.createdOn?.length > 0 && {
          startDate: params?.createdOn[0],
          endDate: params?.createdOn[1],
        }),
      createdOn: null,
    },
  });
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [exportSubmittals] = useExportSubmittalsMutation();

  const submittals = useMemo(() => data ?? [], [data]);

  const [pdfUrl, setPdfUrl] = React.useState(null);
  const exportPdfDocument2 = async () => {
    const body = {
      rfis: submittals,
    };
    const url = await exportSubmittals(body);
    setPdfUrl(url.data);
  };

  const isMobile = useMediaQuery("(max-width:820px)");

  const deleteSubmittals = async (e) => {
    try {
      const body = {
        ids: Object.keys(selectedSubmittals),
      };
      const response = await removeSubmittal(body).unwrap();
      if (response) {
        setOpenDelete(false);
        setSelectedSubmittals({});
        PopUpAlert("success", tGeneral("done"), tGeneral("deletedSuccessfully"));
      }
    } catch (err) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      datadogRum.addError({
        message: "Submittals - Delete - Error",
        stack: err.stack ? err.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: currentProject?.idEnterprise,
          module: "Submittals",
          project: currentProject?.id,
        },
        timestamp: Date.now(),
      });
    }
  };
  
  return (
    <>
      <TitleWidgets
        title={tSubmittals("submittalTitleModule")}
        supportDisabled
        moduleName="submittals"
        backEnabled={false}
        distributionListName={DISTRIBUTION_LIST_NAMES.submittals}
        showDelete={Object.keys(selectedSubmittals).length > 0 && ability.can("delete", "submittals")}
        onDelete={() => setOpenDelete(true)}
        enableSupport
        addNameTour="add-submittal-1"
      />
      <Can I="view" a="submittals">
        <Box sx={{ width: "100%" }}>
          <SubmittalsTable
            submittals={submittals}
            selectedSubmittals={selectedSubmittals}
            setSelectedSubmittals={setSelectedSubmittals}
            setFilters={(val) => setParams(val)}
            isLoading={isSubmittalsLoading || isSubmittalsFetching}
          />
          <ModalWithAction
            isOpen={openDelete}
            onClose={() => setOpenDelete(false)}
            title={tSubmittals("deleteSubmittalsTitle")}
            content={tSubmittals("deleteSubmittalsContent")}
            icon="delete"
            actionLabel={tGeneral("delete")}
            action={deleteSubmittals}
            isLoading={isDeleteLoading}
          />
        </Box>
      </Can>

      {/* If there no access to see this module */}
      <Can I="none" a="submittals">
        {tGeneral("noPermission")}
      </Can>
      {/* For export pdf */}
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
    </>
  );
};

export default Submittals;
