import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Typography from "@mui/material/Typography";
import MuiAppbar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router";
import Lottie from "lottie-react";
import {
  Close,
  Autorenew,
  Add,
  Visibility,
  Edit,
  Restore,
  PictureAsPdf,
  Settings,
  Delete,
} from "@mui/icons-material";
import { Lock, LockOpen, CircleSharp, Search } from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups"; //meeting
import PlayCircleIcon from "@mui/icons-material/PlayCircle"; //video
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded"; //workflow
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded"; //guide me
import { ListItemButton, ListItemIcon, Card, Divider } from "@mui/material";
import { ContactSupport } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
// Calendly
import { InlineWidget } from "react-calendly";
// Youtube player
import YouTube from "react-youtube";
// Tutorial del modulo
import { useTour } from "@reactour/tour";
// React router
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fontWeight } from "@mui/system";
import { useTranslation } from "react-i18next";

import animationData from "../../components/projectDashboard/16627-firework.json";
//Icons
import Rfis from "./../../assets/workflow/Rfis.png";
import Roles_en from "./../../assets/workflow/Roles_en.png";
import Roles_es from "./../../assets/workflow/Roles_es.png";
import { setCurrentGuideMe } from "../../features/project/projectSlice";
import Can from "../../utils/can";
import { useGetActiveRfisQuery } from "../../features/project/modules/rfiApiSlice";
import { selectCurrentProject } from "../../features/project/projectSlice";
import { selectLocale } from "../../features/preferences/preferencesSlice";
import { useGuideMeModal } from "./hooks/useGuideMeModal";
import {
  useGetRolesFromEnterprisesQuery,
  useGetUserProjectsByRoleQuery,
} from "../../features/enterprise/enterpriseApiSlice";
import { selectCurrentEnterprise } from "../../features/enterprise/enterpriseSlice";
// Support Menu
import { SupportMenu } from "./components/SupportMenu";
import { SupportMenuModalMetting } from "./components/modals/SupportMenuModalMetting";
import { SupportMenuItemHeader } from "./components/SupportMenuItemHeader";
import { SupportContent } from "./components/SupportContent";
import { SupportListItem } from "./components/SupportListItem";
import { SupportMenuModalWorkflow } from "./components/modals/SupportMenuModalWorkflow";
import { SupportMenuModalGuideMe } from "./components/modals/SupportMenuModalGuideMe";
import { useRoleSteps } from "./components/steps/roles";

const SupportButtonRoles = () => {
  const dispatch = useDispatch();
  const { t: translation } = useTranslation("roles");
  const [recordsLength, setRecordsLength] = useState(0);
  const locale = useSelector(selectLocale);
  const [imgWorkflow] = useState(locale == "en" ? Roles_en : Roles_es);
  const currentEnterprise = useSelector(selectCurrentEnterprise);

  const { data: records, isSuccess } = useGetRolesFromEnterprisesQuery(
    currentEnterprise?.id,
  );
  const { data: users, isSuccess: getUsersIsSucess } =
    useGetUserProjectsByRoleQuery(currentEnterprise?.id);

  // Support button (make component)
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [anchorSupp, setAnchorSupp] = useState(null);
  //For open
  const handleClick = (event) => {
    setAnchorSupp(event.currentTarget);
    setMenuIsOpen(true);
  };
  //For close
  const handleClose = () => {
    setAnchorSupp(null);
    setMenuIsOpen(false);
  };
  // End support button

  // Meeting modal
  const [meetingModalIsOpened, setMeetingModalIsOpened] = useState(false);
  const openMeetingModal = () => setMeetingModalIsOpened(true);
  const closeMeetingModal = () => setMeetingModalIsOpened(false);
  // Workflow modal
  const [workflowModalIsOpened, setWorkflowModalIsOpened] = useState(false);
  const openWorkflowModal = () => setWorkflowModalIsOpened(true);
  const closeWorkflowModal = () => setWorkflowModalIsOpened(false);
  // guide me modal
  const [guideModalIsOpened, setGuideModalIsOpened] = useState(false);
  const openGuideModal = () => setGuideModalIsOpened(true);
  const closeGuideModal = () => setGuideModalIsOpened(false);

  const [{ addSteps, editSteps, deleteSteps, settingsSteps }] = useRoleSteps();

  const [{ handleGuideMe }] = useGuideMeModal({
    addSteps,
    editSteps,
    deleteSteps,
    settingsSteps,
  });

  useEffect(() => {
    if (records && isSuccess) {
      setRecordsLength(records.length);
    }
  }, [records, isSuccess]);

  return (
    <div>
      {/* Button */}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={translation("support")}
      >
        <IconButton
          id="support-button"
          aria-controls={menuIsOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuIsOpen ? "true" : undefined}
          sx={{ color: "#007FFF" }}
          onClick={handleClick}
        >
          <ContactSupport />
        </IconButton>
      </Tooltip>

      <SupportMenu anchor={anchorSupp} open={menuIsOpen} onClose={handleClose}>
        {/* Meeting */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openMeetingModal();
          }}
        >
          <SupportMenuItemHeader title={translation("meeting")}>
            <GroupsIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalMetting
          open={meetingModalIsOpened}
          onClose={closeMeetingModal}
        />
        {/* Workflow */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openWorkflowModal();
          }}
        >
          <SupportMenuItemHeader title={translation("workflow")}>
            <AppRegistrationRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalWorkflow
          open={workflowModalIsOpened}
          diagramImageUrl={imgWorkflow}
          onClose={closeWorkflowModal}
        />

        {/* Guide Me */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openGuideModal();
          }}
        >
          <SupportMenuItemHeader title={translation("guideMe")}>
            <TouchAppRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalGuideMe
          title={`${translation("guideMe")} ${translation("moduleElement")}`}
          open={guideModalIsOpened}
          onClose={closeGuideModal}
        >
          <SupportContent>
            {/* ADD */}
            <SupportListItem
              can="add"
              moduleName={"roles"}
              onClick={() => {
                handleGuideMe("add");
                dispatch(setCurrentGuideMe("roles-add"));
                closeGuideModal();
              }}
            >
              <Add sx={{ marginRight: 1 }} />
              {`${translation("addButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* EDIT */}
            <SupportListItem
              can="edit"
              moduleName={"roles"}
              onClick={() => {
                handleGuideMe("edit");
                dispatch(setCurrentGuideMe("roles-edit"));
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <Edit sx={{ marginRight: 1 }} />
              {`${translation("editButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* DELETE */}
            <SupportListItem
              can="delete"
              moduleName={"roles"}
              onClick={() => {
                handleGuideMe("delete");
                dispatch(setCurrentGuideMe("roles-delete"));
                closeGuideModal();
              }}
              disabled={recordsLength == 0}
            >
              <Delete sx={{ marginRight: 1 }} />
              {`${translation("deleteButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* SETTINGS */}
            <SupportListItem
              can="settings"
              moduleName={"roles"}
              onClick={() => {
                handleGuideMe("settings");
                dispatch(setCurrentGuideMe("roles-settings"));
                closeGuideModal();
              }}
            >
              <Settings sx={{ marginRight: 1 }} />
              {`${translation("changeRoleButtonTour")}`}
            </SupportListItem>
          </SupportContent>
        </SupportMenuModalGuideMe>
      </SupportMenu>
    </div>
  );
};

export { SupportButtonRoles };
