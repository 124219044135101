import { Close, Delete, Search, Send } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { PopUpAlert } from "../../../../../components/PopUpAlert";
import BubbleAvatarDeletable from "../../../../../components/userAvatars/BubbleAvatarDeletable";
import UserAvatar from "../../../../../components/userAvatars/UserAvatar";
import { useExportDailyReportMutation } from "../../../../../features/exportPdf";
import { useSendMailMutation } from "../../../../../features/project/modules/dailyReports/dailyReportsApiSlice";
import { useGetProjectUsersQuery } from "../../../../../features/project/projectApiSlice";
import { TooltipIconButton } from "../../../../../components/shared";

function DeletableCC({ mail, onDelete }) {
  return (
    <Chip
      sx={{
        width: "100%",
        height: 40,
        bgcolor: "#f7e4b2",
        fontSize: "0.875rem",
        justifyContent: "left",
        mt: 1,
        padding: 2,
        borderRadius: "23px",
      }}
      label={mail}
      deleteIcon={<Delete style={{ color: "red", marginLeft: "auto" }} />}
      onDelete={() => {
        onDelete(mail);
      }}
    />
  );
}

function SendEmailModal({ isOpen, onClose, reports }) {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const [message, setMessage] = useState("");
  const [distributionList, setDistributionList] = useState([]);
  const [newCC, setNewCC] = useState("");
  const [ccList, setCcList] = useState([]);

  const { data: projectUsers } = useGetProjectUsersQuery(idProject);

  const [exportDailyReport, { isLoading: isExportLoading }] =
    useExportDailyReportMutation();

  const [
    sendMail,
    {
      isLoading: isSendMailLoading,
      isSuccess: isSendEmailSuccess,
      isError: isSendEmailError,
      error: sendEmailError,
    },
  ] = useSendMailMutation();

  const emailSubject = useMemo(() => {
    if (reports) {
      if (reports?.length > 1) {
        return `${tGeneral("dailyReports")} (${reports?.length})`;
      }
      return reports[0]?.title;
    }
    return "";
  }, [reports, tGeneral]);

  const handleSendEmail = async () => {
    const promises = reports.map(async (report) => {
      const pdfLink = await exportDailyReport({ dailyReportId: report.id });
      return pdfLink.data;
    });

    const links = await Promise.all(promises);

    const body = {
      // message: `${message}\r\r\r${links.join("\r")}`,
      message: `${message}`,
      subject: emailSubject,
      cc: ccList,
      pdfLink: links.join("\r"),
    };
    if (distributionList.length > 0) {
      body.recipients = distributionList.map((user) => user.email);
    }
    sendMail(body);
  };

  const handleDeleteUser = (user) => {
    const newDistributionList = [...distributionList].filter(
      (selectedUser) => selectedUser.id !== user.id,
    );
    setDistributionList(newDistributionList);
  };

  const mapUserItems = (user) => ({
    ...user,
    label: `${user.user?.firstName} ${user.user?.lastName}`,
    id: user.user?.id,
    name: `${user.user?.firstName} ${user.user?.lastName}`,
    urlAvatar: user.user?.urlAvatar,
    email: user?.user?.email,
  });

  const optionUsers = useMemo(() => {
    if (projectUsers) {
      return projectUsers.map(mapUserItems);
    }
    return [];
  }, [projectUsers]);

  useEffect(() => {
    if (isSendEmailSuccess) {
      PopUpAlert("success", tGeneral("sent"), tGeneral("sentMessage"));
      setDistributionList([]);
      setMessage([]);
      onClose();
    }
    if (isSendEmailError) {
      PopUpAlert("error", tGeneral("error"), sendEmailError.message);
      setDistributionList([]);
      setMessage([]);
      onClose();
    }
  }, [isSendEmailError, isSendEmailSuccess, sendEmailError, onClose, tGeneral]);

  const handleAddCC = (e) => {
    if (e.key !== "Enter" && e.key !== ";") return;
    const value = e.target.value.replace(";", "");
    if (
      value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) &&
      ccList.indexOf(value) === -1
    ) {
      setCcList((prev) => [...prev, value]);
    }
    setNewCC("");
  };

  const isSendDisabled =
    (distributionList.length === 0 && ccList.length === 0) || message === "";

  const handleDeleteCC = (mail) => {
    setCcList((prev) => [...prev].filter((cc) => cc !== mail));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Card
        sx={{
          p: "26px 40px",
          borderRadius: "20px",
          width: "30%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex">
          <Box flex={1}>
            <Typography fontWeight="bold">{tGeneral("sendToEmail")}</Typography>
            <Typography>{emailSubject}</Typography>
          </Box>
          <TooltipIconButton
            icon={<Close />}
            label={tGeneral("close")}
            onClick={onClose}
            color="gray"
          />
          <TooltipIconButton
            icon={<Send />}
            disabled={isExportLoading || isSendMailLoading || isSendDisabled}
            label={tGeneral("send")}
            onClick={handleSendEmail}
          />
        </Box>
        <Divider sx={{ my: "16px" }} />
        <Box flex={1}>
          <Autocomplete
            multiple
            options={optionUsers}
            disableCloseOnSelect
            value={distributionList}
            isOptionEqualToValue={(opt, val) => val.id === opt.id}
            onChange={(e, val) => {
              setDistributionList(val);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                <UserAvatar name={option.label} url={option.urlAvatar} />
                <Typography>{option.label}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <Box ref={params.InputProps.ref}>
                <TextField
                  {...params.inputProps}
                  fullWidth
                  label={tGeneral("distributionList")}
                  sx={{ padding: 0, height: "55px" }}
                  InputProps={{
                    endAdornment: <Search />,
                    sx: { height: "55px", padding: "14px !important" },
                  }}
                />
              </Box>
            )}
          />
          <Box
            sx={{
              overflow: "auto",
              minHeight: "150px",
              maxHeight: "150px",
              display: "flex",
              flexDirection: "column",
              justifyContent: distributionList.length ? "initial" : "center",
            }}
          >
            {distributionList.length ? (
              distributionList?.map((user) => (
                <BubbleAvatarDeletable
                  key={user.id}
                  user={user}
                  editable // To show delete button
                  setValues={() => {
                    handleDeleteUser(user);
                  }}
                />
              ))
            ) : (
              <Typography
                fontSize="1rem"
                fontWeight="medium"
                textAlign="center"
              >
                {tGeneral("noUsersSelected")}
              </Typography>
            )}
          </Box>
          {/* <Autocomplete
            multiple
            onChange={(e, val) => {
              handleChangeCc(e, val);
            }}
            id="tags-filled"
            options={[]}
            freeSolo
            value={cc}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="CC" />
            )}
          /> */}
          <TextField
            sx={{ mt: "16px" }}
            fullWidth
            placeholder="CC"
            onChange={(e) => setNewCC(e.target.value)}
            value={newCC}
            onKeyUpCapture={handleAddCC}
          />
          <Box
            sx={{
              overflow: "auto",
              minHeight: "150px",
              maxHeight: "150px",
              display: "flex",
              flexDirection: "column",
              justifyContent: ccList.length > 0 ? "initial" : "center",
            }}
          >
            {ccList.length > 0 ? (
              ccList.map((cc) => (
                <DeletableCC mail={cc} onDelete={handleDeleteCC} />
              ))
            ) : (
              <Typography
                fontSize="1rem"
                fontWeight="medium"
                textAlign="center"
              >
                {tGeneral("noCCSelected")}
              </Typography>
            )}
          </Box>
          <TextField
            sx={{ mt: "16px" }}
            fullWidth
            placeholder={tGeneral("message")}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={5}
            multiline
            maxRows={5}
          />
        </Box>
      </Card>
    </Modal>
  );
}

export { SendEmailModal };
