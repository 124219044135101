import React, { useMemo } from "react";
import { Outlet } from "react-router";
// MUI Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import Appbar from "../Appbar";
// Custom Components
import NoEnterprises from "../../views/enterpriseDashboard/NoEnterprises";
// Redux
// Redux: selectors
import { selectCurrentEnterprise } from "../../features/enterprise/enterpriseSlice";
// Redux: reducers
// Redux: queries
import { useGetEnterprisesQuery } from "../../features/enterprise/enterpriseApiSlice";
import { NoInternetConnection, ServerError } from "../shared";
import { useOnlineStatus } from "../../hooks";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingY: 1,
    marginY: 2,
  },
};

const drawerWidthLeft = 240;

function DashboardContent({ isLoading, hasEnterprises, isError }) {
  const isOnline = useOnlineStatus();

  if (!isOnline) {
    return <NoInternetConnection />;
  }

  if (isLoading) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <ServerError />;
  }

  if (!hasEnterprises) {
    return (
      <Box
        display="flex"
        alignItems="center"
        height="100vh"
        justifyContent="center"
      >
        <NoEnterprises />
      </Box>
    );
  }

  return <Outlet />;
}

function EnterpriseDashboardLayout() {
  // Enterprises query from RTK Query
  const { data: enterprises, isLoading, isError } = useGetEnterprisesQuery();

  const currentEnterprise = useSelector(selectCurrentEnterprise);

  return (
    <>
      <Appbar
        title={currentEnterprise?.name}
        type="enterprise"
        drawerWidthLeft={drawerWidthLeft}
      />
      <Box
        sx={{
          flex: 1,
          width: { sm: `calc(100% - ${drawerWidthLeft}px)` },
          ml: { sm: `${drawerWidthLeft}px` },
          height: "100vh",
        }}
      >
        {/* <div>
          <h3>Manage your things here</h3>
          <Can I="view" a="enterpriseInfo">
            <p>I can view enterprise info (enterprise admin)</p>
          </Can>
        </div> */}
        <DashboardContent
          isError={isError}
          isLoading={isLoading}
          hasEnterprises={enterprises?.length > 0}
        />
      </Box>
    </>
  );
}

export default EnterpriseDashboardLayout;
