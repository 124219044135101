// @ts-check
/* eslint react/prop-types: 0 */

import { Box, Button, Dialog, DialogContent, TextField, Typography } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import TransitionComponent from "../TransitionComponent";

export default function Import(props) {
  const { client, open, setDataSource, setOpen } = props;

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const upload = async (file) => {
    setLoading(true);
    try {
      const data = new FormData();
      data.append("file", file);
      // eslint-disable-next-line react/prop-types
      const response = await client.request({
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: "/import",
      });
      setDataSource(response.data);
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "success",
        title: tProjectSchedule("Success"),
      });
    } catch (error) {
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "error",
        title: tProjectSchedule("Error"),
      });
      // eslint-disable-next-line no-console
      console.error(error);
    }
    setLoading(false);
    setOpen(false);
  };

  const downloadSampleFile = async () => {
      try {
          const response = await client.request({
              headers: {
                  "Content-Type": "application/json",
              },
              method: "GET",
              responseType: "blob",
              url: "/download-sample-file",
          });

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "project-schedule.xlsx");

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
      } catch (error) {
          console.error("Error downloading the file", error);
      }
  };

  const populateSampleData = async () => {
    setLoading(true);
    try {
      const response = await client.request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "GET",
        url: "/populate-sample-data",
      });

      setDataSource(response.data);
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "success",
        title: tProjectSchedule("Success"),
      });

      setOpen(false);
    } catch (error) {
      console.error("Error sending request to populate sample data", error);

      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "error",
        title: tProjectSchedule("Error"),
      });
    }
    setLoading(false);
  };

  return (
    <Dialog
      fullWidth
      onClose={() => setOpen(false)}
      open={open}
      PaperProps={{
        className: "project-schedule",
      }}
      scroll="body"
      // @ts-ignore
      TransitionComponent={TransitionComponent}
    >
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Typography
          fontSize="md"
          fontWeight="bold"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {tProjectSchedule("Import from Excel")}
        </Typography>
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            await upload(file);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              onChange={(event) => {
                // @ts-ignore
                const file = event.target.files[0];
                if (file) {
                  setFile(file);
                }
              }}
              size="small"
              type="file"
            />
            <Button
              color="primary"
              disabled={!file || loading}
              sx={{
                padding: "10px 50px",
              }}
              variant="contained"
              type="submit"
            >
              {loading ? tProjectSchedule("Submitting...") : tProjectSchedule("Submit")}
            </Button>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <span>
                <a href="#" onClick={() => downloadSampleFile()}>
                  Click here
                </a>{" "}
                to download the sample file. Your uploaded file must be based on this template and adhere to the
                outlined requirements.
              </span>
            </Box>
            <Button
              color="primary"
              sx={{
                padding: "10px 50px",
              }}
              variant="contained"
              onClick={() => {
                if (!loading) {
                  populateSampleData();
                }
              }}
            >
              POPULATE SAMPLE DATA
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
