export const general = {
  addFilter: "添加筛选",
  settings: "设置",
  restore: "恢复",
  location: "位置",
  locations: "位置",
  uploadedBy: "上传者",
  attendance: "出勤",
  assignees: "被分配者",
  assigned: "已分配",
  category: "类别",
  priority: "优先级",
  createdBy: "创建者",
  distributionList: "分发列表",
  noUsersSelected: "未选择用户",
  noAttendanceUsers: "无出勤用户",
  actions: "操作",
  day: "天",
  month: "月",
  year: "年",
  week: "周",
  rename: "重命名",
  delete: "删除",
  deleteAll: "全部删除",
  cancel: "取消",
  confirm: "确认",
  edit: "编辑",
  information: "信息",
  comments: "评论",
  takenOn: "拍摄于",
  uploadedOn: "上传于",
  errorMessage: "发生错误，请稍后重试。",
  close: "关闭",
  save: "保存",
  logOut: "退出",
  view: "查看",
  seeDetails: "查看详情",
  download: "下载",
  add: "添加",
  update: "更新",
  accept: "接受",
  loading: "加载中",
  loadingDots: "加载中...",
  email: "电子邮件",
  phone: "电话",
  name: "姓名",
  lastName: "姓氏",
  position: "职位",
  enterprise: "企业",
  role: "角色",
  module: "模块",
  superAdmin: "超级管理员",
  admin: "管理员",
  viewOnly: "只读",
  none: "无",
  invalid: "无效",
  search: "搜索",
  projectSearch: "项目范围搜索",
  open: "打开",
  open_action: "打开",
  still_open: "仍然打开",
  public: "公开",
  private: "私有",
  privacy: "隐私",
  date: "日期",
  closedDate: "关闭日期",
  status: "状态",
  title: "标题",
  draft: "草稿",
  favorite: "收藏",
  back: "返回",
  configUpdated: "配置更新成功",
  errorConfigUpdated: "无法更新配置",
  hour: "小时",
  selectStatus: "选择状态",
  publish: "发布",
  attendanceConfirmed: "出勤确认成功",
  errorAttendanceConfirmed: "无法确认出勤",
  copy: "复制",
  confirmAttendance: "确认出勤",
  pdf: "PDF",
  creator: "创建者",
  lastSeen: "上次查看",
  lastEdit: "上次编辑",
  systemLanguage: "系统语言",
  address: "地址",
  street: "街道",
  number: "号码",
  country: "国家",
  state: "州",
  city: "城市",
  zipCode: "邮政编码",
  next: "下一步",
  en: "英语",
  es: "西班牙语",
  file: "文件",
  files: "文件",
  dateReceived: "接收日期",
  features: "功能",
  drawings: "图纸",
  drawing: "图纸",
  submittals: "提交",
  rfis: "RFI",
  dailyReports: "日报",
  issues: "问题",
  photos: "照片",
  tasks: "任务",
  inspections: "检查",
  documents: "文档",
  meetings: "会议",
  delay: "延迟",
  directory: "目录",
  hours: "小时",
  deliveries: "交付",
  announcements: "公告",
  user: "用户",
  project: "项目",
  projects: "项目",
  subscribe: "订阅",
  notFound: "未找到项目",
  noProjects: "目前尚未创建项目",
  createButton: "如果您想添加一个项目，请点击创建项目按钮开始该过程",
  createProject: "创建项目",
  loadingEnterprises: "加载企业中...",
  generalDirectory: "总目录",
  superAdmins: "超级管理员",
  roles: "角色",
  manager: "经理",
  constructionDirector: "建筑总监",
  architect: "建筑师",
  engineer: "工程师",
  active: "活动",
  inactive: "非活动",
  generalInfo: "常规信息",
  changePlan: "更改计划",
  billing: "计费",
  paymentMethod: "付款方式",
  updateProfile: "更新企业资料",
  youSureEnterprise: "您确定要使用此数据更新企业资料吗？",
  enterpriseName: "企业名称",
  enterpriseType: "企业类型",
  enterpriseImage: "企业标志",
  success: "成功",
  done: "完成！",
  enterpriseUpdated: "企业信息更新成功。",
  error: "错误",
  errorBig: "错误！",
  errorOccurred: "发生错误，请稍后重试。",
  logout: "注销",
  profile: "个人资料",
  currentPlan: "当前计划",
  per: "每",
  activeProjects: "活动项目",
  activeUsers: "活动用户",
  annual: "年度",
  monthly: "月度",
  allTheFeatures: "所有功能",
  unlimitedProjects: "无限项目",
  unlimitedUsers: "无限用户",
  unlimitedStorage: "无限存储",
  translationSpanglish: "西班牙语-英语翻译",
  companyReports: "公司品牌报告",
  customizableReports: "可定制的报告模板",
  home: "主页",
  changePassword: "更改密码",
  tickets: "工单",
  preferences: "首选项",
  userManual: "用户手册",
  notifications: "通知",
  male: "男性",
  female: "女性",
  editProfile: "编辑个人资料",
  firstName: "名字",
  birthDate: "出生日期",
  gender: "性别",
  inProcess: "进行中",
  currentPassword: "当前密码",
  newPassword: "新密码",
  repeatPassword: "重复密码",
  passwordNotice:
    "密码必须包含至少1个大写字母、1个小写字母、1个数字、1个特殊字符（_、#、?、!、@、$、%、^、&、*或-）并且长度为8个字符。",
  passwordYouSure: "您确定要更改密码吗？",
  enterPassword: "输入密码",
  changedPassword: "密码已成功更改",
  welcomeTickets: "欢迎！请在下方选择所发现的问题的类别。",
  mobile: "移动设备",
  browser: "浏览器",
  device: "设备",
  details: "详情",
  send: "发送",
  mode: "模式",
  dark: "暗色",
  light: "亮色",
  exportCvs: "导出 CVS",
  exportPdf: "导出 PDF",
  newSuperAdmin: "新超级管理员",
  dashboard: "仪表盘",
  adminPanel: "管理员面板",
  visibleModules: "可见模块",
  orderModules: "排序模块",
  projectAdmin: "项目管理",
  specification: "规格",
  specifications: "规格",
  costCodes: "成本代码",
  connections: "连接",
  projectName: "项目名称",
  translation: "翻译",
  managers: "经理",
  constructionManager: "施工经理",
  projectEngineer: "项目工程师",
  editInfo: "编辑信息",
  on: "开启",
  off: "关闭",
  addSuperAdmin: "添加超级管理员",
  deleteSuperAdmin: "删除超级管理员",
  areYouAdmin: "您确定要删除此超级管理员吗？执行此操作后，无法撤消",
  writeDetail: "详细描述发现的错误。",
  dragFiles: "拖放文件到此处，或单击选择文件",
  dragFile: "拖放文件到此处，或单击选择文件",
  emails: "电子邮件",
  subject: "主题",
  to: "发送至",
  createdOn: "创建于",
  info: "信息",
  move: "移动",
  favorites: "收藏",
  generalInformation: "常规信息",
  permissions: "权限",
  managePermissions: "管理权限",
  changeHistory: "更改历史",
  published: "已发布",
  deleted: "已删除",
  noPermission: "您没有权限查看此页面",
  recycleBin: "回收站",
  not_defined: "未定义",
  closed: "已关闭",
  no_assinged_users: "无分配用户",
  due_date: "截止日期",
  closed_date: "关闭日期",
  approve: "审核并签名",
  emptyDataSourceMessage: "没有记录可显示",
  of: "的",
  deletedPermanently: "永久删除",
  deletedSuccessfully: "删除成功",
  updatedSuccessfully: "更新成功",
  createdSuccessfully: "创建成功",
  recoveredSuccessfully: "恢复成功",
  closedSuccessfully: "关闭成功",
  approvedSuccessfully: "审核成功",
  cantRestore: "无法恢复",
  cantClose: "无法关闭",
  cantCreate: "无法创建",
  cantApprove: "无法审核",
  cantDelete: "无法删除",
  cantEdit: "无法编辑",
  deleteRFI: "您确定要删除RFI吗？",
  question: "问题",
  startWriting: "开始写作...",
  additionalInformation: "附加信息",
  receivedFrom: "接收自",
  company: "公司",
  cost: "成本",
  price: "价格",
  currency: "货币",
  mxn: "MXN - 墨西哥比索",
  usd: "USD - 美元",
  emptyQuestion: "问题不能为空",
  createDate: "创建日期",
  newResponse: "新回复...",
  newComment: "新评论...",
  newTopic: "新主题...",
  topics: "主题",
  showTranslation: "显示翻译",
  closeTranslation: "关闭翻译",
  viewFiles: "查看文件",
  images: "图片",
  noImages: "无图片",
  noDocuments: "无文档",
  attachFiles: "附加文件或拖放",
  january: "一月",
  february: "二月",
  march: "三月",
  april: "四月",
  may: "五月",
  june: "六月",
  july: "七月",
  august: "八月",
  september: "九月",
  october: "十月",
  november: "十一月",
  december: "十二月",
  noEnterprises: "看起来您还没有企业！",
  pendingItems: "待处理事项",
  weather: "天气",
  todoList: "待办事项列表",
  ensureThat: "确保您的项目具有有效的地址以检索天气信息，或稍后重试。",
  addItemList: "将项目添加到待办事项列表",
  noAnno: "没有公告可查看",
  daysLeft: "剩余天数",
  congratulations: "恭喜",
  noPending: "您没有待处理事项。",
  noFilterableElements: "找不到与搜索条件匹配的元素",
  addList: "添加待办事项列表",
  activityDesc: "活动描述",
  expirationDate: "到期日期",
  onTime: "按时",
  overdue: "逾期",
  areYouTask: "您确定要将此任务标记为已完成吗？",
  trashCan: "垃圾桶",
  newAnnouncement: "新公告",
  startDate: "开始日期",
  time: "时间",
  limitDate: "限制日期",
  cancelled: "已取消",
  operations: "操作已取消。",
  task: "任务",
  dailyReport: "日报",
  deletedBy: "删除者",
  deletedAt: "删除于",
  publishedDate: "发布日期",
  moved: "已移动",
  selectAll: "全选",
  pleaseMakeSureAllDataIsCorrect: "请确保所有数据正确。",
  addLocation: "添加位置",
  new: "新建",
  sendSomeoneComment: "向@某人发送评论",
  endDate: "结束日期",
  recent: "最近",
  thisWeek: "本周",
  thisMonth: "本月",
  lastWeek: "上周",
  projectConfigurationUpdateSuccess: "项目配置更新成功",
  description: "描述",
  enterValidTitle: "请输入有效的标题",
  enterValidDescription: "请输入有效的描述",
  enterValidDate: "请输入有效的日期",
  enterValidStatus: "请输入有效的状态",
  enterValidPriority: "请输入有效的优先级",
  enterValidLocation: "请输入有效的位置",
  enterValidCategory: "请输入有效的类别",
  fieldRequired: "此字段为必填项",
  cantUndone: "此操作无法撤消。",
  users: "用户",
  minutes: "分钟",
  noOne: "无人",
  noComments: "无评论",
  noTasks: "无任务",
  updated: "已更新",
  restored: "已恢复",
  created: "已创建",
  successfully: "成功",
  labelRowsPerPage: "每页行数：",
  labelRowsSelect: "行",
  firstAriaLabel: "第一页",
  firstTooltip: "第一页",
  previousAriaLabel: "上一页",
  previousTooltip: "上一页",
  nextAriaLabel: "下一页",
  nextTooltip: "下一页",
  lastAriaLabel: "最后一页",
  lastTooltip: "最后一页",
  adminEnabledSuccessfully: "管理员用户已成功启用",
  cantEnableAdmin: "无法启用管理员用户",
  adminDisabledSuccessfully: "管理员用户已成功禁用",
  cantDisableAdmin: "无法禁用管理员用户",
  emptyFields: "空字段",
  cantCreateUser: "无法创建用户",
  userCreatedSuccessfully: "用户创建成功",
  userEditedSuccessfully: "用户编辑成功",
  cantEditUser: "无法编辑用户",
  userDeletedSuccessfully: "用户删除成功",
  cantDeleteUser: "无法删除用户",
  companyCreatedSuccessfully: "公司创建成功",
  cantCreateCompany: "无法创建公司",
  totalEnterprises: "企业总数",
  totalContacts: "联系人总数",
  enterpriseRoleUpdated: "企业角色更新成功",
  cantUpdateEnterpriseRole: "无法更新企业角色",
  enterpriseRoleDeleted: "企业角色删除成功",
  cantDeleteEnterpriseRole: "无法删除企业角色",
  newRole: "新角色",
  cantEditUnclassifiedRole: "无法编辑未分类角色",
  cantDeleteUnclassifiedRole: "无法删除未分类角色",
  noUsersRole: "没有具有此角色的用户",
  deleteRole: "删除角色",
  deleteRoleMessage: "您确定要删除此角色吗？执行此操作后，无法撤消。",
  editRole: "编辑角色",
  roleName: "角色名称",
  invalidName: "无效名称",
  cantCreateRole: "无法创建角色",
  roleCreatedSuccessfully: "角色创建成功",
  roleEditedSuccessfully: "角色编辑成功",
  cantEditRole: "无法编辑角色",
  addRole: "添加角色",
  roleNameEmpty: "角色名称不能为空",
  projectCreated: "项目创建成功",
  cantCreateProject: "无法创建项目",
  userName: "用户名",
  selectUser: "选择用户",
  userAlreadyAdded: "用户已添加",
  newProject: "新项目",
  skip: "跳过",
  fillRequiredFields: "填写必填字段",
  exportInfo: "导出信息",
  deleteImage: "删除图片",
  clickAddProject: "点击添加项目",
  other: "其他",
  projectTypeCreated: "项目类型创建成功",
  cantCreateProjectType: "无法创建项目类型",
  projectType: "项目类型",
  addProjectType: "添加项目类型",
  cover: "封面",
  logo: "标志",
  recommendedSize: "推荐尺寸",
  projectManager: "项目经理",
  mexico: "墨西哥",
  unitedStates: "美国",
  guatemala: "危地马拉",
  colombia: "哥伦比亚",
  chile: "智利",
  argentina: "阿根廷",
  projectLimitReached: "项目限制已达到",
  projectsLimitReached: "项目限制已达到",
  projectAddWarning:
    "您确定要继续创建一个项目吗？执行此操作时，将在下一个发票中额外收取一个项目的费用。",
  billingInformation: "账单信息",
  enterpriseInfoUpdated: "企业信息更新成功",
  cantUpdateEnterpriseInfo: "无法更新企业信息",
  reference: "参考",
  amount: "金额",
  paymentDate: "付款日期",
  emailRecipient: "电子邮件收件人",
  cardNumber: "卡号",
  mmaa: "MM/YY",
  addCard: "添加卡片",
  selectAvatar: "选择头像",
  userUpdate: "用户更新成功",
  cantUpdateUser: "无法更新用户",
  avatarUpdateError: "头像更新错误",
  feelsLike: "体感温度",
  weatherValidation:
    "确保您的项目具有邮政编码和国家/地区以检索天气信息，或稍后再试",
  clear: "晴朗",
  cloudy: "多云",
  mostlyClear: "大部分晴朗",
  partlyCloudy: "局部多云",
  mostlyCloudy: "大部分多云",
  fog: "雾",
  lightFog: "轻雾",
  drizzle: "毛毛雨",
  rain: "雨",
  lightRain: "小雨",
  heavyRain: "大雨",
  snow: "雪",
  flurries: "飘雪",
  lightSnow: "小雪",
  heavySnow: "大雪",
  drizzleFreezing: "冻毛毛雨",
  freezingRain: "冻雨",
  freezingLightRain: "冻小雨",
  freezingHeavyRain: "冻大雨",
  icePellets: "冰粒",
  heavyIcePellets: "大冰粒",
  lightIcePellets: "小冰粒",
  thunderstorm: "雷暴",
  Sun: "星期日",
  Mon: "星期一",
  Tue: "星期二",
  Wed: "星期三",
  Thu: "星期四",
  Fri: "星期五",
  Sat: "星期六",
  cantUpdateConfig: "无法更新配置",
  drawingsAdded: "图纸添加成功",
  modified: "修改",
  modification: "修改",
  from: "从",
  moveFolderTo: "移动文件夹到",
  moveFileTo: "移动文件到",
  locationCreated: "位置创建成功",
  cantCreateLocation: "无法创建位置",
  locationUpdated: "位置更新成功",
  cantUpdateLocation: "无法更新位置",
  locationDeleted: "位置删除成功",
  cantDeleteLocation: "无法删除位置",
  type: "类型",
  addType: "添加类型",
  typeAdded: "类型添加成功",
  editType: "编辑类型",
  typeUpdated: "类型更新成功",
  deleteType: "删除类型",
  typeDeleted: "类型删除成功",
  superLocation: "超级位置",
  sureDelete: "您确定要删除",
  specificationAdded: "规格添加成功",
  costCodeAdded: "成本代码添加成功",
  specificationUpdated: "规格更新成功",
  costCodeUpdated: "成本代码更新成功",
  specificationDeleted: "规格删除成功",
  costCodeDeleted: "成本代码删除成功",
  addSpecification: "添加规格",
  addCostCode: "添加成本代码",
  editSpecification: "编辑规格",
  editCostCode: "编辑成本代码",
  code: "代码",
  deleteSpecification: "删除规格",
  deleteCostCode: "删除成本代码",
  deleteGeneric: "您确定要删除此元素吗？执行此操作后，无法撤消",
  seen: "已查看",
  create: "创建",
  rfi: "RFIs",
  meeting: "会议",
  project_directory: "项目目录",
  createuser: "创建用户",
  deleteAgendaItem: "删除议程项",
  deleteAgendaItemMessage: "您确定要删除此议程项吗？执行此操作后，无法撤消。",
  viewCurrentFiles: "查看当前文件",
  reportAnIssue: "报告问题",
  message: "消息",
  sendToEmail: "发送到电子邮件",
  sent: "已发送",
  sentMessage: "电子邮件已成功发送。",
  timezone: "时区",
  agenda: "议程",
  newPasswordTitle: "请输入您的新密码",
  assignTo: "分配给用户",
  comment: "评论",
  linkTo: "链接到",
  deliveryDate: "交付日期",
  responsible: "负责人",
  invalidEmail: "无效的电子邮件",
  publishedBy: "发布者",
  privacyOptions: "隐私选项",
  person: "人员",
  companies: "公司",
  onlyFavorites: "仅收藏夹",
  approvedBy: "批准者",
  approvalDate: "批准日期",
  externalContacts: "外部联系人",
  retry: "重试",
  generalErrorMessage: "出现错误，请重试",
  yesDeleteIt: "是的，删除",
  creators: "创建者",
  deleteInspection: "您确定要删除检查吗？",
  deleteInspectionTemplate: "您确定要删除检查模板吗？",
  sections: "部分",
  items: "项目",
  cancelPrompt: "您确定要取消吗？",
  graph: "图表",
  quickTemplate: "+ 快速模板",
  saveAsTemplate: "另存为模板",
  createRevision: "创建修订版本",
  generate: "生成",
  videos: "视频",
  noVideos: "无视频",
  audios: "音频",
  noAudios: "无音频",
  in_revision: "修订中",
  logoutMessage: "您确定要注销吗？",
  exportCsv: "导出CSV",
  compare: "比较",
  approved: "已批准",
  comparing: "比较中",
  more: "更多",
  noCCSelected: "未选择抄送收件人",
  and: "和",
  removeSuperAdminTitle: "删除超级管理员角色",
  removeSuperAdminDescription:
    "此操作将从用户中删除超级管理员角色，而不会删除用户。",
  noResults: "无结果",
  noResultsMessage: "尝试使用不同的筛选条件。",
  assignedProjects: "已分配的项目",
  noAssignedProjects: "无已分配的项目",
  userAddedSuccessfully: "用户添加成功",
  projectSchedule: "项目进度",
  noOptions: "无选项",
  noData: "未找到数据",
  order: "顺序",
  changeStatus: "更改状态",
  changeStatusContent: "您确定要更改用户状态吗？",
  select: "选择",
  sign: "签名",
  useExisting: "使用现有的",
  reject: "拒绝",
  rejectedSuccessfully: "已成功拒绝",
  reviewedBy: "审核人：",
  timeAgo: "{{time}} {{unit}} 之前",
  seconds: "秒",
  onlyUnread: "仅显示未读",
  days: "天",
  image: "图片",
  reviewDate: "审核日期",
  publicationDate: "发布日期",
  inspection: "检查",
  announcement: "公告",
  updatedProject: "项目信息更新成功。",
  draw: "绘制",
  history: "历史",
  templates: "模板",
  useFileName: "使用文件名",
  progress: "进度",
  bim: "BIM",
  reports: "报告",
  report: "报告",
  constants: "常量",
  importInfoMessage:
    "在此处，您可以从另一个项目中导入信息，以节省创建过程中的时间。",
  importInfo: "导入信息",
  imported: "已导入",
  importedSuccessfully: "导入成功",
  createdDate: "创建日期",
  acceptedBy: "接受者",
  acceptedDate: "接受日期",
  projectNetwork: "项目网络",
  share: "分享",
  shared: "已分享",
  sharedSuccessfully: "分享成功",
  sharedOnly: "仅分享",
  altitude: "海拔",
  fullscreen: "全屏",
  metric: "公制",
  imperial: "英制",
  meters: "米",
  feet: "英尺",
  inches: "英寸",
  daily_reports: "报告",
  upload: "上传",
  enterprises: "企业",
  all: "全部",
  total: "总计",
  modules: "模块",
  filters: "筛选",
  filterBy: "筛选",
  clearFilter: "清洁",
  typeToAdd: "输入以添加",
  saveChanges: "保存更改",
  format: "格式",
  showResults: "显示结果",
  activity: "活动",
  allProjects: "所有项目",
  allModules: "所有模块",
  analytics: "分析",
  uploadingQtyFiles: "正在上传 {{qty}} 个文件",
  technicalIssues: "我们目前遇到技术问题",
  comeBackLater: "请几分钟后再来。",
  noInternetConnection: "没有网络连接",
  checkConnection: "请检查您的连接，或连接到 Wi-Fi。",
  charactersLimitError: "已达到字符限制",
  maxCharacters: "最多 {{max}} 个字符",
  sharedBy: "分享者",
  ccInstruction: "在有效的电子邮件后按 Enter 或 ';' 以添加",
};
