import { Circle } from "@mui/icons-material";
import { Box, Card, Divider, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubcontractorCard } from "./SubcontractorCard";

const COLORS = [
  "#F9DF3A",
  "#412107",
  "#F6CE19",
  "#6f4114",
  "#47CFD6",
  "#835010",
  "#C68D08",
  "#9E640A",
];

function ManpowerSummary({ report }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");

  const [chartParam, setChartParam] = useState("assistants");

  const manpower = useMemo(() => {
    if (report) {
      return report.manpower.map((m, i) => ({ ...m, color: COLORS[i] }));
    }
    return [];
  }, [report]);

  const summary = useMemo(() => {
    if (manpower.length > 0) {
      return manpower
        .reduce((prev, m) => [...prev, ...m.detail], [])
        .reduce(
          (acc, m) => {
            return {
              assistants: acc.assistants + (m.name ? 1 : m.assistants),
              hours: acc.hours + m.hours,
            };
          },
          { assistants: 0, hours: 0 },
        );
    }
    return { assistants: 0, hours: 0 };
  }, [manpower]);
  return (
    <Box
      flex={2}
      sx={{ overflowY: "auto", maxHeight: "40vh", p: "1rem 0.5rem" }}
    >
      <Box display="flex" mb="1rem" columnGap="0.5rem">
        {manpower.map((m) => (
          <Box display="flex" columnGap="0.5rem" alignItems="center">
            <Circle
              sx={{
                width: "0.5rem",
                height: "0.5rem",
                color: m.color,
              }}
            />
            <Typography>{m.subcontractor.name}</Typography>
          </Box>
        ))}
      </Box>
      <Box display="flex" px="0.5rem" columnGap="1rem">
        <Box display="flex" flexDirection="column" rowGap="0.5rem" flex={1}>
          <Typography>{tGeneral("total")}</Typography>
          <Card
            variant="outlined"
            sx={{
              backgroundColor:
                chartParam === "assistants" ? "#FDF9C4" : "#FAFAFA",
              display: "flex",
              flexDirection: "column",
              rowGap: "0.5rem",
              p: "0.5rem 1rem",
              cursor: "pointer",
              borderRadius: "1rem",
              border: "none",
            }}
            component="div"
            onClick={() => setChartParam("assistants")}
          >
            <Typography fontWeight="700">{summary.assistants}</Typography>
            <Typography>{tDailyReports("assistants")}</Typography>
          </Card>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: chartParam === "hours" ? "#FDF9C4" : "#FAFAFA",
              display: "flex",
              flexDirection: "column",
              rowGap: "0.5rem",
              p: "0.5rem 1rem",
              cursor: "pointer",
              borderRadius: "1rem",
              border: "none",
            }}
            component="div"
            onClick={() => setChartParam("hours")}
          >
            <Typography fontWeight="700">{summary.hours}</Typography>
            <Typography>{tDailyReports("workedHours")}</Typography>
          </Card>
        </Box>
        <Box flex={1}>
          <PieChart
            series={[
              {
                data: manpower.map((m) => ({
                  label: m.subcontractor.name,
                  value: m.detail.reduce(
                    (acc, m) => acc + (m[chartParam] > 0 ? m[chartParam] : 1),
                    0,
                  ),
                  color: m.color,
                })),
                innerRadius: 60,
                outerRadius: 85,
                paddingAngle: 4,
                cornerRadius: 7,
                startAngle: -45,
                endAngle: 315,
                cy: 90,
                cx: 90,
                highlightScope: { faded: "global", highlighted: "item" },
                faded: {
                  innerRadius: 80,
                  additionalRadius: 0,
                  color: "gray",
                },
              },
            ]}
            width="100%"
            height="100%"
            sx={{ "& .MuiChartsLegend-root": { display: "none" } }}
          />
        </Box>
      </Box>
      <Divider sx={{ my: "1rem" }} />
      <Typography fontSize="1.25rem">{tGeneral("enterprises")}</Typography>
      {report.manpower.map((m) => (
        <SubcontractorCard manpower={m} />
      ))}
    </Box>
  );
}

export { ManpowerSummary };
