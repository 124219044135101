import { Box, Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useGetDisciplinesQuery,
  useGetDrawingIdsQuery,
  useGetSetsQuery,
} from "../../../../features/drawings";
import { CommentDrawingModal } from "./CommentDrawingModal";
import { DrawingRow } from "./DrawingRow";
import { ModalApproveDrawing } from "../../../../components/drawings/ModalApproveDrawing";

function UploadDrawingsTable({
  selectedDrawings,
  setSelectedDrawings,
  drawings,
  isLoading,
}) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const [commentDrawing, setCommentDrawing] = useState(null);
  const [viewDrawing, setViewDrawing] = useState(null);

  const { data: disciplines } = useGetDisciplinesQuery(idProject);
  const { data: idAuxs } = useGetDrawingIdsQuery(idProject);
  const { data: sets } = useGetSetsQuery(idProject);

  const handleSelect = (id) => {
    if (selectedDrawings.indexOf(id) > -1) {
      setSelectedDrawings((prev) => prev.filter((prevId) => prevId !== id));
    } else {
      setSelectedDrawings((prev) => [...prev, id]);
    }
  };

  const handleComment = (id, comment) => {
    setCommentDrawing({ id, comment });
  };

  return (
    <Box>
      <Box
        display="grid"
        gridTemplateColumns="4fr 3fr 2fr 1fr 2fr 2fr 100px"
        columnGap="1rem"
        sx={{ backgroundColor: "#E1E1E1", p: "1rem 0.5rem 1rem 2rem" }}
      >
        <Typography pl="1rem">{tGeneral("file")}</Typography>
        <Typography textAlign="center">ID</Typography>
        <Typography textAlign="center">{tGeneral("name")}</Typography>
        <Typography textAlign="center">{tDrawings("revision")}</Typography>
        <Typography textAlign="center">Set</Typography>
        <Typography textAlign="center">{tDrawings("discipline")}</Typography>
        <Typography textAlign="center">{tGeneral("actions")}</Typography>
      </Box>
      <Box sx={{ maxHeight: "70vh", overflowY: "auto" }}>
        {isLoading
          ? [...Array(20)].map(() => (
              <Skeleton
                variant="rounded"
                width="100%"
                height="60px"
                sx={{ mb: "0.5rem" }}
              />
            ))
          : drawings.map((drawing) => (
              <DrawingRow
                isSelected={selectedDrawings.indexOf(drawing.id) > -1}
                onSelect={handleSelect}
                drawing={drawing}
                disciplines={disciplines}
                idAuxs={idAuxs}
                sets={sets}
                onDelete={() => {}}
                onEdit={() => {}}
                onComment={handleComment}
                onView={() => setViewDrawing(drawing.id)}
              />
            ))}
      </Box>
      {commentDrawing && (
        <CommentDrawingModal
          idDrawing={commentDrawing?.id}
          comment={commentDrawing.comment}
          isOpen={Boolean(commentDrawing)}
          onClose={() => setCommentDrawing(null)}
        />
      )}
      {viewDrawing && (
        <ModalApproveDrawing
          isOpen={Boolean(viewDrawing)}
          onClose={() => setViewDrawing(null)}
          idRevision={viewDrawing}
        />
      )}
    </Box>
  );
}

export { UploadDrawingsTable };
