// @ts-check
/* eslint react/prop-types: 0 */

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar, Box, Button, Checkbox, ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function FilterAssignees(props) {
  const { assignees, options, setAssignees } = props;

  const ref = useRef(null);

  const [open, setOpen] = useState(false);

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const handleChange = (option) => {
    setAssignees((assignees) => {
      return assignees.includes(option.name)
        ? assignees.filter((assignee) => assignee !== option.name)
        : [...assignees, option.name];
    });
  };

  return (
    <>
      <Button
        color="secondary"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        onClick={() => {
          setOpen((open) => !open);
        }}
        ref={ref}
        sx={{
          textTransform: "none",
        }}
        variant="text"
      >
        {assignees.length === 0 ? tProjectSchedule("Everyone") : `${assignees.length} ${tProjectSchedule("Assignees")}`}
      </Button>
      <Popper
        anchorEl={ref.current}
        open={open}
        placement="bottom-start"
        role={undefined}
        sx={{
          margin: "5px 0 0 10px",
          zIndex: 1201,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left top",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => {
                  // @ts-ignore
                  if (ref.current && ref.current.contains(event.target)) {
                    return;
                  }
                  setOpen(false);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "20px",
                    width: "250px",
                  }}
                >
                  <strong>
                    <small>{tProjectSchedule("Select Assignees")}</small>
                  </strong>
                  {options.map((option) => {
                    return (
                      <Box
                        key={option.initials}
                        sx={{
                          alignItems: "center",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <Checkbox
                          checked={assignees.includes(option.name)}
                          onClick={() => handleChange(option)}
                          sx={{
                            padding: "0",
                          }}
                        />
                        <Avatar
                          key={option.name}
                          onClick={() => handleChange(option)}
                          src={option.src}
                          sx={{
                            backgroundColor: option.backgroundColor,
                            fontSize: 10,
                            height: 20,
                            width: 20,
                          }}
                        >
                          {option.initials}
                        </Avatar>
                        <Box
                          onClick={() => handleChange(option)}
                          sx={{
                            userSelect: "none",
                          }}
                        >
                          <small>{option.name}</small>
                        </Box>
                      </Box>
                    );
                  })}
                  <Button
                    color="primary"
                    onClick={() => {
                      setOpen(false);
                    }}
                    sx={{
                      textTransform: "none",
                    }}
                    variant="contained"
                  >
                    {tProjectSchedule("Close")}
                  </Button>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
