import Lottie from "lottie-react";
import { CircleSharp, Lock, LockOpen } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import animationData from "../../../projectDashboard/16627-firework.json";

const useDrawingsSteps = () => {
  const { t: translation } = useTranslation("drawings");

  const addSteps = [
    {
      target: "[data-tour='add-drawings-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_addDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_addDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='docs-add-file-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-drawings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-drawings-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_addDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_addDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-drawings-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_addDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_addDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-drawings-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_addDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_addDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_addDrawing")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const viewSteps = [
    {
      target: "[data-tour='view-drawings-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_viewDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_viewDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='view-drawings-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_viewDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_viewDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='view-drawings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_viewDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_viewDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='view-drawings-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_viewDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_viewDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='view-drawings-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_viewDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_viewDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_viewDrawing")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const editSteps = [
    {
      target: "[data-tour='edit-drawings-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_editDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_editDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='edit-drawings-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_editDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_editDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='edit-drawings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_editDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_editDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='edit-drawings-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_editDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_editDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='edit-drawings-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_editDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_editDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='edit-drawings-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_editDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_editDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='edit-drawings-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_editDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_editDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_editDrawing")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const recycleSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_recycleDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_recycleDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='recycle']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_recycleDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_recycleDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "input[type='checkbox",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_recycleDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_recycleDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='confirm-recycle']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_recycleDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_recycleDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_recycleDrawing")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const exportSteps = [
    {
      target: "[data-tour='export-drawings-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_exportDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_exportDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='export-drawings-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_exportDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_exportDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='export-drawings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_exportDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_exportDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='export-drawings-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_exportDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_exportDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_exportDrawing")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const settingsSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_settingsDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_settingsDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='settings']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_settingsDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_settingsDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='settings-drawings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_settingsDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_settingsDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='settings-drawings-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_settingsDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_settingsDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='settings-drawings-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_settingsDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_settingsDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_settingsDrawing")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const reviewAndPublishSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_reviewAndPublishDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_reviewAndPublishDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='review-and-publish-drawings-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_reviewAndPublishDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_reviewAndPublishDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='review-and-publish-drawings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_reviewAndPublishDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_reviewAndPublishDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='review-and-publish-drawings-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_reviewAndPublishDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_reviewAndPublishDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='review-and-publish-drawings-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_reviewAndPublishDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_reviewAndPublishDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='review-and-publish-drawings-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_reviewAndPublishDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_reviewAndPublishDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "input[type='checkbox']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_reviewAndPublishDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_reviewAndPublishDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='review-and-publish-drawings-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_reviewAndPublishDrawing_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_reviewAndPublishDrawing_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_reviewAndPublishDrawing")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  return [
    {
      addSteps,
      viewSteps,
      editSteps,
      recycleSteps,
      exportSteps,
      settingsSteps,
      reviewAndPublishSteps,
    },
  ];
};

export { useDrawingsSteps };
