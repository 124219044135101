// @ts-check
/* eslint react/prop-types: 0 */

import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function DateFormat(props) {
  const { dateFormat, options, setDateFormat } = props;

  const ref = useRef(null);

  const [open, setOpen] = useState(false);

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const today = new Date();

  return (
    <>
      <Button
        color="secondary"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        onClick={() => {
          setOpen((open) => !open);
        }}
        ref={ref}
        sx={{
          textTransform: "none",
        }}
        variant="text"
      >
        {tProjectSchedule("Date Format:")} {moment(today).format(dateFormat)}
      </Button>
      <Popper
        anchorEl={ref.current}
        open={open}
        placement="bottom-start"
        role={undefined}
        sx={{
          margin: "5px 0 0 10px",
          zIndex: 1201,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left top",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => {
                  // @ts-ignore
                  if (ref.current && ref.current.contains(event.target)) {
                    return;
                  }
                  setOpen(false);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 0",
                    width: "250px",
                  }}
                >
                  {options.map(({ category, options }) => {
                    return (
                      <div key={category}>
                        <Box
                          sx={{
                            padding: "7.5px 20px",
                          }}
                        >
                          <strong>
                            <small>{category}</small>
                          </strong>
                        </Box>
                        {options.map((option) => {
                          return (
                            <Box
                              key={option}
                              onClick={() => {
                                setDateFormat(option);
                                setOpen(false);
                              }}
                              sx={{
                                backgroundColor: option === dateFormat ? "#ddf7fe" : "transparent",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "7.5px 20px",
                                "&:hover": {
                                  backgroundColor: "#eeeeee",
                                },
                              }}
                            >
                              <small>{moment(today).format(option)}</small>
                              {option === dateFormat ? <CheckIcon fontSize="small" /> : null}
                            </Box>
                          );
                        })}
                      </div>
                    );
                  })}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
