import { apiSlice } from "../../api/apiSlice";
import { objectToParamString } from "../../utils";

const notificationCenterApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationsByEnterprise: builder.query({
      query: ({ idEnterprise, ...params }) =>
        `/notificationCenter/${idEnterprise}${objectToParamString(params)}`,
      providesTags: ["Notifications"],
    }),
    getUnreadNotificacionsCounter: builder.query({
      query: ({ idEnterprise, ...params }) =>
        `/notificationCenter/counter/${idEnterprise}${objectToParamString(
          params,
        )}`,
      providesTags: ["Notifications"],
    }),
    markAsRead: builder.mutation({
      query: (idNotification) => ({
        url: `/notificationCenter/read/${idNotification}`,
        method: "PUT",
      }),
      invalidatesTags: ["Notifications"],
    }),
    markAllAsRead: builder.mutation({
      query: (idEnterprise) => ({
        url: `/notificationCenter/readAll/${idEnterprise}`,
        method: "PUT",
      }),
      invalidatesTags: ["Notifications"],
    }),
    deleteNotification: builder.mutation({
      query: (idNotification) => ({
        url: `/notificationCenter/delete/${idNotification}`,
        method: "PUT",
      }),
      invalidatesTags: ["Notifications"],
    }),
  }),
});

export const {
  useGetNotificationsByEnterpriseQuery,
  useLazyGetNotificationsByEnterpriseQuery,
  useGetUnreadNotificacionsCounterQuery,
  useMarkAsReadMutation,
  useMarkAllAsReadMutation,
  useDeleteNotificationMutation,
} = notificationCenterApiSlice;
