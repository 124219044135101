import { datadogRum } from "@datadog/browser-rum";
import {
  Add,
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Close,
  Delete,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Search,
} from "@mui/icons-material";
import RestoreIcon from "@mui/icons-material/Restore";
import {
  AvatarGroup,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "jspdf-autotable";
import clone from "just-clone";
import MaterialTable from "material-table";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";

import No from "../../assets/inspectionsTemplates/no.png";
import { PopUpAlert } from "../../components/PopUpAlert";
import { ModalFileViewer } from "../../components/shared/ModalFileViewer";
import BubbleAvatarDeletable from "../../components/userAvatars/BubbleAvatarDeletable";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import {
  useDeleteInspectionTemplateMutation,
  useGetActiveInspectionsTemplatesQuery,
} from "../../features/project/modules/inspectionsTemplates/apiSlice";
import {
  selectSettingsInspectionTemplatePopupOpen,
  setCurrentInspectionTemplate,
  setSampleInspectionTemplate,
} from "../../features/project/modules/inspectionsTemplates/slice";
import {
  useGetProjectConfigurationQuery,
  useGetProjectUsersQuery,
  useGetUserRoleQuery,
  useUpdateProjectConfigMutation,
} from "../../features/project/projectApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
  selectCurrentProjectConfig,
  selectCurrentProjectUsers,
  selectCurrentRole,
  setCurrentProjectConfig,
} from "../../features/project/projectSlice";
import { useSendToEmailMutation } from "../../features/ses/sesApiSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
import { capitalizeFirstLetterOfSentence } from "../../services/helpers/capitalizeFirstLetterOfSentence";
import { positiveAndNeutralIntegerValidator } from "../../services/validators/positiveAndNeutralIntegerValidator";
import Can from "../../utils/can";
import { TitleWidgets } from "../../components/shared";
import { useAppTourContext } from "../../components/supportButton/context/context";
import CaslContext from "../../utils/caslContext";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

function InspectionsTemplates() {
  const darkMode = useSelector(selectTheme);
  const userRole = useSelector(selectCurrentRole);

  const { t: tGeneral } = useTranslation("general");
  const { t: tInspectionsTemplates } = useTranslation("inspectionsTemplates");
  const { t: tInspections } = useTranslation("inspections");

  const columns = [
    // Num
    {
      field: "num",
      title: "Num",
      initialEditValue: "",
      // styles for header of column
      headerStyle: {
        textAlign: "center",
        width: "5.5%",
        maxWidth: "5.5%",
        fontWeight: "bold",
      },
      // styles for cells of column
      cellStyle: {
        fontSize: "1rem",
        textAlign: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "5.5%",
        maxWidth: "5.5%",
      },
      // defaultSort: "desc",
    },

    // Title
    {
      field: "title",
      title: tGeneral("title"),
      initialEditValue: "",
      // styles for header of column
      headerStyle: {
        textAlign: "left",
        width: "25%",
        maxWidth: "25%",
        fontWeight: "bold",
      },
      // styles for cells of column
      cellStyle: {
        fontSize: 12,
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "25%",
        maxWidth: "25%",
      },
      render: (rowData) => (
        <Typography
          fontSize="1rem"
          maxWidth="200px"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {capitalizeFirstLetterOfSentence(rowData.title)}
        </Typography>
      ),
    },

    // Code
    {
      field: "code",
      title: tGeneral("code"),
      initialEditValue: "",
      // styles for header of column
      headerStyle: {
        textAlign: "left",
        width: "8.2%",
        maxWidth: "8.2%",
        fontWeight: "bold",
      },
      // styles for cells of column
      cellStyle: {
        fontSize: 12,
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "8.2%",
        maxWidth: "8.2%",
      },
      render: (rowData) => (
        <Typography
          fontSize="1rem"
          maxWidth="200px"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {rowData.code}
        </Typography>
      ),
    },

    // Category
    {
      field: "category",
      title: tGeneral("category"),
      initialEditValue: "",
      // styles for header of column
      headerStyle: {
        textAlign: "left",
        width: "25%",
        maxWidth: "25%",
        fontWeight: "bold",
      },
      // styles for cells of column
      cellStyle: {
        fontSize: 10,
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "25%",
        maxWidth: "25%",
      },
      render: (rowData) => (
        <Typography
          fontSize="1rem"
          maxWidth="200px"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {rowData.category}
        </Typography>
      ),
    },

    // Updated
    {
      field: "updatedDate",
      title: tGeneral("updated"),
      initialEditValue: "",
      // styles for header of column
      headerStyle: {
        textAlign: "center",
        width: "8.2%",
        maxWidth: "8.2%",
        fontWeight: "bold",
      },
      // styles for cells of column
      cellStyle: {
        fontSize: 10,
        textAlign: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "8.2%",
        maxWidth: "8.2%",
      },
      render: (rowData) => (
        <Typography fontSize="1rem">
          {formatDate(rowData.updatedDate)}
        </Typography>
      ),
    },

    // Sections
    {
      field: "sections",
      title: tInspections("sections"),
      initialEditValue: "",
      // styles for header of column
      headerStyle: {
        textAlign: "center",
        width: "8.2%",
        maxWidth: "8.2%",
        fontWeight: "bold",
      },
      // styles for cells of column
      cellStyle: {
        fontSize: 10,
        textAlign: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "8.2%",
        maxWidth: "8.2%",
      },
      render: (rowData) => (
        <Typography
          fontSize="1rem"
          maxWidth="200px"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {rowData.sections.length}
        </Typography>
      ),
    },

    // Items
    {
      field: "items",
      title: tInspections("items"),
      initialEditValue: "",
      // styles for header of column
      headerStyle: {
        textAlign: "center",
        width: "8.2%",
        maxWidth: "8.2%",
        fontWeight: "bold",
      },
      // styles for cells of column
      cellStyle: {
        fontSize: 10,
        textAlign: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "8.2%",
        maxWidth: "8.2%",
      },
      render: (rowData) => (
        <Typography
          fontSize="1rem"
          maxWidth="200px"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {rowData.sections
            ?.map((section) => section.items.length)
            .reduce((a, b) => a + b, 0)}
        </Typography>
      ),
    },

    // Created By
    {
      field: "createdBy",
      title: tGeneral("createdBy"),
      initialEditValue: "",
      // styles for header of column
      headerStyle: {
        textAlign: "center",
        width: "8.2%",
        maxWidth: "8.2%",
        fontWeight: "bold",
      },
      // styles for cells of column
      cellStyle: {
        fontSize: 10,
        textAlign: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "8.2%",
        maxWidth: "8.2%",
      },
      render: (rowData) => (
        <Box display="flex" justifyContent="center">
          <LightTooltip
            title={[rowData.createdBy]
              .filter((user) => currentProjectUsers.find((u) => u.id === user))
              .map((user) => currentProjectUsers.find((u) => u.id === user))
              .map((user) => (
                <Box key={user.id} sx={{ display: "flex", marginY: "2px" }}>
                  {user.urlAvatar ? (
                    <Avatar
                      alt={user?.name}
                      src={user?.urlAvatar}
                      sx={{
                        bgcolor: "#ffc900",
                        width: 24,
                        height: 24,
                        fontSize: 12,
                        boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                      }}
                      style={{
                        border: "0.8px solid white",
                      }}
                    >
                      {`${user?.name?.split(" ")[0][0]}${
                        user?.name?.split(" ")[1][0]
                      }`}
                    </Avatar>
                  ) : (
                    <Avatar
                      key={user.id}
                      alt={user.name}
                      sx={{
                        bgcolor: "#ffc900",
                        width: 24,
                        height: 24,
                        fontSize: 12,
                        boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                      }}
                      style={{
                        border: "0.8px solid white",
                      }}
                    >
                      {`${user?.name?.split(" ")[0][0]}${
                        user?.name?.split(" ")[1][0]
                      }`}
                    </Avatar>
                  )}

                  {/* <Avatar sx={{ width: 24, height: 24 }} /> */}
                  <Typography sx={{ fontSize: 15 }}>
                    &nbsp;{user.name}
                  </Typography>
                </Box>
              ))}
          >
            <AvatarGroup
              max={3}
              sx={{
                "& .MuiAvatar-root": { width: 28, height: 28, fontSize: 15 },
                "& .MuiTooltip-tooltip": {
                  border: "solid skyblue 1px",
                  color: "deepskyblue",
                },
                "& .MuiAvatarGroup-root": {
                  marginX: "auto",
                  textAlign: "center",
                },
              }}
            >
              {[rowData.createdBy]
                .filter((user) =>
                  currentProjectUsers.find((u) => u.id === user),
                )
                .map((user) => currentProjectUsers.find((u) => u.id === user))
                .map((user) => {
                  if (user.urlAvatar != null) {
                    return (
                      <Avatar
                        key={user.id}
                        alt={user.name}
                        src={user?.urlAvatar}
                        sx={{
                          bgcolor: "#ffc900",
                          boxShadow: "0 0 5px 0 rgba(200, 200, 200, 1)",
                        }}
                      >
                        {`${user.name.split(" ")[0][0]}${
                          user.name.split(" ")[1][0]
                        }`}
                      </Avatar>
                    );
                  }
                  return (
                    <Avatar
                      key={user.id}
                      alt={user.name}
                      sx={{
                        bgcolor: "#ffc900",
                        boxShadow: "0 0 5px 0 rgba(200, 200, 200, 1)",
                      }}
                    >
                      {`${user.name?.split(" ")[0][0]}${
                        user.name?.split(" ")[1][0]
                      }`}
                    </Avatar>
                  );
                })}
            </AvatarGroup>
          </LightTooltip>
        </Box>
      ),
    },

    // Actions
    {
      field: "action",
      title: tGeneral("actions"),
      initialEditValue: "",
      sorting: false,
      // styles for header of column
      headerStyle: {
        textAlign: "center",
        width: "18.3%",
        maxWidth: "18.3%",
        fontWeight: "bold",
      },
      // styles for cells of column
      cellStyle: {
        fontSize: 12,
        textAlign: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "18.3%",
        maxWidth: "18.3%",
      },
      render: (rowData) => (
        <Can I="view" a="inspections">
          {
            <ButtonGroup sx={{ width: "100%", justifyContent: "space-around" }}>
              {/* EDIT users allowed: Super Admin */}
              <Can I="edit" a="inspections">
                <Tooltip
                  title={tGeneral("edit")}
                  data-tour={
                    rowData && rowData.tableData?.id === 0
                      ? "inspection-templates-edit-1"
                      : ""
                  }
                >
                  <IconButton
                    type="submit"
                    // title="Edit"
                    size="small"
                    onClick={() => {
                      dispatch(setCurrentInspectionTemplate(rowData));
                      navigate("../inspections/templates/form");
                    }}
                    sx={{
                      color: darkMode ? "white" : "black",
                      ":hover": {
                        color: "#FBCB04",
                      },
                      m: [0, 0, 0, 0],
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              </Can>
            </ButtonGroup>
          }
        </Can>
      ),
    },
  ];

  function formatDate(d) {
    const months = [
      tGeneral("january"),
      tGeneral("february"),
      tGeneral("march"),
      tGeneral("april"),
      tGeneral("may"),
      tGeneral("june"),
      tGeneral("july"),
      tGeneral("august"),
      tGeneral("september"),
      tGeneral("october"),
      tGeneral("november"),
      tGeneral("december"),
    ];
    const date = new Date(d);
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  }

  const filterOptions = [
    {
      value: "category",
      label: tGeneral("category"),
    },
  ];

  const { idProject, idEnterprise } = useParams();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const settingsInspectionTemplatePopupOpen = useSelector(
    selectSettingsInspectionTemplatePopupOpen,
  );
  const { data: projectConfiguration } =
    useGetProjectConfigurationQuery(idProject);
  const projectConfig = useSelector(selectCurrentProjectConfig);
  const { data: projectUsers } = useGetProjectUsersQuery(idProject);
  const [updateConfig, { isSuccess: isSuccesUpdateConfig }] =
    useUpdateProjectConfigMutation();
  const currentProjectUsers = useSelector(selectCurrentProjectUsers);
  const [removeInspectionTemplate] = useDeleteInspectionTemplateMutation();
  const [data, setData] = useState([]);
  const currentUser = useSelector(selectCurrentUser);

  const [daysError, setDaysError] = useState(false);
  const [userList, setUserList] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [deleteIds, setDeleteIds] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  // For bubble avatars
  const [usersDistribution, setUsersDistribution] = useState([]);

  // Filters
  const [openAddFilter, setOpenAddFilter] = useState(false);
  const [paramsInspectionsTemplates, setParamsInspectionsTemplates] =
    useState(null);
  const [currentFilter, setCurrentFilter] = useState();
  const [categoryList, setCategoryList] = useState([]);

  // Send by email
  const [sendingInspectionsTemplates, setSendingInspectionsTemplates] =
    useState(null);
  const [
    sendMail,
    {
      isLoading: isSendMailLoading,
      isSuccess: isSendEmailSuccess,
      isError: isSendEmailError,
      error: sendEmailError,
    },
  ] = useSendToEmailMutation();

  const { data: inspectionsTemplates, isSuccess } =
    useGetActiveInspectionsTemplatesQuery(
      { idProject, ...paramsInspectionsTemplates },
      {
        skip: !idProject,
      },
    );
  const tableRef = React.createRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pdfUrl, setPdfUrl] = React.useState(null);

  const getIds = (names) => {
    return userList
      .filter((v) => names && names.includes(v.name))
      .map((v) => v.id);
  };

  const getUserInfo = (names) => {
    return userList
      .filter((v) => names && names.includes(v.name))
      .map((v) => {
        return {
          id: v.id,
          name: v.name,
          urlAvatar: v.urlAvatar,
        };
      });
  };
  const customSearchFunction = (query) => {
    const dataTemp = inspectionsTemplates
      .filter(function (obj) {
        return (
          obj.number === query ||
          obj.title?.toLowerCase().includes(query.toLowerCase()) ||
          query.toLowerCase().includes(obj.title?.toLowerCase())
        );
      })
      .map((inspectionTemplate) => ({
        ...inspectionTemplate,
        id: inspectionTemplate.id,
        bPrivate: inspectionTemplate.bPrivate,
        action: "all", // get user permissions
        num: inspectionTemplate.number,
        deliveryDate: inspectionTemplate.deliveryDate
          ? formatDate(inspectionTemplate.deliveryDate)
          : "N/A",
        closedDate: formatDate(inspectionTemplate.closedDate),
        createdDate: formatDate(inspectionTemplate.createDate),
      }));
    setData(dataTemp);
  };

  const styles = {
    secondaryFilter: {
      ml: 1,
      width: "23ch",
      verticalAlign: "center",
    },
    inputTitle: {
      backgroundColor: darkMode ? "" : "transparent",
      borderRadius: 2,
      mt: 0,
    },
    selectInput: {
      backgroundColor: darkMode ? "" : "#FFF5DB",
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow followCursor />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 10,
    },
  }));

  const { data: roleData } = useGetUserRoleQuery({
    idUser: currentUser?.id,
    idProject,
  });

  useEffect(() => {
    setLoadingData(true);

    if (projectConfiguration) {
      dispatch(setCurrentProjectConfig(projectConfiguration));
    }

    if (isSuccess && inspectionsTemplates && projectUsers) {
      const usersList = projectUsers
        .filter(function (obj) {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.id,
          name: `${user.user.firstName} ${user.user.lastName}`,
          urlAvatar: user.user.urlAvatar,
          idUser: user.user.id,
        }));
      setUserList(usersList);

      const dataTemp = inspectionsTemplates.map((inspectionTemplate) => ({
        ...inspectionTemplate,
        id: inspectionTemplate.id,
        bPrivate: inspectionTemplate.bPrivate,
        action: "all", // get user permissions
        num: inspectionTemplate.number,
        deliveryDate: inspectionTemplate.deliveryDate
          ? formatDate(inspectionTemplate.deliveryDate)
          : "N/A",
        closedDate: formatDate(inspectionTemplate.closedDate),
        createdDate: formatDate(inspectionTemplate.createDate),
      }));
      setData(dataTemp);

      // for guide me tours
      const newData = clone(dataTemp[0]);
      dispatch(setSampleInspectionTemplate(newData));

      setLoadingData(false);
    }
  }, [
    isSuccess,
    inspectionsTemplates,
    projectUsers,
    isSuccesUpdateConfig,
    projectConfiguration,
    roleData,
  ]);

  useEffect(() => {
    if (settingsInspectionTemplatePopupOpen == true) {
      // open modal
      setOpen(true);
    } else {
      // close modal
      setOpen(false);
    }
  }, [settingsInspectionTemplatePopupOpen]);

  useEffect(() => {
    if (isSendEmailSuccess) {
      PopUpAlert("success", tGeneral("sent"), tGeneral("sentMessage"));
      setSendingInspectionsTemplates(null);
    }
    if (isSendEmailError) {
      PopUpAlert("error", tGeneral("error"), sendEmailError.message);
      setSendingInspectionsTemplates(null);
    }
  }, [isSendEmailError, isSendEmailSuccess, sendEmailError, tGeneral]);

  const updateProjectConfig = async (e) => {
    const finalValue = document.getElementById("defaultDaysTextField").value;
    const numberIsValid = positiveAndNeutralIntegerValidator(finalValue);

    if (numberIsValid === "") {
      const distributionIdList = getIds(personName);
      const body = {
        idProject,
        inspectionTemplateDistributionList: distributionIdList,
      };
      try {
        const response = await updateConfig(body).unwrap();

        if (response) {
          setOpen(false);
          showAlert(tGeneral("configUpdated"), "success");
        }
      } catch (err) {
        showAlert(tGeneral("cantUpdateConfig"), "error");
        if (datadogRum?.addError) {
          datadogRum.addError({
            message: "Inspections - Templates Config - Error",
            stack: err.stack ? err.stack : "invalid stack",
            custom: {
              app: "buildpeer-web",
              enterprise: idEnterprise,
              module: "Inspections",
              project: idProject,
            },
            timestamp: Date.now(),
          });
        }
      }
    } else {
      setDaysError(true);
    }
  };

  const changeAssignedUsers = (e) => {
    const { value } = e.target;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    setUsersDistribution(getUserInfo(value));
  };

  function showAlert(message, type) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }

  const deleteInspectionTemplate = async (e) => {
    try {
      const body = {
        ids: deleteIds,
        idProject,
      };
      const response = await removeInspectionTemplate(body).unwrap();
      if (response) {
        setOpenDelete(false);
        setDeleteIds([]);
        showAlert(tGeneral("deletedSuccessfully"), "success");
      }
    } catch (err) {
      console.error(err);
      showAlert(tGeneral("cantDelete"), "error");
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Template Delete - Error",
          stack: err.stack ? err.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
  };

  const filterInspectionsTemplates = (value, filter) => {
    const params = { ...paramsInspectionsTemplates };

    switch (filter) {
      case "category":
        setCategoryList(value);
        if (value.length > 0) params.category = value;
        else params.category = undefined;

        if (currentFilter === "category") setCurrentFilter();
        break;
      default:
        break;
    }

    setParamsInspectionsTemplates(params);
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    // Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const isMobile = useMediaQuery("(max-width:820px)");

  // Select items for delete
  const handleSelectInspectionsTemplates = (rows) => {
    const selected = rows.map((inspectionTemplate) => inspectionTemplate?.id);
    setDeleteIds(selected);
  };

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  const generalActions = [
    {
      name: `${tGeneral("restore")}`,
      icon: <RestoreIcon />,
      userHasPermission: userRole?.inspections === 3,
      onClick: () => {
        navigate("../inspections/templates/recycle");
      },
      permissions: "superAdmin",
    },
    {
      name: `${tGeneral("add")}`,
      icon: <Add />,
      userHasPermission: !!(
        userRole?.inspections === 3 || userRole?.inspections === 2
      ),
      onClick: () => {
        dispatch(setCurrentInspectionTemplate(null));
        navigate("../inspections/templates/form");
        // dispatch(setCurrentAnnouncement(null));
      },
      permissions: "admin",
    },
  ];

  const actions = () => {
    if (deleteIds.length > 0) {
      if (userRole?.inspections === 3) {
        return [
          {
            name: tGeneral("delete"),
            icon: <Delete />,
            onClick: () => {
              setOpenDelete(true);
            },
          },
        ];
      }
      return [];
    }
    if (userRole?.inspections === 3) {
      return generalActions.filter((action) => {
        return (
          action.permissions === "superAdmin" || action.permissions === "admin"
        );
      });
    }
    if (userRole?.inspections === 2) {
      return generalActions.filter((action) => {
        return action.permissions === "admin";
      });
    }
    return [];
  };
  const {
    state: { tourActive, stepIndex, run },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 2 &&
      currentGuideMe === "inspections-add-template"
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe]);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 0 &&
      currentGuideMe === "inspections-templates-edit" &&
      data
    ) {
      if (data.length > 0) {
        dispatch(setCurrentInspectionTemplate(data[0]));
      }
    }
  }, [tourActive, stepIndex, currentGuideMe, data]);

  const ability = useContext(CaslContext);

  return (
    <Box overflow="hidden">
      <TitleWidgets
        actions={actions()}
        backEnabled
        customSearchFunction={customSearchFunction}
        moduleName="inspectionsTemplates"
        searchEnabled
        supportDisabled
        title={tInspectionsTemplates("title")}
        addFunction={() => {
          dispatch(setCurrentInspectionTemplate(null));
          navigate("../inspections/templates/form");
        }}
        addNameTour="add-inspection-templates-3"
        enableSupport
        onDelete={deleteInspectionTemplate}
        showDelete={
          deleteIds.length > 0 && ability.can("edit", "inspectionsTemplates")
        }
      />
      <Can I="view" a="inspections">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginBottom={2}
          p="10px"
          sx={{ overflowY: "auto" }}
        >
          <Box
            display="flex"
            flex={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            {isSuccess ? (
              <FormControl size="small" sx={{ minWidth: "15%" }}>
                <InputLabel>{tGeneral("addFilter")}</InputLabel>
                <Select
                  open={openAddFilter}
                  onClose={() => {
                    setOpenAddFilter(false);
                  }}
                  onOpen={() => {
                    setOpenAddFilter(true);
                  }}
                  label={tGeneral("addFilter")}
                  MenuProps={{ disableScrollLock: true }}
                  defaultValue=""
                  value={currentFilter === undefined ? "" : currentFilter}
                  onChange={(e) => {
                    setCurrentFilter(e.target.value);
                  }}
                >
                  {filterOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}

            {/* Category Select */}
            {(currentFilter === "category" || categoryList.length > 0) && (
              <FormControl size="small" sx={styles.secondaryFilter}>
                <InputLabel id="userTag1" sx={styles.inputTitle}>
                  {categoryList.length > 0 ? "" : tGeneral("category")}
                </InputLabel>
                <Select
                  labelId="categoryField"
                  id="demo-multiple-checkbox1"
                  multiple
                  onChange={(e) => {
                    filterInspectionsTemplates(e.target.value, "category");
                  }}
                  value={categoryList}
                  startAdornment={
                    categoryList.length > 0 ? (
                      <IconButton
                        onClick={(e) => {
                          filterInspectionsTemplates([], "category");
                        }}
                      >
                        <Clear />
                      </IconButton>
                    ) : (
                      ""
                    )
                  }
                  input={
                    <OutlinedInput
                      label={
                        categoryList.length > 0 ? "" : tGeneral("category")
                      }
                    />
                  }
                  renderValue={(selected) =>
                    categoryList.length > 0
                      ? `${tGeneral("category")} (${categoryList.length})`
                      : `${tGeneral("category")}`
                  }
                  MenuProps={MenuProps}
                  sx={styles.selectInput}
                >
                  {projectConfig?.taskCategoryList?.map((category) => (
                    <MenuItem key={category} value={category}>
                      <Checkbox
                        checked={categoryList.indexOf(category) > -1}
                        sx={{
                          color: "black",
                          ":checked": {
                            color: "#FBCB04",
                          },
                          borderColor:
                            categoryList.indexOf(category) > -1
                              ? "#f7e4b2"
                              : "black",
                        }}
                      />
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </Box>
        <Box sx={{ height: "100%", width: "100%" }}>
          {((isSuccess && data && data.length > 0) ||
            (isSuccess &&
              data &&
              data.length === 0 &&
              categoryList &&
              categoryList.length > 0)) && (
            <>
              <MaterialTable
                tableRef={tableRef}
                // icons
                icons={tableIcons}
                // column definition
                columns={columns}
                // actual data
                data={data}
                localization={tableLocalization}
                style={{ overflow: "auto" }}
                options={{
                  toolbar: false,
                  showTitle: false,
                  emptyRowsWhenPaging: false,
                  sorting: false,
                  actionsColumnIndex: -1,
                  maxBodyHeight: "65vh",
                  overflowY: "auto",
                  selection: true,
                  paging: true,
                  exportButton: false,
                  pageSize: 30,
                  pageSizeOptions: [10, 20, 30],
                  showFirstLastPageButtons: true,
                  // Styles
                  // headerStyle: {
                  //   textAlign: "center",
                  //   fontSize: "1rem",
                  // },
                  // Styles for rows
                  rowStyle: {
                    height: "60.5px",
                  },
                  padding: "dense",
                  draggable: false,
                }}
                // actions (buttons)
                // actions={actionsTable}
                checkboxSelection
                disableSelectionOnClick
                onSelectionChange={handleSelectInspectionsTemplates}
              />

              <Dialog
                open={openDelete}
                onClose={(e) => setOpenDelete(false)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle
                  sx={{
                    backgroundColor: "#ffc900",
                    color: "white",
                  }}
                  id="scroll-dialog-title"
                >
                  {tGeneral("delete")} Inspection - Template
                </DialogTitle>
                <DialogContent dividers>
                  <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                  >
                    {tGeneral("deleteInspectionTemplate")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={(e) => setOpenDelete(false)}>
                    {tGeneral("cancel")}
                  </Button>
                  <Button onClick={(e) => deleteInspectionTemplate()}>
                    {tGeneral("delete")}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          {!loadingData &&
            data &&
            data.length === 0 &&
            categoryList &&
            categoryList.length === 0 && (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 10,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: darkMode,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {tInspectionsTemplates("no_inspections_templates_title")}
                  </Typography>
                  <Can I="add" a="inspections">
                    <>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: darkMode ? "white" : "#B0B7C1",
                          textAlign: "center",
                          mt: 2,
                          width: "100%",
                        }}
                      >
                        {tInspectionsTemplates("no_inspections_templates_text")}
                      </Typography>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        my="15px"
                        width="100%"
                      >
                        <Button
                          sx={{
                            // width: isMobile ? "40%" : "10%",
                            width: "30%",
                            color: "black",
                            backgroundColor: "#ffc900",
                            ":hover": {
                              color: "black",
                              backgroundColor: "#d9ab00",
                            },
                            py: "0.5rem",
                          }}
                          startIcon={<Add />}
                          variant="contained"
                          onClick={(event) => {
                            dispatch(setCurrentInspectionTemplate(null));
                            navigate("../inspections/templates/form");
                          }}
                        >
                          {tInspectionsTemplates("add_button_tour")}
                        </Button>
                      </Box>
                    </>
                  </Can>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "right",
                    }}
                  >
                    <img
                      alt="No Inspections Templates"
                      src={No}
                      style={{ width: isMobile ? "90%" : "45%" }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          <Modal
            open={open}
            onClose={(e) => setOpen(false)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              sx={(theme) => ({
                height: "auto",
                borderRadius: "20px",
                p: "25px 40px",
                width: "30%",
                [theme.breakpoints.down("lg")]: {
                  width: "50%",
                },
              })}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  sx={{ flex: 1 }}
                  fontSize="1.5625rem"
                  fontWeight="bold"
                >
                  {tGeneral("settings")}
                </Typography>
                <IconButton onClick={(e) => setOpen(false)}>
                  <Close fontSize="1.5625rem" />
                </IconButton>
              </Box>
              <Divider sx={{ my: "20px" }} />
              <FormControl sx={{ width: "100%", mx: "auto" }}>
                <InputLabel id="userTag" sx={{ mx: "auto" }}>
                  {personName.length > 0 ? "" : tGeneral("distributionList")}
                </InputLabel>
                <Select
                  labelId="userTag"
                  id="demo-multiple-checkbox"
                  multiple
                  value={personName}
                  onChange={(e) => changeAssignedUsers(e)}
                  IconComponent={() => (
                    <Search
                      style={{
                        pointerEvents: "none",
                        position: "absolute",
                        right: "10px",
                      }}
                    />
                  )}
                  input={
                    <OutlinedInput
                      label={
                        personName.length > 0
                          ? ""
                          : tGeneral("distributionList")
                      }
                    />
                  }
                  renderValue={(selected) => tGeneral("distributionList")}
                  MenuProps={MenuProps}
                  sx={{ mx: "auto", width: "100%" }}
                >
                  {userList.map((user) => (
                    <MenuItem key={user.id} value={user.name}>
                      <Chip
                        sx={{
                          width: "100%",
                          height: 45,
                          bgcolor:
                            personName.indexOf(user.name) > -1
                              ? "#f7e4b2"
                              : "transparent",
                          borderColor:
                            personName.indexOf(user.name) > -1
                              ? "#f7e4b2"
                              : "transparent",
                          justifyContent: "left",
                          alignContent: "center",
                        }}
                        size="medium"
                        variant="outlined"
                        icon={
                          <Checkbox
                            checked={personName.indexOf(user.name) > -1}
                            sx={{
                              color: "black",
                              "&.Mui-checked": {
                                color: "#FBCB04",
                              },
                              borderColor:
                                personName.indexOf(user.name) > -1
                                  ? "#f7e4b2"
                                  : "black",
                            }}
                          />
                        }
                        label={
                          <BubbleAvatarDeletable
                            key={user.id}
                            user={user}
                            editable={false} // To hide delete button
                            color={false} // To hide background color
                            isList // Don´t add padding
                          />
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
                <Box sx={{ maxHeight: "250px", overflow: "auto" }}>
                  {usersDistribution.length ? (
                    usersDistribution?.map((member) => (
                      <BubbleAvatarDeletable
                        key={member.id}
                        user={member}
                        editable // To show delete button
                        setValues={(value) => {
                          const index = personName.indexOf(value);
                          const newNames = [...personName];
                          newNames.splice(index, 1);
                          setPersonName(newNames);
                          setUsersDistribution(getUserInfo(newNames));
                        }}
                      />
                    ))
                  ) : (
                    <Typography
                      fontSize="1rem"
                      fontWeight="medium"
                      textAlign="center"
                      marginTop={1}
                    >
                      {tGeneral("noUsersSelected")}
                    </Typography>
                  )}
                </Box>
              </FormControl>
              <Box display="flex" columnGap="16px" mt="10px">
                <Button
                  sx={{
                    width: "50%",
                    color: "black",
                    backgroundColor: "white",
                    borderColor: "black",
                    border: 1,
                    ":hover": {
                      color: "white",
                      backgroundColor: "#b4b4b4",
                    },
                    py: "0.5rem",
                  }}
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={(e) => setOpen(false)}
                >
                  {tGeneral("cancel")}
                </Button>
                <Button
                  sx={{
                    width: "50%",
                    color: "black",
                    backgroundColor: "#ffc900",
                    ":hover": {
                      color: "black",
                      backgroundColor: "#d9ab00",
                    },
                    py: "0.5rem",
                  }}
                  type="submit"
                  variant="contained"
                  disabled={!!daysError}
                  onClick={updateProjectConfig}
                >
                  {tGeneral("save")}
                </Button>
              </Box>
            </Card>
          </Modal>
        </Box>
      </Can>
      <Can I="none" a="inspections">
        {tGeneral("noPermission")}
      </Can>
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
    </Box>
  );
}

export default InspectionsTemplates;
