import { Check, Close, Save } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAssignAnnotationMutation } from "../../features/annotations";
import { useGetProjectUsersQuery } from "../../features/project/projectApiSlice";
import { PopUpAlert } from "../PopUpAlert";
import { FilterableUserDropdown, TooltipIconButton } from "../shared";

function ModalAnnotationLinkUser({ isOpen, onClose, annotation }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");
  const { data: projectUsersData } = useGetProjectUsersQuery(idProject);
  const [assignAnnotation, { isSuccess, isError, error, isLoading }] =
    useAssignAnnotationMutation();

  const [assignedUsers, setAssignedUsers] = useState([]);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (annotation) {
      setAssignedUsers(annotation.assignedTo?.map((user) => user.id));
    }
  }, [annotation]);

  const handleAssignUsers = () => {
    assignAnnotation({
      assignedTo: assignedUsers.filter((user) => user !== null),
      idAnnotation: annotation.id,
      comment,
      drawingId: annotation.idObject,
    });
  };

  const projectUsers = useMemo(() => {
    if (projectUsersData) {
      return projectUsersData.map(({ user }) => user);
    }
    return [];
  }, [projectUsersData]);

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDrawings("annotationAssignedMessage"),
      );
      onClose();
    }
    if (isError) {
      PopUpAlert("error", "Error", error.message);
      onClose();
    }
  }, [isSuccess, isError, error, onClose, tDrawings, tGeneral]);

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
      open={isOpen}
      onClose={onClose}
    >
      <Card
        sx={{
          width: "40%",
          p: "32px 40px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflowY: "scroll",
        }}
      >
        <Box>
          <Box display="flex" alignItems="center">
            <Typography fontSize="1.563rem" sx={{ flex: 1 }} fontWeight="bold">
              {tGeneral("assignTo")}
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
            <TooltipIconButton
              label={
                annotation?.assignedTo?.length > 0
                  ? tGeneral("save")
                  : tGeneral("add")
              }
              icon={annotation?.assignedTo?.length > 0 ? <Save /> : <Check />}
              disabled={!assignedUsers.length}
              onClick={handleAssignUsers}
              isLoading={isLoading}
            />
          </Box>
          <Divider sx={{ my: "20px" }} />
          <FormControl fullWidth>
            <FilterableUserDropdown
              title={tGeneral("users")}
              userList={projectUsers}
              selectedUsersList={projectUsers.filter(
                (user) => assignedUsers.indexOf(user.id) > -1,
              )}
              selectedUsersFormatted={assignedUsers}
              handleUpdateUsersList={(newIds) =>
                setAssignedUsers(newIds.map((u) => u.id))
              }
            />
          </FormControl>
          <Box
            display="flex"
            sx={{
              maxWidth: "100%",
              alignItems: "center",
              overflowX: "auto",
              columnGap: "5px",
              mt: "5px",
              minHeight: "70px",
            }}
          >
            {projectUsers
              ?.filter((user) => assignedUsers.indexOf(user.id) > -1)
              .map((user) => (
                <Box
                  display="flex"
                  alignItems="center"
                  borderRadius="25px"
                  columnGap="5px"
                  sx={{
                    backgroundColor: "#B0B7C1",
                    p: "5px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Avatar src={user.urlAvatar} sx={{ bgcolor: "#FFC42D" }}>
                    {`${user.firstName[0]}${user.lastName[0]}`}
                  </Avatar>
                  <ListItemText
                    primary={`${user.firstName} ${user.lastName}`}
                  />
                </Box>
              ))}
          </Box>
          {annotation?.assignedTo?.length === 0 && (
            <FormControl
              fullWidth
              sx={{ my: "10px" }}
              disabled={annotation?.assignedTo?.length}
            >
              <InputLabel>{tGeneral("comment")}</InputLabel>
              <OutlinedInput
                fullWidth
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                label={tGeneral("comment")}
                multiline
                maxRows={4}
                rows={4}
              />
            </FormControl>
          )}
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalAnnotationLinkUser };
