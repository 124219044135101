import { Box } from "@mui/material";
import TaskBoardColumn from "./TaskBoardColumn";
import { useTranslation } from "react-i18next";
import { DragDropContext } from "react-beautiful-dnd";
import { ModalWithAction } from "../../shared";
import { useEffect, useState } from "react";
import { useSetDoingTaskMutation, useSetDoneTaskMutation } from "../../../features/project/modules/tasks/tasksApiSlice";
import { PopUpAlert } from "../../PopUpAlert";

function TaskBoard({
  tasks = [],
  users = [],
  locations = [],
  height = "85vh",
}) {
  // Translation
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");
  // TODO: Change this filtering logic for three diff API calls
  const backlogTasks = tasks.filter((task) => task.status === "backlog");
  const doingTasks = tasks.filter((task) => task.status === "doing");
  const doneTasks = tasks.filter((task) => task.status === "done");

  const [closingTask, setClosingTask] = useState(null);

  const [setDoingTask, { isError: isDoingError }] = useSetDoingTaskMutation();
  const [setDoneTask, { isError: isDoneError }] = useSetDoneTaskMutation();

  useEffect(() => {
    if (isDoingError || isDoneError) {
      PopUpAlert(
        "error",
        "Error",
        tTasks("generalErrorMessage"),
      );
    }
  }, [isDoingError, isDoneError]);

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // Check for feature
    } else {
      switch (source.droppableId) {
        case "backlog":
          if (destination.droppableId === "doing") {
            const itemBacklog = backlogTasks[source.index];
            setDoingTask({ id: itemBacklog?.id });
          }
          break;
        case "doing":
          if (destination.droppableId === "done") {
            const itemDoing = doingTasks[source.index];
            setClosingTask(itemDoing?.id);
          }
          break;
          // Check for feature
        // case "done":
          
        //   break;
        default:
          break;
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
        paddingX: 1,
        paddingY: 1,
        height,
      }}
    >
      {/* Backlog column */}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <TaskBoardColumn
          title={tTasks("backlog")}
          tasks={backlogTasks}
          users={users}
          locations={locations}
          droppableId="backlog"
        />
        {/* Doing column */}
        <TaskBoardColumn
          title={tTasks("doing")}
          tasks={doingTasks}
          users={users}
          locations={locations}
          borders
          droppableId="doing"
        />
        {/* Done column */}
        <TaskBoardColumn
          title={tTasks("done")}
          users={users}
          tasks={doneTasks}
          locations={locations}
          droppableId="done"
        />
      </DragDropContext>
      <ModalWithAction
        actionLabel={tGeneral("accept")}
        action={() => {
          setDoneTask({ id: closingTask });
          setClosingTask(null);
        }}
        isOpen={Boolean(closingTask)}
        onClose={() => setClosingTask(null)}
        title={tTasks("closeTask")}
        content={tTasks("closeTaskMessage")}
      />
    </Box>
  );
}

export default TaskBoard;
