import axios from "axios";

import Swal from "sweetalert2";

export default async function pdf({ data, token, url, download }) {
  Swal.showLoading();
  try {
    const response = await axios.request({
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      responseType: "blob",
      url,
    });
    const file = new Blob([response.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(file);
    link.download = download;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  Swal.close();
}
