// @ts-check
/* eslint react/prop-types: 0 */

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar, AvatarGroup, Box, Button, Checkbox, ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Assignees(props) {
  const { assignees, options, setAssignees } = props;

  const ref = useRef(null);

  const [open, setOpen] = useState(false);

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const handleChange = (o) => {
    setAssignees((assignees) => {
      return assignees.find((assignee) => assignee.id === o.id)
        ? assignees.filter((assignee) => assignee.id !== o.id)
        : [...assignees, o];
    });
  };

  return (
    <>
      <Button
        color="secondary"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        onClick={() => {
          setOpen((open) => !open);
        }}
        ref={ref}
        sx={{
          minWidth: "0",
          textTransform: "none",
        }}
        variant="text"
      >
        <AvatarGroup max={3}>
          {assignees.length > 0 ? (
            assignees.map((assignee) => {
              return (
                <Avatar alt={assignee.name} src={assignee.urlAvatar}>
                  {assignee.initials}
                </Avatar>
              );
            })
          ) : (
            <Avatar alt="N/A">N/A</Avatar>
          )}
        </AvatarGroup>
      </Button>
      <Popper
        anchorEl={ref.current}
        open={open}
        placement="bottom-start"
        role={undefined}
        sx={{
          margin: "5px 0 0 10px",
          zIndex: 1401,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left top",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => {
                  // @ts-ignore
                  if (ref.current && ref.current.contains(event.target)) {
                    return;
                  }
                  setOpen(false);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "20px",
                    width: "250px",
                  }}
                >
                  <strong>
                    <small>{tProjectSchedule("Select Assignees")}</small>
                  </strong>
                  {options.map((option) => {
                    const o = {
                      id: option.user.id,
                      backgroundColor: "#fbcb04",
                      initials: `${option.user.firstName[0]}${option.user.lastName[0]}`,
                      firstName: option.user.firstName,
                      lastName: option.user.lastName,
                      name: `${option.user.firstName} ${option.user.lastName}`,
                      email: option.user.email,
                      urlAvatar: option.user.urlAvatar,
                    };
                    return (
                      <Box
                        key={o.id}
                        sx={{
                          alignItems: "center",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <Checkbox
                          checked={assignees.find((assignee) => assignee.id === o.id)}
                          onClick={() => handleChange(o)}
                          sx={{
                            padding: "0",
                          }}
                        />
                        <Avatar
                          key={o.name}
                          onClick={() => handleChange(o)}
                          src={o.src}
                          sx={{
                            backgroundColor: o.backgroundColor,
                            fontSize: 10,
                            height: 20,
                            width: 20,
                          }}
                        >
                          {o.initials}
                        </Avatar>
                        <Box
                          onClick={() => handleChange(o)}
                          sx={{
                            userSelect: "none",
                          }}
                        >
                          <small>{o.name}</small>
                        </Box>
                      </Box>
                    );
                  })}
                  <Button
                    color="primary"
                    onClick={() => {
                      setOpen(false);
                    }}
                    sx={{
                      textTransform: "none",
                    }}
                    variant="contained"
                  >
                    {tProjectSchedule("Close")}
                  </Button>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
