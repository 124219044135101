import { tour } from "./tour";

export const dailyReports = {
  ...tour,
  moduleElement: "보고서",
  dailyReports: "일일 보고서",
  reports: "보고서",
  emptyReportsTitle: "현재 생성된 보고서가 없습니다",
  emptyReportsContent:
    "추가하려면 보고서 추가 버튼을 클릭하여 프로세스를 시작하십시오",
  emptyInactiveReportsTitle: "현재 재활용통에 보고서가 없습니다",
  emptyInactiveReportsContent: "보고서를 삭제하면 여기에 표시됩니다",
  addReport: "보고서 추가",
  addDailyReport: "일일 보고서 추가",
  editReport: "보고서 편집",
  dueDate: "마감일",
  newReport: "새 보고서",
  creators: "작성자",
  assigned: "담당자",
  distributionList: "배포 목록",
  private: "비공개",
  num: "번호",
  title: "제목",
  status: "상태",
  closedDate: "마감일",
  actions: "작업",
  detail: "상세",
  exportPdf: "PDF 내보내기",
  exportCvs: "CSV 내보내기",
  fetchReportIssue: "이 보고서를 로드하는 중에 문제가 발생했습니다",
  retry: "재시도",
  createdDate: "작성일",
  atHour: "시",
  lastSeen: "최근 확인",
  lastEdit: "최근 편집",
  approveReport: "보고서 승인",
  dailyReportsRecycleBin: "휴지통 (보고서)",
  confirmDelete:
    "선택한 일일 보고서를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다",
  successRecovered: "일일 보고서가 성공적으로 복구되었습니다",
  errorRecovered: "일일 보고서를 복구할 수 없습니다",
  successDeleted: "일일 보고서가 성공적으로 삭제되었습니다",
  errorDeleted: "일일 보고서를 삭제할 수 없습니다",
  approvedReport: "보고서가 성공적으로 승인되었습니다",
  errorApprovedReport: "보고서를 승인할 수 없습니다",
  deleteReport: "보고서 삭제",
  report: "보고서",
  addDescription: "설명 추가",
  // other
  obligatory: "의무적",
  public: "공개",
  congratulations: "축하합니다!",
  rfi_title_module: "RFI",
  no_rfis_title: "현재 RFI가 생성되지 않았습니다",
  no_rfis_text: "추가하려면 RFI 추가 버튼을 클릭하여 프로세스를 시작하십시오",
  meeting: "회의",
  workflow: "워크플로우",
  guideMe: "가이드",
  support: "지원",
  days: "일",
  rfi_recycle_bin: "RFI 휴지통",
  // add steps
  step1_addDR_title: "1. 보고서 추가",
  step1_addDR_text: "여기를 클릭하여 새 보고서를 추가하십시오",
  step2_addDR_title: "2. 제목",
  step2_addDR_text: "새 보고서를 설명할 수 있는 제목을 작성하십시오",
  step3_addDR_title: "3. 마감일",
  step3_addDR_text: "담당자의 승인을 받기 위한 마감일을 선택하십시오",
  step4_addDR_title: "4. 상태",
  step4_addDR_text1_1: "상태를 선택하십시오",
  step4_addDR_text1_2: "활성 보고서로 게시하려면 'OPEN'을 선택하십시오",
  step4_addDR_text2_1: "상태를 선택하십시오",
  step4_addDR_text2_2:
    "보고서를 게시하지 않고 초안으로 유지하려면 'DRAFT'를 선택하십시오",
  step5_addDR_title: "5. 개인 정보",
  step5_addDR_text_1: "보고서의 개인 정보 유형을 선택하십시오",
  step5_addDR_text_2:
    "모듈에 액세스할 수 있는 모든 사용자가 보고서를 볼 수 있습니다",
  step5_addDR_text_3: "참가자만이 이 보고서를 볼 수 있습니다",
  step6_addDR_title: "6. 작성자",
  step6_addDR_text: "본인과 동일한 권한을 가진 사용자를 선택하십시오",
  step7_addDR_title: "7. 담당자",
  step7_addDR_text: "보고서를 승인할 사람을 선택하십시오",
  step8_addDR_title: "8. 배포 목록",
  step8_addDR_text: "이 보고서에 대해 알림을 받을 사람을 선택하십시오",
  step9_addDR_title: "9. 카테고리",
  step9_addDR_text1_1: "카테고리를 선택하십시오",
  step9_addDR_text1_2: "여러 보고서를 생성하려면 '기본'을 선택하십시오",
  step9_addDR_text2:
    "진행 상황 및 비용 측정이 포함된 보고서를 생성하려면 '작업 진행'을 선택하십시오",
  step9_addDR_text3:
    "문제만을 다루는 보고서를 생성하려면 '문제'를 선택하십시오",
  step9_addDR_text4:
    "지연 시간 및 시간을 포함한 보고서를 생성하려면 '지연'을 선택하십시오",
  step10_addDR_title: "10. 새 보고서 추가",
  step10_addDR_text: "여기를 클릭하여 새 보고서를 시작하십시오",
  step11_addDR_title: "11. 위치 선택",
  step11_addDR_text:
    "보고서에 보고할 위치를 선택하거나 추가하려면 여기를 클릭하십시오",
  step12_addDR_title: "12. 설명",
  step12_addDR_text: "작업 진행에 대한 설명을 작성하십시오",
  step13_addDR_title: "13. 사진 또는 파일 첨부",
  step13_addDR_text: "설명에 사진 또는 파일을 첨부하십시오",
  step14_addDR_title: "14. 추가",
  step14_addDR_text: "보고서를 게시하려면 여기를 클릭하십시오",
  stepFinal_addDR: "보고서 생성 방법을 배웠습니다",
  // VIEW - guide me steps
  step1_viewDR_title: "1. 보고서 보기",
  step1_viewDR_text: "여기를 클릭하여 보고서를 시각화하십시오",
  step2_viewDR_title: "2. 카테고리",
  step2_viewDR_text: "보고서가 속한 카테고리를 확인하십시오",
  step3_viewDR_title: "3. 일반 정보 보고서",
  step3_viewDR_text: "이 보고서의 전체 정보를 표시합니다",
  step4_viewDR_title: "{{stepNumber}}. 위치별 설명 보고서",
  step4_viewDR_text: "이 보고서와 사진의 전체 정보를 표시합니다",
  step5_viewDR_title: "{{stepNumber}}. 댓글",
  step5_viewDR_text: "보고된 위치에 댓글을 작성할 수 있습니다",
  step7_viewDR_title: "{{stepNumber}}. 승인",
  step7_viewDR_text: "보고서를 승인합니다",
  stepFinal_viewDR: "보고서 보는 방법을 배웠습니다",
  step_view_reports_description_title: "{{stepNumber}}. 설명",
  step_view_reports_description_text: "보고서의 전체 설명을 확인합니다",
  step_view_report_progress_title: "{{stepNumber}}. 지연 및 비용",
  step_view_report_progress_text: "보고서의 진행 상황과 비용 측정을 확인합니다",
  // EDIT - guide me steps
  step1_editDR_title: "1. 보고서 편집",
  step1_editDR_text: "여기를 클릭하여 보고서를 편집하십시오",
  step9_editDR_title: "{{stepNumber}}. 새 보고서 추가",
  step9_editDR_text: "여기를 클릭하여 새 보고서를 시작하십시오",
  step10_editDR_title: "{{stepNumber}}. 생성된 보고서 편집",
  step10_editDR_text: "보고서에 대한 정보를 확인하고 편집할 수 있습니다",
  step11_editDR_title: "{{stepNumber}}. 파일 및 정보",
  step11_editDR_text: "생성된 보고서에 파일을 편집하거나 추가할 수 있습니다",
  step13_editDR_title: "{{stepNumber}}. 저장",
  step13_editDR_text: "수정된 보고서를 저장하려면 여기를 클릭하십시오",
  stepFinal_editDR: "보고서 편집 방법을 배웠습니다",
  step11_edit_report_progress_title: "{{stepNumber}}. 진행 및 비용",
  step11_edit_report_progress_text:
    "보고서의 진행 상황과 비용을 편집하거나 추가할 수 있습니다",
  step10_edit_report_problem: "보고서 설명 및 파일 수정",
  step9_edit_report_delay_title: "9. 지연",
  step9_edit_report_delay_text: "보고서의 지연 일수 또는 시간을 편집하십시오",
  step10_edit_report_delay: "보고서 설명 및 파일 수정",
  // RECYCLE - guide me steps
  step1_recycleDR_title: "1. 보고서 휴지통",
  step1_recycleDR_text: "여기를 클릭하여 휴지통을 엽니다",
  step2_recycleDR_title: "2. 휴지통 메뉴",
  step2_recycleDR_text: "휴지통의 메뉴입니다",
  step3_recycleDR_title: "3. 보고서 보기",
  step3_recycleDR_text: "전체 보고서를 확인할 수 있습니다",
  step4_recycleDR_title: "4. 보고서 복원",
  step4_recycleDR_text: "복원할 보고서를 선택하십시오",
  step5_recycleDR_title: "5. 선택한 보고서 복원",
  step5_recycleDR_text: "선택한 보고서를 복원하려면 여기를 클릭하십시오",
  stepFinal_recycleDR: "휴지통과 상호 작용하는 방법을 배웠습니다",
  // EXPORT - guide me steps
  step1_exportDR_title: "1. PDF 내보내기",
  step1_exportDR_text: "보고서를 PDF 형식으로 내보내려면 여기를 클릭하십시오",
  step2_exportDR_title: "2. 이메일 보내기",
  step2_exportDR_text: "이 보고서를 이메일로 공유하려면 여기를 클릭하십시오",
  step3_exportDR_title: "3. CSV 내보내기",
  step3_exportDR_text:
    "일부 또는 모든 보고서를 CSV 파일로 내보내려면 선택하거나 필터링하십시오",
  step4_exportDR_title: "4. PDF 내보내기",
  step4_exportDR_text:
    "일부 또는 모든 보고서를 PDF 파일로 내보내려면 선택하거나 필터링하십시오",
  step5_exportDR_title: "5. 보고서 내부 화면",
  step5_exportDR_text: "상세 보기에서 PDF로 내보낼 수 있습니다",
  stepFinal_exportDR: "보고서 내보내기 방법을 배웠습니다",
  // SETTINGS - guide me steps
  step1_settingsDR_title: "2. 보고서 설정",
  step1_settingsDR_text: "여기를 클릭하여 설정을 수정하십시오",
  step2_settingsDR_title: "3. 배포 목록",
  step2_settingsDR_text: "전역적으로 속하는 배포 목록의 사용자를 수정하십시오",
  step3_settingsDR_title: "4. 설정 저장",
  step3_settingsDR_text: "설정을 저장하려면 여기를 클릭하십시오",
  stepFinal_settingsDR: "보고서 설정과 상호 작용하는 방법을 배웠습니다",
  due: "마감일",
  closed: "마감",
  basic: "기본",
  workProgress: "작업 진행",
  problem: "문제",
  delay: "지연",
  advance: "진행",
  unit: "단위",
  addUnit: "단위 추가",
  unitAdded: "단위가 성공적으로 추가되었습니다",
  risk: "위험",
  highRisk: "높은 위험",
  mediumRisk: "중간 위험",
  lowRisk: "낮은 위험",
  workerName: "작업자 이름",
  jobType: "작업 유형",
  workedHours: "작업 시간",
  assistants: "보조",
  manpower: "인력",
  subcontractorMessage:
    "오늘 {{date}}에 총 {{total}}명이 참석했습니다. 확인해 주세요.",
  job: "작업",
};
