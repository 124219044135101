const status = {
  open: "Open",
  closed: "Closed",
  close: "Closed",
  in_revision: "In progress",
  draft: "Draft",
  pending: "Pending",
  doing: "Open",
  backlog: "Open",
  rejected: "Rejected",
  done: "Done",
  inactive: "Inactive",
};

export { status };
