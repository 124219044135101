import {
  ArrowBack,
  Lock,
  LockOpen,
  Menu as MenuIcon,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectCurrentGuideMe } from "../../features/project/projectSlice";
import { statusStyles } from "../../utils";
import { useAppTourContext } from "../supportButton/context/context";
import { TooltipIconButton } from "./TooltipIconButton";

function DetailsHeader({
  title,
  number,
  status = "open",
  bPrivate,
  showMainAction = false,
  mainAction,
  mainActionDataTour,
  actions,
  onBack,
  backIcon,
  showMore = true,
  customElements = [],
  titleBreakLine = false,
  secondaryAction = null,
  showSecondayAction = false,
  categoryObject = null,
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tStatus } = useTranslation("status");
  const [anchorEl, setAnchorEl] = useState(null);
  const moreRef = useRef();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (!tourActive || !steps || !moreRef.current) return;

    if (
      (stepIndex === steps.length - 1 || stepIndex <= 5) &&
      currentGuideMe === "rfi-view"
    ) {
      setAnchorEl(null);
      return;
    }
    if (
      (stepIndex === 6 || stepIndex === steps.length - 2) &&
      currentGuideMe === "rfi-view"
    ) {
      setAnchorEl(moreRef.current);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, steps, currentGuideMe, moreRef]);

  useEffect(() => {
    if (!tourActive || !steps || !moreRef.current) return;

    if (
      ((stepIndex === steps.length - 1 || stepIndex < 6) &&
      (currentGuideMe === "daily-reports-view"||
      currentGuideMe === "reports-view-work-progress" ||
      currentGuideMe === "reports-view-problem")) ||
      ((stepIndex === steps.length - 1 || stepIndex < 5) && currentGuideMe === "reports-view-delay")
    ) {
      setAnchorEl(null);
      return;
    }

    if ((stepIndex === 6 && (currentGuideMe === "daily-reports-view" || currentGuideMe === "reports-view-problem")) ||
      (stepIndex === 7 && currentGuideMe === "reports-view-work-progress") ||
        (stepIndex === 5 && currentGuideMe === "reports-view-delay")) {
        setAnchorEl(moreRef.current);
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
  }, [tourActive, stepIndex, steps, currentGuideMe, moreRef]);

  return (
    <Box borderBottom="1px solid #FBCB04" width="100%">
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="60px"
        minHeight="60px"
        maxHeight="60px"
      >
        <Box flex={1} display="flex" alignItems="center" columnGap="10px">
          <IconButton title="Back" onClick={onBack}>
            {backIcon ?? <ArrowBack />}
          </IconButton>
          <Chip
            label={`#${number}`}
            sx={{
              color: "white",
              fontWeight: "medium",
              backgroundColor: "#FFC42D",
              fontSize: "1rem",
              width: "auto",
            }}
          />
          {categoryObject &&
            <Chip
              label={categoryObject.label}
              sx={{
                color: "black",
                fontWeight: "bold",
                backgroundColor: categoryObject.color,
                fontSize: "1rem",
                width: "auto",
              }}
              data-tour="category"
            />
          }
        </Box>
        {!titleBreakLine && <Typography variant="h5">{title}</Typography>}
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          columnGap="10px"
        >
          {customElements?.map((el) => el)}
          <Box display="flex" alignItems="center" columnGap="5px">
            {bPrivate ? (
              <Lock fontSize="small" />
            ) : (
              <LockOpen fontSize="small" />
            )}
            <Typography>
              {bPrivate ? tGeneral("private") : tGeneral("public")}
            </Typography>
          </Box>
          <Chip
            label={tStatus(status)}
            sx={[
              {
                color: "white",
                fontWeight: "medium",
                fontSize: "1rem",
                width: "120px",
              },
              statusStyles[status],
            ]}
          />
          {mainAction && showMainAction && (
            <TooltipIconButton
              icon={mainAction.icon}
              label={mainAction.label}
              onClick={mainAction.onClick}
              isLoading={mainAction.isLoading}
              disable={mainAction.isLoading}
              color="#FBCB04"
              data-tour={mainActionDataTour}
            />
          )}
          {showSecondayAction && secondaryAction}
          {showMore && (
            <TooltipIconButton
              icon={<MenuIcon />}
              label={tGeneral("more")}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
              ref={moreRef}
              color="#FBCB04"
              data-tour="more"
            />
          )}
          {showMore && (
            <Menu
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
            >
              {actions?.map(
                ({ icon, label, onClick, dataTour = "", disabled = false }) => (
                  <MenuItem
                    disabled={disabled}
                    onClick={onClick}
                    data-tour={dataTour}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{label}</ListItemText>
                  </MenuItem>
                ),
              )}
            </Menu>
          )}
        </Box>
      </Box>
      {titleBreakLine && (
        <Typography textAlign="center" variant="h5">
          {title}
        </Typography>
      )}
    </Box>
  );
}

export { DetailsHeader };
