import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetProjectTypesQuery } from "../../../features/project/projectApiSlice";
import moment from "moment";
import { useMemo, useRef } from "react";
import { FilePreviewHorizontalScroll, TooltipIconButton } from "../../shared";
import { Close } from "@mui/icons-material";
import {
  useGetCitiesQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
} from "../../../features/geolocation";

function EditImage({ name, height }) {
  const { t: tGeneral } = useTranslation("general");
  const { watch, setValue } = useFormContext();
  const inputRef = useRef();

  const imageFile = watch(name);

  const imageSrc = useMemo(() => {
    if (imageFile) {
      return URL.createObjectURL(imageFile);
    }
    return null;
  }, [imageFile]);

  const handleImageChange = (files) => {
    setValue(name, files[0]);
  };

  return imageFile ? (
    <Box position="relative">
      <img src={imageSrc} style={{ maxHeight: height, height }} alt={name} />
      <TooltipIconButton
        icon={<Close />}
        label={tGeneral("delete")}
        onClick={() => setValue(name, null)}
        sx={{ position: "absolute", top: 0, right: 0, color: "red" }}
      />
    </Box>
  ) : (
    <Box sx={{ height, maxHeight: height }}>
      <FilePreviewHorizontalScroll
        onAddPhotos={handleImageChange}
        files={imageFile ? [imageFile] : []}
        accept="image/*"
        multiple={false}
        height="100%"
      />
    </Box>
  );
}

function BasicInfo() {
  const { idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { register, watch, control } = useFormContext();

  const { data: projectTypes } = useGetProjectTypesQuery(idEnterprise);

  const [country, state] = watch(["country", "state"]);

  const { data: countries } = useGetCountriesQuery({});
  const countryCode = useMemo(() => {
    if (country && countries) {
      return countries.find((c) => c.name === country)?.iso2;
    }
    return null;
  }, [country, countries]);
  const { data: states } = useGetStatesQuery(countryCode, {
    skip: !countryCode,
  });

  const stateCode = useMemo(() => {
    if (state && states) {
      return states.find((s) => s.name === state)?.iso2;
    }
    return null;
  }, [state, states]);
  const { data: cities } = useGetCitiesQuery(
    { country: countryCode, state: stateCode },
    {
      skip: !state,
    },
  );

  const timezones = moment.tz.names();

  const formattedTimezones = timezones
    .map((timezone) => {
      const offset = moment.tz(timezone).format("Z");
      const slashIndex = timezone.indexOf("/");
      const location =
        slashIndex !== -1 ? timezone.slice(slashIndex + 1) : timezone;
      const formattedValue = `(GMT${offset}) ${location.replace(/_/g, " ")}`;
      return { timezone, offset, formattedValue };
    })
    .sort((a, b) => {
      if (a.offset !== b.offset) {
        return parseFloat(a.offset) - parseFloat(b.offset); // Sort by GMT offset
      } else {
        return a.timezone.localeCompare(b.timezone); // Sort alphabetically if offset is equal
      }
    });

  return (
    <Box display="flex" flexDirection="column" rowGap="0.5rem">
      <TextField {...register("title")} label={tGeneral("title")} fullWidth />
      <Box display="flex" columnGap="0.5rem">
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>{tGeneral("projectType")}</InputLabel>
              <Select {...field} label={tGeneral("projectType")}>
                {projectTypes?.map((type) => (
                  <MenuItem key={type[0].id} value={type[0].name}>
                    {type[0].name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="timeZone"
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>{tGeneral("timezone")}</InputLabel>
              <Select
                {...field}
                MenuProps={{ sx: { maxHeight: "300px" } }}
                label={tGeneral("timezone")}
              >
                {formattedTimezones?.map((timezone) => (
                  <MenuItem key={timezone.timezone} value={timezone.timezone}>
                    {timezone.timezone}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Box>
      <Accordion>
        <AccordionSummary>Info</AccordionSummary>
        <AccordionDetails>
          <Box display="flex">
            <Box display="flex" flexDirection="column" flex={1}>
              <EditImage name="logo" height="300px" />
              <EditImage name="cover" height="100px" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              flex={2}
              sx={{ "& > div": { columnGap: "0.5rem", width: "100%" } }}
            >
              <Box display="flex">
                <TextField
                  size="small"
                  fullWidth
                  {...register("street")}
                  label={tGeneral("street")}
                />
                <TextField
                  size="small"
                  fullWidth
                  {...register("number")}
                  label={tGeneral("number")}
                />
              </Box>
              <Box display="flex">
                <Controller
                  control={control}
                  name="country"
                  render={({ field }) => (
                    <FormControl size="small" fullWidth>
                      <InputLabel>{tGeneral("country")}</InputLabel>
                      <Select
                        label={tGeneral("country")}
                        {...field}
                        MenuProps={{ sx: { maxHeight: "300px" } }}
                      >
                        {countries?.map((country) => (
                          <MenuItem value={country.name} key={country.id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="state"
                  render={({ field }) => (
                    <FormControl size="small" fullWidth disabled={!states}>
                      <InputLabel>{tGeneral("state")}</InputLabel>
                      <Select
                        label={tGeneral("state")}
                        {...field}
                        MenuProps={{ sx: { maxHeight: "300px" } }}
                      >
                        {states?.map((state) => (
                          <MenuItem value={state.name} key={state.id}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
              <Box display="flex">
                <Controller
                  control={control}
                  name="city"
                  render={({ field }) => (
                    <FormControl size="small" fullWidth disabled={!cities}>
                      <InputLabel>{tGeneral("city")}</InputLabel>
                      <Select
                        label={tGeneral("city")}
                        {...field}
                        MenuProps={{ sx: { maxHeight: "300px" } }}
                      >
                        {cities?.map((city) => (
                          <MenuItem value={city.name} key={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <TextField
                  size="small"
                  fullWidth
                  {...register("zipCode")}
                  label={tGeneral("zipCode")}
                />
              </Box>
              <Box display="flex">
                <TextField
                  size="small"
                  fullWidth
                  {...register("constructionDirector")}
                  label={tGeneral("constructionDirector")}
                />
                <TextField
                  size="small"
                  fullWidth
                  {...register("projectArchitect")}
                  label={tGeneral("architect")}
                />
              </Box>
              <Box display="flex">
                <TextField
                  size="small"
                  fullWidth
                  {...register("projectManager")}
                  label={tGeneral("projectManager")}
                />
                <TextField
                  size="small"
                  fullWidth
                  {...register("projectEngineer")}
                  label={tGeneral("projectEngineer")}
                />
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export { BasicInfo };
