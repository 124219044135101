import { datadogRum } from "@datadog/browser-rum";
import React, { useState } from "react";
import { matchPath, useLocation, useNavigate, useParams } from "react-router";
// Redux reducers & selectors
import { useDispatch, useSelector } from "react-redux";
// Material UI Components
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import BookRoundedIcon from "@mui/icons-material/BookRounded"; // Directory
import CachedRoundedIcon from "@mui/icons-material/CachedRounded"; // RFIs
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded"; // Project
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded"; // Daily Reports
import FolderRoundedIcon from "@mui/icons-material/FolderRounded"; // Documents
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded"; // Dashboard
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded"; // Meetings
import HomeRoundedIcon from "@mui/icons-material/HomeRounded"; // Home
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded"; // Photos
import LibraryAddCheckRoundedIcon from "@mui/icons-material/LibraryAddCheckRounded"; // Inspections
import NoteRoundedIcon from "@mui/icons-material/NoteRounded"; // Drawings
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PollRoundedIcon from "@mui/icons-material/PollRounded"; // ProjectSchedule
import StickyNote2RoundedIcon from "@mui/icons-material/StickyNote2Rounded"; // Tasks
import ViewModule from "@mui/icons-material/ViewModule";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { useTranslation } from "react-i18next";

import {
  AssignmentTurnedInRounded,
  Hub,
  Logout,
  Notifications,
  Search,
} from "@mui/icons-material";
import { Badge, ButtonGroup, Typography } from "@mui/material";
import Bim from "../../assets/icons/modules/bim.png";
import BuildPeerLogo from "../../assets/logo.png";
import {
  resetEnterprises,
  selectCurrentEnterprise,
} from "../../features/enterprise/enterpriseSlice";
import { resetPreferences } from "../../features/preferences/preferencesSlice";
import { useGetProjectByIdQuery } from "../../features/project/projectApiSlice";
import { resetProjects } from "../../features/project/projectSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
import DrawerAvatar from "../userAvatars/DrawerAvatar";

import {
  ImgInline,
  ModalWithAction,
  NotificationsDrawer,
  TooltipIconButton,
} from "../shared";

import { useLogoutMutation } from "../../features/auth/authApiSlice";
import { setAccessToken } from "../../features/auth/authSlice";
import { useGetUnreadNotificacionsCounterQuery } from "../../features/notificationCenter/notificationCenterApiSlice";
import Can from "../../utils/can";
import ModalSearchProjectOverview from "../ModalSearchProjectOverview";

function DrawerProject(props) {
  const { t: tGeneral } = useTranslation("general");
  const { idProject, idEnterprise } = useParams();
  const [modulesOpen, setModulesOpen] = useState(false);
  const [modulesSettings, setModulesSettings] = useState(false);
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const [logout] = useLogoutMutation();
  const { data: project } = useGetProjectByIdQuery(idProject);
  const { data: notificationsData } = useGetUnreadNotificacionsCounterQuery(
    {
      idEnterprise,
      status: "unread",
    },
    { skip: !idEnterprise },
  );
  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Get current user
  const user = useSelector(selectCurrentUser);

  // Get current enterprise
  const enterprise = useSelector(selectCurrentEnterprise);

  // Open & close enterprise collapsable item
  const toggleModules = () => {
    setModulesOpen(!modulesOpen);
  };
  // Open & close enterprise collapsable item
  const toggleModulesSettings = () => {
    setModulesSettings(!modulesSettings);
  };

  const moduleInfo = [
    {
      name: "drawings",
      pathName: "drawings",
      icon: <NoteRoundedIcon />,
    },
    // {
    //   name: "Punch List",
    //   pathName: "punchList",
    //   icon: <ThumbUpRoundedIcon />,
    // },
    {
      name: "directory",
      pathName: "directory",
      icon: <BookRoundedIcon />,
    },
    {
      name: "rfis",
      pathName: "rfi",
      icon: <CachedRoundedIcon />,
    },
    {
      name: "submittals",
      pathName: "submittal",
      icon: <AssignmentTurnedInRounded />,
    },
    // {
    //   name: "Deliveries",
    //   pathName: "deliveries",
    //   icon: <EventNoteRoundedIcon />,
    // },
    // {
    //   name: "hours",
    //   pathName: "hours",
    //   icon: <WatchLaterRoundedIcon />,
    // },
    {
      name: "meetings",
      pathName: "meetings",
      icon: <GroupsRoundedIcon />,
    },
    {
      name: "photos",
      pathName: "photos",
      icon: <InsertPhotoRoundedIcon />,
    },
    {
      name: "tasks",
      pathName: "tasks",
      icon: <StickyNote2RoundedIcon />,
    },
    {
      name: "inspections",
      pathName: "inspections",
      icon: <LibraryAddCheckRoundedIcon />,
    },
    // {
    //   name: "Issues",
    //   pathName: "issues",
    //   icon: <WarningRoundedIcon />,
    // },
    {
      name: "dailyReports",
      pathName: "dailyReports",
      icon: <FlagRoundedIcon />,
    },
    {
      name: "documents",
      pathName: "documents",
      icon: <FolderRoundedIcon />,
    },
    // {
    //   name: "Delays",
    //   pathName: "delays",
    //   icon: <AssignmentLateRoundedIcon />,
    // },
    // {
    //   name: "KPIs",
    //   pathName: "kpi",
    //   icon: <AssessmentRoundedIcon />,
    // },
    {
      name: "bim",
      pathName: "bim",
      icon: <img src={Bim} alt="bim" style={{ opacity: 0.7 }} />,
    },
  ];

  const baseQuery = `./project/${idProject}`;

  const handleLogout = async () => {
    try {
      dispatch(setAccessToken(null));
      dispatch(resetEnterprises());
      dispatch(resetProjects());
      dispatch(resetPreferences());
      await logout().unwrap();
      navigate("/login", { replace: true });
    } catch (err) {
      console.error(err);
    }
  };

  const drawerContent = (
    <Box display="flex" flexDirection="column" height="100vh" maxHeight="100vh">
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt="10px"
      >
        <ImgInline
          src={project?.logo ?? BuildPeerLogo}
          alt="Project logo"
          svgStyle={{ width: "50px", maxHeight: "50px" }}
          imgStyle={{ maxWidth: "100%", maxHeight: "50px" }}
        />
      </Box>
      <Typography textAlign="center" mb="10px" fontWeight="bold">
        {project?.projectName}
      </Typography>
      <List disablePadding>
        {/* Home */}
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("./dashboard");
              props.toggle();
            }}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgba(251, 203, 4, 0.5)",
              },
            }}
          >
            <ListItemIcon>
              <HomeRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={tGeneral("home")} />
          </ListItemButton>
        </ListItem>
        {/* Dashboard */}
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname.match("dashboard")}
            onClick={() => {
              navigate(`${baseQuery}/dashboard`, { replace: true });
              props.toggle();
            }}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgba(251, 203, 4, 0.5)",
              },
            }}
          >
            <ListItemIcon>
              <GridViewRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={tGeneral("dashboard")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <Can I="view" a="projectSchedule">
            <ListItemButton
              selected={location.pathname.match("project-schedule")}
              onClick={() => {
                navigate(`${baseQuery}/project-schedule`, { replace: true });
                props.toggle();
              }}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "rgba(251, 203, 4, 0.5)",
                },
              }}
            >
              <ListItemIcon>
                <PollRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={tGeneral("projectSchedule")} />
            </ListItemButton>
          </Can>
        </ListItem>
        <ListItem disablePadding>
          <Can I="view" a="analytics">
            <ListItemButton
              selected={location.pathname.match("analytics")}
              onClick={() => {
                navigate(`${baseQuery}/analytics`, { replace: true });
                props.toggle();
              }}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "rgba(251, 203, 4, 0.5)",
                },
              }}
            >
              <ListItemIcon>
                <ShowChartIcon />
              </ListItemIcon>
              <ListItemText primary={tGeneral("analytics")} />
            </ListItemButton>
          </Can>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setIsSearchOpen(true)}>
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText primary={tGeneral("search")} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List sx={{ flex: 1, overflowY: "auto" }} disablePadding>
        {/* Project Modules */}
        <ListItem disablePadding>
          <ListItemButton onClick={toggleModules}>
            <ListItemIcon>
              <ConstructionRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={tGeneral("modules")} />
            {modulesOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>

        <Collapse in={modulesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {moduleInfo
              ?.sort((a, b) => {
                const moduleA = tGeneral(a.name).toUpperCase();
                const moduleB = tGeneral(b.name).toUpperCase();

                if (moduleA < moduleB) {
                  return -1;
                }
                if (moduleA > moduleB) {
                  return 1;
                }
                return 0;
              })
              .map(({ pathName, icon, name }) => {
                return (
                  <Can I="view" a={name} key={pathName}>
                    <ListItemButton
                      selected={location.pathname.match(pathName)}
                      onClick={() => {
                        datadogRum.addAction("view", {
                          app: "buildpeer-web",
                          enterprise: enterprise.id,
                          project: idProject,
                          module: name,
                        });
                        navigate(`${baseQuery}/${pathName}`);
                        props.toggle();
                      }}
                      sx={{
                        pl: 4,
                        "&.Mui-selected": {
                          backgroundColor: "rgba(251, 203, 4, 0.5)",
                        },
                      }}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText
                        primary={tGeneral(
                          name === "dailyReports" ? "reports" : name,
                        )}
                      />
                    </ListItemButton>
                  </Can>
                );
              })}
          </List>
        </Collapse>
        <ListItem disablePadding sx={{}}>
          <ListItemButton
            onClick={toggleModulesSettings}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgba(251, 203, 4, 0.5)",
              },
            }}
          >
            <ListItemIcon>
              <AdminPanelSettingsRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={tGeneral("settings")} />
            {modulesSettings ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        {/* Settings */}
        <Collapse in={modulesSettings} timeout="auto" unmountOnExit sx={{}}>
          <List component="div" disablePadding>
            {/* Admin Panel */}
            <Can I="view" a="adminPanel">
              <ListItemButton
                selected={location.pathname.match("adminPanel")}
                onClick={() => {
                  navigate(`${baseQuery}/adminPanel`);
                  props.toggle();
                }}
                sx={{
                  pl: 4,
                  "&.Mui-selected": {
                    backgroundColor: "rgba(251, 203, 4, 0.5)",
                  },
                }}
              >
                <ListItemIcon>
                  <ViewModule />
                </ListItemIcon>
                <ListItemText primary={tGeneral("adminPanel")} />
              </ListItemButton>
            </Can>

            {/* Notifications */}
            <ListItemButton
              selected={location.pathname.match("projectNotifications")}
              onClick={() => {
                navigate(`${baseQuery}/projectNotifications`);
                props.toggle();
              }}
              sx={{
                pl: 4,
                "&.Mui-selected": {
                  backgroundColor: "rgba(251, 203, 4, 0.5)",
                },
              }}
            >
              <ListItemIcon>
                <NotificationsActiveIcon />
              </ListItemIcon>
              <ListItemText primary={tGeneral("notifications")} />
            </ListItemButton>
            <Can I="superAdmin" a="projectNetwork">
              <ListItemButton
                selected={location.pathname === `${baseQuery}/projectNetwork`}
                onClick={() => {
                  navigate(`${baseQuery}/projectNetwork`);
                  props.toggle();
                }}
                sx={{
                  pl: 4,
                  "&.Mui-selected": {
                    backgroundColor: "rgba(251, 203, 4, 0.5)",
                  },
                }}
              >
                <ListItemIcon>
                  <Hub />
                </ListItemIcon>
                <ListItemText primary={tGeneral("projectNetwork")} />
              </ListItemButton>
            </Can>
          </List>
        </Collapse>

        {/* Settings */}
      </List>
      <Divider />

      {/* User Avatar */}
      <DrawerAvatar user={user} />
      <ButtonGroup sx={{ justifyContent: "space-around", mb: "10px" }}>
        <TooltipIconButton
          icon={<Logout />}
          label={tGeneral("logout")}
          color="black"
          onClick={() => setIsLogoutOpen(true)}
        />

        <TooltipIconButton
          icon={
            <Badge
              badgeContent={notificationsData?.unreadNotifications || 0}
              color="primary"
            >
              <Notifications />
            </Badge>
          }
          label={tGeneral("notifications")}
          color="black"
          onClick={() => setIsNotificationsOpen(true)}
        />
      </ButtonGroup>
      <ModalWithAction
        action={handleLogout}
        actionLabel={tGeneral("accept")}
        title={tGeneral("logout")}
        content={tGeneral("logoutMessage")}
        onClose={() => setIsLogoutOpen(false)}
        isOpen={isLogoutOpen}
      />
      <ModalSearchProjectOverview
        idProject={idProject}
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
      />
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: {
          xs: props.drawerWidth,
        },
        flexShrink: { xs: 1 },
      }}
      aria-label="drawer enterprise"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={props.open}
        onClose={props.toggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth || 240,
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth || 240,
          },
        }}
        open
      >
        {drawerContent}
      </Drawer>
      <NotificationsDrawer
        isOpen={isNotificationsOpen}
        onClose={() => setIsNotificationsOpen(false)}
      />
    </Box>
  );
}

export default DrawerProject;
