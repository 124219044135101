import { tour } from "./tour";

export const tasks = {
  // general tour
  ...tour,
  moduleElement: "タスク",
  // ADD - guide me steps
  task: "タスク",
  tasks: "タスク",
  high: "高",
  medium: "中",
  low: "低",
  taskDeletedSuccessfully: "タスクが正常に削除されました",
  taskRestoredSuccessfully: "タスクが正常に復元されました",
  noTasksCreated: "現在、タスクは作成されていません",
  noTasksContent:
    "追加する場合は、追加ボタンをクリックしてプロセスを開始してください",
  addTask: "タスクを追加",
  backlog: "バックログ",
  doing: "進行中",
  done: "完了",
  creationDate: "作成日",
  deliveryDate: "納期",
  closeDate: "クローズ日",
  category: "カテゴリー",
  priority: "優先度",
  location: "場所",
  startTask: "タスクを開始",
  closeTask: "タスクをクローズ",
  days: "日",
  tasksRecycleBin: "タスクのごみ箱",
  editTask: "タスクを編集",
  addCategory: "カテゴリーを追加",
  // ADD - guide me steps
  step1_addtask_title: "1. タスクを追加",
  step1_addtask_text: "ここをクリックして新しいタスクを追加します",
  step2_addtask_title: "2. タイトル",
  step2_addtask_text: "新しいタスクを説明できるタイトルを書いてください",
  step3_addtask_title: "3. 期限",
  step3_addtask_text: "タスクを完了する期限として期限を選択してください",
  step4_addtask_title: "4. ステータス",
  step4_addtask_text1_1: "ステータスを選択してください",
  step4_addtask_text1_2: "タスクをすぐに公開するには「OPEN」を選択します。",
  step4_addtask_text2_1: "次の",
  step4_addtask_text2_2:
    "タスクを公開せずにドラフトとして保持するには「DRAFT」ステータスを選択します。",
  step5_addtask_title: "5. プライバシー",
  step5_addtask_text:
    "ここをクリックして、誰でも見ることができるようにするか、関係者のみに見えるようにするかを選択します",
  step5_addtask_text_1: "タスクのプライバシータイプを選択してください",
  step5_addtask_text_2:
    "モジュールにアクセスできるすべての人がタスクを見ることができます",
  step5_addtask_text_3: "関係者のみがこのタスクを見ることができます",
  step6_addtask_title: "6. 担当者",
  step6_addtask_text: "タスクを完了する必要がある人々を選択します",
  step7_addtask_title: "7. 配布リスト",
  step7_addtask_text: "このタスクについて通知する人々を選択します",
  step8_addtask_title: "8. 説明",
  step8_addtask_text: "タスクの内容についての説明を書いてください",
  step9_addtask_title: "9. ファイルのアップロード",
  step9_addtask_text:
    "ここをクリックするか、画像やドキュメントなどのファイルをドラッグアンドドロップします",
  step10_addtask_title: "10. カテゴリー",
  step10_addtask_text: "タスクのカテゴリーを選択してください",
  step11_addtask_title: "11. 場所",
  step11_addtask_text: "タスクを実行するプロジェクト内の場所を選択します",
  step12_addtask_title: "12. 優先度",
  step12_addtask_text: "高、中、低の優先度から選択してください",
  step13_addtask_title: "13. 公開",
  step13_addtask_text:
    "タスクを公開するにはここをクリックします。該当する場合はドラフトとして保存します",
  stepFinal_addtask: "新しいタスクの作成方法を学びました",
  // VIEW - guide me steps
  step1_viewtask_title: "1. タスクの表示",
  step1_viewtask_text: "タスクをクリックしてその内容を表示します。",
  step2_viewtask_title: "2. 関係者",
  step2_viewtask_text: "このセクションでは、担当者と作成者を確認できます。",
  step3_viewtask_title: "3. タスクデータ",
  step3_viewtask_text:
    "このセクションでは、期限、ステータス、優先度などのタスクの主なデータを確認できます。",
  step4_viewtask_title: "4. 説明",
  step4_viewtask_text:
    "ここでは、オーナーによって作成されたタスクの説明を確認できます。",
  step6_viewtask_title: "6. 返信",
  step6_viewtask_text:
    "メインの説明に返信を書き、ファイルを添付します。異なる返信は説明の下に表示されます。",
  step7_viewtask_title: "7. コメント",
  step7_viewtask_text:
    "返信されたメッセージにコメントを残し、必要に応じてファイルを添付します。コメントは返信メッセージの下に表示されます。",
  step8_viewtask_title: "8. タスクの開始/終了",
  step8_viewtask_text:
    "タスクを開始したことをマークするにはここをクリックします。既に開始されている場合は、タスクを終了するためにクリックします。",
  stepFinal_viewtask: "タスクの表示方法を学びました",
  // EDIT - guide me steps
  step1_edittask_title: "1. タスクの編集",
  step1_edittask_text:
    "3つのドットをクリックしてさまざまなオプションを開きます: 「編集」をクリックします。",
  step2_edittask_title: "2. 情報",
  step2_edittask_text:
    "このセクションでは、タスクのタイトル、期限、ステータス、プライバシーを編集できます。",
  step3_edittask_title: "3. 説明と添付ファイル",
  step3_edittask_text:
    "このセクションでは、タスクの説明を編集し、添付ファイルを削除できます。",
  step4_edittask_title: "4. 追加情報",
  step4_edittask_text:
    "このセクションでは、カテゴリー、優先度、場所などの追加情報を編集できます。",
  step4_edittask_text1_1: "ステータスを選択してください",
  step4_edittask_text1_2:
    "タスクを公開するために「OPEN」ステータスを選択します。",
  step4_edittask_text2_1: "次の",
  step4_edittask_text2_2:
    "タスクを公開せずにドラフトとして保持するために「DRAFT」ステータスを選択します。",
  step5_edittask_title: "5. 担当者",
  step5_edittask_text: "タスクの担当者を追加または削除します",
  step5_edittask_text_1: "タスクのプライバシータイプを選択してください",
  step5_edittask_text_2:
    "モジュールにアクセスできるすべての人がタスクを見ることができます",
  step5_edittask_text_3: "関係者のみがこのタスクを見ることができます",
  step6_edittask_title: "6. 配布リスト",
  step6_edittask_text: "配布リストに人々を追加または削除します",
  step7_edittask_title: "7. 変更を保存",
  step7_edittask_text: "変更を保存してタスクを更新するにはここをクリックします",
  stepFinal_edittask: "タスクの編集方法を学びました",
  // RECYCLE - guide me steps
  step1_recycletask_title: "2. タスクのごみ箱",
  step1_recycletask_text:
    "ここにはプロジェクトから削除されたタスクが表示されます。",
  step2_recycletask_title: "3. タスクの選択",
  step2_recycletask_text: "復元するタスクを選択します。",
  step3_recycletask_title: "4. タスクの復元",
  step3_recycletask_text: "選択したタスクを復元するにはここをクリックします。",
  stepFinal_recycletask: "ごみ箱とのやり取りの方法を学びました",
  // EXPORT - guide me steps
  step1_exporttask_title:
    "3つのドットをクリックしてさまざまなオプションを開きます:",
  step1_exporttask_text1_1: "PDFにエクスポート",
  step1_exporttask_text1_2:
    "選択したタスクをPDFファイルにエクスポートするにはここをクリックします。",
  step1_exporttask_text2_1: "メールで送信",
  step1_exporttask_text2_2:
    "選択したタスクをメールで送信するにはここをクリックします。",
  stepFinal_exporttask: "タスクのエクスポート方法を学びました",
  // SETTINGS - guide me steps
  step1_settingstask_title: "2. タスクの設定",
  step1_settingstask_text: "設定を変更するにはここをクリックします",
  step3_settingstask_title: "3. 配布リスト",
  step3_settingstask_text:
    "作成されたタスクのデフォルトの配布リストの人々を選択します。",
  step4_settingstask_title: "4. 設定の保存",
  step4_settingstask_text: "設定を保存するにはここをクリックします",
  stepFinal_settingstask: "タスクの設定とのやり取りの方法を学びました",
  // other
  obligatory: "必須",
  public: "公開",
  private: "非公開",
  congratulations: "おめでとうございます！",
  rfi_title_module: "RFIs",
  no_rfis_title: "現在、RFIは作成されていません",
  no_rfis_text:
    "追加する場合は、RFIを追加するボタンをクリックしてプロセスを開始してください",
  meeting: "ミーティング",
  workflow: "ワークフロー",
  guideMe: "ガイド",
  support: "サポート",
  closeTaskMessage: "このタスクをクローズしてもよろしいですか？",
  subtasks: "サブタスク",
  addSubtask: "サブタスクを追加",
  noSubtasks: "作成されたサブタスクはありません",
  inList: "リスト内",
};
