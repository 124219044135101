import { apiSlice } from "../../api/apiSlice";

export const sesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Send PDF by email
    sendToEmail: builder.mutation({
      query: (body) => ({
        url: "/ses/sendMail",
        method: "POST",
        body,
      }),
      //   providesTags: ["Ses"],
    }),
    sendToEmailFile: builder.mutation({
      query: (body) => ({
        url: "/ses/sendMailFile",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useSendToEmailMutation, useSendToEmailFileMutation } = sesApiSlice;
