import { apiSlice } from "../../api/apiSlice";
import { objectToParamString } from "../../utils";

export const enterpriseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createEnterprise: builder.mutation({
      query: (body) => ({
        url: "enterprise/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
    updateEnterprise: builder.mutation({
      query: (body) => ({
        url: "enterprise/editOnboardingEnterprise",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
    // get enterprise
    getEnterprises: builder.query({
      query: () => "/enterprise/get",
      keepUnusedDataFor: 60,
      providesTags: ["Enterprises"],
    }),
    getEnterpriseProjects: builder.query({
      query: (idEnterprise) => `/enterprise/${idEnterprise}/projects`,
      keepUnusedDataFor: 60,
      providesTags: ["Enterprises", "Projects"],
    }),
    getUserProjectsByEnterprise: builder.query({
      query: (idEnterprise) =>
        `/enterprise/${idEnterprise}/getUserProjectsByEnterprise`,
      keepUnusedDataFor: 60,
      providesTags: ["Enterprises", "Projects"],
    }),
    getUsersFromEnterprise: builder.query({
      query: ({ idEnterprise, ...params }) =>
        `/enterprise/${idEnterprise}/getUsers${objectToParamString(params)}`,
      keepUnusedDataFor: 60,
      providesTags: ["Enterprises", "Projects"],
    }),
    getUserProjectsByRole: builder.query({
      query: (idEnterprise) =>
        `/enterprise/${idEnterprise}/getUserProjectsByRole`,
      keepUnusedDataFor: 60,
      providesTags: ["Enterprises"],
    }),
    // roles
    // get Roles From Enterprises
    getRolesFromEnterprises: builder.query({
      query: (idEnterprise) => `/enterprise/${idEnterprise}/getRoles`,
      keepUnusedDataFor: 60,
      providesTags: ["Enterprises", "Roles"],
    }),
    // Mutation for get roles
    getEnterpriseRoles: builder.mutation({
      query: (body) => ({
        url: "/enterprise/getRoles",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),

    // update Enterprise's information
    updateEnterpriseInfo: builder.mutation({
      query: (body) => ({
        url: "/enterprise/updateInfo",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),

    // get user
    getUser: builder.query({
      query: () => "/user/get",
      keepUnusedDataFor: 60,
      providesTags: ["User"],
    }),

    // update User's information
    updateUserInfo: builder.mutation({
      query: (body) => ({
        url: "/user/updateInfo",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises", "User"],
    }),
    // admins - get users
    getAdminUsers: builder.query({
      query: (idEnterprise) => `/enterprise/${idEnterprise}/getAdmins`,
      keepUnusedDataFor: 60,
      providesTags: ["Enterprises"],
    }),
    // admins - enable user as admin
    enableAdminUser: builder.mutation({
      query: (body) => ({
        url: "/enterprise/enableAdmin",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
    // admins - disable admin users
    disableAdminUsers: builder.mutation({
      query: (body) => ({
        url: "/enterprise/disableAdmin",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),

    // roles
    // create role
    createRole: builder.mutation({
      query: (body) => ({
        url: "/role/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
    // read (get) role
    readRole: builder.query({
      query: (idRol) => `/role/read/${idRol}`,
      keepUnusedDataFor: 60,
      providesTags: [""],
    }),
    // delete role
    deleteRole: builder.mutation({
      query: (body) => ({
        url: "/role/delete",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
    createLogo: builder.mutation({
      query: (body) => ({
        url: "/enterprise/create-logo",
        method: "POST",
        body,
      }),
      // invalidatesTags: ["Images"],
    }),
    // read (get) tutorial modal status
    getTutorials: builder.query({
      query: (idUser) => `/enterprise/${idUser}/getTutorials`,
      keepUnusedDataFor: 20,
      providesTags: ["Enterprises"],
    }),
    // update tutorial modal status data
    updateTutorial: builder.mutation({
      query: (body) => ({
        url: "/enterprise/updateTutorial",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
    getEnterpriseById: builder.query({
      query: (idEnterprise) => ({
        url: `/enterprise/get/${idEnterprise}`,
      }),
      providesTags: ["Enterprises"],
    }),
    canAddUser: builder.mutation({
      query: (body) => ({
        url: "/enterprise/canAddUser",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
    canAddProject: builder.mutation({
      query: (body) => ({
        url: "/enterprise/canAddProject",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
    updateUsersLimit: builder.mutation({
      query: (body) => ({
        url: "/enterprise/updateUsersLimit",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
    createEnterpriseUser: builder.mutation({
      query: ({ idEnterprise, ...body }) => ({
        url: `/enterprise/user/${idEnterprise}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
    changeEnterpriseUserStatus: builder.mutation({
      query: ({ idEnterprise, idUser }) => ({
        url: `/enterprise/userStatus/${idEnterprise}/${idUser}`,
        method: "PUT",
      }),
      invalidatesTags: ["Enterprises"],
    }),
    getAssignedProjectsFromUser: builder.query({
      query: ({ idEnterprise, idUser }) => ({
        url: `/enterprise/${idEnterprise}/user/${idUser}/assignedProjects`,
      }),
      providesTags: ["Enterprises"],
    }),
    editAssignedProjectsFromUser: builder.mutation({
      query: ({ idEnterprise, ...body }) => ({
        url: `/enterprise/${idEnterprise}/user/editProjects`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
    changeDashboardView: builder.mutation({
      query: ({ idEnterprise, ...body }) => ({
        url: `/enterprise/${idEnterprise}/user/changeDashboardView`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Enterprises"],
    }),
  }),
});

export const {
  useCreateEnterpriseMutation,
  useGetEnterprisesQuery,
  useGetUserQuery,
  useGetUsersFromEnterpriseQuery,
  useGetUserProjectsByRoleQuery,
  useUpdateEnterpriseInfoMutation,
  useUpdateUserInfoMutation,
  useGetAdminUsersQuery,
  useDisableAdminUsersMutation,
  useEnableAdminUserMutation,
  useCreateRoleMutation,
  useGetRolesFromEnterprisesQuery,
  useDeleteRoleMutation,
  useReadRoleQuery,
  useCreateLogoMutation,
  useGetEnterpriseRolesMutation,
  useUpdateTutorialMutation,
  useGetTutorialsQuery,
  useUpdateEnterpriseMutation,
  useGetEnterpriseByIdQuery,
  useGetEnterpriseProjectsQuery,
  useGetUserProjectsByEnterpriseQuery,
  useCanAddUserMutation,
  useCanAddProjectMutation,
  useUpdateUsersLimitMutation,
  useCreateEnterpriseUserMutation,
  useChangeEnterpriseUserStatusMutation,
  useGetAssignedProjectsFromUserQuery,
  useEditAssignedProjectsFromUserMutation,
  useChangeDashboardViewMutation,
} = enterpriseApiSlice;
