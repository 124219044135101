import { tour } from "./tour";

export const drawings = {
  // general tour
  ...tour,
  moduleElement: "绘图",
  exportButtonTour: "下载",
  reviewAndPublishButtonTour: "审核和发布",
  // ADD - guide me steps
  step1_addDrawing_title: "1. 添加",
  step1_addDrawing_text: "点击 + 图标添加新的绘图。",
  step2_addDrawing_title: "2. 添加文件",
  step2_addDrawing_text: "附加文件，或将其拖放到虚线框内。",
  step3_addDrawing_title: "3. 绘图集名称",
  step3_addDrawing_text: "选择或输入绘图集的名称。",
  step4_addDrawing_title: "4. 绘图日期",
  step4_addDrawing_text: "选择绘图的日期。",
  step5_addDrawing_title: "5. 绘图接收日期",
  step5_addDrawing_text: "选择绘图的接收日期。",
  step6_addDrawing_title: "6. 保存",
  step6_addDrawing_text: "点击此处保存绘图。",
  step_final_addDrawing: "您已学会如何添加绘图。",
  // REVIEW & PUBLISH - guide me steps
  step1_reviewAndPublishDrawing_title: "1. 更多选项",
  step1_reviewAndPublishDrawing_text: "点击此处查看更多选项。",
  step2_reviewAndPublishDrawing_title: "2. 审核绘图",
  step2_reviewAndPublishDrawing_text: "点击此处打开待审核绘图的部分。",
  step3_reviewAndPublishDrawing_title: "3. 批准",
  step3_reviewAndPublishDrawing_text: "点击此处查看绘图的信息。",
  step4_reviewAndPublishDrawing_title: "4. 修改信息",
  step4_reviewAndPublishDrawing_text: "进行所需的更改",
  step5_reviewAndPublishDrawing_title: "5. 批准",
  step5_reviewAndPublishDrawing_text: "点击此处批准绘图。",
  step6_reviewAndPublishDrawing_title: "6. 切换到发布",
  step6_reviewAndPublishDrawing_text: "点击此处查看待发布的绘图列表。",
  step7_reviewAndPublishDrawing_title: "7. 选择绘图",
  step7_reviewAndPublishDrawing_text: "选择要发布的绘图。",
  step8_reviewAndPublishDrawing_title: "8. 发布",
  step8_reviewAndPublishDrawing_text: "点击此处发布所选的绘图。",
  step_final_reviewAndPublishDrawing: "您已学会如何审核和发布绘图。",
  // VIEW - guide me steps
  step1_viewDrawing_title: "1. 查看",
  step1_viewDrawing_text: "点击此处查看绘图。",
  step2_viewDrawing_title: "2. 修订",
  step2_viewDrawing_text: "选择修订的编号。",
  step3_viewDrawing_title: "3. 绘图",
  step3_viewDrawing_text: "选择是否需要更改绘图。",
  step4_viewDrawing_title: "4. 注释",
  step4_viewDrawing_text: "点击此处向绘图添加注释。",
  step5_viewDrawing_title: "5. 显示/隐藏注释",
  step5_viewDrawing_text: "点击此处显示或隐藏注释",
  step_final_viewDrawing: "您已学会如何查看绘图。",
  // EDIT - guide me steps
  step1_editDrawing_title: "1. 编辑",
  step1_editDrawing_text: "点击此处编辑绘图。",
  step2_editDrawing_title: "2. 绘图名称",
  step2_editDrawing_text: "编辑绘图的名称。",
  step3_editDrawing_title: "3. 绘图集名称",
  step3_editDrawing_text: "选择或编辑绘图集的名称。",
  step4_editDrawing_title: "4. 学科",
  step4_editDrawing_text: "选择学科。",
  step5_editDrawing_title: "5. 隐私",
  step5_editDrawing_text: "选择绘图是否对所有人可见。",
  step6_editDrawing_title: "6. 隐私级别",
  step6_editDrawing_text: "如果激活，将限制对某些公司和个人的访问。",
  step7_editDrawing_title: "7. 保存",
  step7_editDrawing_text: "点击此处保存更改。",
  step_final_editDrawing: "您已学会如何编辑绘图。",
  // RECYCLE - guide me steps
  step1_recycleDrawing_title: "1. 更多选项",
  step1_recycleDrawing_text: "点击此处查看更多选项。",
  step2_recycleDrawing_title: "2. 绘图回收站",
  step2_recycleDrawing_text: "点击此处查看绘图回收站。",
  step3_recycleDrawing_title: "3. 选择绘图",
  step3_recycleDrawing_text: "选择要恢复的绘图。",
  step4_recycleDrawing_title: "4. 恢复",
  step4_recycleDrawing_text: "点击此处恢复所选的绘图。",
  step_final_recycleDrawing: "您已学会如何恢复绘图。",
  // DOWNLOAD - guide me steps
  step1_exportDrawing_title: "1. 更多操作",
  step1_exportDrawing_text: "点击 3 个点以打开包含更多操作的菜单。",
  step2_exportDrawing_title: "2. 下载",
  step2_exportDrawing_text: "点击此处下载绘图。",
  step3_exportDrawing_title: "3. 替代下载",
  step3_exportDrawing_text: "您还可以将光标放在 #Rev 列上以查看修订列表。",
  step4_exportDrawing_title: "4. 下载修订",
  step4_exportDrawing_text: "点击以下载任何要下载的文件。",
  step_final_exportDrawing: "您已学会如何下载绘图。",
  // SETTINGS - guide me steps
  step1_settingsDrawing_title: "1. 更多选项",
  step1_settingsDrawing_text: "点击此处查看更多选项。",
  step2_settingsDrawing_title: "2. 设置",
  step2_settingsDrawing_text: "点击此处打开设置模态框。",
  step3_settingsDrawing_title: "3. 分发列表",
  step3_settingsDrawing_text: "添加或删除默认访问绘图模块的人员。",
  step4_settingsDrawing_title: "4. 学科",
  step4_settingsDrawing_text: "点击此处访问学科选项卡。",
  step5_settingsDrawing_title: "5. 添加学科",
  step5_settingsDrawing_text: "输入要添加的学科名称。",
  step_final_settingsDrawing: "您已学会如何配置绘图模块。",

  addDrawing: "添加绘图",
  reviewDrawings: "审核绘图",
  drawingsToCheck: "待审核",
  drawingsToPublish: "待发布",
  discipline: "学科",
  disciplines: "学科",
  addNewSet: "添加新集",
  setName: "集名称",
  drawingDate: "绘图日期",
  deletingDrawingMessage: "确定要删除此绘图吗？此操作无法撤消",
  deleteDrawing: "删除绘图",
  drawingsDeletedMessage: "绘图删除成功。",
  drawingsPublishedMessage: "绘图发布成功。",
  drawingsRestoredMessage: "绘图恢复成功。",
  recycleBin: "绘图回收站",
  drawingName: "绘图名称",
  deleteDrawingsMessage: "确定要删除这些绘图吗？",
  deleteLastRevision: "删除最后一次修订",
  revision: "修订",
  noDrawingsCreated: "未创建绘图",
  addDrawingsValidation: "如果要添加绘图，请点击添加按钮开始该过程",
  line: "线",
  rectangle: "矩形",
  ellipse: "椭圆",
  arrow: "箭头",
  polyline: "折线",
  annotationPublishedMessage: "注释发布成功。",
  freetext: "自由文本",
  annotationAssignedMessage: "注释分配成功。",
  annotationLinkedMessage: "注释链接成功。",
  approveDrawing: "批准绘图",
  drawingId: "绘图 ID",
  approve: "批准",
  publishAnnotations: "发布注释",
  deleteAnnotations: "删除注释",
  deleteAnnotationsContent: "确定要删除 ${1} 条注释吗？",
  annotationDeletedMessage: "注释删除成功。",
  drawingsAddedTitle: "添加绘图集",
  drawingsAddedContent: "绘图已成功添加。上传到系统后，我们将通知您。",
  reviseDrawings: "修订绘图",
  area: "区域",
  calibrate: "校准",
  distance: "距离",
  includeAnnotations: "包括注释",
  excludeAnnotations: "排除注释",
  editDrawing: "编辑绘图",
  addDiscipline: "添加学科",
  editDrawingId: "编辑绘图 ID",
  editedDrawingMessage: "绘图更新成功。",
  compareWith: "与之比较",
  selectDrawgingsToCompare: "选择要比较的绘图",
  revisionsDeletedSuccessfully: "修订删除成功",
  links: "链接",
  showAnnotations: "显示注释",
  hideAnnotations: "隐藏注释",
  measure: "测量",
  generated: "已生成",
  comparisionGeneratedSuccessfully: "比较成功生成",
  setCalibration: "调整校准",
  calibrateDescription: "此平面的所有测量将参考以下指定的校准。",
  calibrationNote: "注意：这些测量值不准确。",
  calibrateInstructions: "选择平面上的两个点进行校准",
  fileSizeLimit: "最大文件大小: 10GB (总计)",
  ploadingQtyDrawings: "正在上传{{qty}}个图纸",
  uploadDrawings: "上传图纸",
  comment: "评论",
  commentDrawing: "添加评论到图纸",
  commentPlaceholder: "写下关于图纸的评论",
  drawingViewer: "图纸查看器",
};
