import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect, useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { useTranslation } from "react-i18next";

import { Box, Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";

import useMediaQuery from "@mui/material/useMediaQuery";
import "jspdf-autotable";

// Alert
import { Add } from "@mui/icons-material";
import Swal from "sweetalert2";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import {
  useDeleteRfiMutation,
  useGetRfisQuery,
} from "../../features/project/modules/rfiApiSlice";
import { setCurrentRfi } from "../../features/project/modules/rfiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
} from "../../features/project/projectSlice";

import NoRfis from "../../assets/rfi/NoRfis.png";

import Can from "../../utils/can";

import { DISTRIBUTION_LIST_NAMES } from "../../constants/projectDashboard";

import { RFITable } from "../../components/projectDashboard/Rfis";
import { ModalFileViewer } from "../../components/shared/ModalFileViewer";
import { useExportRfisMutation } from "../../features/exportPdf";
import { TitleWidgets } from "../../components/shared";
import RfiDeleteModal from "../../components/projectDashboard/Rfis/RfiDeleteModal";
import CaslContext from "../../utils/caslContext";
import { useAppTourContext } from "../../components/supportButton/context/context";

function RFIs() {
  const darkMode = useSelector(selectTheme);

  const { idProject } = useParams();
  const currentProject = useSelector(selectCurrentProject);
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const {
    state: { tourActive, stepIndex, run },
    setState,
  } = useAppTourContext();

  const ability = useContext(CaslContext);

  // Translations
  const { t: tRfis } = useTranslation("rfis");
  const { t: tGeneral } = useTranslation("general");

  const [params, setParams] = useState({});
  const [selectedRfis, setSelectedRfis] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [removeRfi] = useDeleteRfiMutation();
  const {
    data,
    isLoading: isRfisLoading,
    isFetching: isRfisFetching,
  } = useGetRfisQuery({
    idProject,
    params: {
      ...params,
      ...(params?.createdOn !== null &&
        params?.createdOn?.length > 0 && {
          startDate: params?.createdOn[0],
          endDate: params?.createdOn[1],
        }),
      createdOn: null,
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [exportRfis] = useExportRfisMutation();

  const rfis = useMemo(() => data ?? [], [data]);

  const [pdfUrl, setPdfUrl] = React.useState(null);
  const exportPdfDocument2 = async () => {
    const body = {
      rfis,
    };
    const url = await exportRfis(body);
    setPdfUrl(url.data);
  };

  function showAlert(message, type) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }

  // Delete rfi
  const deleteRfi = async (e) => {
    try {
      const body = {
        ids: Object.keys(selectedRfis),
      };
      const response = await removeRfi(body).unwrap();
      if (response) {
        setOpenDelete(false);
        setSelectedRfis({});
        showAlert(tGeneral("deletedSuccessfully"), "success");
      }
    } catch (err) {
      showAlert(tGeneral("cantDelete"), "error");
      datadogRum.addError({
        message: "RFI's - Delete - Error",
        stack: err.stack ? err.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: currentProject?.idEnterprise,
          module: "RFI's",
          project: currentProject?.id,
        },
        timestamp: Date.now(),
      });
    }
  };

  const isMobile = useMediaQuery("(max-width:820px)");

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 0 &&
      (currentGuideMe === "rfis-add" ||
        currentGuideMe === "rfi-edit" ||
        currentGuideMe === "rfi-view") &&
      !run
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, run, currentGuideMe]);

  return (
    <>
      <TitleWidgets
        title={tRfis("rfi_title_module")}
        supportDisabled
        moduleName="rfis"
        backEnabled={false}
        distributionListName={DISTRIBUTION_LIST_NAMES.rfis}
        showDelete={
          Object.keys(selectedRfis).length > 0 && ability.can("delete", "rfis")
        }
        onDelete={() => setOpenDelete(true)}
        enableSupport
        addNameTour="add-rfi-1"
        showExports={false}
      />
      <Can I="view" a="rfis">
        <Box sx={{ width: "100%" }}>
          <RFITable
            rfis={rfis}
            selectedRfis={selectedRfis}
            setSelectedRfis={setSelectedRfis}
            setFilters={(val) => setParams(val)}
            isLoading={isRfisLoading || isRfisFetching}
          />
          <RfiDeleteModal
            isOpen={openDelete}
            onClose={(e) => setOpenDelete(false)}
            handleDelete={deleteRfi}
          />
        </Box>
      </Can>

      {/* If there no access to see this module */}
      <Can I="none" a="rfis">
        {tGeneral("noPermission")}
      </Can>
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
    </>
  );
}

export default RFIs;
