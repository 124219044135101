import {
  Architecture,
  ArrowDropDown,
  Download,
  FormatShapes,
  GridOn,
  PhotoSizeSelectActual,
  Redo,
  Search,
  Straighten,
  Undo,
  WidthFull,
  WidthNormal,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useEffect, useMemo, useState } from "react";
import { ReactComponent as AnnotationIcon } from "../../../assets/drawings/annotation.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/drawings/arrow.svg";
import { ReactComponent as EllipseIcon } from "../../../assets/drawings/ellipse.svg";
import { ReactComponent as LineIcon } from "../../../assets/drawings/line.svg";
import { ReactComponent as PolylineIcon } from "../../../assets/drawings/polyline.svg";
import { ReactComponent as RectangleIcon } from "../../../assets/drawings/rectangle.svg";
import { ReactComponent as SignatureIcon } from "../../../assets/drawings/signature.svg";
import { StyleToolbar } from "./StyleToolbar";

const DEFAULT_STYLES = {
  FillColor: { R: 0, G: 0, B: 0, A: 0 },
  Color: { R: 0, G: 0, B: 0, A: 0 },
  StrokeColor: { R: 248, G: 36, B: 0, A: 1 },
  Opacity: 1,
  StrokeThickness: 5,
};

function DrawingsToolbar({
  webViewerInstance,
  expanded,
  showMeasure,
  toggleAnnotationList,
  annotations,
  isFreeText,
  currentAnnotations,
  scale,
}) {
  const { Tools } = webViewerInstance.Core;

  const SHAPES = useMemo(() => {
    if (Tools) {
      return [
        {
          label: "line",
          value: Tools.ToolNames.LINE,
          icon: (
            <LineIcon
              style={{ color: "#000", height: "24px", width: "24px" }}
            />
          ),
        },
        {
          label: "arrow",
          value: Tools.ToolNames.ARROW,
          icon: (
            <ArrowIcon
              style={{ color: "#000", height: "24px", width: "24px" }}
            />
          ),
        },
        {
          label: "rectangle",
          value: Tools.ToolNames.RECTANGLE,
          icon: (
            <RectangleIcon
              style={{ color: "#000", height: "24px", width: "24px" }}
            />
          ),
        },
        {
          label: "ellipse",
          value: Tools.ToolNames.ELLIPSE,
          icon: (
            <EllipseIcon
              style={{ color: "#000", height: "24px", width: "24px" }}
            />
          ),
        },
        {
          label: "polyline",
          value: Tools.ToolNames.POLYLINE,
          icon: (
            <PolylineIcon
              style={{ color: "#000", height: "24px", width: "24px" }}
            />
          ),
        },
      ];
    }
    return [];
  }, [Tools]);

  const { t: tDrawings } = useTranslation("drawings");
  const { t: tGeneral } = useTranslation("general");
  const [selectedShape, setSelectedShape] = useState(Tools.ToolNames.LINE);
  const [currentTool, setCurrentTool] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [toolStyle, setToolStyle] = useState(DEFAULT_STYLES);
  const [fitWidth, setFitWidth] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchString, setSearchString] = useState("");

  const currentShape = useMemo(() => {
    const selShape = SHAPES.find((shape) => shape.value === selectedShape);
    return selShape;
  }, [selectedShape, SHAPES]);

  const handleZoomIn = () => {
    const zoomLevel = webViewerInstance.UI.getZoomLevel();
    webViewerInstance.UI.setZoomLevel(zoomLevel + 0.25);
  };

  const handleZoomOut = () => {
    const zoomLevel = webViewerInstance.UI.getZoomLevel();
    if (zoomLevel > 1) {
      webViewerInstance.UI.setZoomLevel(zoomLevel - 0.25);
    }
  };

  const handleMeasurementTool = (tool) => {
    const { documentViewer, Tools, Annotations } = webViewerInstance.Core;
    let newTool = documentViewer.getTool(Tools.ToolNames.PAN);
    if (!tool || tool === currentTool) {
      documentViewer.setToolMode(newTool);
      setCurrentTool(null);
      return;
    }
    newTool = documentViewer.getTool(tool);
    newTool.setStyles({
      StrokeColor: new Annotations.Color(248, 36, 0, 1),
      Opacity: 1,
      StrokeThickness: 5,
    });
    if (scale) {
      newTool.setStyles({
        Scale: scale,
        Precision: 0.01,
      });
    }
    documentViewer.setToolMode(newTool);
    setCurrentTool(tool);
  };

  const handleChangeTool = (tool) => {
    const { documentViewer, Tools } = webViewerInstance.Core;
    let newTool = documentViewer.getTool(Tools.ToolNames.PAN);
    if (!tool || tool === currentTool) {
      documentViewer.setToolMode(newTool);
      setCurrentTool(null);
      return;
    }
    newTool = documentViewer.getTool(tool);
    newTool.setStyles(toolStyle);
    documentViewer.setToolMode(newTool);
    setCurrentTool(tool);
  };

  const handleSelectShape = (shape) => {
    handleChangeTool(shape);
    setSelectedShape(shape);
    setAnchorEl(null);
  };

  const handleSetToolStyle = (newStyles) => {
    const { documentViewer } = webViewerInstance.Core;
    const newTool = documentViewer.getTool(currentTool);
    newTool.setStyles(newStyles);
    documentViewer.setToolMode(newTool);
    setToolStyle({ ...toolStyle, ...newStyles });
  };

  const handleFitMode = () => {
    const { FitMode, setFitMode } = webViewerInstance.UI;

    if (fitWidth) {
      setFitWidth(false);
      setFitMode(FitMode.FitPage);
    } else {
      setFitWidth(true);
      setFitMode(FitMode.FitWidth);
    }
  };

  const isShape = SHAPES.findIndex((shape) => shape.value === currentTool) > -1;

  useEffect(() => {
    if (!isShape) {
      setToolStyle(DEFAULT_STYLES);
    }
  }, [isShape]);

  const canUndo = useMemo(() => {
    if (webViewerInstance && annotations?.length > 0) {
      const annotationHistoryManager =
        webViewerInstance.Core.documentViewer.getAnnotationHistoryManager();
      return annotationHistoryManager.canUndo();
    }
    return false;
  }, [webViewerInstance, annotations]);

  const canRedo = useMemo(() => {
    if (webViewerInstance && annotations?.length > 0) {
      const annotationHistoryManager =
        webViewerInstance.Core.documentViewer.getAnnotationHistoryManager();
      return annotationHistoryManager.canRedo();
    }
    return false;
  }, [webViewerInstance, annotations]);

  const handleUndo = () => {
    const annotationHistoryManager =
      webViewerInstance.Core.documentViewer.getAnnotationHistoryManager();
    annotationHistoryManager.undo();
  };

  const handleRedo = () => {
    const annotationHistoryManager =
      webViewerInstance.Core.documentViewer.getAnnotationHistoryManager();
    annotationHistoryManager.redo();
  };

  const toggleSearch = () => {
    setSearching((prev) => !prev);
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value);
    webViewerInstance.Core.documentViewer.textSearchInit(
      e.target.value,
      webViewerInstance.Core.Search.Mode.HIGHLIGHT,
    );
  };

  if (showMeasure) {
    return (
      <Box
        display="flex"
        sx={{
          maxHeight: expanded ? "1000px" : "0px",
          transition: "1s all ease",
          opacity: showMeasure ? "1" : "0",
          overflow: "hidden",
          height: "60px",
          alignItems: "center",
        }}
      >
        <Button
          variant="text"
          sx={{ flexDirection: "column", textTransform: "none" }}
          color="secondary"
          onClick={() =>
            handleMeasurementTool(Tools.ToolNames.DISTANCE_MEASUREMENT)
          }
        >
          <Straighten />
          <Typography fontSize="0.75rem">{tDrawings("distance")}</Typography>
        </Button>
        <Button
          variant="text"
          sx={{ flexDirection: "column", textTransform: "none" }}
          color="secondary"
          onClick={() =>
            handleMeasurementTool(Tools.ToolNames.AREA_MEASUREMENT)
          }
        >
          <GridOn />
          <Typography fontSize="0.75rem">{tDrawings("area")}</Typography>
        </Button>
        <Button
          variant="text"
          sx={{ flexDirection: "column", textTransform: "none" }}
          color="secondary"
          onClick={() =>
            handleMeasurementTool(Tools.ToolNames.CALIBRATION_MEASUREMENT)
          }
        >
          <Architecture />
          <Typography fontSize="0.75rem">{tDrawings("calibrate")}</Typography>
        </Button>
        {currentTool === Tools.ToolNames.CALIBRATION_MEASUREMENT && (
          <Typography fontStyle="italic">
            {tDrawings("calibrateInstructions")}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Box
        display="flex"
        sx={{
          maxHeight: "1000px",
          transition: "1s all ease",
          opacity: "1",
          overflow: "hidden",
          height: "60px",
          alignItems: "center",
        }}
      >
        <IconButton onClick={toggleAnnotationList}>
          <AnnotationIcon
            style={{ color: "#000", height: "24px", width: "24px" }}
          />
        </IconButton>
        <IconButton onClick={handleZoomOut}>
          <ZoomOut />
        </IconButton>
        <IconButton onClick={handleZoomIn}>
          <ZoomIn />
        </IconButton>
        <IconButton onClick={handleFitMode}>
          {fitWidth ? <WidthFull /> : <WidthNormal />}
        </IconButton>
        <IconButton onClick={() => handleChangeTool(Tools.ToolNames.SIGNATURE)}>
          <SignatureIcon
            style={{ color: "#000", height: "24px", width: "24px" }}
          />
        </IconButton>
        <IconButton
          onClick={() => handleChangeTool(Tools.ToolNames.ADD_IMAGE_CONTENT)}
        >
          <PhotoSizeSelectActual />
        </IconButton>
        <IconButton onClick={() => handleChangeTool(Tools.ToolNames.FREETEXT)}>
          <FormatShapes />
        </IconButton>
        <ButtonGroup
          variant="text"
          sx={{
            ".MuiButtonGroup-grouped:not(:last-of-type)": {
              border: "none",
            },
            backgroundColor: isShape ? "#FFC42D" : "white",
          }}
        >
          <Button
            variant="text"
            color="secondary"
            sx={{ textTransform: "none", alignItems: "center" }}
            onClick={() => handleChangeTool(selectedShape)}
          >
            {currentShape.icon}
            <Typography>{tDrawings(currentShape.label)}</Typography>
          </Button>
          <IconButton
            sx={{ p: 0, m: 0 }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <ArrowDropDown />
          </IconButton>
        </ButtonGroup>
        <IconButton onClick={toggleSearch}>
          <Search />
        </IconButton>
        {searching && (
          <OutlinedInput
            size="small"
            value={searchString}
            onChange={handleSearch}
            placeholder={tGeneral("search")}
          />
        )}
        <IconButton onClick={(e) => setDownloadAnchorEl(e.currentTarget)}>
          <Download />
        </IconButton>
        <Menu
          anchorEl={downloadAnchorEl}
          open={Boolean(downloadAnchorEl)}
          onClose={() => setDownloadAnchorEl(null)}
        >
          <MenuItem
            onClick={() =>
              webViewerInstance.UI.downloadPdf({ includeAnnotations: true })
            }
          >
            <Typography>{tDrawings("includeAnnotations")}</Typography>
          </MenuItem>
          <MenuItem
            onClick={() =>
              webViewerInstance.UI.downloadPdf({ includeAnnotations: false })
            }
          >
            <Typography>{tDrawings("excludeAnnotations")}</Typography>
          </MenuItem>
        </Menu>
        <IconButton disabled={!canUndo} onClick={handleUndo}>
          <Undo />
        </IconButton>
        <IconButton disabled={!canRedo} onClick={handleRedo}>
          <Redo />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {SHAPES.map((shape) => {
            const isSelected = selectedShape === shape.value;
            return (
              <MenuItem
                key={shape.value}
                onClick={() => handleSelectShape(shape.value)}
                sx={{ backgroundColor: isSelected ? "#FFC42D" : "transparent" }}
              >
                <ListItemIcon>{shape.icon}</ListItemIcon>
                <Typography>{tDrawings(shape.label)}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
      {(isShape || isFreeText) && (
        <StyleToolbar
          setStyles={handleSetToolStyle}
          showCloud={currentTool === Tools.ToolNames.RECTANGLE}
          isFreeText={isFreeText}
        />
      )}
    </Box>
  );
}

export { DrawingsToolbar };
