// @ts-check

import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import excel from "../../../img/excel.png";
import Export from "./Modals/Export";
import Import from "./Modals/Import";

export default function Settings(props) {
  // eslint-disable-next-line react/prop-types
  const { client, dataSource, setDataSource } = props;

  const ref = useRef(null);

  const [open, setOpen] = useState(false);
  const [export_, setExport] = useState(false);
  const [import_, setImport] = useState(false);

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  return (
    <>
      <Button
        color="secondary"
        onClick={() => {
          setOpen((open) => !open);
        }}
        ref={ref}
        sx={{
          minWidth: "0",
          textTransform: "none",
        }}
        variant="text"
      >
        <SettingsIcon />
      </Button>
      <Popper
        anchorEl={ref.current}
        open={open}
        placement="bottom-start"
        role={undefined}
        sx={{
          margin: "5px 0 0 10px",
          zIndex: 1201,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left top",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => {
                  // @ts-ignore
                  if (ref.current && ref.current.contains(event.target)) {
                    return;
                  }
                  setOpen(false);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "20px",
                    width: "250px",
                  }}
                >
                  <strong>
                    <small>{tProjectSchedule("Import or export")}</small>
                  </strong>
                  <Box
                    onClick={() => {
                      setImport(true);
                    }}
                    sx={{
                      alignItems: "center",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <img alt="" height="20" src={excel} width="20" />
                    <small>{tProjectSchedule("Import from Excel")}</small>
                  </Box>
                  <Box
                    onClick={async () => {
                      setExport(true);
                    }}
                    sx={{
                      alignItems: "center",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <img alt="" height="20" src={excel} width="20" />
                    <small>{tProjectSchedule("Export to Excel")}</small>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Import
        client={client}
        dataSource={dataSource}
        open={import_}
        setDataSource={setDataSource}
        setOpen={setImport}
      />
      <Export client={client} open={export_} setOpen={setExport} />
    </>
  );
}
