import { datadogRum } from "@datadog/browser-rum";
import {
  Check,
  ContentCopy,
  Done,
  EditNote,
  PictureAsPdf,
  PlayArrow,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Link from "../../../assets/icons/sharedModules/link.png";
// import { specificStatusProps } from "../../../constants/projectDashboard/generalProps";
import { PopUpAlert } from "../../../components/PopUpAlert";
import AgendaItemDetail from "../../../components/projectDashboard/Meetings/AgendaItemDetail";
import GeneralInfo from "../../../components/projectDashboard/Meetings/GeneralInfo";
import {
  DetailsHeader,
  ModalLastEdit,
  ModalSeenBy,
  TooltipIconButton,
} from "../../../components/shared";
import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import SkeletonLoading from "../../../components/shared/SkeletonLoading";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { useExportMeetingMutation } from "../../../features/exportPdf";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import {
  useConfirmAttendenceMeetingMutation,
  useGetMeetingByIdQuery,
  useSetStatusMeetingMutation,
  useStartMeetingMutation,
} from "../../../features/project/modules/meetings/meetingApiSlice";
import { useGetAllProjectUsersQuery } from "../../../features/project/projectApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
} from "../../../features/project/projectSlice";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { getUserInfoByIdUser } from "../../../services/helpers/userInfo";
import CaslContext from "../../../utils/caslContext";
import pdf from "../../../utils/pdf";

function MeetingDetails() {
  const { idMeeting } = useParams();
  const { data: currentMeeting } = useGetMeetingByIdQuery(idMeeting, {
    skip: idMeeting === null,
  });
  const currentProject = useSelector(selectCurrentProject);
  const ability = useContext(CaslContext);
  const [load, setLoad] = React.useState(false);
  const [exportMeeting, { isLoadingPDF, data, error }] =
    useExportMeetingMutation();
  const [pdfUrl, setPdfUrl] = React.useState(null);

  const { idEnterprise, idProject } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:820px)");

  const { t: tMeetings } = useTranslation("meetings");
  const { t: tGeneral } = useTranslation("general");
  const currentUser = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);
  const darkMode = useSelector(selectTheme);

  const { data: projectUsers } = useGetAllProjectUsersQuery(idProject);
  const [startCurrentMeeting] = useStartMeetingMutation();
  const [
    confirmAttendance,
    { isLoading, isError, isSuccess, error: confirmError },
  ] = useConfirmAttendenceMeetingMutation();
  const [changeMeetingStatus] = useSetStatusMeetingMutation();

  const [inProgress, setInProgress] = useState(false);
  const [updatingAttendanceList, setUpdatingAttendanceList] = useState(false);
  const [closingMeeting, setClosingMeeting] = useState(false);
  const [userList, setUserList] = useState([]);
  const [items, setItems] = useState([]);
  const [copyLabel, setCopyLabel] = useState("");
  const [openLastSeen, setOpenLastSeen] = useState(false);
  const [openLastEdit, setOpenLastEdit] = useState(false);
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  const meetingsDetailsRef = useRef();

  const exportPdfDocument = async () => {
    await pdf({
      data: {},
      token,
      url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/meetings/${idMeeting}`,
      download: `${currentMeeting.title}.pdf`,
    });
  };

  useEffect(() => {
    if (
      tourActive &&
      currentGuideMe === "meetings-view" &&
      stepIndex === 1 &&
      currentMeeting
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, currentGuideMe, stepIndex, currentMeeting]);

  useEffect(() => {
    setInProgress(true);
    if (currentMeeting) {
      const currentItems = currentMeeting?.agenda?.map((agendaItem) => ({
        id: agendaItem.id,
        title: agendaItem.title,
        time: agendaItem.time,
        topics: agendaItem.topics?.map((topic) => ({
          id: topic.id,
          title: topic.title,
          tasks: topic.tasks.map((task) => ({
            ...task,
            assignedUsers:
              userList.length > 0
                ? getUserInfoByIdUser(task.assignedTo, userList)
                : [],
          })),
          comments: topic.comments,
        })),
        files: agendaItem.files,
      }));
      setItems(currentItems);
      setInProgress(false);
    }
  }, [currentMeeting, idMeeting, userList]);

  const goBack = () => {
    navigate(-1);
  };

  const startMeeting = async (e) => {
    try {
      const response = await startCurrentMeeting({
        id: currentMeeting?.id,
      }).unwrap();
      if (response) {
        PopUpAlert("success", tMeetings("meetingStarted"));
      }
    } catch (error) {
      PopUpAlert("error", tMeetings("errorMeetingStarted"));
      datadogRum.addError({
        message: "Meetings - Start - Error",
        stack: error.stack ? error.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: currentProject?.idEnterprise,
          module: "Meetings",
          project: currentProject?.id,
        },
        timestamp: Date.now(),
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert("success", tMeetings("confirmedAssistance"), "");
    }
    if (isError) {
      PopUpAlert("error", "Error", tGeneral("errorAttendanceConfirmed"));
    }
  }, [isSuccess, isError, confirmError, tGeneral, tMeetings]);

  const closeMeeting = async (e) => {
    setClosingMeeting(true);
    try {
      const bodyClose = {
        id: currentMeeting?.id,
        status: "close",
        closeDate: new Date(),
      };
      const response = await changeMeetingStatus(bodyClose).unwrap();
      if (response) {
        PopUpAlert("success", tMeetings("meetingClosed"));
        navigate(-1);
      }
    } catch (error) {
      PopUpAlert("error", tMeetings("errorMeetingClosed"));
      datadogRum.addError({
        message: "Meetings - Close - Error",
        stack: error.stack ? error.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: currentProject?.idEnterprise,
          module: "Meetings",
          project: currentProject?.id,
        },
        timestamp: Date.now(),
      });
    }
    setClosingMeeting(false);
  };

  const showMeetingAction = useMemo(() => {
    if (!currentMeeting || currentMeeting?.status === "close") return false;
    return (
      currentMeeting?.createdBy.id === currentUser.id ||
      (ability.can("close", "meetings") && currentMeeting.bStarted)
    );
  }, [currentMeeting, currentUser, ability.rules]);
  // TODO: make class component
  function PropertyCard({ value, label, img }) {
    const handleCopy = (e) => {
      e.preventDefault();
      navigator.clipboard.writeText(value);
      // e.clipboardData.setData("Text", value)
      setCopyLabel("Link copied");
      setTimeout(() => setCopyLabel(""), 2000);
    };

    return (
      <Card
        sx={{
          display: "flex",
          width: "100%",
          flex: 1,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            margin={0.5}
            gap="20px"
            flexDirection={isMobile ? "column" : "row"}
          >
            {img}
            <Box margin={0.5} width="100%">
              <Typography
                fontSize="12px"
                color={darkMode ? "white" : "GrayText"}
              >
                {label}
              </Typography>
              <Typography
                fontSize="14px"
                fontWeight="medium"
                textAlign="initial"
                // onClick={() => window.open(value, '_blank', 'noopener,noreferrer')}
              >
                {value ? (
                  <a
                    href={`${value}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ color: "#009DFF" }}
                  >
                    {value}
                  </a>
                ) : (
                  "N/A"
                )}
              </Typography>
            </Box>
            {value && (
              <Box
                display="flex"
                width={isMobile ? "100%" : "5%"}
                alignItems="center"
              >
                <Tooltip title={copyLabel}>
                  <IconButton onClick={(e) => handleCopy(e)}>
                    <ContentCopy sx={{ color: darkMode ? "white" : "black" }} />
                    {isMobile && (
                      <Typography ml={1} color={darkMode ? "white" : "black"}>
                        {tGeneral("copy")}
                      </Typography>
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }

  useEffect(() => {
    if (
      tourActive &&
      currentGuideMe === "meetings-view" &&
      stepIndex >= 5 &&
      meetingsDetailsRef.current
    ) {
      meetingsDetailsRef.current.style.overflowY = "scroll";
    }
  }, [tourActive, stepIndex, currentGuideMe, meetingsDetailsRef.current]);

  const handleConfirmAttendance = () => {
    const body = {
      id: currentMeeting?.id,
      confirmedAttendenceList: currentMeeting?.confirmedAttendenceList?.map(
        (user) => user.id,
      ),
      newConfirmed: currentUser.id,
    };
    confirmAttendance(body);
  };

  return (
    <Box width="100%">
      <DetailsHeader
        bPrivate={currentMeeting?.bPrivate}
        mainAction={
          currentMeeting?.bStarted
            ? {
                icon: <Done />,
                label: tMeetings("closeMeeting"),
                onClick: () => closeMeeting(),
              }
            : {
                icon: <PlayArrow />,
                label: tMeetings("startMeeting"),
                onClick: () => startMeeting(),
              }
        }
        showMainAction={showMeetingAction}
        status={currentMeeting?.status}
        onBack={goBack}
        actions={[
          {
            onClick: () => {
              setLoad(true);
              exportPdfDocument().then(() => setLoad(false));
            },
            icon: <PictureAsPdf />,
            label: tGeneral("exportPdf"),
          },
          {
            onClick: () => setOpenLastEdit(true),
            icon: <EditNote />,
            label: tGeneral("lastEdit"),
          },
          {
            onClick: () => setOpenLastSeen(true),
            icon: <RemoveRedEye />,
            label: tGeneral("lastSeen"),
          },
        ]}
        number={currentMeeting?.number ?? ""}
        title={currentMeeting?.title}
        secondaryAction={
          <TooltipIconButton
            icon={<Check />}
            label={tGeneral("confirmAttendance")}
            onClick={handleConfirmAttendance}
          />
        }
        showSecondayAction={
          !currentMeeting?.bStarted &&
          currentMeeting?.attendenceList.findIndex(
            (user) => user.id === currentUser.id,
          ) > -1 &&
          currentMeeting?.confirmedAttendenceList.findIndex(
            (user) => user.id === currentUser.id,
          ) < 0
        }
      />
      <Divider />
      <Box
        sx={{ width: "100%", height: "75vh", overflow: "auto", p: "10px" }}
        ref={meetingsDetailsRef}
      >
        {!inProgress && currentMeeting ? (
          <>
            {/* People details, date, hour an location */}
            <GeneralInfo currentMeeting={currentMeeting} />
            {/* Url Meeting */}
            <Box
              display="block"
              flexDirection="row"
              justifyContent="center"
              my="30px"
              width="100%"
              data-tour="view-meetings-3"
            >
              <PropertyCard
                img={
                  <img
                    src={Link}
                    alt="Link"
                    style={{ height: 40, width: 40 }}
                  />
                }
                label={tMeetings("urlMeeting")}
                value={currentMeeting?.url}
              />
            </Box>
            <Divider />
            {/* Agenda */}
            <Box
              display="flex"
              flexDirection="column"
              my={2}
              width="100%"
              data-tour="view-meetings-4"
            >
              <Typography fontWeight="bold">{tMeetings("agenda")}</Typography>
              <Card sx={{ my: 2, width: "100%" }}>
                <CardContent sx={{ px: 5 }}>
                  {items?.map((item, index) => (
                    <AgendaItemDetail
                      key={item?.id}
                      index={index + 1}
                      item={item}
                      canBeComment={
                        currentMeeting.bStarted &&
                        currentMeeting.status === "open" &&
                        (currentMeeting?.createdBy.id === currentUser?.id ||
                          currentMeeting?.attendenceList?.findIndex(
                            (u) =>
                              u.id === currentUser?.id ||
                              u.idDirectory === currentUser.id,
                          ) > -1 ||
                          currentMeeting?.distributionList?.findIndex(
                            (u) =>
                              u.id === currentUser?.id ||
                              u.idDirectory === currentUser.id,
                          ) > -1)
                      }
                      canModifyTask={
                        currentMeeting.bStarted &&
                        currentMeeting.status === "open" &&
                        (currentMeeting?.createdBy.id === currentUser?.id ||
                          currentMeeting?.attendenceList?.findIndex(
                            (u) =>
                              u.id === currentUser?.id ||
                              u.idDirectory === currentUser.id,
                          ) > -1)
                      }
                    />
                  ))}
                  {items?.length === 0 && (
                    <Typography>{tMeetings("noAgendaItems")}</Typography>
                  )}
                </CardContent>
              </Card>
            </Box>

            {Boolean(pdfUrl) && (
              <ModalFileViewer
                file={{ url: pdfUrl }}
                isOpen={Boolean(pdfUrl)}
                onClose={() => setPdfUrl(null)}
              />
            )}
            <ModalSeenBy
              isOpen={openLastSeen}
              idObject={idMeeting}
              onClose={() => setOpenLastSeen(false)}
            />
            <ModalLastEdit
              isOpen={openLastEdit}
              idObject={idMeeting}
              onClose={() => setOpenLastEdit(false)}
            />
          </>
        ) : (
          <SkeletonLoading isTable={false} />
        )}
      </Box>
    </Box>
  );
}

export default MeetingDetails;
