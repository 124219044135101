import { AttachFile, Close, DragHandle, ExpandMore } from "@mui/icons-material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import DeleteImg from "../../../assets/icons/delete.png";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import { FilePreviewHorizontalScroll } from "../../shared";
import TopicForm from "./TopicForm";
import TopicItem from "./TopicItem";

import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import ViewFiles from "../../ViewFiles";
import { useAppTourContext } from "../../supportButton/context/context";

const hiddenWidth = "0%";
const hoverWidth = "5%";

function AgendaItem({
  item,
  index,
  isDragging,
  setCurrentItem,
  setOnDelete,
  setDeletedTopic,
}) {
  const darkMode = useSelector(selectTheme);

  const { t: tGeneral } = useTranslation("general");
  const { t: tMeetings } = useTranslation("meetings");

  const [itemName, setItemName] = useState("");
  const [topics, setTopics] = useState([]);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(0);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [viewFiles, setViewFiles] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const [widthDrag, setWidthDrag] = useState(hiddenWidth);

  const { watch, setValue } = useFormContext();
  const deletedFiles = watch("deletedFiles");

  useEffect(() => {
    setItemName(item?.title);
    setTime(item?.time);
    setTopics(item?.topics);
  }, [item]);

  useEffect(() => {
    if (isDragging) {
      setWidthDrag(hoverWidth);
    } else {
      setWidthDrag(hiddenWidth);
    }
  }, [isDragging]);

  const handleOnDeleteFile = (file) => {
    const tempFiles = [...files];
    setFiles(tempFiles.filter((f) => f.name !== file.name));
    setCurrentItem(
      tempFiles.filter((f) => f.name !== file.name),
      "files",
    );
    if (file.id) {
      setValue("deletedFiles", [...deletedFiles, file.id]);
    }
  };

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (tourActive && currentGuideMe === "meetings-edit") {
      if (stepIndex === 9) {
        setIsNameFocused(true);
        setOpen(true);
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }
  }, [tourActive, stepIndex, currentGuideMe]);

  return (
    <>
      <Accordion
        expanded={open}
        onMouseEnter={() => setWidthDrag(hoverWidth)}
        onMouseLeave={() => setWidthDrag(hiddenWidth)}
      >
        {/* Header */}
        <AccordionSummary
          expandIcon={
            <IconButton onClick={() => setOpen(!open)}>
              <ExpandMore />
            </IconButton>
          }
          sx={{ backgroundColor: darkMode ? "" : open ? "#E8E8E8" : "white" }}
        >
          <Box
            sx={{
              width: widthDrag,
              transition: "width 0.3s ease",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
            }}
          >
            <DragHandle />
          </Box>
          <Box width="100%">
            {!isNameFocused ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ mt: 1, width: "auto" }}
                  onClick={() => {
                    setIsNameFocused(true);
                  }}
                >
                  {itemName}
                </Typography>
                {(files.length > 0 || currentFiles.length > 0) && (
                  <AttachFile
                    sx={{
                      mt: 1,
                    }}
                  />
                )}
              </Box>
            ) : (
              <>
                <TextField
                  autoFocus
                  value={itemName}
                  onChange={(e) => {
                    setItemName(e.target.value);
                    setCurrentItem(e.target.value, "title");
                  }}
                  onBlur={(e) => setIsNameFocused(false)}
                  sx={{ backgroundColor: darkMode ? "" : "white" }}
                  data-tour="edit-meetings-10"
                />
                {(files.length > 0 || currentFiles.length > 0) && (
                  <AttachFile sx={{ mt: 2 }} />
                )}
              </>
            )}
          </Box>
          <IconButton onClick={() => setOpenDelete(true)}>
            <Close sx={{ color: "red" }} />
          </IconButton>
        </AccordionSummary>
        {/* Content */}
        <AccordionDetails sx={{ backgroundColor: darkMode ? "" : "#F2F2F2" }}>
          <Box sx={{ mx: 3 }}>
            {/* Topics */}
            <span data-tour="edit-meetings-11">
              <Typography>
                {tMeetings("topicsToDiscuss").toUpperCase()}
              </Typography>
              {item.topics.map((topic, index) => (
                <Box key={topic.id} sx={{ my: 1 }}>
                  <TopicItem
                    topic={topic}
                    index={index}
                    setSelectedTopic={(index) => {
                      const currentTopics = [...topics];
                      if (!currentTopics[index].bNew) {
                        setDeletedTopic(currentTopics[index].id);
                      }
                      currentTopics.splice(index, 1);
                      setTopics(currentTopics);
                      setCurrentItem(currentTopics, "topics");
                    }}
                    setModifiedTopic={(value) => {
                      const currentTopics = [...topics];
                      currentTopics[index].title = value;
                      setTopics(currentTopics);
                      setCurrentItem(currentTopics, "topics");
                    }}
                  />
                </Box>
              ))}
              <Box sx={{ mt: 2, mb: 3 }}>
                <TopicForm
                  setTopic={(value) => {
                    const newTopic = {
                      title: value,
                      bNew: true,
                    };
                    const currentTopics = [...topics];
                    currentTopics.push(newTopic);
                    setTopics(currentTopics);
                    setCurrentItem(currentTopics, "topics");
                  }}
                />
              </Box>
            </span>
            <Divider sx={{ border: 1, color: "#E1E1E1" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 1,
                rowGap: 1,
              }}
            >
              {/* Time */}
              <Typography>{tGeneral("time").toUpperCase()}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 1,
                }}
                data-tour="edit-meetings-12"
              >
                <TextField
                  type="number"
                  value={time}
                  onChange={(e) => {
                    if (e.target.value.length <= 3) {
                      setTime(e.target.value);
                      setCurrentItem(e.target.value, "time");
                    }
                  }}
                  sx={{
                    backgroundColor: darkMode ? "" : "white",
                    width: "100px",
                  }}
                />
                <Typography>{tGeneral("minutes")}</Typography>
              </Box>
              <span data-tour="edit-meetings-13">
                {/* Files */}
                <Box display="flex" alignItems="center" columnGap={1}>
                  <DescriptionOutlinedIcon />
                  <Typography>{tGeneral("files").toUpperCase()}</Typography>
                </Box>
                <FilePreviewHorizontalScroll
                  files={item.files}
                  onDelete={handleOnDeleteFile}
                  onAddPhotos={(files) => {
                    setFiles(files);
                    setCurrentItem(files, "files");
                  }}
                />
              </span>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* Delete dialog */}
      <Modal
        open={openDelete}
        onClose={(e) => setOpenDelete(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          sx={(theme) => ({
            height: "auto",
            borderRadius: "20px",
            p: "25px 40px",
            width: "30%",
            [theme.breakpoints.down("lg")]: {
              width: "50%",
            },
          })}
        >
          <Box display="flex" justifyContent="right">
            <IconButton onClick={(e) => setOpenDelete(false)}>
              <Close fontSize="1.5625rem" />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={DeleteImg} alt="Delete" width="70px" />
            <Typography
              sx={{
                fontWeight: "bold",
                color: darkMode ? "white" : "black",
                mt: 1,
              }}
            >
              {tGeneral("deleteAgendaItem").toUpperCase()}
            </Typography>
            <Typography
              sx={{
                color: darkMode ? "white" : "black",
                transform: "none",
                mx: 1,
                mt: 2,
                textAlign: "center",
              }}
            >
              {tGeneral("deleteAgendaItemMessage")}
            </Typography>
            {/* Actions */}
            <Box display="flex" columnGap="16px" mt="20px" width="100%">
              <Button
                sx={{
                  width: "50%",
                  color: "black",
                  backgroundColor: "white",
                  borderColor: "black",
                  border: 1,
                  ":hover": {
                    color: "white",
                    backgroundColor: "#b4b4b4",
                  },
                  py: "0.5rem",
                }}
                fullWidth
                type="submit"
                variant="contained"
                onClick={(e) => setOpenDelete(false)}
              >
                {tGeneral("cancel")}
              </Button>
              <Button
                sx={{
                  width: "50%",
                  color: "black",
                  backgroundColor: "#ffc900",
                  ":hover": {
                    color: "black",
                    backgroundColor: "#d9ab00",
                  },
                  py: "0.5rem",
                }}
                type="submit"
                variant="contained"
                onClick={(e) => {
                  setOnDelete(index);
                }}
              >
                {tGeneral("accept")}
              </Button>
            </Box>
          </Box>
        </Card>
      </Modal>
      {/* Modal view files */}
      <Modal
        open={viewFiles}
        onClose={() => setViewFiles(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ViewFiles files={currentFiles} />
      </Modal>
    </>
  );
}

export default AgendaItem;
