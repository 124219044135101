import { CheckBox, Close, Settings } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetChecklistDrawingsQuery } from "../../../features/project/modules/drawingsChecklist/drawingsChecklistApiSlice";
import { TooltipIconButton } from "../../shared";
import { ChecklistDisciplineAccordion } from "./ChecklistDisciplineAccordion";
import { TotalCompleted } from "./TotalCompleted";

function CheckListDrawerContent({ isOpen, onClose }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawingsChecklist } = useTranslation("drawingsChecklist");
  const { data: checklist, isLoading } = useGetChecklistDrawingsQuery(
    idProject,
    {
      skip: !idProject || !isOpen,
    },
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        display="flex"
        height="48px"
        alignItems="center"
        columnGap="0.8rem"
        px="1rem"
        mb="1rem"
      >
        <CheckBox sx={{ color: "#E6B60C" }} />
        <Typography fontSize="1.5rem">
          {tDrawingsChecklist("checkList")}
        </Typography>
        <Box flex={1} />
        <TooltipIconButton
          icon={<Settings />}
          label={tGeneral("settings")}
          color="gray"
        />
        <TooltipIconButton
          icon={<Close />}
          label={tGeneral("close")}
          color="gray"
          onClick={onClose}
        />
      </Box>
      <Divider sx={{ mb: "0.5rem" }} />
      <TotalCompleted
        drawings={checklist?.reduce(
          (prev, curr) => [...prev, ...curr.drawings],
          [],
        )}
        isTotal
      />
      {checklist?.map((discipline, index) => (
        <ChecklistDisciplineAccordion discipline={discipline} index={index} />
      ))}
    </Box>
  );
}

function CheckListDrawer({ isOpen, onClose }) {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      hideBackdrop={false}
      variant="permanent"
    >
      <Box width={900} pt="16px">
        <CheckListDrawerContent isOpen={isOpen} onClose={onClose} />
      </Box>
    </Drawer>
  );
}

export { CheckListDrawer };
