// eslint-disable-next-line import/prefer-default-export
export const projectSchedule = {
  "% Complete": "% Complete",
  "$ Project Cost": "$ Project Cost",
  "Add New": "Add New",
  "All Colors": "All Colors",
  "All Dates": "All Dates",
  "Are you sure you want to delete the activity": "Are you sure you want to delete the activity",
  "Are you sure you want to delete the group": "Are you sure you want to delete the group",
  "Are you sure you want to delete the milestone": "Are you sure you want to delete the milestone",
  "Are you sure?": "Are you sure?",
  "Choose color": "Choose color",
  "Copy Link": "Copy Link",
  "Create Activity": "Create Activity",
  "Create Group": "Create Group",
  "Create Milestone": "Create Milestone",
  "Create Roadblock": "Create Roadblock",
  "Daily Reports": "Daily Reports",
  "Date Format:": "Date Format:",
  "Default Color": "Default Color",
  "Delete Activity": "Delete Activity",
  "Delete Group": "Delete Group",
  "Delete Milestone": "Delete Milestone",
  "Dependent On": "Dependent On",
  "Depends On": "Depends On",
  "Due Date": "Due Date",
  "End Date": "End Date",
  "Expand All Groups": "Expand All Groups",
  "Export to Excel": "Export to Excel",
  "Hide Completed": "Hide Completed",
  "Import from Excel": "Import from Excel",
  "Import or export": "Import or export",
  "Invite people": "Invite people",
  "No other activity depends on this activity.": "No other activity depends on this activity.",
  "Project Schedule": "Project Schedule",
  "Real Duration": "Real Duration",
  "Real End Date": "Real End Date",
  "Real End": "Real End",
  "Real Start Date": "Real Start Date",
  "Real Start": "Real Start",
  "Real Times": "Real Times",
  "Scheduled Duration": "Scheduled Duration",
  "Scheduled End Date": "Scheduled End Date",
  "Scheduled End": "Scheduled End",
  "Scheduled Start Date": "Scheduled Start Date",
  "Scheduled Start": "Scheduled Start",
  "Scheduled Times": "Scheduled Times",
  "Select a type": "Select a type",
  "Select Assignees": "Select Assignees",
  "Select Colors": "Select Colors",
  "Show Completed": "Show Completed",
  "Start Date": "Start Date",
  "Submitting...": "Submitting...",
  "There are no comments in this activity.": "There are no comments in this activity.",
  "There are no comments in this group.": "There are no comments in this group.",
  "There are no dependents/depedencies of this activity.": "There are no dependents/depedencies of this activity.",
  "There are no histories in this group.": "There are no histories in this group.",
  "There are no items in this module.": "There are no items in this module.",
  "There are no more daily reports available.": "There are no more daily reports available.",
  "There are no more documents available.": "There are no more documents available.",
  "There are no more drawings available.": "There are no more drawings available.",
  "There are no more inspections available.": "There are no more inspections available.",
  "There are no more meetings available.": "There are no more meetings available.",
  "There are no more RFIs available.": "There are no more RFIs available.",
  "There are no more tasks available.": "There are no more tasks available.",
  "There is history in this activity.": "There is history in this activity.",
  "There is history in this group.": "There is history in this group.",
  "This activity does noes depend on any other activity.": "This activity does noes depend on any other activity.",
  "This feature is under construction.": "This feature is under construction.",
  "Update Roadblock": "Update Roadblock",
  "You have marked this milestone as completed.": "You have marked this milestone as completed.",
  "You have marked this milestone as not completed.": "You have marked this milestone as not completed.",
  "You have not invited anyone yet.": "You have not invited anyone yet.",
  Activities: "Activities",
  Activity: "Activity",
  Assigned: "Assigned",
  Assignees: "Assignees",
  Budget: "Budget",
  Cancel: "Cancel",
  Chart: "Chart",
  Close: "Close",
  CLOSE: "CLOSE",
  Colors: "Colors",
  Comments: "Comments",
  Cost: "Cost",
  Create: "Create",
  Day: "Day",
  Dependencies: "Dependencies",
  Description: "Description",
  Difference: "Difference",
  Documents: "Documents",
  Drawings: "Drawings",
  Duration: "Duration",
  Email: "Email",
  Error: "Error",
  Everyone: "Everyone",
  Expenses: "Expenses",
  Files: "Files",
  Grid: "Grid",
  History: "History",
  Inspections: "Inspections",
  Invite: "Invite",
  Meetings: "Meetings",
  Milestone: "Milestone",
  Name: "Name",
  No: "No",
  OPEN: "OPEN",
  Permissions: "Permissions",
  Predecessor: "Predecessor",
  Progress: "Progress",
  RESCHEDULE: "RESCHEDULE",
  RFIs: "RFIs",
  Roadblocks: "Roadblocks",
  Select: "Select",
  Send: "Send",
  Share: "Share",
  Status: "Status",
  Success: "Success",
  Successor: "Successor",
  Tasks: "Tasks",
  Title: "Title",
  to: "to",
  Total: "Total",
  Update: "Update",
  Week: "Week",
  Yes: "Yes",
  "DOWNLOAD PDF": "DOWNLOAD PDF",
  "Additional Columns": "Additional Columns",
  ACTIVITY_CREATED: "Activity was created",
  ACTIVITY_UPDATED_ASSIGNEES_CREATED: 'Assignee "{{ name }}" was added',
  ACTIVITY_UPDATED_ASSIGNEES_REMOVED: 'Assignee "{{ name }}" was removed',
  ACTIVITY_UPDATED_COLOR: 'Color was changed from "{{ old }}" to "{{ new }}"',
  ACTIVITY_UPDATED_DESCRIPTION: 'Description was changed from "{{ old }}" to "{{ new }}"',
  ACTIVITY_UPDATED_ID_ACTIVITY_UPDATED: 'Predecessor was changed from "{{ old }}" to "{{ new }}"',
  ACTIVITY_UPDATED_PROGRESS: 'Progress was changed from "{{ old }}" to "{{ new }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_CREATED: 'Expense line "{{ title }}" was added',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_REMOVED: 'Expense line "{{ title }}" was removed',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_AMOUNT:
    'Amount was changed from "{{ old }}" to "{{ new }}" in the expense line "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_FILE:
    'File was changed from "{{ old }}" to "{{ new }}" in the expense line "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_TITLE:
    'Title was changed from "{{ old }}" to "{{ new }}" in the expense line "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_BUDGET: 'Budget was changed from "{{ old }}" to "{{ new }}"',
  ACTIVITY_UPDATED_REAL_TIMES_END: 'Real Times End was changed from "{{ old }}" to "{{ new }}"',
  ACTIVITY_UPDATED_REAL_TIMES_START: 'Real Times Start was changed from "{{ old }}" to "{{ new }}"',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_END: 'Scheduled Times End was changed from "{{ old }}" to "{{ new }}"',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_START: 'Scheduled Times Start was changed from "{{ old }}" to "{{ new }}"',
  ACTIVITY_UPDATED_TITLE: 'Title was changed from "{{ old }}" to "{{ new }}"',
  DAILY_REPORT_CREATED: 'Daily Report "{{ title }}" was added',
  DAILY_REPORT_REMOVED: 'Daily Report "{{ title }}" was removed',
  DOCUMENT_CREATED: 'Document "{{ name }}" was added',
  DOCUMENT_REMOVED: 'Document "{{ name }}" was removed',
  DRAWING_CREATED: 'Drawing "{{ name }}" was added',
  DRAWING_REMOVED: 'Drawing "{{ name }}" was removed',
  INSPECTION_CREATED: 'Inspection "{{ title }}" was added',
  INSPECTION_REMOVED: 'Inspection "{{ title }}" was removed',
  MEETING_CREATED: 'Meeting "{{ title }}" was added',
  MEETING_REMOVED: 'Meeting "{{ title }}" was removed',
  RFI_CREATED: 'RFI "{{ title }}" was added',
  RFI_REMOVED: 'RFI "{{ title }}" was removed',
  ROADBLOCK_CREATED: 'Roadblock "{{ description }}" was created',
  ROADBLOCK_UPDATED_ASSIGNEES_CREATED: 'Assignee "{{ name }}" was added to Roadblock "{{ description }}"',
  ROADBLOCK_UPDATED_ASSIGNEES_REMOVED: 'Assignee "{{ name }}" was removed from Roadblock "{{ description }}"',
  ROADBLOCK_UPDATED_DATE: 'Date was changed from "{{ old }}" to "{{ new }}" in Roadblock "{{ description }}"',
  ROADBLOCK_UPDATED_DESCRIPTION:
    'Description was changed from "{{ old }}" to "{{ new }}" in Roadblock "{{ description }}"',
  ROADBLOCK_UPDATED_STATUS: 'Status was changed from "{{ old }}" to "{{ new }}" in Roadblock "{{ description }}"',
  ROADBLOCK_UPDATED_TYPE: 'Type was changed from "{{ old }}" to "{{ new }}" in Roadblock "{{ description }}"',
  TASK_CREATED: 'Task "{{ title }}" was added',
  TASK_REMOVED: 'Task "{{ title }}" was removed',
};
