// eslint-disable-next-line import/prefer-default-export
export const projectSchedule = {
  "% Complete": "完了%",
  "$ Project Cost": "プロジェクト費用",
  "Add New": "新規追加",
  "All Colors": "全ての色",
  "All Dates": "全ての日付",
  "Are you sure you want to delete the activity": "本当にこの活動を削除してもよろしいですか？",
  "Are you sure you want to delete the group": "本当にこのグループを削除してもよろしいですか？",
  "Are you sure you want to delete the milestone": "本当にこのマイルストーンを削除してもよろしいですか？",
  "Are you sure?": "よろしいですか？",
  "Choose color": "色を選ぶ",
  "Copy Link": "リンクをコピー",
  "Create Activity": "活動を作成",
  "Create Group": "グループを作成",
  "Create Milestone": "マイルストーンを作成",
  "Create Roadblock": "障害を作成",
  "Daily Reports": "日報",
  "Date Format:": "日付形式：",
  "Default Color": "デフォルトの色",
  "Delete Activity": "活動を削除",
  "Delete Group": "グループを削除",
  "Delete Milestone": "マイルストーンを削除",
  "Dependent On": "依存先",
  "Depends On": "依存する",
  "Due Date": "期日",
  "End Date": "終了日",
  "Expand All Groups": "すべてのグループを展開",
  "Export to Excel": "Excelにエクスポート",
  "Hide Completed": "完了を隠す",
  "Import from Excel": "Excelからインポート",
  "Import or export": "インポートまたはエクスポート",
  "Invite people": "人を招待する",
  "No other activity depends on this activity.": "他の活動がこの活動に依存しているわけではありません。",
  "Project Schedule": "プロジェクトスケジュール",
  "Real Duration": "実際の期間",
  "Real End Date": "実際の終了日",
  "Real End": "実際の終了",
  "Real Start Date": "実際の開始日",
  "Real Start": "実際の開始",
  "Real Times": "実時間",
  "Scheduled Duration": "予定された期間",
  "Scheduled End Date": "予定終了日",
  "Scheduled End": "予定された終了",
  "Scheduled Start Date": "予定開始日",
  "Scheduled Start": "予定された開始",
  "Scheduled Times": "予定時間",
  "Select a type": "タイプを選択",
  "Select Assignees": "割り当てられた人を選択",
  "Select Colors": "色を選択",
  "Show Completed": "完了を表示",
  "Start Date": "開始日",
  "Submitting...": "送信中...",
  "There are no comments in this activity.": "この活動にコメントはありません。",
  "There are no comments in this group.": "このグループにコメントはありません。",
  "There are no dependents/depedencies of this activity.": "この活動に依存するものはありません。",
  "There are no histories in this group.": "このグループに履歴はありません。",
  "There are no items in this module.": "このモジュールにアイテムはありません。",
  "There are no more daily reports available.": "これ以上の日報はありません。",
  "There are no more documents available.": "これ以上の文書はありません。",
  "There are no more drawings available.": "これ以上の図面はありません。",
  "There are no more inspections available.": "これ以上の検査はありません。",
  "There are no more meetings available.": "これ以上の会議はありません。",
  "There are no more RFIs available.": "これ以上のRFIはありません。",
  "There are no more tasks available.": "これ以上のタスクはありません。",
  "There is history in this activity.": "この活動には履歴があります。",
  "There is history in this group.": "このグループには履歴があります。",
  "This activity does noes depend on any other activity.": "この活動は他の活動に依存していません。",
  "This feature is under construction.": "この機能は現在開発中です。",
  "Update Roadblock": "障害の更新",
  "You have marked this milestone as completed.": "このマイルストーンを完了としてマークしました。",
  "You have marked this milestone as not completed.": "このマイルストーンを未完了としてマークしました。",
  "You have not invited anyone yet.": "まだ誰も招待していません。",
  Activities: "活動",
  Activity: "活動",
  Assigned: "割り当て済み",
  Assignees: "割り当てられた人",
  Budget: "予算",
  Cancel: "キャンセル",
  Chart: "チャート",
  Close: "閉じる",
  CLOSE: "閉じる",
  Colors: "色",
  Comments: "コメント",
  Cost: "コスト",
  Create: "作成",
  Day: "日",
  Dependencies: "依存関係",
  Description: "説明",
  Difference: "差異",
  Documents: "文書",
  Drawings: "図面",
  Duration: "持続時間",
  Email: "メール",
  Error: "エラー",
  Everyone: "全員",
  Expenses: "経費",
  Files: "ファイル",
  Grid: "グリッド",
  History: "履歴",
  Inspections: "検査",
  Invite: "招待",
  Meetings: "会議",
  Milestone: "マイルストーン",
  Name: "名前",
  No: "いいえ",
  OPEN: "開く",
  Permissions: "権限",
  Predecessor: "前任者",
  Progress: "進行中",
  RESCHEDULE: "再スケジュール",
  RFIs: "RFI",
  Roadblocks: "障害物",
  Select: "選択",
  Send: "提出",
  Share: "共有",
  Status: "ステータス",
  Success: "成功",
  Successor: "後継者",
  Tasks: "タスク",
  Title: "タイトル",
  to: "に",
  Total: "合計",
  Update: "更新",
  Week: "週",
  Yes: "はい",
  "DOWNLOAD PDF": "PDFをダウンロード",
  "Additional Columns": "追加の列",
  ACTIVITY_CREATED: "アクティビティが作成されました",
  ACTIVITY_UPDATED_ASSIGNEES_CREATED: '担当者 "{{ name }}" が追加されました',
  ACTIVITY_UPDATED_ASSIGNEES_REMOVED: '担当者 "{{ name }}" が削除されました',
  ACTIVITY_UPDATED_COLOR: '色が "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_DESCRIPTION: '説明が "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_ID_ACTIVITY_UPDATED: '前任者が "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_PROGRESS: '進捗が "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_CREATED: '経費ライン "{{ title }}" が追加されました',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_REMOVED: '経費ライン "{{ title }}" が削除されました',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_AMOUNT:
    '経費ライン "{{ number }}" の金額が "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_FILE:
    '経費ライン "{{ number }}" のファイルが "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_TITLE:
    '経費ライン "{{ number }}" のタイトルが "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_PROJECT_COST_BUDGET: '予算が "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_REAL_TIMES_END: '実際の終了時刻が "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_REAL_TIMES_START: '実際の開始時刻が "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_END: '予定終了時刻が "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_START: '予定開始時刻が "{{ old }}" から "{{ new }}" に変更されました',
  ACTIVITY_UPDATED_TITLE: 'タイトルが "{{ old }}" から "{{ new }}" に変更されました',
  DAILY_REPORT_CREATED: '日次報告書 "{{ title }}" が追加されました',
  DAILY_REPORT_REMOVED: '日次報告書 "{{ title }}" が削除されました',
  DOCUMENT_CREATED: '文書 "{{ name }}" が追加されました',
  DOCUMENT_REMOVED: '文書 "{{ name }}" が削除されました',
  DRAWING_CREATED: '図面 "{{ name }}" が追加されました',
  DRAWING_REMOVED: '図面 "{{ name }}" が削除されました',
  INSPECTION_CREATED: '検査 "{{ title }}" が追加されました',
  INSPECTION_REMOVED: '検査 "{{ title }}" が削除されました',
  MEETING_CREATED: '会議 "{{ title }}" が追加されました',
  MEETING_REMOVED: '会議 "{{ title }}" が削除されました',
  RFI_CREATED: 'RFI "{{ title }}" が追加されました',
  RFI_REMOVED: 'RFI "{{ title }}" が削除されました',
  ROADBLOCK_CREATED: '障害 "{{ description }}" が作成されました',
  ROADBLOCK_UPDATED_ASSIGNEES_CREATED: '障害 "{{ description }}" に担当者 "{{ name }}" が追加されました',
  ROADBLOCK_UPDATED_ASSIGNEES_REMOVED: '障害 "{{ description }}" から担当者 "{{ name }}" が削除されました',
  ROADBLOCK_UPDATED_DATE: '障害 "{{ description }}" の日付が "{{ old }}" から "{{ new }}" に変更されました',
  ROADBLOCK_UPDATED_DESCRIPTION: '障害 "{{ description }}" の説明が "{{ old }}" から "{{ new }}" に変更されました',
  ROADBLOCK_UPDATED_STATUS: '障害 "{{ description }}" の状態が "{{ old }}" から "{{ new }}" に変更されました',
  ROADBLOCK_UPDATED_TYPE: '障害 "{{ description }}" のタイプが "{{ old }}" から "{{ new }}" に変更されました',
  TASK_CREATED: 'タスク "{{ title }}" が追加されました',
  TASK_REMOVED: 'タスク "{{ title }}" が削除されました',
};
