import { Add, ArrowBack, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useAddLocationsMutation,
  useGetLocationsQuery,
} from "../../../../features/locations/locationApiSlice";
import { selectCurrentProject } from "../../../../features/project/projectSlice";

const locationTypes = ["tier 1", "tier 2", "tier 3"];

function UploadPhotoLocationSelect({
  selectedLocation,
  onChange,
  mb = "20px",
  size = "medium",
}) {
  const currentProject = useSelector(selectCurrentProject);
  const { t: tGeneral } = useTranslation("general");
  const { t: tPhotos } = useTranslation("photos");

  const { data: locations, isLoading: isLocationsLoading } =
    useGetLocationsQuery(currentProject.id, {
      skip: !currentProject.id,
      refetchOnMountOrArgChange: true,
    });
  const [addLocation, { isLoading }] = useAddLocationsMutation();
  const [currentTier, setCurrentTier] = React.useState(0);
  const [superLocation, setSuperLocation] = React.useState([]);
  const [newLocation, setNewLocation] = React.useState(null);

  const handleAddLocation = async () => {
    await addLocation({
      idProject: currentProject.id,
      locationName: newLocation,
      locationType: locationTypes[currentTier],
      superLocationId:
        currentTier > 0 ? superLocation[currentTier - 1].id : null,
    });
    setNewLocation(null);
  };

  const handleNextTier = (location) => {
    if (currentTier < tiers.length) {
      setSuperLocation((prev) => [...prev, location]);
      setCurrentTier((prev) => prev + 1);
      setNewLocation(null);
    }
  };

  const handlePrevTier = () => {
    const lastId = superLocation[currentTier - 1].id;
    setSuperLocation((prev) => prev.filter((loc) => loc.id !== lastId));
    setCurrentTier((prev) => prev - 1);
    setNewLocation(null);
  };

  const tiers = React.useMemo(() => {
    if (locations) {
      return Object.keys(locations);
    }
    return [];
  }, [locations]);

  const filterBySuperLocation = (location) => {
    if (superLocation.length === 0) {
      return true;
    }
    return superLocation[currentTier - 1].id === location.superLocation;
  };

  const isLocationAdding = newLocation !== null;

  if (isLocationsLoading) {
    return null;
  }

  return (
    <FormControl fullWidth sx={{ mb }} size={size}>
      <InputLabel
        id="location-select-label"
        sx={{ color: "black", fontWeight: "bold" }}
      >
        {tGeneral("location")}
      </InputLabel>
      <Select
        labelId="location-select-label"
        MenuProps={{ sx: { maxHeight: "30%" } }}
        id="location-select"
        value={selectedLocation ?? ""}
        label={tGeneral("location")}
        onChange={(event, child) => {
          const { value } = event.target;
          event.stopPropagation();
          onChange(
            event.target.value.toString(),
            locations[tiers[currentTier]].find(
              (location) => location.id === value,
            )?.locationName,
          );
        }}
        SelectDisplayProps={{ style: { backgroundColor: "white" } }}
        renderValue={(value) => (
          <Typography>
            {
              locations[tiers[currentTier]]?.find(
                (location) => location.id === value,
              )?.locationName
            }
          </Typography>
        )}
        data-tour="add-photos-2"
      >
        {currentTier > 0 && (
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handlePrevTier();
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight="bold">
              {superLocation[currentTier - 1].locationName}
            </Typography>
          </Box>
        )}

        {tiers?.length &&
          locations &&
          locations[tiers[currentTier]]
            ?.filter(filterBySuperLocation)
            ?.map((location) => (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  if (location.id === selectedLocation) {
                    onChange(null);
                  }
                }}
                key={location.id}
                value={location.id}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  {location.locationName}
                  {currentTier < tiers.length - 1 && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNextTier(location);
                      }}
                    >
                      <ChevronRight />
                    </IconButton>
                  )}
                </Box>
              </MenuItem>
            ))}
        <Box display="flex" columnGap="10px" px="10px">
          {isLocationAdding ? (
            <OutlinedInput
              fullWidth
              value={newLocation}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.stopPropagation();
                setNewLocation(e.target.value);
              }}
              size="small"
              endAdornment={
                <Button
                  onClick={handleAddLocation}
                  variant="contained"
                  size="small"
                >
                  {isLoading ? (
                    <CircularProgress color="secondary" size="1.2rem" />
                  ) : (
                    tGeneral("add")
                  )}
                </Button>
              }
            />
          ) : (
            <Button
              variant="text"
              startIcon={<Add />}
              fullWidth
              style={{
                justifyContent: "flex-start",
                paddingLeft: "3%",
                color: "black",
              }}
              onClick={() => setNewLocation("")}
            >
              {tGeneral("addLocation")}
            </Button>
          )}
        </Box>
      </Select>
    </FormControl>
  );
}

export { UploadPhotoLocationSelect };
