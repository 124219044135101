import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  Skeleton,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import dialogBg from "../../../../assets/announcements/dialogBg.png";
import dialogPerson from "../../../../assets/announcements/dialogPerson.svg";
import { useAppTourContext } from "../../../../components/supportButton/context/context";
import { useGetAnnouncementQuery } from "../../../../features/project/modules/announcements/announcementApiSlice";
import { selectCurrentGuideMe } from "../../../../features/project/projectSlice";
import { DateFormat } from "../../../../services/helpers/dateFormat";

function AnnouncementDialog(props) {
  const { isOpen, onClose } = props;
  const { idAnnouncement } = useParams();
  const navigate = useNavigate();
  // Translations
  const { t: tGeneral } = useTranslation("general");
  const { t: tAnnouncements } = useTranslation("announcements");
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const { data: announcement, isLoading } = useGetAnnouncementQuery(
    idAnnouncement,
    {
      skip: !idAnnouncement,
    },
  );

  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (!tourActive || !steps || !announcement) return;

    if (
      tourActive &&
      stepIndex === 1 &&
      currentGuideMe === "announcement-view" &&
      announcement
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      stepIndex === steps.length - 1 &&
      currentGuideMe === "announcement-view"
    ) {
      navigate(-1);
    }
  }, [currentGuideMe, steps, tourActive, stepIndex, announcement]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: { borderRadius: 22, width: "420px" },
        "data-tour": "view-announcement-2",
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#ffc900",
          backgroundImage: `url(${dialogBg})`,
          color: "white",
          padding: 2,
          p: 3,
          opacity: 1,
        }}
        id="scroll-dialog-title"
      >
        <Typography
          color="secondary"
          variant="h6"
          sx={{
            fontWeight: "bold",
          }}
        >
          {tAnnouncements("announcement")}
          <IconButton
            aria-label="closeDialog"
            size="small"
            onClick={onClose}
            sx={{ color: "black", position: "absolute", right: 15 }}
          >
            <Close />
          </IconButton>
        </Typography>

        {isLoading ? (
          <Skeleton variant="wave" />
        ) : (
          <DateFormat
            date={announcement?.expirationDate}
            props={{ position: "relative", top: -5 }}
          />
        )}
        <Divider
          sx={{
            backgroundColor: "black",
            borderBottomWidth: 1,
            mt: 2,
            mb: 2,
          }}
        >
          <img
            src={dialogPerson}
            alt="PersonLogo"
            style={{
              width: 140,
              height: 140,
              position: "absolute",
              top: 12,
              right: 50,
            }}
          />
        </Divider>
      </DialogTitle>
      <DialogContent
        sx={{
          margin: 3,
        }}
      >
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
          sx={{ color: "black", fontSize: 14 }}
        >
          {announcement?.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          margin: "auto",
          pb: 2,
        }}
      >
        <Button
          autoFocus
          onClick={onClose}
          variant="outlined"
          color="secondary"
          sx={{ pl: 8, pr: 8 }}
        >
          {tGeneral("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AnnouncementDialog;
