// eslint-disable-next-line import/prefer-default-export
export const projectSchedule = {
  "% Complete": "% Completo",
  "$ Project Cost": "Custo do Projeto",
  "Add New": "Adicionar Novo",
  "All Colors": "Todas as Cores",
  "All Dates": "Todas as Datas",
  "Are you sure you want to delete the activity": "Tem certeza de que deseja excluir a atividade?",
  "Are you sure you want to delete the group": "Tem certeza de que deseja excluir o grupo?",
  "Are you sure you want to delete the milestone": "Tem certeza de que deseja excluir o marco?",
  "Are you sure?": "Você tem certeza?",
  "Choose color": "Escolha a cor",
  "Copy Link": "Copiar link",
  "Create Activity": "Criar Atividade",
  "Create Group": "Criar Grupo",
  "Create Milestone": "Criar Marco",
  "Create Roadblock": "Criar Obstáculo",
  "Daily Reports": "Relatórios Diários",
  "Date Format:": "Formato da Data:",
  "Default Color": "Cor Padrão",
  "Delete Activity": "Excluir Atividade",
  "Delete Group": "Excluir Grupo",
  "Delete Milestone": "Excluir Marco",
  "Dependent On": "Dependente De",
  "Depends On": "Depende De",
  "Due Date": "Data de Vencimento",
  "End Date": "Data de Fim",
  "Expand All Groups": "Expandir Todos os Grupos",
  "Export to Excel": "Exportar para Excel",
  "Hide Completed": "Ocultar Concluídos",
  "Import from Excel": "Importar do Excel",
  "Import or export": "Importar ou exportar",
  "Invite people": "Convidar pessoas",
  "No other activity depends on this activity.": "Nenhuma outra atividade depende desta.",
  "Project Schedule": "Agenda do Projeto",
  "Real Duration": "Duração Real",
  "Real End Date": "Data de Término Real",
  "Real End": "Fim Real",
  "Real Start Date": "Data de Início Real",
  "Real Start": "Início Real",
  "Real Times": "Tempos Reais",
  "Scheduled Duration": "Duração Programada",
  "Scheduled End Date": "Data de Término Programada",
  "Scheduled End": "Fim Programado",
  "Scheduled Start Date": "Data de Início Programada",
  "Scheduled Start": "Início Programado",
  "Scheduled Times": "Tempos Programados",
  "Select a type": "Selecione um tipo",
  "Select Assignees": "Selecionar Atribuídos",
  "Select Colors": "Selecionar Cores",
  "Show Completed": "Mostrar Concluídos",
  "Start Date": "Data de Início",
  "Submitting...": "Submetendo...",
  "There are no comments in this activity.": "Não há comentários nesta atividade.",
  "There are no comments in this group.": "Não há comentários neste grupo.",
  "There are no dependents/depedencies of this activity.": "Não há dependentes/dependências desta atividade.",
  "There are no histories in this group.": "Não há históricos neste grupo.",
  "There are no items in this module.": "Não há itens neste módulo.",
  "There are no more daily reports available.": "Não há mais relatórios diários disponíveis.",
  "There are no more documents available.": "Não há mais documentos disponíveis.",
  "There are no more drawings available.": "Não há mais desenhos disponíveis.",
  "There are no more inspections available.": "Não há mais inspeções disponíveis.",
  "There are no more meetings available.": "Não há mais reuniões disponíveis.",
  "There are no more RFIs available.": "Não há mais RFIs disponíveis.",
  "There are no more tasks available.": "Não há mais tarefas disponíveis.",
  "There is history in this activity.": "Há histórico nesta atividade.",
  "There is history in this group.": "Há histórico neste grupo.",
  "This activity does noes depend on any other activity.": "Esta atividade não depende de nenhuma outra.",
  "This feature is under construction.": "Esta funcionalidade está em construção.",
  "Update Roadblock": "Atualizar Bloqueio",
  "You have marked this milestone as completed.": "Você marcou este marco como concluído.",
  "You have marked this milestone as not completed.": "Você marcou este marco como não concluído.",
  "You have not invited anyone yet.": "Você ainda não convidou ninguém.",
  Activities: "Atividades",
  Activity: "Atividade",
  Assigned: "Atribuído",
  Assignees: "Atribuídos",
  Budget: "Orçamento",
  Cancel: "Cancelar",
  Chart: "Gráfico",
  Close: "Fechar",
  CLOSE: "FECHAR",
  Colors: "Cores",
  Comments: "Comentários",
  Cost: "Custo",
  Create: "Criar",
  Day: "Dia",
  Dependencies: "Dependências",
  Description: "Descrição",
  Difference: "Diferença",
  Documents: "Documentos",
  Drawings: "Desenhos",
  Duration: "Duração",
  Email: "E-mail",
  Error: "Erro",
  Everyone: "Todos",
  Expenses: "Despesas",
  Files: "Arquivos",
  Grid: "Grade",
  History: "Histórico",
  Inspections: "Inspeções",
  Invite: "Convidar",
  Meetings: "Reuniões",
  Milestone: "Marco",
  Name: "Nome",
  No: "Não",
  OPEN: "ABRIR",
  Permissions: "Permissões",
  Predecessor: "Predecessor",
  Progress: "Progresso",
  RESCHEDULE: "REAGENDAR",
  RFIs: "RFIs",
  Roadblocks: "Obstáculos",
  Select: "Selecionar",
  Send: "Submeter",
  Share: "Compartilhar",
  Status: "Status",
  Success: "Sucesso",
  Successor: "Sucessor",
  Tasks: "Tarefas",
  Title: "Título",
  to: "para",
  Total: "Total",
  Update: "Atualizar",
  Week: "Semana",
  Yes: "Sim",
  "DOWNLOAD PDF": "BAIXAR PDF",
  "Additional Columns": "Colunas Adicionais",
  ACTIVITY_CREATED: "A atividade foi criada",
  ACTIVITY_UPDATED_ASSIGNEES_CREATED: 'Atribuído "{{ name }}" foi adicionado',
  ACTIVITY_UPDATED_ASSIGNEES_REMOVED: 'Atribuído "{{ name }}" foi removido',
  ACTIVITY_UPDATED_COLOR: 'A cor foi alterada de "{{ old }}" para "{{ new }}"',
  ACTIVITY_UPDATED_DESCRIPTION: 'A descrição foi alterada de "{{ old }}" para "{{ new }}"',
  ACTIVITY_UPDATED_ID_ACTIVITY_UPDATED: 'O predecessor foi alterado de "{{ old }}" para "{{ new }}"',
  ACTIVITY_UPDATED_PROGRESS: 'O progresso foi alterado de "{{ old }}" para "{{ new }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_CREATED: 'Linha de despesa "{{ title }}" foi adicionada',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_REMOVED: 'Linha de despesa "{{ title }}" foi removida',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_AMOUNT:
    'O valor foi alterado de "{{ old }}" para "{{ new }}" na linha de despesa "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_FILE:
    'O arquivo foi alterado de "{{ old }}" para "{{ new }}" na linha de despesa "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_TITLE:
    'O título foi alterado de "{{ old }}" para "{{ new }}" na linha de despesa "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_BUDGET: 'O orçamento foi alterado de "{{ old }}" para "{{ new }}"',
  ACTIVITY_UPDATED_REAL_TIMES_END: 'O horário de término real foi alterado de "{{ old }}" para "{{ new }}"',
  ACTIVITY_UPDATED_REAL_TIMES_START: 'O horário de início real foi alterado de "{{ old }}" para "{{ new }}"',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_END: 'O horário de término programado foi alterado de "{{ old }}" para "{{ new }}"',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_START: 'O horário de início programado foi alterado de "{{ old }}" para "{{ new }}"',
  ACTIVITY_UPDATED_TITLE: 'O título foi alterado de "{{ old }}" para "{{ new }}"',
  DAILY_REPORT_CREATED: 'Relatório diário "{{ title }}" foi adicionado',
  DAILY_REPORT_REMOVED: 'Relatório diário "{{ title }}" foi removido',
  DOCUMENT_CREATED: 'Documento "{{ name }}" foi adicionado',
  DOCUMENT_REMOVED: 'Documento "{{ name }}" foi removido',
  DRAWING_CREATED: 'Desenho "{{ name }}" foi adicionado',
  DRAWING_REMOVED: 'Desenho "{{ name }}" foi removido',
  INSPECTION_CREATED: 'Inspeção "{{ title }}" foi adicionada',
  INSPECTION_REMOVED: 'Inspeção "{{ title }}" foi removida',
  MEETING_CREATED: 'Reunião "{{ title }}" foi adicionada',
  MEETING_REMOVED: 'Reunião "{{ title }}" foi removida',
  RFI_CREATED: 'RFI "{{ title }}" foi adicionado',
  RFI_REMOVED: 'RFI "{{ title }}" foi removido',
  ROADBLOCK_CREATED: 'Obstáculo "{{ description }}" foi criado',
  ROADBLOCK_UPDATED_ASSIGNEES_CREATED: 'Atribuído "{{ name }}" foi adicionado ao obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_ASSIGNEES_REMOVED: 'Atribuído "{{ name }}" foi removido do obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_DATE: 'A data foi alterada de "{{ old }}" para "{{ new }}" no obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_DESCRIPTION:
    'A descrição foi alterada de "{{ old }}" para "{{ new }}" no obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_STATUS: 'O status foi alterado de "{{ old }}" para "{{ new }}" no obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_TYPE: 'O tipo foi alterado de "{{ old }}" para "{{ new }}" no obstáculo "{{ description }}"',
  TASK_CREATED: 'Tarefa "{{ title }}" foi adicionada',
  TASK_REMOVED: 'Tarefa "{{ title }}" foi removida',
};
