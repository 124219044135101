import {
  CheckCircle,
  Close,
  ExpandMore,
  InsertDriveFile,
  WarningAmber,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { v4 } from "uuid";
import {
  useGetPresignUrlsMutation,
  useAddDrawingV2Mutation,
} from "../../../features/drawings";
import {
  selectUploadingFiles,
  setUploadingFiles,
} from "../../../features/drawings/drawingsSlice";
import { TooltipIconButton } from "../../shared";
import { useCreatePresignedUrlMutation } from "../../../features/s3/s3ApiSlice";
import { useCreateDocumentMutation } from "../../../features/project/modules/documents/documentsApiSlice";

function UploadDrawingsModal() {
  const { idProject, idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errorFiles, setErrorFiles] = useState([]);
  const uploadingDrawings = useSelector(selectUploadingFiles);

  const [createPresignedUrl] = useCreatePresignedUrlMutation();
  const [createDocument] = useCreateDocumentMutation();
  const [getPresignUrls] = useGetPresignUrlsMutation();
  const [addDrawingV2] = useAddDrawingV2Mutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadingDrawings) {
      const newFiles = [...uploadingDrawings];
      setFiles((prev) => [
        ...prev.map((f) => ({ ...f, processed: true })),
        ...newFiles.map((file) => ({
          ...file,
          id: v4(),
        })),
      ]);
      dispatch(setUploadingFiles(null));
    }
  }, [uploadingDrawings, dispatch, setFiles]);

  const uploadDrawing = useCallback(
    async (files) => {
      const batchId = v4();
      const newFiles = [...files].filter((f) => !f.processed);
      const fileMeta = newFiles.map((file) => ({
        idProject,
        idEnterprise,
        filename: file.file.name,
        id: file.id,
        moduleName: "drawings",
        batchId,
      }));
      const nonDwgFile = [];
      const { uploadUrls } = await getPresignUrls(fileMeta).unwrap();
      const uploadArr = newFiles.map(async (file, idx) => {
        const uploadUrl = uploadUrls[idx];
        const uploadStatus = await fetch(uploadUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: file.file,
        });
        if (!uploadStatus.status === 200) {
          setErrorFiles((prev) => [...prev, file.id]);
        } else {
          if (!file.file.name.endsWith(".dwg")) {
            nonDwgFile.push({
              pdfFile: uploadUrl.split("?")[0],
              drawingFile: null,
              idProject,
            });
          }
          setUploadedFiles((prev) => [...prev, file.id]);
        }
      });
      await Promise.all(uploadArr);
      if (nonDwgFile.length > 0) await addDrawingV2(nonDwgFile);
    },
    [getPresignUrls, addDrawingV2, idProject, idEnterprise],
  );

  const uploadDocument = useCallback(
    async (file) => {
      const presignedUrl = await createPresignedUrl({
        idEnterprise,
        idProject,
        moduelName: "Documents",
        name: file.file.name,
      });
      await fetch(presignedUrl.data, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: file.file,
      });
      const requestBody = {
        idProject,
        url: presignedUrl.data.split("?")[0],
        name: file.file.name,
        size: (file.file.size / 1000000).toFixed(2),
        versionNo: 1,
        allowedUsers: file.allowedUsers ?? [],
        allowedCompanies: file.allowedCompanies ?? [],
        bActive: true,
        bPrivate: file.bPrivate,
        idProjectFolder: file.idProjectFolder,
      };
      const res = await createDocument(requestBody).unwrap();
      if (res.error) {
        setErrorFiles((prev) => [...prev, file.id]);
      } else {
        setUploadedFiles((prev) => [...prev, file.id]);
      }
    },
    [createDocument, createPresignedUrl, idEnterprise, idProject],
  );

  const startUpload = useCallback(async () => {
    const newFiles = [...files].filter((f) => !f.processed);
    const drawingFiles = newFiles.filter((f) => f.type === "drawing");
    const documentFiles = newFiles.filter((f) => f.type === "document");
    if (drawingFiles.length > 0) {
      await uploadDrawing(drawingFiles);
    }
    if (documentFiles.length > 0) {
      for (const file of newFiles) {
        await uploadDocument(file);
      }
    }
  }, [files, uploadDocument, uploadDrawing]);

  useEffect(() => {
    if (files.length) {
      startUpload();
    }
  }, [files, startUpload]);

  const handleClose = (e) => {
    e.stopPropagation();
    setFiles([]);
  };

  const allUploaded = useMemo(
    () =>
      files.every(
        (file) =>
          uploadedFiles.indexOf(file.id) > -1 ||
          errorFiles.indexOf(file.id) > -1,
      ),
    [files, uploadedFiles, errorFiles],
  );

  const getStatusIcon = (id) => {
    if (uploadedFiles.indexOf(id) > -1) {
      return <CheckCircle sx={{ color: "green" }} />;
    }
    if (errorFiles.indexOf(id) > -1) {
      return <WarningAmber sx={{ color: "#FFC62B" }} />;
    }
    return <CircularProgress size={15} />;
  };

  if (files.length === 0) return null;

  return (
    <Accordion
      sx={{
        position: "absolute",
        bottom: 0,
        right: 0,
        width: "400px",
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
      }}
      defaultExpanded
    >
      <AccordionSummary
        sx={{
          backgroundColor: "#F5F5F5",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
        }}
        expandIcon={<ExpandMore />}
      >
        <Box display="flex" alignItems="center" width="100%">
          <Typography>
            {tGeneral("uploadingQtyFiles", {
              qty: files.length,
            })}
          </Typography>
          <Box flex={1} />
          <TooltipIconButton
            icon={<Close />}
            label={tGeneral("close")}
            onClick={handleClose}
            disabled={!allUploaded}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          flexDirection="column"
          maxHeight="300px"
          sx={{ overflowY: "auto", overflowX: "hidden" }}
          rowGap="0.5rem"
        >
          {files.map((file) => (
            <Box
              display="flex"
              alignItems="center"
              height="1.5rem"
              columnGap="0.5rem"
              p="0.25rem 1rem"
            >
              <InsertDriveFile sx={{ color: "#E6B60C" }} />
              <Tooltip title={file.file.name}>
                <Typography
                  noWrap
                  sx={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {file.file.name}
                </Typography>
              </Tooltip>
              {getStatusIcon(file.id)}
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export { UploadDrawingsModal };
