import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  TextField,
  Box,
  Button,
  MenuItem,
  Switch,
  FormControl,
  InputLabel,
  IconButton,
  CardActions,
  Divider,
  Card,
  Modal,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  Lock,
  LockOpen,
  CircleSharp,
  Subject,
  DescriptionOutlined,
  InfoOutlined,
  Add,
} from "@mui/icons-material";
import Select from "@mui/material/Select";
import {
  useGetProjectConfigurationQuery,
  useGetProjectUsersQuery,
} from "../../../features/project/projectApiSlice";
import {
  useCreateSubmittalMutation,
  useEditSubmittalMutation,
  useGetSubmittalByIdQuery,
} from "../../../features/project/modules/submittals/submittalApiSlice";
import { useTranslation } from "react-i18next";
import { useUploadFilesMutation } from "../../../features/files";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { PopUpAlert } from "../../../components/PopUpAlert";
import {
  CreateFormUsersSide,
  FilePreviewHorizontalScroll,
  FormHeader,
} from "../../../components/shared";
import { DatePicker } from "@mui/x-date-pickers";
import { NestedLocationSelect } from "../../../components/shared/NestedLocationSelect";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import TypeSelect from "../../../components/projectDashboard/Submittals/TypeSelect";
import CostCodeSelect from "../../../components/projectDashboard/Submittals/CostCodeSelect/CostCodeSelect";
import SpecificationSelect from "../../../components/shared/SpecificationSelect/SpecificationSelect";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//Props
//TODO: put in global styles, also used in rfis
const label = { inputProps: { "aria-label": "Color switch demo" } };

function SubmittalForm() {
  const { idProject, idSubmittal } = useParams();

  const navigate = useNavigate();

  const { t: tGeneral } = useTranslation("general");
  const { t: tSubmittals } = useTranslation("submittals");
  // const { currentStep, isOpen: isTourOpen } = useTour(); // CHECK

  const { data: projectUsersData, isSuccess } =
    useGetProjectUsersQuery(idProject);
  const { data: submittal } = useGetSubmittalByIdQuery(idSubmittal, {
    skip: !idSubmittal,
  });
  const { data: projectConfig } = useGetProjectConfigurationQuery(idProject);

  const [
    newSubmittal,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateSubmittalMutation();
  const [
    updateSubmittal,
    {
      isLaoding: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useEditSubmittalMutation();
  const [uploadFiles, { isLoading: isFilesLoading }] = useUploadFilesMutation();

  const [deletedFiles, setDeletedFiles] = useState([]);

  const validationSchema = yup.object({
    title: yup.string("Enter a valid title").required("This field is required"),
    dueDate: yup
      .string("Enter a valid date")
      .required("This field is required"),
    assignedTo: yup.array().of(yup.string()).min(1, "This field is required"),
  });

  const formContext = useForm({
    defaultValues: {
      title: "",
      status: "open",
      assignedTo: [],
      dueDate: null,
      bPrivate: false,
      files: [],
      question: "",
      receivedFrom: "",
      specification: "",
      type: "",
      location: "",
      codeCost: "",
      distributionList: [],
    },
    disabled: isCreateLoading || isUpdateLoading || isFilesLoading,
    resolver: yupResolver(validationSchema),
  });

  const { register, getValues, control, watch, setValue, handleSubmit, reset } =
    formContext;

  const projectUsers = useMemo(
    () => (projectUsersData ? projectUsersData.map((user) => user.user) : []),
    [projectUsersData],
  );

  useEffect(() => {
    if (projectConfig && !idSubmittal) {
      setValue("distributionList", projectConfig["submittalsDistributionList"]);
      const newDate = moment(new Date())
        .add(projectConfig["submittalsDefaultDeliveryDays"], "days")
        .startOf("day");
      setValue("dueDate", newDate);
    }
  }, [projectConfig, idSubmittal, setValue]);

  useEffect(() => {
    if (isUpdateSuccess) {
      PopUpAlert(
        "success",
        tGeneral("updated"),
        tGeneral("updatedSuccessfully"),
      );
      navigate("../../submittal");
    }
    if (isUpdateError) {
      PopUpAlert("error", "Error", updateError.message);
    }
  }, [isUpdateSuccess, isUpdateError, updateError, tGeneral, navigate]);

  useEffect(() => {
    if (isCreateSuccess) {
      PopUpAlert(
        "success",
        tGeneral("created"),
        tGeneral("createdSuccessfully"),
      );
      navigate("../submittal");
    }
    if (isCreateError) {
      PopUpAlert("error", "Error", createError.message);
    }
  }, [isCreateSuccess, isCreateError, createError, tGeneral, navigate]);

  useEffect(() => {
    if (submittal) {
      const {
        assignedTo,
        distributionList,
        question: { question, files },
        dueDate,
        bPrivate,
        specification,
        idType,
        idCostCode,
        idLocation,
        status,
        title,
        receivedFrom,
        specificationObject,
        typeObject,
        costCodeObject,
      } = submittal;
      reset({
        assignedTo: assignedTo.map((user) => user.id),
        distributionList: distributionList.map((user) => user.id),
        question,
        files,
        dueDate: moment(dueDate).utc(),
        bPrivate,
        specification: specificationObject ? specificationObject.id : "",
        type: typeObject ? typeObject.id : "",
        codeCost: costCodeObject ? costCodeObject.id : "",
        location: idLocation,
        status,
        title,
        receivedFrom: receivedFrom ? receivedFrom.id : null,
      });
    }
  }, [submittal, reset]);

  const statusOptions = [
    {
      value: "open",
      label: tGeneral("open_action").toUpperCase(),
      color: "#5ce381",
    },
    {
      value: "draft",
      label: tGeneral("draft").toUpperCase(),
      color: "#ffc900",
    },
    {
      value: "closed",
      label: tGeneral("closed").toUpperCase(),
      color: "#707070",
    },
  ];

  const onSubmit = async ({
    files,
    receivedFrom,
    specification,
    type,
    codeCost,
    location,
    ...formData
  }) => {
    const body = {
      ...formData,
    };

    if (receivedFrom) body.receivedFrom = receivedFrom;
    if (specification) body.specification = specification;
    if (type) body.type = type;
    if (codeCost) body.codeCost = codeCost;
    if (location) body.location = location;

    if (files.length > 0) {
      const idQuestion = idSubmittal ? submittal.question.id : uuidv4();
      const filesFormData = new FormData();
      files.forEach((file) => {
        filesFormData.append("files", file);
      });
      await uploadFiles({
        idObject: idQuestion,
        moduleName: "submittals",
        body: filesFormData,
      });
      body.idQuestion = idQuestion;
    }
    if (idSubmittal) {
      body.id = idSubmittal;
      body.deletedFiles = deletedFiles;
      body.idQuestion = submittal.question.id;
      body.idChat = submittal.question.id;
      updateSubmittal(body);
    } else {
      body.idProject = idProject;
      body.createDate = moment(new Date());
      newSubmittal(body);
    }
  };

  const handleDeleteQuestionFile = (file, i) => {
    const files = getValues("files");
    if (file.id) {
      setDeletedFiles([...deletedFiles, file.id]);
    }
    files.splice(i, 1);
    setValue("files", [...files]);
  };

  const bPrivate = watch("bPrivate");
  const files = watch("files");
  const assignedTo = watch("assignedTo");
  const distributionList = watch("distributionList");
  const idReceivedFrom = watch("receivedFrom");
  const selectedType = watch("type");
  const selectedLocation = watch("location");
  const selectedCostCode = watch("codeCost");
  const selectedSpecification = watch("specification");

  const handleAddFiles = (newFiles) => setValue("files", newFiles);

  const getSelectedReceivedFrom = (idUser) => {
    const selectedUser = projectUsers.find((user) => user.id === idUser);
    if (!selectedUser) return "";

    return `${selectedUser.firstName} ${selectedUser.lastName}`;
  };

  return (
    <FormProvider {...formContext}>
      <Box width="100%" component="form" onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          onBack={() => navigate(idSubmittal ? "./../.." : "./..")}
          isEditing={Boolean(idSubmittal)}
          isSubmit
          title={
            idSubmittal
              ? `${tGeneral("edit")} ${tSubmittals("submittal")}`
              : `${tGeneral("add")} ${tSubmittals("submittal")}`
          }
          dataTourNameButton="add-submittal-15"
        />
        <Box
          display="flex"
          width="100%"
          columnGap="10px"
          height="88vh"
          p="10px"
          overflow="hidden"
        >
          <Box
            flex={2}
            display="flex"
            flexDirection="column"
            rowGap="10px"
            pt="10px"
            overflow="hidden"
          >
            <Box display="flex" columnGap="10px">
              <Controller
                control={control}
                name="title"
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    label={tGeneral("title")}
                    placeholder={tGeneral("title")}
                    {...field}
                    data-tour="add-submittal-2"
                  />
                )}
              />
              <Box data-tour="add-submittal-3">
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="dueDate"
                  render={({ field }) => (
                    <DatePicker
                      label={tGeneral("due_date")}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          required
                          label={tGeneral("due_date")}
                          {...params}
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box display="flex" columnGap="10px">
              <FormControl fullWidth>
                <InputLabel>{tGeneral("status")}</InputLabel>
                <Select
                  placeholder={tGeneral("status")}
                  label={tGeneral("status")}
                  {...register("status")}
                  value={watch("status")}
                  data-tour="add-submittal-4"
                  MenuProps={{
                    disableScrollLock: true,
                    MenuListProps: {
                      "data-tour": "highlighted-add-submittal-4",
                    },
                  }}
                  // {...(isTourOpen && currentStep < 4 && { open: true })}
                >
                  {statusOptions
                    .filter(({ value }) => idSubmittal || value !== "closed")
                    .map(({ value, label, color }) => (
                      <MenuItem value={value} key={value}>
                        <Box display="flex" alignItems="center">
                          <CircleSharp sx={{ color }} />
                          {label}
                        </Box>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Card sx={{ width: "100%", height: "100%" }}>
                <CardActions
                  sx={{ justifyContent: "space-between" }}
                  data-tour="add-submittal-5"
                >
                  <Box>
                    <IconButton title="Back">
                      {bPrivate ? <Lock /> : <LockOpen />}
                    </IconButton>
                    {bPrivate ? tGeneral("private") : tGeneral("public")}
                  </Box>
                  <Switch
                    {...label}
                    {...register("bPrivate")}
                    color="primary"
                  />
                </CardActions>
              </Card>
            </Box>
            <Divider />
            <Box
              flex={1}
              sx={{ overflowY: "auto", width: "100%", overflowX: "hidden" }}
            >
              <Box data-tour="add-submittal-7">
                <Box
                  sx={{ alignItems: "center", display: "flex", mb: "10px" }}
                  columnGap="10px"
                >
                  <Subject />
                  {tGeneral("question")}
                </Box>
                <Controller
                  name="question"
                  fullWidth
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      rows={6}
                      fullWidth
                      multiline
                      required
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box data-tour="add-submittal-8">
                <Box
                  display="flex"
                  alignItems="center"
                  mt="10px"
                  columnGap="10px"
                >
                  <DescriptionOutlined />
                  <Typography>{tGeneral("files")}</Typography>
                </Box>
                <FilePreviewHorizontalScroll
                  onAddPhotos={handleAddFiles}
                  onDelete={handleDeleteQuestionFile}
                  files={files}
                />
              </Box>
              <Divider />
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                  p: "5px",
                  height: "auto",
                }}
                data-tour="edit-submittal-8"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  mt="10px"
                  columnGap="10px"
                >
                  <InfoOutlined />
                  <Typography>{tGeneral("additionalInformation")}</Typography>
                </Box>
                <Box display="flex" columnGap="10px">
                  <FormControl fullWidth data-tour="add-submittal-9">
                    <InputLabel>{tGeneral("receivedFrom")}</InputLabel>
                    <Select
                      label={tGeneral("receivedFrom")}
                      {...register("receivedFrom")}
                      value={idReceivedFrom}
                      MenuProps={{ sx: { maxHeight: "300px" } }}
                      renderValue={getSelectedReceivedFrom}
                      // {...(isTourOpen && currentStep > 5 && { open: true })}
                    >
                      {projectUsers.map((user) => (
                        <MenuItem
                          value={user.id}
                          key={user.id}
                          sx={{ display: "flex" }}
                        >
                          <Avatar src={user.urlAvatar}>
                            {user.firstName[0]}
                            {user.lastName[0]}
                          </Avatar>
                          <Typography ml="10px">
                            {user.firstName} {user.lastName}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/*
                  <Controller
                    disabled
                    name="company"
                    control={control}
                    render={({ field }) => (
                    */}
                  <TextField
                    fullWidth
                    // {...field}
                    disabled
                    label={tGeneral("company")}
                    placeholder={tGeneral("company")}
                    value={
                      idReceivedFrom
                        ? projectUsers.find(
                            (projectUser) => projectUser.id === idReceivedFrom,
                          )?.firstName
                        : ""
                    }
                  />
                  {/*
                    )}
                  />
                  */}
                </Box>
                <Box display="flex" columnGap="10px">
                  <Box sx={{ minWidth: "49%" }} data-tour="add-submittal-10">
                    <TypeSelect
                      selectedType={selectedType}
                      onChange={(type) => setValue("type", type)}
                    />
                  </Box>
                  <Box sx={{ minWidth: "49%" }} data-tour="add-submittal-11">
                    <NestedLocationSelect
                      selectedLocation={selectedLocation}
                      onChange={(location) => setValue("location", location)}
                    />
                  </Box>
                </Box>
                <Box display="flex" columnGap="10px">
                  <Box sx={{ minWidth: "49%" }} data-tour="add-submittal-12">
                    <SpecificationSelect
                      selectedSpecification={selectedSpecification}
                      onChange={(specification) =>
                        setValue("specification", specification)
                      }
                    />
                  </Box>
                  <Box sx={{ minWidth: "49%" }} data-tour="add-submittal-13">
                    <CostCodeSelect
                      selectedCostCode={selectedCostCode}
                      onChange={(costCode) => setValue("codeCost", costCode)}
                    />
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>
          <CreateFormUsersSide
            distributionListName={DISTRIBUTION_LIST_NAMES.submittals}
            lists={[
              {
                label: tGeneral("assigned"),
                name: "assignedTo",
                dataTourName: "add-submittal-6",
                required: true,
              },
            ]}
            distributionListDataTour="add-submittal-14"
          />
        </Box>
        <Modal
          open={isUpdateLoading || isCreateLoading || isFilesLoading}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size="100px" color="primary" />
        </Modal>
      </Box>
    </FormProvider>
  );
}

export default SubmittalForm;
