import { useState, useEffect, useMemo, forwardRef, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
// Icons
import {
  Add,
  Delete,
  Edit,
  Search,
  Refresh,
  ArrowForwardIosSharp,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import stringUtil from "../../utils/string";
import { selectCurrentEnterprise } from "../../features/enterprise/enterpriseSlice";
import {
  useDeleteContactMutation,
  useGetContactsQuery,
} from "../../features/enterprise/contact/contactApiSlice";
import ContactFormModal from "../../components/enterpriseDashboard/contacts/ContactFormModal";
import { PopUpAlert } from "../../components/PopUpAlert";
import { EmptyState, TitleWidgets } from "../../components/shared";
import UserAvatar from "../../components/userAvatars/UserAvatar";
import { useDebounce } from "../../hooks";
import { useAppTourContext } from "../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../features/project/projectSlice";

function ExternalContacts() {
  const theme = useTheme();
  const { t: tContacts } = useTranslation("contacts");
  const { t: tGeneral } = useTranslation("general");
  const currentEnterprise = useSelector(selectCurrentEnterprise);
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredContacts, setFilteredContacts] = useState(null);
  const [editingContact, setEditingContact] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const {
    data: contacts,
    isLoading,
    isError,
    refetch,
  } = useGetContactsQuery(currentEnterprise.id);

  const [
    deleteContact,
    { isError: isDeleteContactError, isSuccess: isDeleteContactSuccess },
  ] = useDeleteContactMutation();

  const onChangeSearch = (query) => {
    setSearchQuery(query);
    updateFilteredContactsDebounced(query);
  };

  const updateFilteredContactsDebounced = useDebounce(() => {
    if (!searchQuery) {
      setFilteredContacts(null);
      return;
    }
    const lowercaseQuery = searchQuery.toLowerCase();
    const filtered = contacts.filter(
      (contact) =>
        contact.firstName.includes(lowercaseQuery) ||
        contact.lastName.includes(lowercaseQuery) ||
        contact.email.includes(lowercaseQuery) ||
        `+${contact.countryCode}${contact.phone}`.includes(lowercaseQuery) ||
        contact.company.includes(lowercaseQuery),
    );
    setFilteredContacts(filtered);
  }, 300);

  const handleDeleteContact = (contactId) => {
    Swal.fire({
      title: tContacts("deleteContactConfirmation"),
      text: tGeneral("cantUndone"),
      confirmButtonText: tGeneral("yesDeleteIt"),
      confirmButtonColor: theme.palette.error.main,
      showCancelButton: true,
      cancelButtonText: tGeneral("cancel"),
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteContact(contactId);
      }
    });
  };

  const handleEditContact = (contact) => {
    setEditingContact(contact);
    setContactModalOpen(true);
  };

  const {
    state: { tourActive, stepIndex, steps, run },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (tourActive && currentGuideMe === "external-contact-add") {
      if (stepIndex === 1 || stepIndex === steps.length - 2) {
        if (!contactModalOpen) {
          setEditingContact(null);
          setContactModalOpen(true);
        }
        if (!run) {
          setTimeout(() => {
            setState({ run: true });
          }, 500);
        }
      }

      if (stepIndex === steps.length - 1 || stepIndex === 0) {
        setContactModalOpen(false);
      }
    }
  }, [tourActive, stepIndex, steps, currentGuideMe, run]);

  useEffect(() => {
    if (!tourActive || !contacts || !steps) return;

    if (tourActive && currentGuideMe === "external-contact-edit") {
      if (stepIndex === 1 || stepIndex === steps.length - 2) {
        if (contacts.length > 0) {
          setEditingContact(contacts[0]);
          setContactModalOpen(true);
          setTimeout(() => {
            setState({ run: true });
          }, 500);
        }
      }

      if (steps.length - 1 === stepIndex || stepIndex === 0) {
        setContactModalOpen(false);
        setEditingContact(null);
      }
    }
  }, [tourActive, stepIndex, currentGuideMe, contacts, steps]);

  useEffect(() => {
    if (!tourActive || !contacts || !steps) return;

    if (tourActive && currentGuideMe === "external-contact-delete") {
      if (stepIndex === 1 || stepIndex === steps.length - 2) {
        if (contacts.length > 0) {
          handleDeleteContact();
          setTimeout(() => {
            setState({ run: true });
          }, 500);
        }
      }

      if (steps.length - 1 === stepIndex || stepIndex === 0) {
        Swal.close();
      }
    }
  }, [tourActive, stepIndex, currentGuideMe, contacts, steps]);

  useEffect(() => {
    if (isDeleteContactError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("generalErrorMessage"));
    }
    if (isDeleteContactSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        tContacts("deleteContactSuccess"),
      );
    }
  }, [isDeleteContactError, isDeleteContactSuccess]);

  if (isLoading) {
    return (
      <Box
        width={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        py={8}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }
  if (isError) {
    return (
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        py={8}
      >
        <Typography fontSize={20} fontWeight="bold" textAlign="center" mb={4}>
          {tContacts("loadingError")}
        </Typography>
        <Button
          variant="contained"
          startIcon={<Refresh />}
          onClick={() => refetch()}
        >
          {tGeneral("retry")}
        </Button>
      </Box>
    );
  }
  return (
    <>
      <TitleWidgets
        title={tContacts("externalContacts")}
        addFunction={() => {
          setEditingContact(null);
          setContactModalOpen(true);
        }}
        showMoreOptions={false}
        moduleName="externalContact"
        enableSupport
        addNameTour="add-contact-1"
      />
      {/* Mobile searchbar */}
      <Box
        width={1}
        display={{ xs: "flex", md: "none" }}
        alignItems="center"
        marginBottom={2}
      >
        <TextField
          margin="dense"
          variant="outlined"
          type="text"
          label={tGeneral("search")}
          value={searchQuery}
          onChange={(e) => {
            onChangeSearch(e.target.value);
          }}
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box width={1}>
        {contacts?.length > 0 ? (
          <ContactAccordions
            contacts={filteredContacts || contacts}
            handleEdit={handleEditContact}
            handleDelete={handleDeleteContact}
          />
        ) : (
          <EmptyState
            title={tContacts("noContactsCreated")}
            content={tContacts("noContactsContent")}
            module="meetings"
            disableCasl
            button={
              <Button
                variant="contained"
                onClick={() => {
                  setEditingContact(null);
                  setContactModalOpen(true);
                }}
              >
                <Add /> {tContacts("addContact")}
              </Button>
            }
          />
        )}
      </Box>
      {contactModalOpen && (
        <ContactFormModal
          editingContact={editingContact}
          open={contactModalOpen}
          setOpen={setContactModalOpen}
        />
      )}
    </>
  );
}
// TODO: These components are temporary, delete when we migrate to stop using the "accordions" and use proper TableViews
const ContactAccordions = ({ contacts, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }
  const contactsByCompany = groupBy(contacts, "company");

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme, expanded }) => ({
    borderRadius: 11,
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "&.MuiAccordionSummary-root": {
      backgroundColor: "#FFEBBC",
      "&.Mui-expanded": {
        backgroundColor: "#FFC42D",
      },
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const {
    state: { tourActive, stepIndex, steps, run },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 0 &&
      (currentGuideMe === "external-contact-edit" ||
        currentGuideMe === "external-contact-delete")
    ) {
      setIsExpanded(true);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe]);

  return (
    <>
      {Object.entries(contactsByCompany)?.map(
        ([companyName, companyContacts], index) => {
          return (
            <Accordion
              defaultExpanded={index === 0 && isExpanded ? true : false}
              key={companyName}
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />
                }
              >
                <Typography style={{ fontWeight: "bold", fontSize: 22 }}>
                  {`${stringUtil.capitalizeFirstLetter(companyName)} (${
                    companyContacts?.length
                  })`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {companyContacts?.length > 0 && (
                  <ContactTable
                    companyContacts={companyContacts}
                    handleEdit={props.handleEdit}
                    handleDelete={props.handleDelete}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          );
        },
      )}
    </>
  );
};

const ContactTable = ({ companyContacts, ...props }) => {
  const { t: tGeneral } = useTranslation("general");
  const tableIcons = {
    Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Info: forwardRef((props, ref) => <Info {...props} ref={ref} />),
  };
  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };
  const columns = [
    {
      field: "avatar",
      title: "",
      width: 80,
      render: (rowData) => (
        <UserAvatar
          sx={{ m: 10 }}
          key={rowData?.id}
          name={`${stringUtil.capitalizeFirstLetter(
            rowData?.firstName,
          )} ${stringUtil.capitalizeFirstLetter(rowData?.lastName)}`}
        />
      ),
    },
    {
      field: "firstName",
      title: tGeneral("name"),
      width: 250,
      render: (rowData) => (
        <Typography>
          {stringUtil.capitalizeFirstLetter(rowData?.firstName)}
        </Typography>
      ),
    },
    {
      field: "lastName",
      title: tGeneral("lastName"),
      width: 250,
      render: (rowData) => (
        <Typography>
          {stringUtil.capitalizeFirstLetter(rowData?.lastName)}
        </Typography>
      ),
    },
    {
      field: "email",
      title: tGeneral("email"),
      width: 100,
      render: (rowData) => (
        <Button
          variant="text"
          sx={{
            textDecoration: "underline",
            textTransform: "lowercase",
            color: "#009DFF",
          }}
          onClick={() => window.open(`mailto:${rowData.email}`)}
        >
          {rowData?.email}
        </Button>
      ),
    },
    {
      field: "phone",
      title: tGeneral("phone"),
      width: 80,
      render: (rowData) => (
        <Button
          variant="text"
          sx={{ color: "black" }}
          onClick={() =>
            window.open(`tel:+${rowData?.countryCode}${rowData?.phone}`)
          }
        >
          {`+${rowData?.countryCode} ${rowData?.phone}`}
        </Button>
      ),
    },
    {
      title: tGeneral("actions"),
      width: 100,
      sorting: false,
      render: (rowData) => (
        <ButtonGroup>
          <IconButton
            title={tGeneral("edit")}
            onClick={() => {
              props.handleEdit(rowData);
            }}
            sx={{
              color: "black",
            }}
            data-tour={rowData.tableData.id === 0 ? "edit-contact-1" : ""}
          >
            <Edit />
          </IconButton>
          <IconButton
            title={tGeneral("delete")}
            onClick={() => {
              props.handleDelete(rowData?.id);
            }}
            sx={{
              color: "red",
            }}
            data-tour={rowData.tableData.id === 0 ? "delete-contact-1" : ""}
          >
            <Delete />
          </IconButton>
        </ButtonGroup>
      ),
    },
  ];
  const contactsData = useMemo(() => {
    if (companyContacts) {
      return companyContacts.map((contact) => ({ ...contact }));
    }
    return [];
  }, [companyContacts]);
  return (
    <MaterialTable
      icons={tableIcons}
      columns={columns}
      data={contactsData}
      localization={tableLocalization}
      options={{
        sorting: false,
        actionsColumnIndex: -1,
        toolbar: false,
        paging: false,
      }}
    />
  );
};

export default ExternalContacts;
