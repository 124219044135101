import { Close } from "@mui/icons-material";
import { Box, Divider, Drawer, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { TooltipIconButton } from "../../../../../components/shared";
import { DateFilter } from "./DateFilter";
import { OptionsFilter } from "./OptionsFilter";
import { ProjectUsersFilter } from "./ProjectUsersFilter";
import { useGetSharedProjectsQuery } from "../../../../../features/project/modules/dailyReports/dailyReportsApiSlice";

function FiltersDrawer({ isOpen, onClose, filters, setFilters }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tStatus } = useTranslation("status");

  const { data: sharedProjectsData } = useGetSharedProjectsQuery(idProject);

  const sharedProjects = useMemo(() => {
    return (
      sharedProjectsData?.map((project) => ({
        value: project.id,
        label: project.name,
      })) ?? []
    );
  }, [sharedProjectsData]);

  const [tempFilters, setTempFilters] = useState({
    range: [
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ],
    createdBy: [],
    assignedTo: [],
    status: [],
    sharedBy: [],
  });

  useEffect(() => {
    setTempFilters({
      range: [
        {
          startDate: filters?.startDate ? new Date(filters.startDate) : null,
          endDate: filters?.endDate ? new Date(filters.endDate) : null,
          key: "selection",
        },
      ],
      createdBy: filters?.createdBy ?? [],
      assignedTo: filters?.assignedTo ?? [],
      status: filters?.status ?? [],
      sharedBy: filters?.sharedBy ?? [],
    });
  }, [filters]);

  const handleChange = (name, value) => {
    const newValues = { ...tempFilters };
    newValues[name] = value;
    setTempFilters(newValues);
  };

  const handleClose = () => {
    setFilters({
      ...filters,
      status: tempFilters.status,
      createdBy: tempFilters.createdBy,
      assignedTo: tempFilters.assignedTo,
      startDate: tempFilters.range[0].startDate
        ? new Date(tempFilters.range[0].startDate).toISOString()
        : null,
      endDate: tempFilters.range[0].endDate
        ? new Date(tempFilters.range[0].endDate).toISOString()
        : null,
    });
    onClose();
  };

  return (
    <Drawer open={isOpen} onClose={handleClose} anchor="right">
      <Box>
        <Box display="flex" alignItems="center" p="1rem 0.5rem 0 0.5rem">
          <Typography flex={1} fontSize="1.25rem" px="0.5rem">
            {tGeneral("filters")}
          </Typography>
          <TooltipIconButton
            icon={<Close />}
            onClick={handleClose}
            label={tGeneral("close")}
            color="black"
          />
        </Box>
        <Divider sx={{ my: "0.5rem" }} />
        <Box display="flex" flexDirection="column" px="0.5rem">
          <Typography fontSize="0.75rem" px="0.5rem">
            {tGeneral("filterBy")}
          </Typography>
          <OptionsFilter
            value={tempFilters.sharedBy}
            onChange={(newVal) => handleChange("sharedBy", newVal)}
            title={tGeneral("sharedBy")}
            options={sharedProjects}
          />
          <DateFilter
            value={tempFilters.range}
            onChange={(newVal) => handleChange("range", newVal)}
          />
          <ProjectUsersFilter
            value={tempFilters.assignedTo}
            onChange={(newVal) => handleChange("assignedTo", newVal)}
            title={tGeneral("assigned")}
          />
          <ProjectUsersFilter
            value={tempFilters.createdBy}
            onChange={(newVal) => handleChange("createdBy", newVal)}
            title={tGeneral("createdBy")}
          />
          <OptionsFilter
            value={tempFilters.status}
            onChange={(newVal) => handleChange("status", newVal)}
            title={tGeneral("status")}
            options={[
              { value: "open", label: tStatus("open") },
              { value: "closed", label: tStatus("closed") },
              { value: "draft", label: tStatus("draft") },
            ]}
          />
        </Box>
      </Box>
    </Drawer>
  );
}

export { FiltersDrawer };
