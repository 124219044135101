import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
// MUI
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
// Icons
import { ArrowBack, Search } from "@mui/icons-material";

// Custom imports
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { PopUpAlert } from "../../../components/PopUpAlert";
import TaskBoard from "../../../components/projectDashboard/Tasks/TaskBoard";
import { useGetLocationsQuery } from "../../../features/locations/locationApiSlice";
import {
  useGetTasksQuery,
  useRestoreTaskMutation,
} from "../../../features/project/modules/tasks/tasksApiSlice";
import {
  resetSelectedTasks,
  selectSelectedTasks,
} from "../../../features/project/modules/tasks/tasksSlice";
import { useDistributionList } from "../../../hooks";
import TaskDetails from "../../../components/projectDashboard/Tasks/TaskDetails";
import { TitleWidgets } from "../../../components/shared";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";

function TaskRecycle() {
  // Translation
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");
  const { idProject } = useParams();

  // Selectors
  const selectedTasks = useSelector(selectSelectedTasks);
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  // Queries & mutations
  const {
    data: tasks,
    isLoading: tasksLoading,
    isSuccess: tasksSuccess,
  } = useGetTasksQuery({ idProject, bActive: false });
  const { data: locations } = useGetLocationsQuery(idProject, {
    skip: !idProject,
    refetchOnMountOrArgChange: true,
  });
  const [
    restoreTask,
    {
      isSuccess: isRestoreTaskSuccess,
      isError: isRestoreTaskError,
      isLoading: isRestoreTaskLoading,
    },
  ] = useRestoreTaskMutation();

  // Hooks
  const { projectUsers } = useDistributionList(idProject);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Search
  const [searchValue, setSearchValue] = useState("");
  const customSearchFunction = (value) => {
    setSearchValue(value);
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // Reset selected tasks on component unmount
    return () => {
      dispatch(resetSelectedTasks());
    };
  }, []);

  useEffect(() => {
    if (isRestoreTaskSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tTasks("taskRestoredSuccessfully"),
      );
      dispatch(resetSelectedTasks());
    }
  }, [isRestoreTaskSuccess]);

  useEffect(() => {
    if (isRestoreTaskError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      dispatch(resetSelectedTasks());
    }
  }, [isRestoreTaskError]);

  const handleRestoreTasks = async () => {
    await restoreTask({
      ids: selectedTasks,
    }).unwrap();
  };

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();
  useEffect(() => {
    if (
      tasks &&
      tasksSuccess &&
      tourActive &&
      stepIndex === 2 &&
      currentGuideMe === "tasks-recycle"
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, tasks, tasksSuccess]);

  return (
    <>
      <TitleWidgets
        title={tTasks("tasksRecycleBin")}
        showAdd={false}
        backEnabled
        restoreDisabled={!selectedTasks.length}
        onRestore={handleRestoreTasks}
        isRecycle
      />
      {/* Loading */}
      {tasksLoading && (
        <Box display="flex" justifyContent="center" width="100%" marginTop={10}>
          <CircularProgress size={60} />
        </Box>
      )}
      {/* Tasks available */}
      {tasksSuccess && (
        <TaskBoard
          tasks={tasks?.filter(function (obj) {
            return obj.title.toLowerCase().includes(searchValue.toLowerCase());
          })}
          users={projectUsers}
          locations={locations}
          height="88vh"
        />
      )}
      <LoadingOverlay open={isRestoreTaskLoading} />
      <TaskDetails />
    </>
  );
}

export default TaskRecycle;
