import { useEffect, useState } from "react";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_EN as LocalizationEN } from "material-react-table/locales/en";
import { MRT_Localization_ES as LocalizationES } from "material-react-table/locales/es";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLocale } from "../../features/preferences/preferencesSlice";

function StyledMaterialReactTable({
  columns,
  data,
  filtersComponent,
  selectedRows = {},
  setSelectedRows,
  enableRowSelection = false,
  isLoading = false,
  enableRowActions = true,
  onClickRow,
  emptyStateMessage = null,
  sizeActions = null,
  pageSize = 100,
  ...props
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tMaterialTable } = useTranslation("materialTable");
  const id = "material-react-table";

  const [height, setHeight] = useState("600px");

  const updateHeight = () => {
    const table = document.getElementById(id);
    if (table) {
      const parent = table.parentNode.parentNode;
      const parentTop = parent.getBoundingClientRect().top;
      const parentBottom = parentTop + parent.offsetHeight;
      setHeight(`${table.offsetHeight + window.innerHeight - parentBottom}px`);
    }
  };

  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const locale = useSelector(selectLocale);
  return (
    <MaterialReactTable
      // localization={locale === "es" ? LocalizationES : LocalizationEN}
      localization={{
        actions: tMaterialTable("actions"),
        and: tMaterialTable("and"),
        cancel: tMaterialTable("cancel"),
        changeFilterMode: tMaterialTable("changeFilterMode"),
        changeSearchMode: tMaterialTable("changeSearchMode"),
        clearFilter: tMaterialTable("clearFilter"),
        clearSearch: tMaterialTable("clearSearch"),
        clearSelection: tMaterialTable("clearSelection"),
        clearSort: tMaterialTable("clearSort"),
        clickToCopy: tMaterialTable("clickToCopy"),
        copy: tMaterialTable("copy"),
        collapse: tMaterialTable("collapse"),
        collapseAll: tMaterialTable("collapseAll"),
        columnActions: tMaterialTable("columnActions"),
        copiedToClipboard: tMaterialTable("copiedToClipboard"),
        dropToGroupBy: tMaterialTable("dropToGroupBy"),
        edit: tMaterialTable("edit"),
        expand: tMaterialTable("expand"),
        expandAll: tMaterialTable("expandAll"),
        filterArrIncludes: tMaterialTable("filterArrIncludes"),
        filterArrIncludesAll: tMaterialTable("filterArrIncludesAll"),
        filterArrIncludesSome: tMaterialTable("filterArrIncludesSome"),
        filterBetween: tMaterialTable("filterBetween"),
        filterBetweenInclusive: tMaterialTable("filterBetweenInclusive"),
        filterByColumn: tMaterialTable("filterByColumn"),
        filterContains: tMaterialTable("filterContains"),
        filterEmpty: tMaterialTable("filterEmpty"),
        filterEndsWith: tMaterialTable("filterEndsWith"),
        filterEquals: tMaterialTable("filterEquals"),
        filterEqualsString: tMaterialTable("filterEqualsString"),
        filterFuzzy: tMaterialTable("filterFuzzy"),
        filterGreaterThan: tMaterialTable("filterGreaterThan"),
        filterGreaterThanOrEqualTo: tMaterialTable(
          "filterGreaterThanOrEqualTo",
        ),
        filterInNumberRange: tMaterialTable("filterInNumberRange"),
        filterIncludesString: tMaterialTable("filterIncludesString"),
        filterIncludesStringSensitive: tMaterialTable(
          "filterIncludesStringSensitive",
        ),
        filterLessThan: tMaterialTable("filterLessThan"),
        filterLessThanOrEqualTo: tMaterialTable("filterLessThanOrEqualTo"),
        filterMode: tMaterialTable("filterMode"),
        filterNotEmpty: tMaterialTable("filterNotEmpty"),
        filterNotEquals: tMaterialTable("filterNotEquals"),
        filterStartsWith: tMaterialTable("filterStartsWith"),
        filterWeakEquals: tMaterialTable("filterWeakEquals"),
        filteringByColumn: tMaterialTable("filteringByColumn"),
        goToFirstPage: tMaterialTable("goToFirstPage"),
        goToLastPage: tMaterialTable("goToLastPage"),
        goToNextPage: tMaterialTable("goToNextPage"),
        goToPreviousPage: tMaterialTable("goToPreviousPage"),
        grab: tMaterialTable("grab"),
        groupByColumn: tMaterialTable("groupByColumn"),
        groupedBy: tMaterialTable("groupedBy"),
        hideAll: tMaterialTable("hideAll"),
        hideColumn: tMaterialTable("hideColumn"),
        max: tMaterialTable("max"),
        min: tMaterialTable("min"),
        move: tMaterialTable("move"),
        noRecordsToDisplay:
          emptyStateMessage ?? tMaterialTable("noRecordsToDisplay"),
        noResultsFound: tMaterialTable("noResultsFound"),
        of: tMaterialTable("of"),
        or: tMaterialTable("or"),
        pin: tMaterialTable("pin"),
        pinToLeft: tMaterialTable("pinToLeft"),
        pinToRight: tMaterialTable("pinToRight"),
        resetColumnSize: tMaterialTable("resetColumnSize"),
        resetOrder: tMaterialTable("resetOrder"),
        rowActions: tMaterialTable("rowActions"),
        rowNumber: tMaterialTable("rowNumber"),
        rowNumbers: tMaterialTable("rowNumbers"),
        rowsPerPage: tMaterialTable("rowsPerPage"),
        save: tMaterialTable("save"),
        search: tMaterialTable("search"),
        selectedCountOfRowCountRowsSelected: tMaterialTable(
          "selectedCountOfRowCountRowsSelected",
        ),
        select: tMaterialTable("select"),
        showAll: tMaterialTable("showAll"),
        showAllColumns: tMaterialTable("showAllColumns"),
        showHideColumns: tMaterialTable("showHideColumns"),
        showHideFilters: tMaterialTable("showHideFilters"),
        showHideSearch: tMaterialTable("showHideSearch"),
        sortByColumnAsc: tMaterialTable("sortByColumnAsc"),
        sortByColumnDesc: tMaterialTable("sortByColumnDesc"),
        sortedByColumnAsc: tMaterialTable("sortedByColumnAsc"),
        sortedByColumnDesc: tMaterialTable("sortedByColumnDesc"),
        thenBy: tMaterialTable("thenBy"),
        toggleDensity: tMaterialTable("toggleDensity"),
        toggleFullScreen: tMaterialTable("toggleFullScreen"),
        toggleSelectAll: tMaterialTable("toggleSelectAll"),
        toggleSelectRow: tMaterialTable("toggleSelectRow"),
        toggleVisibility: tMaterialTable("toggleVisibility"),
        ungroupByColumn: tMaterialTable("ungroupByColumn"),
        unpin: tMaterialTable("unpin"),
        unpinAll: tMaterialTable("unpinAll"),
      }}
      columns={columns}
      data={data}
      enableStickyHeader
      enableHiding={false}
      getRowId={(row) => row.id}
      renderTopToolbar={() => filtersComponent}
      initialState={{ density: "comfortable", pagination: { pageSize } }}
      onRowSelectionChange={setSelectedRows}
      state={{
        rowSelection: selectedRows,
        isLoading,
      }}
      enableDensityToggle={false}
      enableColumnActions={false}
      enableFilters={false}
      defaultColumn={{
        size: 0,
        minSize: 20,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      }}
      enableRowSelection={enableRowSelection}
      muiTableProps={{
        id,
        sx: { height },
      }}
      layoutMode="grid"
      enableRowActions={enableRowActions}
      positionActionsColumn="last"
      icons={{ SortIcon: null }}
      muiTableBodyProps={{ sx: { display: "block" } }}
      muiTableBodyRowProps={({ row }) => ({
        sx: { height: "60px", cursor: onClickRow ? "pointer" : "default" },
        onClick: () => {
          if (onClickRow) {
            onClickRow(row);
          }
        },
      })}
      muiTableContainerProps={{
        sx: {
          overflowX: "hidden",
          width: "100%",
        },
      }}
      displayColumnDefOptions={{
        "mrt-row-select": {
          size: 0,
        },
        "mrt-row-actions": {
          size: sizeActions ?? 100,
          header: tGeneral("actions"),
        },
        "mrt-row-expand": {
          size: 0,
        },
      }}
      {...props}
    />
  );
}
export { StyledMaterialReactTable };
