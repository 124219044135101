/* eslint-disable react/no-danger */
// @ts-check
/* eslint react/prop-types: 0 */

import { Alert, Avatar, Box } from "@mui/material";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useTranslation } from "react-i18next";
import { renderCurrency, renderDate, renderPercent } from "../../../utils/projectSchedule";

export default function Histories(props) {
  const { dateFormat, histories, users } = props;

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  if (histories.length === 0) {
    return <Alert severity="info">{tProjectSchedule("There are no histories in this group.")}</Alert>;
  }

  return histories.map((history) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Box
        key={`${history.date}${history.user.id}`}
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          justifyContent: "flex-start",
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              textAlign: "right",
              width: "75px",
            }}
          >
            {moment(history.date).format("DD/MM/YY")}
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "50px",
            }}
          >
            <Box
              sx={{
                transform: "scale(0.75)",
              }}
            >
              <Avatar src={history.user.urlAvatar} />
            </Box>
          </Box>
          <Box>
            <strong>{history.user.name}</strong> {tProjectSchedule("made")} {history.histories.length}{" "}
            {tProjectSchedule("change(s)")}
          </Box>
        </Box>
        {history.histories.map((history) => {
          return (
            <Box
              key={history.id}
              sx={{
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  maxWidth: "75px",
                  minWidth: "75px",
                  textAlign: "right",
                }}
              >
                {moment(history.createdDate).format("hh:mm A")}
              </Box>
              <Box
                sx={{
                  alignItems: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  height: "100%",
                  justifyContent: "center",
                  maxWidth: "50px",
                  minWidth: "50px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#cccccc",
                    height: "100%",
                    width: "1px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <ul
                  style={{
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {history.contents.lines.map((line) => {
                    switch (line.type) {
                      case "ACTIVITY_CREATED": {
                        return <li>{tProjectSchedule("ACTIVITY_CREATED")}</li>;
                      }
                      case "ACTIVITY_UPDATED_ASSIGNEES_CREATED": {
                        return <li>{tProjectSchedule("ACTIVITY_UPDATED_ASSIGNEES_CREATED", { name: line.name })}</li>;
                      }
                      case "ACTIVITY_UPDATED_ASSIGNEES_REMOVED": {
                        return <li>{tProjectSchedule("ACTIVITY_UPDATED_ASSIGNEES_REMOVED", { name: line.name })}</li>;
                      }
                      case "ACTIVITY_UPDATED_COLOR": {
                        return <li>{tProjectSchedule("ACTIVITY_UPDATED_COLOR", { old: line.old, new: line.new })}</li>;
                      }
                      case "ACTIVITY_UPDATED_DESCRIPTION": {
                        return (
                          <li>{tProjectSchedule("ACTIVITY_UPDATED_DESCRIPTION", { old: line.old, new: line.new })}</li>
                        );
                      }
                      case "ACTIVITY_UPDATED_ID_ACTIVITY_UPDATED": {
                        return (
                          <li>
                            {tProjectSchedule("ACTIVITY_UPDATED_ID_ACTIVITY_UPDATED", { old: line.old, new: line.new })}
                          </li>
                        );
                      }
                      case "ACTIVITY_UPDATED_PROGRESS": {
                        return (
                          <li>
                            {tProjectSchedule("ACTIVITY_UPDATED_PROGRESS", {
                              old: renderPercent(line.old),
                              new: renderPercent(line.new),
                            })}
                          </li>
                        );
                      }
                      case "ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_CREATED": {
                        return (
                          <li>
                            {tProjectSchedule("ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_CREATED", { title: line.title })}
                          </li>
                        );
                      }
                      case "ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_REMOVED": {
                        return (
                          <li>
                            {tProjectSchedule("ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_REMOVED", { title: line.title })}
                          </li>
                        );
                      }
                      case "ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_AMOUNT": {
                        return (
                          <li>
                            {tProjectSchedule("ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_AMOUNT", {
                              old: line.old,
                              new: line.new,
                              number: parseInt(line.number, 10) + 1,
                            })}
                          </li>
                        );
                      }
                      case "ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_FILE": {
                        return (
                          <li>
                            {tProjectSchedule("ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_FILE", {
                              old: line.old,
                              new: line.new,
                              number: parseInt(line.number, 10) + 1,
                            })}
                          </li>
                        );
                      }
                      case "ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_TITLE": {
                        return (
                          <li>
                            {tProjectSchedule("ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_TITLE", {
                              old: line.old,
                              new: line.new,
                              number: parseInt(line.number, 10) + 1,
                            })}
                          </li>
                        );
                      }
                      case "ACTIVITY_UPDATED_PROJECT_COST_BUDGET": {
                        return (
                          <li>
                            {tProjectSchedule("ACTIVITY_UPDATED_PROJECT_COST_BUDGET", {
                              old: renderCurrency(line.old),
                              new: renderCurrency(line.new),
                            })}
                          </li>
                        );
                      }
                      case "ACTIVITY_UPDATED_REAL_TIMES_END": {
                        return (
                          <li
                            dangerouslySetInnerHTML={{
                              __html: tProjectSchedule("ACTIVITY_UPDATED_REAL_TIMES_END", {
                                old: renderDate(line.old, dateFormat),
                                new: renderDate(line.new, dateFormat),
                              }),
                            }}
                          />
                        );
                      }
                      case "ACTIVITY_UPDATED_REAL_TIMES_START": {
                        return (
                          <li
                            dangerouslySetInnerHTML={{
                              __html: tProjectSchedule("ACTIVITY_UPDATED_REAL_TIMES_START", {
                                old: renderDate(line.old, dateFormat),
                                new: renderDate(line.new, dateFormat),
                              }),
                            }}
                          />
                        );
                      }
                      case "ACTIVITY_UPDATED_SCHEDULED_TIMES_END": {
                        return (
                          <li
                            dangerouslySetInnerHTML={{
                              __html: tProjectSchedule("ACTIVITY_UPDATED_SCHEDULED_TIMES_END", {
                                old: renderDate(line.old, dateFormat),
                                new: renderDate(line.new, dateFormat),
                              }),
                            }}
                          />
                        );
                      }
                      case "ACTIVITY_UPDATED_SCHEDULED_TIMES_START": {
                        return (
                          <li
                            dangerouslySetInnerHTML={{
                              __html: tProjectSchedule("ACTIVITY_UPDATED_SCHEDULED_TIMES_START", {
                                old: renderDate(line.old, dateFormat),
                                new: renderDate(line.new, dateFormat),
                              }),
                            }}
                          />
                        );
                      }
                      case "ACTIVITY_UPDATED_TITLE": {
                        return <li>{tProjectSchedule("ACTIVITY_UPDATED_TITLE", { old: line.old, new: line.new })}</li>;
                      }
                      case "DAILY_REPORT_CREATED": {
                        return <li>{tProjectSchedule("DAILY_REPORT_CREATED", { title: line.title })}</li>;
                      }
                      case "DAILY_REPORT_REMOVED": {
                        return <li>{tProjectSchedule("DAILY_REPORT_REMOVED", { title: line.title })}</li>;
                      }
                      case "DOCUMENT_CREATED": {
                        return <li>{tProjectSchedule("DOCUMENT_CREATED", { name: line.name })}</li>;
                      }
                      case "DOCUMENT_REMOVED": {
                        return <li>{tProjectSchedule("DOCUMENT_REMOVED", { name: line.name })}</li>;
                      }
                      case "DRAWING_CREATED": {
                        return <li>{tProjectSchedule("DRAWING_CREATED", { name: line.name })}</li>;
                      }
                      case "DRAWING_REMOVED": {
                        return <li>{tProjectSchedule("DRAWING_REMOVED", { name: line.name })}</li>;
                      }
                      case "INSPECTION_CREATED": {
                        return <li>{tProjectSchedule("INSPECTION_CREATED", { title: line.title })}</li>;
                      }
                      case "INSPECTION_REMOVED": {
                        return <li>{tProjectSchedule("INSPECTION_REMOVED", { title: line.title })}</li>;
                      }
                      case "MEETING_CREATED": {
                        return <li>{tProjectSchedule("MEETING_CREATED", { title: line.title })}</li>;
                      }
                      case "MEETING_REMOVED": {
                        return <li>{tProjectSchedule("MEETING_REMOVED", { title: line.title })}</li>;
                      }
                      case "RFI_CREATED": {
                        return <li>{tProjectSchedule("RFI_CREATED", { title: line.title })}</li>;
                      }
                      case "RFI_REMOVED": {
                        return <li>{tProjectSchedule("RFI_REMOVED", { title: line.title })}</li>;
                      }
                      case "ROADBLOCK_CREATED": {
                        return <li>{tProjectSchedule("ROADBLOCK_CREATED", { description: line.description })}</li>;
                      }
                      case "ROADBLOCK_UPDATED_ASSIGNEES_CREATED": {
                        return (
                          <li>
                            {tProjectSchedule("ROADBLOCK_UPDATED_ASSIGNEES_CREATED", {
                              name: line.name,
                              description: line.description,
                            })}
                          </li>
                        );
                      }
                      case "ROADBLOCK_UPDATED_ASSIGNEES_REMOVED": {
                        return (
                          <li>
                            {tProjectSchedule("ROADBLOCK_UPDATED_ASSIGNEES_REMOVED", {
                              name: line.name,
                              description: line.description,
                            })}
                          </li>
                        );
                      }
                      case "ROADBLOCK_UPDATED_DATE": {
                        return (
                          <li>
                            {tProjectSchedule("ROADBLOCK_UPDATED_DATE", {
                              old: renderDate(line.old, dateFormat),
                              new: renderDate(line.new, dateFormat),
                              description: line.description,
                            })}
                          </li>
                        );
                      }
                      case "ROADBLOCK_UPDATED_DESCRIPTION": {
                        return (
                          <li>
                            {tProjectSchedule("ROADBLOCK_UPDATED_DESCRIPTION", {
                              old: line.old,
                              new: line.new,
                              description: line.description,
                            })}
                          </li>
                        );
                      }
                      case "ROADBLOCK_UPDATED_STATUS": {
                        return (
                          <li>
                            {tProjectSchedule("ROADBLOCK_UPDATED_STATUS", {
                              old: line.old.toUpperCase(),
                              new: line.new.toUpperCase(),
                              description: line.description,
                            })}
                          </li>
                        );
                      }
                      case "ROADBLOCK_UPDATED_TYPE": {
                        return (
                          <li>
                            {tProjectSchedule("ROADBLOCK_UPDATED_TYPE", {
                              old: line.old.toUpperCase(),
                              new: line.new.toUpperCase(),
                              description: line.description,
                            })}
                          </li>
                        );
                      }
                      case "TASK_CREATED": {
                        return <li>{tProjectSchedule("TASK_CREATED", { title: line.title })}</li>;
                      }
                      case "TASK_REMOVED": {
                        return <li>{tProjectSchedule("TASK_REMOVED", { title: line.title })}</li>;
                      }
                      default: {
                        return <li>-</li>;
                      }
                    }
                  })}
                </ul>
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  });
}
