import { Download } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";

function ExpandedImage({ expandImage, openImage, handleClose }) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (expandImage) setImage(expandImage);
  }, [expandImage]);

  const download = () => {
    const el = document.createElement("a");
    el.setAttribute("href", image.url);
    el.setAttribute("download", image.name);
    el.setAttribute("target", "_blank");
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  return (
    <Dialog
      open={openImage}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent
        dividers
        sx={{
          textAlign: "center",
          padding: 0,
        }}
      >
        <Card>
          {image !== null ? (
            <CardContent sx={{ padding: "7%" }}>
              <IconButton
                onClick={download}
                sx={{ position: "absolute", right: "50px", top: 0, mr: 2 }}
              >
                <Download color="primary" />
              </IconButton>
              <IconButton
                onClick={handleClose}
                sx={{ position: "absolute", right: 0, top: 0, mr: 2 }}
              >
                <Close />
              </IconButton>
              <img src={image.url} alt="" width="100%" height="100%" />
            </CardContent>
          ) : null}
        </Card>
      </DialogContent>
    </Dialog>
  );
}

export { ExpandedImage };
