import { Box } from "@mui/material";
import { useState } from "react";
import { TitleWidgets } from "../../shared";
import { BimTable } from "./BimTable";
import { ModalAddBim } from "./ModalAddBim";

function Bim() {
  const [showAdd, setShowAdd] = useState(false);
  return (
    <Box>
      <TitleWidgets
        title="BIM"
        moduleName="rfis"
        addFunction={() => setShowAdd(true)}
        showAdd
      />
      <BimTable />
      <ModalAddBim isOpen={showAdd} onClose={() => setShowAdd(false)} />
    </Box>
  );
}

export { Bim };
