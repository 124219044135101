const status = {
  open: "打开",
  closed: "关闭",
  close: "关闭",
  in_revision: "审核中",
  draft: "草稿",
  pending: "待处理",
  doing: "进行中",
  backlog: "积压",
  done: "完成",
  inactive: "未激活",
};

export { status };
