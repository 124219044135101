import {
  Add,
  Apartment,
  Delete,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { Box, Card, Checkbox, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { v4 } from "uuid";
import {
  TooltipIconButton,
  UpdateTextFieldModal,
} from "../../../../../components/shared";
import {
  useAddJobTypeMutation,
  useAddSubcontractorMutation,
  useUpdateJobTypeMutation,
  useUpdateSubcontractorMutation,
} from "../../../../../features/project/modules/dailyReports/manpowerApiSlice";
import { AutocompleteCell } from "../../../Drawings/UploadDrawings/DrawingRow/AutocompleteCell";

function ManpowerCard({ index, subcontractors, jobTypes, onDelete }) {
  const { idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { control } = useFormContext();
  const { fields: manpowerFields, update: updateManpower } = useFieldArray({
    control,
    name: "manpower",
  });
  const {
    update,
    fields,
    append,
    remove: removeRecords,
  } = useFieldArray({
    control,
    name: `manpower[${index}].records`,
  });
  const manpower = manpowerFields[index];

  const [editing, setEditing] = useState(null);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [addSubcontractor, { isLoading: isSubcontractorLoading }] =
    useAddSubcontractorMutation();
  const [updateSubcontractor, { isLoading: isEditSubcontractorLoading }] =
    useUpdateSubcontractorMutation();

  const [addJobType, { isLoading: isJobTypeLoading }] = useAddJobTypeMutation();
  const [updateJobType, { isLoading: isEditJobTypeLoading }] =
    useUpdateJobTypeMutation();

  const onAddSubcontractor = async (name) => {
    const newSubcontractor = await addSubcontractor({ idEnterprise, name });
    updateManpower(index, {
      ...manpower,
      idSubcontractor: newSubcontractor.data.id,
    });
  };
  const handleUpdate = async (newValue) => {
    if (editing.type === "jobType") {
      await updateJobType({ ...editing, name: newValue });
    } else {
      await updateSubcontractor({ ...editing, name: newValue });
    }
    setEditing(null);
  };

  const onAddJobType = async (name, index, record) => {
    const newJobType = await addJobType({ idEnterprise, name });
    update(index, { ...record, idJobType: newJobType.data.id });
  };

  const handleSelectRecord = (checked, index) => {
    if (checked) {
      setSelectedRecords([...selectedRecords, index]);
    } else {
      setSelectedRecords(selectedRecords.filter((i) => i !== index));
    }
  };

  const handleDeleteRecords = () => {
    if (
      fields.length === selectedRecords.length ||
      selectedRecords.length === 0
    ) {
      onDelete();
      return;
    }
    removeRecords(selectedRecords);
    setSelectedRecords([]);
  };

  return (
    <Card
      variant="outlined"
      sx={{ p: "0.625rem 0.5rem", mb: "1rem", backgroundColor: "#EEE" }}
    >
      <Box height="2rem" display="flex" justifyContent="flex-end">
        <TooltipIconButton
          icon={<Delete />}
          label={tGeneral("delete")}
          color="red"
          onClick={handleDeleteRecords}
        />
      </Box>
      <AutocompleteCell
        options={subcontractors.map((s) => ({ value: s.id, label: s.name }))}
        value={manpower.idSubcontractor}
        onCreate={onAddSubcontractor}
        onChange={(val) =>
          updateManpower(index, { ...manpower, idSubcontractor: val })
        }
        onEdit={(id, name) =>
          setEditing({ type: "subContractor", idObject: id, value: name })
        }
        startAdornment={<Apartment sx={{ color: "#E6B60C" }} />}
        label={tGeneral("enterprise")}
        isLoading={isSubcontractorLoading}
      />
      <Box
        display="grid"
        gridTemplateColumns="20px 2fr 2fr 1fr 1fr"
        columnGap="0.5rem"
        alignItems="center"
      >
        <Box />
        <Typography>{tGeneral("name")}</Typography>
        <Typography textAlign="center">{tDailyReports("jobType")}</Typography>
        <Typography textAlign="center">
          {tDailyReports("assistants")}
        </Typography>
        <Typography textAlign="center">
          {tDailyReports("workedHours")}
        </Typography>
      </Box>
      {fields.map((record, index) => (
        <Box
          display="grid"
          gridTemplateColumns="20px 2fr 2fr 1fr 1fr"
          columnGap="0.5rem"
          mb="0.5rem"
        >
          <Checkbox
            checkedIcon={<RadioButtonChecked />}
            icon={<RadioButtonUnchecked />}
            checked={selectedRecords.indexOf(index) > -1}
            onChange={(e) => handleSelectRecord(e.target.checked, index)}
          />
          <TextField
            size="small"
            value={record.name}
            onChange={(e) => update(index, { ...record, name: e.target.value })}
            sx={{
              "& .Mui-disabled": {
                backgroundColor: "#F5F5F5",
              },
              backgroundColor: "white",
            }}
            disabled={record.assistants > 0}
          />
          <AutocompleteCell
            options={jobTypes.map((jt) => ({ value: jt.id, label: jt.name }))}
            value={record.idJobType}
            onChange={(val) => update(index, { ...record, idJobType: val })}
            onCreate={(name) => onAddJobType(name, index, record)}
            onEdit={(id, name) =>
              setEditing({ type: "jobType", idObject: id, value: name })
            }
            isLoading={isJobTypeLoading}
          />
          <TextField
            size="small"
            type="number"
            value={record.assistants}
            onChange={(e) =>
              update(index, { ...record, assistants: e.target.value })
            }
            sx={{
              "& .Mui-disabled": {
                backgroundColor: "#F5F5F5",
              },
              backgroundColor: "white",
            }}
            disabled={record.name !== ""}
          />
          <TextField
            size="small"
            type="number"
            value={record.hours}
            onChange={(e) =>
              update(index, { ...record, hours: e.target.value })
            }
            sx={{
              backgroundColor: "white",
            }}
          />
        </Box>
      ))}
      <TooltipIconButton
        label={tGeneral("add")}
        icon={<Add />}
        onClick={() =>
          append({
            id: v4(),
            bNew: true,
            name: "",
            idJobType: null,
            assistants: 0,
            hours: 0,
          })
        }
      />
      <UpdateTextFieldModal
        isLoading={isEditJobTypeLoading || isEditSubcontractorLoading}
        isOpen={Boolean(editing)}
        label={
          editing?.type === "jobType"
            ? tDailyReports("jobType")
            : tGeneral("enterprise")
        }
        onClose={() => setEditing(null)}
        onUpdate={handleUpdate}
        title={
          editing?.type === "jobType"
            ? tDailyReports("jobType")
            : tGeneral("enterprise")
        }
        value={editing?.value}
      />
    </Card>
  );
}

export { ManpowerCard };
