import { CircleSharp, LocationOn, Lock, LockOpen } from "@mui/icons-material";
import SubjectIcon from "@mui/icons-material/Subject";
import {
  Box,
  Card,
  CardActions,
  Divider,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

// Constants
// import { statusOptions } from "../../../constants/projectDashboard/selectOptions";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { switchPrivacyLabel } from "../../../constants/projectDashboard/generalProps";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { PopUpAlert } from "../../../components/PopUpAlert";
import AgendaForm from "../../../components/projectDashboard/Meetings/AgendaForm";
import AgendaItem from "../../../components/projectDashboard/Meetings/AgendaItem";
import { CreateFormUsersSide, FormHeader } from "../../../components/shared";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants";
import { useUploadFilesMutation } from "../../../features/files";
import {
  useCreateMeetingMutation,
  useGetMeetingByIdQuery,
  useUpdateMeetingMutation,
} from "../../../features/project/modules/meetings/meetingApiSlice";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

function MeetingForm() {
  const { idProject, idMeeting } = useParams();
  const navigate = useNavigate();

  const { t: tMeetings } = useTranslation("meetings");
  const { t: tGeneral } = useTranslation("general");

  const statusOptions = [
    {
      value: "open",
      label: tGeneral("open_action").toUpperCase(),
      color: "#5ce381",
    },
    {
      value: "close",
      label: tGeneral("closed").toUpperCase(),
      color: "#707070",
    },
    {
      value: "draft",
      label: tGeneral("draft").toUpperCase(),
      color: "#ffc900",
    },
  ];
  const { data: meeting, isLoading: isMeetingLoading } = useGetMeetingByIdQuery(
    idMeeting,
    {
      skip: !idMeeting,
    },
  );

  const [
    addMeeting,
    { isSuccess: isCreateSuccess, isError: isCreateError, error: createError },
  ] = useCreateMeetingMutation();
  const [uploadFiles] = useUploadFilesMutation();
  const [
    updateMeeting,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError },
  ] = useUpdateMeetingMutation();

  const defaultDate = moment();
  const dateNow = moment(new Date()).format("YYYY-MM-DD");

  const validationSchema = yup.object({
    title: yup
      .string("Enter a valid title")
      .required(tGeneral("fieldRequired")),
    attendenceList: yup
      .array()
      .of(yup.string())
      .min(1)
      .required(tGeneral("fieldRequired")),
  });

  const formContext = useForm({
    defaultValues: {
      title: "",
      bPrivate: false,
      date: dateNow,
      hour: defaultDate,
      url: "",
      location: "",
      status: "open",
      attendenceList: [],
      distributionList: [],
      agendaItems: [],
      deletedFiles: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const { register, watch, setValue, reset } = formContext;
  const bPrivate = watch("bPrivate");
  // Agenda
  const agendaItems = watch("agendaItems");
  const [inProcess, setInProcess] = useState(false);

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      PopUpAlert(
        "success",
        isCreateSuccess ? tGeneral("created") : tGeneral("updated"),
        isCreateSuccess
          ? tGeneral("createdSuccessfully")
          : tGeneral("updatedSuccessfully"),
      );
      setInProcess(false);
      navigate(-1);
    }
    if (isCreateError || isUpdateError) {
      PopUpAlert(
        "error",
        "Error",
        isCreateError ? createError.message : updateError.message,
      );
      setInProcess(false);
    }
  }, [
    isCreateSuccess,
    isUpdateSuccess,
    isUpdateError,
    isCreateError,
    updateError,
    createError,
    tGeneral,
    navigate,
  ]);

  // For edit meeting
  const [idsDeletedTopics, setIdsDeletedTopics] = useState([]);
  const [idsDeletedItems, setIdsDeletedItems] = useState([]);

  const styles = {
    divider: {
      border: 1,
      color: "#E1E1E1",
    },
  };

  useEffect(() => {
    if (meeting) {
      const {
        title,
        bPrivate,
        date,
        hour,
        url,
        location,
        status,
        attendenceList,
        distributionList,
      } = meeting;
      const meetingAgendas = meeting?.agenda?.map((item, index) => ({
        id: item.id,
        bNew: false,
        order: index,
        title: item.title,
        topics: item.topics.map((topic) => ({
          id: topic.id,
          bNew: false,
          title: topic.title,
        })),
        time: item.time,
        files: item.files,
      }));
      const [h, m, p] = hour.replace(":", " ").split(" ");
      reset({
        title,
        bPrivate,
        status,
        date: moment.utc(date).format("YYYY-MM-DD"),
        hour: moment(date).set({
          hour: Number(h) + (p === "PM" ? 12 : 0),
          minute: m,
        }),
        url,
        location,
        attendenceList: attendenceList.map((user) => user.id),
        distributionList: distributionList.map((user) => user.id),
        agendaItems: meetingAgendas,
        deletedFiles: [],
      });
    }
  }, [meeting, reset]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    // create new array from original agenda items data
    const items = [...agendaItems];

    // remove original index of dragged item, and receive that item
    const [reorderedItems] = items.splice(result.source.index, 1);

    // inject that item at its destination value
    items.splice(result.destination.index, 0, reorderedItems);

    let counter = -1;
    // update state
    setValue(
      "agendaItems",
      items.map((item) => {
        counter += 1;
        return { ...item, order: counter };
      }),
    );
  };

  // Used for styling of the list
  const getItemStyle = (isDragging, draggableStyle, itemOrder) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: "0 0 0 0",
    order: itemOrder,

    // change background colour if dragging
    color: isDragging ? "#black" : "black",

    width: "100%",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const onSubmit = async ({ deletedFiles, ...formData }) => {
    setInProcess(true);
    const items = [];
    const uploadFilesPromises = [];
    formData.agendaItems.forEach((item) => {
      const agendaBody = {
        order: item.order,
        title: item.title,
        time: item.time,
        topics: idMeeting ? item.topics : item.topics.map((t) => t.title),
        bNew: item.bNew,
        ...(!item.bNew && { id: item.id }),
      };
      if (item.files.length > 0) {
        agendaBody.id = item.bNew ? uuidv4() : item.id;
        const agendaFormData = new FormData();
        item.files.forEach((file) => {
          agendaFormData.append("files", file);
        });
        uploadFilesPromises.push(
          uploadFiles({
            idObject: agendaBody.id,
            moduleName: "meetings",
            body: agendaFormData,
          }),
        );
      }

      items.push(agendaBody);
    });
    await Promise.all(uploadFilesPromises);
    const body = {
      ...formData,
      idProject,
      meetingDate: new Date(`${formData.date}T06:00:00.000Z`),
      hour: moment(formData.hour).format("h:mm A"),
      agendaItems: items,
    };
    if (idMeeting) {
      body.id = idMeeting;
      body.idsDeletedItems = idsDeletedItems;
      body.idsDeletedTopics = idsDeletedTopics;
      body.deletedFiles = deletedFiles;
      updateMeeting(body);
    } else {
      addMeeting(body);
    }
  };

  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const meetingsRef = useRef();

  const {
    setState,
    state: { tourActive, stepIndex },
  } = useAppTourContext();

  useLayoutEffect(() => {
    if (tourActive) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive]);

  useEffect(() => {
    if (
      tourActive &&
      currentGuideMe === "meetings-add" &&
      stepIndex === 10 &&
      meetingsRef.current
    ) {
      meetingsRef.current.style.overflowY = "scroll";
    }
  }, [tourActive, stepIndex, currentGuideMe, meetingsRef.current]);

  useEffect(() => {
    if (
      tourActive &&
      currentGuideMe === "meetings-edit" &&
      stepIndex >= 9 &&
      meetingsRef.current
    ) {
      meetingsRef.current.style.overflowY = "scroll";
    }
  }, [tourActive, stepIndex, currentGuideMe, meetingsRef.current]);

  return (
    <FormProvider {...formContext}>
      <Box component="form" onSubmit={(e) => e.preventDefault()}>
        {/* Header */}
        <Box sx={{ width: "100%" }}>
          <FormHeader
            isEditing={Boolean(idMeeting)}
            onBack={() => navigate(-1)}
            title={
              idMeeting ? tMeetings("editMeeting") : tMeetings("addMeeting")
            }
            onDone={formContext.handleSubmit(onSubmit)}
            dataTourNameButton="add-meetings-16"
          />
        </Box>
        <Box
          display="flex"
          height="88vh"
          sx={{ overflowY: "auto" }}
          px="10px"
          columnGap="10px"
        >
          {/* Left content */}
          <Box
            flex={2}
            display="flex"
            flexDirection="column"
            rowGap="16px"
            pt="10px"
            overflow="hidden"
          >
            {/* Row 1 */}
            <Box display="flex" columnGap="10px">
              {/* Title */}
              <TextField
                required
                variant="outlined"
                label={tGeneral("title")}
                {...register("title")}
                InputLabelProps={{ shrink: watch("title").length > 0 }}
                sx={{ flex: 1 }}
                data-tour="add-meetings-2"
              />

              {/* Switch Private */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Card
                  sx={{ width: "100%", height: "small" }}
                  data-tour="add-meetings-3"
                >
                  <CardActions sx={{ justifyContent: "space-between" }}>
                    <Box>
                      <IconButton title={tGeneral("privacy")}>
                        {bPrivate ? <Lock /> : <LockOpen />}
                      </IconButton>
                      {bPrivate ? tGeneral("private") : tGeneral("public")}
                    </Box>
                    <Switch
                      {...switchPrivacyLabel}
                      checked={bPrivate}
                      {...register("bPrivate")}
                      color="primary"
                    />
                  </CardActions>
                </Card>
              </Box>
            </Box>
            {/* Row 2 */}
            <Box display="flex" columnGap="10px">
              {/* Date and hour */}
              <Box
                display="flex"
                flex={1}
                alignItems="center"
                columnGap="10px"
                data-tour="add-meetings-4"
              >
                <TextField
                  variant="outlined"
                  type="date"
                  label={tGeneral("date")}
                  {...register("date")}
                  sx={{ flex: 3 }}
                />
                <TimePicker
                  showToolbar
                  label={`${tGeneral("hour")}`}
                  value={watch("hour")}
                  onChange={(val) => setValue("hour", val)}
                  renderInput={(params) => (
                    <TextField size="medium" {...params} />
                  )}
                  sx={{ flex: 2 }}
                />
              </Box>
              {/* Location */}
              <Box display="flex" flex={1}>
                <TextField
                  variant="outlined"
                  label={tGeneral("location")}
                  InputProps={{
                    endAdornment: <LocationOn />,
                  }}
                  InputLabelProps={{ shrink: watch("location")?.length > 0 }}
                  {...register("location")}
                  fullWidth
                  data-tour="add-meetings-5"
                />
              </Box>
            </Box>
            {/* Row 3 */}
            <Box display="flex" columnGap="10px">
              {/* URL Meeting */}
              <Box display="flex" flex={1}>
                <TextField
                  variant="outlined"
                  label={tMeetings("urlMeeting")}
                  {...register("url")}
                  sx={{ width: "100%" }}
                  data-tour="add-meetings-6"
                />
              </Box>
              {/* Status */}
              <Box display="flex" flex={1}>
                <FormControl fullWidth required>
                  <InputLabel id="status-label">
                    {tGeneral("selectStatus")}
                  </InputLabel>
                  <Select
                    {...register("status")}
                    required
                    variant="outlined"
                    label={tGeneral("selectStatus")}
                    value={watch("status")}
                    sx={{ maxHeight: "56px" }}
                    data-tour="add-meetings-7"
                  >
                    {(idMeeting && meeting?.status !== "draft"
                      ? statusOptions
                      : statusOptions.filter((status) => {
                          return (
                            status.label !== tGeneral("closed").toUpperCase()
                          );
                        })
                    ).map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Box display="flex" alignItems="center">
                          <CircleSharp sx={{ color: option.color }} />
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Divider sx={{ ...styles.divider, m: 1, width: "100%" }} />
            {/* Agenda */}
            <Box
              display="flex"
              flex={1}
              sx={{ overflowY: "auto" }}
              ref={meetingsRef}
            >
              <Box width="100%">
                <Box display="flex" alignItems="center" columnGap={1}>
                  <SubjectIcon />
                  <Typography fontWeight="bold" fontSize="18px">
                    {tMeetings("agenda")}
                  </Typography>
                </Box>
                {/* Agenda items */}
                <Box sx={{ pb: "20px", width: "100%" }}>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="agendaItemsOrder">
                      {(provided) => (
                        <FormGroup
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          {agendaItems.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                    item.order,
                                  )}
                                >
                                  <AgendaItem
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    isDragging={snapshot.isDragging}
                                    setCurrentItem={(value, field) => {
                                      const currentItem = agendaItems[index];
                                      currentItem[field] = value;
                                      const allItems = [...agendaItems];
                                      allItems[index] = currentItem;
                                      setValue("agendaItems", allItems);
                                    }}
                                    setOnDelete={(idDelete) => {
                                      const currentAgendaItems = [
                                        ...agendaItems,
                                      ];
                                      if (!item.bNew) {
                                        const currentDeletedItems = [
                                          ...idsDeletedItems,
                                        ];
                                        currentDeletedItems.push(
                                          currentAgendaItems[idDelete].id,
                                        );
                                        setIdsDeletedItems(currentDeletedItems);
                                      }
                                      currentAgendaItems.splice(idDelete, 1);
                                      setValue(
                                        "agendaItems",
                                        currentAgendaItems,
                                      );
                                    }}
                                    setDeletedTopic={(idDeletedTopic) => {
                                      const currentDeletedTopics = [
                                        ...idsDeletedTopics,
                                      ];
                                      currentDeletedTopics.push(idDeletedTopic);
                                      setIdsDeletedTopics(currentDeletedTopics);
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </FormGroup>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Box>
                {/* Agenda form */}
                <AgendaForm
                  setAgendaItem={(item) => {
                    const newItem = { ...item };
                    newItem.id = `${agendaItems.length + 1}`;
                    newItem.order = agendaItems.length;
                    newItem.bNew = true;
                    newItem.currentFiles = [];
                    const currentItems = [...agendaItems];
                    currentItems.push(newItem);
                    setValue("agendaItems", currentItems);
                  }}
                />
              </Box>
            </Box>
          </Box>
          {/* Right content */}
          <CreateFormUsersSide
            distributionListName={DISTRIBUTION_LIST_NAMES.meeting}
            distributionListDataTour="add-meetings-9"
            lists={[
              {
                name: "attendenceList",
                required: true,
                label: tGeneral("attendance"),
                dataTourName: "add-meetings-8",
              },
            ]}
          />
        </Box>
        <LoadingOverlay open={inProcess || isMeetingLoading} />
      </Box>
    </FormProvider>
  );
}

export default MeetingForm;
