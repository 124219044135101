import { Download } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import MaterialUIAudioPlayer from "material-ui-audio-player";
import JSZip from "jszip";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

import Docx from "../../../../assets/documents/doc.png";
import Dwg from "../../../../assets/documents/dwg.svg";
import Heic from "../../../../assets/documents/heic.png";
import Pdf from "../../../../assets/documents/pdf.svg";
import Ppt from "../../../../assets/documents/ppt.svg";
import Xlsx from "../../../../assets/documents/xls.svg";
import Zip from "../../../../assets/documents/zip.png";
import Video from "../../../../assets/icons/sharedModules/video.png";
import AudioPlayer from "../../../../components/AudioPlayer";
import { PopUpAlert } from "../../../../components/PopUpAlert";
import { MessageBox } from "../../../../components/question/MessageBox";
import { MessageReply } from "../../../../components/question/MessageReply";
import { ModalCarouselFileViewer } from "../../../../components/shared";
import UserAvatar from "../../../../components/userAvatars/UserAvatar";
import { useCommentObjectMutation } from "../../../../features/message/messageApiSlice";
import { useGetDailyReportChatQuery } from "../../../../features/project/modules/dailyReports/dailyReportsApiSlice";
import { useGetUserPreferencesQuery } from "../../../../features/userPreferences/userPreferencesApiSlice";

function ReportLocationComments({ topic, disabled = false }) {
  const { t: tGeneral } = useTranslation("general");
  const { data: comments } = useGetDailyReportChatQuery(topic.id);
  const { data: userPreferences } = useGetUserPreferencesQuery();
  const [initialIndex, setInitialIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [transcriptLang, setTranscriptLang] = useState(null);
  const [transcriptValue, setTranscriptValue] = useState(null);
  const [voicenoteLang, setVoicenoteLang] = useState(null);
  const [voicenoteUrl, setVoicenoteUrl] = useState(null);

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isDownloadBatchFilesError, setIsDownloadBatchFilesError] =
    useState(false);

  const [commentObject, { isLoading: isCommentLoading }] =
    useCommentObjectMutation();

  const commentReport = (comment) => {
    commentObject({
      idObject: topic.id,
      comment,
      files: [],
      module: "dailyreports",
      objectName: "topic",
    });
  };

  const hasTopics = topic?.files?.length > 0;

  useEffect(() => {
    if (isDownloadBatchFilesError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("generalErrorMessage"));
    }
  }, [isDownloadBatchFilesError]);

  const handleDownload = async () => {
    setIsLoadingDownload(true);

    const elementList = topic?.files.filter(
      (file) => file !== undefined && file,
    );

    const zip = new JSZip();
    await Promise.all(
      elementList.map(async (file, index) => {
        const urlWithCacheBuster = `${
          file.url
        }?cache_buster=${new Date().getTime()}`;
        const res = await fetch(urlWithCacheBuster, { cache: "no-store" });
        const data = await res.blob();
        const fileExtension = file.url.substring(file.url.lastIndexOf(".") + 1);
        zip.file(`${file.name}-${index}.${fileExtension}`, data);
      }),
    )
      .then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          const downloadLink = document.createElement("a");
          downloadLink.download = `${tGeneral("files")}.zip`;
          downloadLink.href = URL.createObjectURL(content);
          downloadLink.click();
          setIsLoadingDownload(false);
        });
      })
      .catch(() => {
        setIsDownloadBatchFilesError(true);
      })
      .finally(() => {
        setIsLoadingDownload(false);
      });
  };

  const renderFiles = (file, index) => {
    const fileType = file.extension;
    const fileName = file.name;

    const handleClose = () => {
      setOpen(false);
      setSelectedFile(null);
      setTranscriptLang(null);
      setTranscriptValue(null);
      setVoicenoteLang(null);
      setVoicenoteUrl(null);
    };

    const handleOpen = () => {
      setOpen(true);
    };

    if (fileType.match(/png|jpg|jpeg|gif|avif|apng|svg|ico/)) {
      return (
        <LazyLoad>
          <Box
            component="div"
            sx={{ cursor: "pointer", height: "100%", width: "100%" }}
            onClick={() => setInitialIndex(index)}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              src={file.url}
              alt={file.name}
            />
          </Box>
        </LazyLoad>
      );
    }
    let sourceImage = Pdf;

    if (fileType.match("doc")) {
      sourceImage = Docx;
    }

    if (fileType.match(/xls|csv/)) {
      sourceImage = Xlsx;
    }

    if (fileType.match(/dwg|cad/)) {
      sourceImage = Dwg;
    }

    if (fileType.match(/ppt/)) {
      sourceImage = Ppt;
    }

    if (fileType.match(/zip|rar/)) {
      sourceImage = Zip;
    }
    if (fileType.match(/heic/)) {
      sourceImage = Heic;
    }
    if (fileType.toLowerCase().match(/mp4|mov|avi/)) {
      sourceImage = Video;
    }
    if (
      fileName.includes("voicenote") &&
      (fileType === "aac" || fileType === "wav" || fileType === "mp4")
    ) {
      return (
        <Box key={file?.url} sx={{ width: "100%" }}>
          {userPreferences && userPreferences.translationFeature !== "off" ? (
            <TranslateIcon
              onClick={() => {
                handleOpen();
                setSelectedFile(fileName);
              }}
              style={{ fontSize: "18px", cursor: "pointer" }}
            />
          ) : null}
          {fileName === selectedFile ? (
            <Modal
              aria-describedby="modal-description"
              aria-labelledby="modal-title"
              onClose={handleClose}
              open={open}
            >
              <Paper
                style={{
                  backgroundColor: "white",
                  border: "2px solid #000",
                  boxShadow: 24,
                  left: "50%",
                  padding: "16px",
                  position: "absolute",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "max-content",
                }}
              >
                <div>
                  <Box
                    sx={{
                      backgroundColor: "#FFEBBC4D",
                      border: "none",
                      borderRadius: "8px",
                      maxWidth: "max-content",
                      mb: "25px",
                      minWidth: "40%",
                      p: "15px",
                    }}
                  >
                    <Typography
                      color="black"
                      component="div"
                      mb="12px"
                      sx={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      <strong>View transcript in:</strong>

                      {["Es", "Pt", "En", "Zh", "Ja", "Ko"].map((lang) => (
                        <Button
                          key={lang}
                          disabled={!file[`transcriptIn${lang}`]}
                          onClick={() => {
                            setTranscriptLang(lang.toLowerCase());
                            setTranscriptValue(file[`transcriptIn${lang}`]);
                          }}
                          sx={{
                            color:
                              transcriptLang === lang.toLowerCase()
                                ? "black"
                                : "primary.main",
                          }}
                        >
                          {lang === "Es"
                            ? "Español (Es)"
                            : lang === "Pt"
                              ? "Português (Pt)"
                              : lang === "En"
                                ? "English (En)"
                                : lang === "Zh"
                                  ? "中文 (Zh)"
                                  : lang === "Ja"
                                    ? "日本語 (Ja)"
                                    : lang === "Ko"
                                      ? "한국어 (Ko)"
                                      : null}
                        </Button>
                      ))}
                    </Typography>

                    <Typography
                      color="black"
                      component="div"
                      mb="12px"
                      sx={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      {transcriptLang && transcriptValue && (
                        <span style={{ marginLeft: "30px", color: "primary" }}>
                          {transcriptValue}
                        </span>
                      )}
                    </Typography>
                    <Typography
                      color="black"
                      component="div"
                      mb="12px"
                      sx={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      <strong>Listen in:</strong>

                      <Button
                        onClick={() => {
                          setVoicenoteLang("og");
                          setVoicenoteUrl(file.url);
                        }}
                        sx={{
                          color:
                            voicenoteLang === "og" || !voicenoteUrl
                              ? "black"
                              : "primary.main",
                        }}
                      >
                        Original
                      </Button>
                      {["Es", "Pt", "En", "Zh", "Ja", "Ko"].map((lang) => (
                        <Button
                          key={lang}
                          disabled={!file[`voiceNoteIn${lang}`]}
                          onClick={() => {
                            setVoicenoteLang(lang.toLowerCase());
                            setVoicenoteUrl(file[`voiceNoteIn${lang}`]);
                          }}
                          sx={{
                            color:
                              voicenoteLang === lang.toLowerCase()
                                ? "black"
                                : "primary.main",
                          }}
                        >
                          {lang === "Es"
                            ? "Español (Es)"
                            : lang === "Pt"
                              ? "Português (Pt)"
                              : lang === "En"
                                ? "English (En)"
                                : lang === "Zh"
                                  ? "中文 (Zh)"
                                  : lang === "Ja"
                                    ? "日本語 (Ja)"
                                    : lang === "Ko"
                                      ? "한국어 (Ko)"
                                      : null}
                        </Button>
                      ))}
                    </Typography>
                  </Box>
                </div>
                <Box
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                    mb: "25px",
                    p: "15px",
                    width: "fit-content",
                  }}
                >
                  <MaterialUIAudioPlayer src={voicenoteUrl || file.url} />{" "}
                </Box>
                <Button
                  color="primary"
                  onClick={handleClose}
                  variant="contained"
                >
                  Close
                </Button>
              </Paper>
            </Modal>
          ) : null}
          <AudioPlayer url={file.url} />
          <Typography
            fontSize="10px"
            fontWeight="light"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {file.name}
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        component="div"
        onClick={() => setInitialIndex(index)}
        sx={{ maxWidth: "180px", cursor: "pointer" }}
      >
        <img
          src={sourceImage}
          alt={fileName}
          width="100%"
          height="110px"
          style={{ objectFit: "contain" }}
        />
        <Tooltip title={fileName}>
          <Typography
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {fileName}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Box height="100%" p="26px 40px">
      {hasTopics && (
        <Box display="flex" flexDirection="column" maxHeight="40%">
          <Box sx={{ display: "flex", width: "100%", marginBottom: "20px" }}>
            <Typography alignItems="flex-start" textAlign="left">
              {tGeneral("files").toUpperCase()}
            </Typography>
            <Box sx={{ width: "97%", display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#ffc900",
                  borderRadius: 1,
                  marginLeft: 1,
                  padding: 1,
                  color: "#000",
                  ":hover": {
                    backgroundColor: "#ffc900",
                  },
                }}
                style={{ minWidth: "24px" }}
                onClick={() => handleDownload()}
                disabled={topic?.files?.length === 0}
              >
                {isLoadingDownload ? (
                  <CircularProgress
                    style={{ width: "18px", height: "18px" }}
                    color="secondary"
                  />
                ) : (
                  <Download style={{ fontSize: "18px" }} />
                )}
              </Button>
            </Box>
          </Box>
          <Box
            display="grid"
            sx={{
              gridTemplateColumns: "1fr 1fr 1fr",
              gridTemplateRows: "150px",
              maxHeight: "100%",
              overflowY: "auto",
            }}
            columnGap="16px"
            rowGap="16px"
          >
            {topic?.files?.map(renderFiles)}
          </Box>
        </Box>
      )}
      <Box
        height={hasTopics ? "50%" : "100%"}
        sx={{
          maxHeight: hasTopics ? "45%" : "100%",
          mt: hasTopics ? "25px" : 0,
          overflowY: hasTopics ? "scroll" : "visible",
        }}
      >
        <Typography>{tGeneral("comments").toUpperCase()}</Typography>
        <Box
          height={hasTopics ? "40%" : "50%"}
          maxHeight={hasTopics ? "40%" : "50%"}
          sx={{ overflowY: "auto" }}
        >
          {comments?.map(
            ({
              firstName,
              lastName,
              createdDate,
              comment,
              files,
              id,
              urlAvatar,
              bActive = true,
            }) => (
              <Box display="flex">
                <UserAvatar url={urlAvatar} bActive={bActive} />
                <MessageBox
                  createdBy={`${firstName} ${lastName}`}
                  createdDate={createdDate}
                  text={comment}
                  files={files}
                  key={id}
                  bgColor="#FFC42D45"
                  width="80%"
                />
              </Box>
            ),
          )}
        </Box>
        {!disabled && (
          <MessageReply
            showConnection={false}
            placeholder={`${tGeneral("newComment")}`}
            reply={commentReport}
            enableFiles={false}
            isLoading={isCommentLoading}
            disabled={disabled}
          />
        )}
      </Box>
      {initialIndex !== null && (
        <ModalCarouselFileViewer
          isOpen={initialIndex !== null}
          onClose={() => setInitialIndex(null)}
          files={topic?.files}
          initialIndex={initialIndex}
        />
      )}
    </Box>
  );
}

export { ReportLocationComments };
