import { tour } from "./tour";
export const rfis = {
  // general tour
  ...tour,
  moduleElement: "RFI",
  // guide me buttons
  add_button_tour: "Añadir",
  view_button_tour: "Visualizar",
  edit_button_tour: "Editar",
  recycle_button_tour: "Papelera",
  export_button_tour: "Exportar",
  settings_button_tour: "Configuración",
  // ADD - guide me steps
  step1_addRfi_title: "1. Añadir RFI",
  step1_addRfi_text: "Haz clic aquí para añadir RFI",
  step2_addRfi_title: "2. Título",
  step2_addRfi_text: "Escribe un título que describa tu RFI",
  step3_addRfi_title: "3. Fecha de entrega",
  step3_addRfi_text:
    "Elige la fecha de entrega como fecha límite para recibir una respuesta del asignado",
  step4_addRfi_title: "4. Estatus",
  step4_addRfi_text1_1: "Elige el estatus",
  step4_addRfi_text1_2: "ABIERTO para publicar el RFI pendiente de respuesta.",
  step4_addRfi_text2_1: "Elige el estatus",
  step4_addRfi_text2_2:
    "BORRADOR para guardar un borrador del RFI sin publicarlo.",
  step5_addRfi_title: "5. Privacidad",
  step5_addRfi_text_1: "Elige el tipo de privacidad del RFI",
  step5_addRfi_text_2: "Cualquier persona con acceso al módulo verá tu RFI",
  step5_addRfi_text_3: "Solo los participantes podrán ver este RFI",
  step6_addRfi_title: "6. Asignado",
  step6_addRfi_text: "Selecciona las personas que responderán tu pregunta",
  step7_addRfi_title: "7. Pregunta",
  step7_addRfi_text:
    "Escribe la pregunta o la información que desees solicitar",
  step8_addRfi_title: "8. Archivos",
  step8_addRfi_text:
    "Sube cualquier tipo de archivo (PDF, Word, Excel, JPG, etc).",
  step9_addRfi_title: "9. Recibido De",
  step9_addRfi_text:
    "Por favor, especifica la persona del proyecto de quien recibiste la información.",
  step10_addRfi_title: "10. Especificaciones",
  step10_addRfi_text:
    "Elige la especificación a la que quieres hacer referencia.",
  step11_addRfi_title: "11. Dibujo",
  step11_addRfi_text: "Elige el dibujo al que deseas vincular este RFI.",
  step12_addRfi_title: "12. Costo",
  step12_addRfi_text:
    "Si este RFI tiene impacto en el costo, puedes agregar la cantidad.",
  step13_addRfi_title: "13. Retraso",
  step13_addRfi_text:
    "Si este RFI tiene un impacto en el retraso, puedes agregar los días y las horas.",
  step14_addRfi_title: "14. Lista de Distribución",
  step14_addRfi_text:
    "Selecciona las personas que deseas notificar sobre este RFI.",
  step15_addRfi_title: "15. Publicar",
  step15_addRfi_text: "Haz clic en publicar para crear el RFI.",
  stepFinal_addRfi: "Haz aprendido cómo crear un RFI",
  // VIEW - guide me steps
  step1_viewRfi_title: "1. Ver RFI",
  step1_viewRfi_text: "Haga clic aquí para visualizar un RFI",
  step2_viewRfi_title: "2. Información general de RFI",
  step2_viewRfi_text: "Muestra la información completa del RFI",
  step3_viewRfi_title: "3. Pregunta oficial",
  step3_viewRfi_text: "Muestra la pregunta oficial con archivos adjuntos",
  step4_viewRfi_title: "4. Traducción de la pregunta oficial",
  step4_viewRfi_text:
    "La pregunta se traduce automáticamente al idioma deseado para que la comunicación sea efectiva a través de las fronteras",
  step5_viewRfi_title: "5. Agregar respuestas y comentarios",
  step5_viewRfi_text:
    "Los asignatarios pueden enviar respuestas y comentarios sobre la solicitud oficial",
  step6_viewRfi_title: "6. Crear temas",
  step6_viewRfi_text:
    "Los usuarios en la lista de distribución también pueden responder y comentar en la sección de temas",
  step7_viewRfi_title: "7. Última vista",
  step7_viewRfi_text: "Puede ver qué usuarios han visto recientemente este RFI",
  step8_viewRfi_title: "8. Última edición",
  step8_viewRfi_text:
    "Puede ver qué usuarios han interactuado recientemente con este RFI",
  step9_viewRfi_title: "10. Cerrar",
  step9_viewRfi_text:
    "Si eres el creador, tienes la responsabilidad de cerrar este RFI una vez que hayas obtenido una respuesta oficial",
  step10_viewRfi_title: "9. Exportar PDF",
  step10_viewRfi_text: "Puede exportar un PDF con información de este RFI",
  stepFinal_viewRfi: "Haz aprendido cómo ver un RFI",
  // EDIT - guide me steps
  step1_editRfi_title: "1. Editar RFI",
  step1_editRfi_text: "Haz clic aquí para editar un RFI",
  step2_editRfi_title: "2. Título",
  step2_editRfi_text: "Escribe un título que pueda describir tu RFI",
  step3_editRfi_title: "3. Fecha de entrega",
  step3_editRfi_text:
    "Elige la fecha de entrega como la fecha límite para recibir una respuesta del asignado",
  step4_editRfi_title: "4. Estatus",
  step4_editRfi_text1_1: "Elige el estatus",
  step4_editRfi_text1_2:
    "ABIERTO para publicar el RFI pendiente de una respuesta.",
  step4_editRfi_text2_1: "Elige el estatus",
  step4_editRfi_text2_2:
    "BORRADOR para mantener un borrador del RFI sin publicarlo.",
  step5_editRfi_title: "5. Privacidad",
  step5_editRfi_text_1: "Elige el tipo de privacidad de RFI",
  step5_editRfi_text_2: "Cualquier persona con acceso al módulo verá tu RFI",
  step5_editRfi_text_3: "Solo los participantes podrán ver este RFI",
  step6_editRfi_title: "6. Asignado",
  step6_editRfi_text: "Marcador de posición",
  step7_editRfi_title: "7. Lista de distribución",
  step7_editRfi_text: "Marcador de posición",
  step8_editRfi_title: "8. Información adicional",
  step8_editRfi_text: "Marcador de posición",
  step9_editRfi_title: "9. Publicar",
  step9_editRfi_text: "Marcador de posición",
  stepFinal_editRfi: "Haz aprendido a editar un RFI",
  // RECYCLE - guide me steps
  step1_recycleRfi_title: "2. Papelera de RFI",
  step1_recycleRfi_text: "Haz clic aquí para abrir la papelera de RFIs",
  step2_recycleRfi_title: "3. Menú de la papelera",
  step2_recycleRfi_text: "Este es el menú de la papelera de RFIs",
  step3_recycleRfi_title: "4. Ver una RFI",
  step3_recycleRfi_text: "Puedes ver la RFI completa",
  step4_recycleRfi_title: "5. Restaurar RFI",
  step4_recycleRfi_text: "Selecciona una o varias RFIs para restaurar",
  stepFinal_recycleRfi:
    "Haz aprendido cómo interactuar con la papelera de RFIs",
  // EXPORT - guide me steps
  step1_exportRfi_title: "2. Exportar PDF",
  step1_exportRfi_text: "Haz clic aquí para exportar la RFI en formato PDF",
  /*step2_exportRfi_title: "3. Enviar correo electrónico",
  step2_exportRfi_text:
    "Haz clic aquí para compartir esta RFI por correo electrónico",*/
  step3_exportRfi_title: "3. Exportar CSV",
  step3_exportRfi_text:
    "Selecciona algunas o filtra todas las RFIs para exportarlas en un archivo CSV",
  /*step4_exportRfi_title: "4. Exportar PDF",
  step4_exportRfi_text:
    "Selecciona algunas o filtra todas las RFIs para exportarlas en un archivo PDF",*/
  step5_exportRfi_title: "4. Pantalla dentro de una RFI",
  step5_exportRfi_text: "Puedes exportar en PDF desde la vista de detalle",
  stepFinal_exportRfi: "Haz aprendido a exportar un RFI",
  // SETTINGS - guide me steps
  step1_settingsRfi_title: "2. Configuración de RFI",
  step1_settingsRfi_text: "Haz clic aquí para modificar la configuración",
  step2_settingsRfi_title: "3. Días",
  step2_settingsRfi_text:
    "Modifica aquí el límite de días para poder responder a la RFI globalmente",
  step3_settingsRfi_title: "4. Lista de distribución",
  step3_settingsRfi_text:
    "Modifica aquí los usuarios que pertenecen a la lista de distribución globalmente",
  step4_settingsRfi_title: "5. Guardar configuración",
  step4_settingsRfi_text: "Haz clic aquí para guardar la configuración",
  stepFinal_settingsRfi:
    "Haz aprendido cómo interactuar con la configuración de RFI",
  // other
  obligatory: "Obligatorio",
  public: "Público",
  private: "Privado",
  congratulations: "¡Felicidades!",
  rfi_title_module: "RFIs",
  no_rfis_title: "No se han creado RFIs (Dudas) hasta el momento",
  no_rfis_text:
    "Si deseas agregar uno, te invitamos a dar click en el botón de añadir para comenzar con el proceso",
  meeting: "Soporte",
  workflow: "Flujo",
  guideMe: "Guíame",
  support: "Soporte",
  days: "Días",
  rfi_recycle_bin: "Papelera (RFIs)",
  addSpec: "Añadir especificación",
  deleteRfi: "Eliminar RFI(s)",
  confirmDelete:
    "¿Estás seguro de eliminar los rfis seleccionado(s)? Al realizar esta acción, no podrá revertirse.",
};
