import { Box, Typography } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function SubcontractorCard({ manpower }) {
  const { t: tDailyReports } = useTranslation("dailyReports");
  const summary = useMemo(() => {
    if (manpower) {
      return manpower.detail.reduce(
        (acc, m) => {
          return {
            assistants: acc.assistants + (m.assistants > 0 ? m.assistants : 1),
            hours: acc.hours + m.hours,
          };
        },
        { assistants: 0, hours: 0 },
      );
    }
    return { assistants: 0, hours: 0 };
  }, [manpower]);
  return (
    <Box p="0.5rem">
      <Box display="flex" py="0.5rem">
        <Typography flex={1} fontWeight="500">
          {manpower.subcontractor.name}
        </Typography>
        <Typography color="#8E8E8E">
          {moment(manpower.createdDate).format("hh:mm, D, MMM, YY")}
        </Typography>
      </Box>
      <Typography color="#4B4B4B" fontWeight="400">
        {summary.assistants} {tDailyReports("assistants")}
      </Typography>
      <Typography color="#4B4B4B" fontWeight="400">
        {summary.hours} {tDailyReports("workedHours")}
      </Typography>
    </Box>
  );
}

export { SubcontractorCard };
