export const photos = {
  addPhoto: "Adicionar foto",
  timeline: "Galeria",
  albums: "Álbuns",
  album: "Álbum",
  photos: "Fotos",
  addAlbum: "Adicionar álbum",
  photosPerPage: "Fotos por página",
  deleteImagesTitle: "Excluir imagens",
  deleteImagesContent: "Tem certeza de que deseja excluir estas imagens?",
  deleteAlbumsContent: "Tem certeza de que deseja excluir estes álbuns?",
  deleteImagetTitle: "Excluir imagem",
  deleteImageContent: "Tem certeza de que deseja excluir esta imagem?",
  setLocation: "Definir localização",
  setAlbumCover: "Definir como capa do álbum",
  setAlbumCoverConfirm:
    "Tem certeza de que deseja definir esta imagem como capa do álbum?",
  movePhotoLocationTitle: "Mover localização",
  movePhotoAlbumTitle: "Mover álbum",
  movePhotoLocationSubtitle: "Mover foto para localização",
  movePhotoAlbumSubtitle: "Mover foto para álbum",
  movePhotoLocationSuccess: "Localização da imagem editada com sucesso",
  movePhotoAlbumSuccess: "Álbum da imagem editado com sucesso",
  selectedPhotos: "Fotos selecionadas",
  perPage: "Por página",
  noImagesCreated: "Não há imagens criadas no momento",
  noAlbumsCreated: "Não há álbuns criados no momento",
  addImagesValidation:
    "Se você deseja adicionar uma, clique no botão Adicionar Foto para iniciar o processo",
  addAlbumsValidation:
    "Se você deseja adicionar um, clique no botão Adicionar Álbum para iniciar o processo",
  projectConfigurationUpdate: "Configuração do projeto atualizada com sucesso",
  newPhotos: "Novas fotos",
  images: "imagens",
  youHaveUploaded: "Você enviou",
  restoreAlbumsQuestion: "Tem certeza de que deseja restaurar este álbum?",
  restoreImagesQuestion: "Tem certeza de que deseja restaurar estas imagens?",
  deletePermanentlyQuestion:
    "Tem certeza de que deseja excluir permanentemente isso?",
  albumName: "Nome do álbum",
  imagesDeletedSuccessfully: "Imagens excluídas com sucesso",
  imageCoverSetSuccessfully: "Capa do álbum definida com sucesso",
  albumDeletedSuccessfully: "Álbum excluído com sucesso",
  // ADD Photo - guide me steps
  step1_addPhotos_title: "1. Adicionar foto",
  step1_addPhotos_text:
    "Na visualização da linha do tempo, clique no botão Adicionar para adicionar um ou vários arquivos de mídia.",
  step2_addPhotos_title: "2. Localização",
  step2_addPhotos_text: "Selecione uma localização pré-definida",
  step3_addPhotos_title: "3. Álbum",
  step3_addPhotos_text: "Selecione um álbum para os arquivos.",
  step4_addPhotos_title: "4. Adicionar arquivos",
  step4_addPhotos_text:
    "Anexe qualquer tipo de foto ou vídeo ou arraste e solte-os na área pontilhada.",
  step5_addPhotos_title: "5. Confirmar",
  step5_addPhotos_text:
    "Clique aqui para confirmar as informações dos arquivos de mídia enviados e publicá-los.",
  stepFinal_addPhotos: "Você aprendeu como adicionar uma foto",
  // ADD Album - guide me steps
  step1_addAlbums_title: "1. Adicionar álbum",
  step1_addAlbums_text:
    "Na visualização de álbuns, clique no botão Adicionar para adicionar um álbum.",
  step2_addAlbums_title: "2. Nome do álbum",
  step2_addAlbums_text: "Digite um nome para o álbum.",
  step3_addAlbums_title: "3. Adicionar",
  step3_addAlbums_text:
    "Clique aqui para adicionar o álbum ao painel de álbuns.",
  stepFinal_addAlbums: "Você aprendeu como adicionar um álbum",
  // VIEW - guide me steps
  step1_viewPhotos_title: "1. Ver arquivo de mídia",
  step1_viewPhotos_text: "Clique em uma foto ou vídeo para visualizá-lo.",
  step2_viewPhotos_title: "2. Visualizar arquivo de mídia",
  step2_viewPhotos_text:
    "Uma vez que você clicou em um arquivo, ele será exibido em tela cheia.",
  step3_viewPhotos_title: "3. Informações",
  step3_viewPhotos_text:
    "No canto superior direito da visualização prévia do arquivo de mídia, serão exibidas a data em que foi tirado, a data e quem o enviou e a localização.",
  step4_viewPhotos_title: "4. Comentários",
  step4_viewPhotos_text:
    "Comente sobre o arquivo de mídia para que outras pessoas possam ver suas observações.",
  step5_viewPhotos_title: "5. Baixar arquivo de mídia",
  step5_viewPhotos_text:
    "Clique aqui para baixar o arquivo de mídia selecionado para o seu dispositivo.",
  step6_viewPhotos_title: "6. Excluir imagem/vídeo",
  step6_viewPhotos_text:
    "Se você é um superadministrador ou administrador, pode excluir os arquivos de mídia enviados.",
  step7_viewPhotos_title: "7. Mais opções",
  step7_viewPhotos_text:
    "Passe o cursor sobre os três pontos e um menu suspenso será exibido com as seguintes opções:",
  step7_viewPhotos_text1_1: "Mudar de álbum",
  step7_viewPhotos_text1_2:
    "Mova o arquivo de mídia selecionado para um álbum diferente.",
  step7_viewPhotos_text2_1: "Mudar localização",
  step7_viewPhotos_text2_2:
    "Altere a localização referenciada pelo arquivo de mídia.",
  step7_viewPhotos_text3_1: "Definir como capa do álbum",
  step7_viewPhotos_text3_2:
    "Defina a foto selecionada como capa do álbum ao qual pertence (isso só funciona com fotos).",
  stepFinal_viewPhotos: "Você aprendeu como visualizar um arquivo de mídia",
  // RECYCLE - guide me steps
  step1_recyclePhotos_title: "1. Lixeira",
  step1_recyclePhotos_text:
    "Clique aqui para abrir o menu da lixeira. Isso o levará ao menu de fotos/álbuns, dependendo da sua visualização atual.",
  step2_recyclePhotos_title: "2. Selecionar arquivos/álbuns para restaurar",
  step2_recyclePhotos_text:
    "Selecione as caixas de seleção dos arquivos/álbuns que deseja restaurar ou excluir permanentemente. Você também pode selecionar os arquivos de um dia inteiro, uma semana ou um mês, se necessário.",
  step3_recyclePhotos_title: "3. Restaurar",
  step3_recyclePhotos_text:
    "Clique aqui para restaurar os arquivos selecionados.",
  step4_recyclePhotos_title: "4. Excluir",
  step4_recyclePhotos_text:
    "Clique aqui para excluir permanentemente os arquivos selecionados.",
  stepFinal_recyclePhotos:
    "Você aprendeu como interagir com a lixeira de fotos",
  // EXPORT - guide me steps
  step1_exportPhotos_title: "1. Selecionar arquivos de mídia",
  step1_exportPhotos_text:
    "Selecione a caixa de seleção de todos os arquivos de mídia que deseja exportar. Você também pode selecionar os arquivos de um dia inteiro, uma semana ou um mês, se necessário.",
  step2_exportPhotos_title: "2. Ações",
  step2_exportPhotos_text:
    "Clique aqui e um menu suspenso aparecerá com as opções de exportar arquivos de mídia em formato PDF (1, 2 ou 4 por página).",
  stepFinal_exportPhotos: "Você aprendeu como exportar um arquivo de mídia",
  // SETTINGS - guide me steps
  step1_settingsPhotos_title: "1. Configurações",
  step1_settingsPhotos_text:
    "Clique aqui para abrir o menu suspenso de configurações.",
  step2_settingsPhotos_title: "2. Lista de distribuição",
  step2_settingsPhotos_text:
    "Selecione as pessoas que terão permissão para ver todos os arquivos de mídia e álbuns.",
  step3_settingsPhotos_title: "3. Salvar",
  step3_settingsPhotos_text: "Salve as configurações.",
  stepFinal_settingsPhotos:
    "Você aprendeu como interagir com as configurações do módulo",
  // other
  obligatory: "Obrigatório",
  public: "Público",
  private: "Privado",
  congratulations: "Parabéns!",
  rfi_title_module: "RFIs (Dúvidas)",
  no_rfis_title: "Nenhuma RFI (Dúvida) foi criada até o momento",
  no_rfis_text:
    "Se você deseja adicionar uma, clique no botão Adicionar para iniciar o processo",
  meeting: "Suporte",
  workflow: "Fluxo",
  guideMe: "Guie-me",
  support: "Suporte",
  days: "Dias",
  rfi_recycle_bin: "Lixeira (RFIs)",
  addSpec: "Adicionar especificação",
  imageUploadedMessage: "Imagens adicionadas com sucesso.",
  captureSite: "Local de captura",
  dateUploaded: "Data de envio",
};
