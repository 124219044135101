import { Box, Button } from "@mui/material";

function ButtonTabs({ tabs, activeTab, setActiveTab }) {
  return (
    <Box display="flex" columnGap="0.5rem" alignItems="center">
      {tabs.map(({ value, label }) => (
        <Button
          variant="outlined"
          sx={{
            backgroundColor: "white",
            border:
              activeTab === value
                ? "0.8px solid #E6B60C"
                : "0.8px solid transparent",
            borderRadius: "1rem",
            p: "0.5rem 1.5rem",
            textTransform: "none",
          }}
          onClick={() => setActiveTab(value)}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
}

export { ButtonTabs };
