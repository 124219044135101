const status = {
  open: "Aberto",
  closed: "Fechado",
  close: "Fechado",
  in_revision: "Em revisão",
  draft: "Rascunho",
  pending: "Pendente",
  doing: "Em andamento",
  backlog: "Em backlog",
  done: "Concluído",
  inactive: "Inativo",
};

export { status };
