import {
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  useGetRolesFromEnterprisesQuery,
  useGetUsersFromEnterpriseQuery,
} from "../../../features/enterprise/enterpriseApiSlice";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { StyledMaterialReactTable, TooltipIconButton } from "../../shared";
import { Add, Delete } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { useState } from "react";

function Users() {
  const { idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");

  const [idUser, setIdUser] = useState(null);
  const [idRole, setIdRole] = useState(null);

  const { watch, setValue } = useFormContext();

  const { data: users, isLoading: isUsersLoading } =
    useGetUsersFromEnterpriseQuery({ idEnterprise });
  const { data: roles, isLoading: isRolesLoading } =
    useGetRolesFromEnterprisesQuery(idEnterprise);

  const currentUsers = watch("users");

  const handleAddUser = () => {
    setValue("users", [...currentUsers, { idUser, idRole }]);
    setIdUser(null);
    setIdRole(null);
  };
  const handleDelete = (id) => {
    setValue(
      "users",
      currentUsers.filter((user) => user.idUser !== id),
    );
  };

  const columns = [
    {
      accessorKey: "idUser",
      header: tGeneral("user"),
      Cell: ({ cell }) => {
        const valUser = users?.find((user) => user.id === cell.getValue());
        return (
          <Typography>
            {valUser?.firstName} {valUser.lastName}
          </Typography>
        );
      },
      size: 100,
    },
    {
      accessorKey: "idRole",
      header: tGeneral("role"),
      Cell: ({ cell }) => (
        <Typography>
          {roles?.find((role) => role.id === cell.getValue())?.name}
        </Typography>
      ),
      size: 100,
    },
  ];

  if (isUsersLoading || isRolesLoading) {
    return (
      <Box display="flex" height="100%" width="100%">
        <CircularProgress sx={{ fontSize: "3rem" }} />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" rowGap="0.5rem">
      <Box display="flex" columnGap="0.5rem">
        <FormControl fullWidth size="small">
          <InputLabel>{tGeneral("user")}</InputLabel>
          <Select
            value={idUser}
            onChange={(e) => setIdUser(e.target.value)}
            MenuProps={{ sx: { maxHeight: "300px" } }}
            label={tGeneral("user")}
            renderValue={(val) => {
              const valUser = users?.find((user) => user.id === val);
              return `${valUser?.firstName} ${valUser?.lastName}`;
            }}
          >
            {users
              ?.filter(
                (user) =>
                  currentUsers.findIndex((cu) => cu.idUser === user.id) < 0,
              )
              ?.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  <Box display="flex" alignItems="center" columnGap="0.5rem">
                    <Avatar src={user.urlAvatar}>
                      {user.firstName[0]}
                      {user.lastName[0]}
                    </Avatar>
                    <Typography>
                      {user.firstName} {user.lastName}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel>{tGeneral("role")}</InputLabel>
          <Select
            label={tGeneral("role")}
            value={idRole}
            onChange={(e) => setIdRole(e.target.value)}
            MenuProps={{ sx: { maxHeight: "300px" } }}
          >
            {roles?.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TooltipIconButton
          label={tGeneral("add")}
          onClick={handleAddUser}
          icon={<Add />}
          disabled={!idRole || !idUser}
        />
      </Box>
      <StyledMaterialReactTable
        columns={columns}
        data={currentUsers}
        enableRowActions
        renderRowActions={({ row }) => (
          <TooltipIconButton
            icon={<Delete />}
            label={tGeneral("delete")}
            onClick={() => handleDelete(row.original?.idUser)}
            color="red"
          />
        )}
      />
    </Box>
  );
}

export { Users };
