import { datadogRum } from "@datadog/browser-rum";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  Delete,
  ExpandMore,
  FirstPage,
  LastPage,
  Message,
  MoreVert,
  RemoveRedEye,
  StickyNote2,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { createSearchParams } from "react-router-dom";
import { useCommentObjectMutation } from "../../../features/message/messageApiSlice";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import { useDeleteTaskMutation } from "../../../features/project/modules/tasks/tasksApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
} from "../../../features/project/projectSlice";
import { capitalizeFirstLetterOfSentence } from "../../../services/helpers/capitalizeFirstLetterOfSentence";
import { PopUpAlert } from "../../PopUpAlert";
import { MessageBox } from "../../question/MessageBox";
import { MessageReply } from "../../question/MessageReply";

import DeleteImg from "../../../assets/icons/delete.png";
import { useAppTourContext } from "../../supportButton/context/context";

const tableIcons = {
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
};

function TopicItemDetail({ topic, canBeComment, canModifyTask }) {
  const { t: tMeetings } = useTranslation("meetings");
  const { t: tGeneral } = useTranslation("general");

  const { idEnterprise, idProject } = useParams();
  const navigate = useNavigate();
  const darkMode = useSelector(selectTheme);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [idTaskToDelete, setIdTaskToDelete] = useState(null);
  const openMenu = Boolean(anchorEl);
  const currentProject = useSelector(selectCurrentProject);

  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  const [commentTopic, { isLoading: isCommentTopicLoading }] =
    useCommentObjectMutation();
  const [deleteTask, { isLoading: isDeleteTaskLoading }] =
    useDeleteTaskMutation();

  const deleteCurrentTask = async () => {
    try {
      const response = await deleteTask({ ids: [idTaskToDelete] }).unwrap();
      if (response) {
        setOpenDelete(false);
        setIdTaskToDelete(null);
        PopUpAlert("success", tMeetings("taskSuccessfullyDeleted"));
      }
    } catch (error) {
      PopUpAlert("error", tMeetings("taskCantDelete"));
      datadogRum.addError({
        message: "Meetings - Task Delete - Error",
        stack: error.stack ? error.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: currentProject?.idEnterprise,
          module: "Meetings",
          project: currentProject?.id,
        },
        timestamp: Date.now(),
      });
    }
  };

  const columns = [
    // Task name
    {
      field: "title",
      title: tMeetings("taskName"),
      initialEditValue: "",
      headerStyle: {
        textAlign: "left",
        width: "22.9%",
        maxWidth: "22.9%",
        fontWeight: "bold",
      },
      cellStyle: {
        fontSize: 12,
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "22.9%",
        maxWidth: "22.9%",
      },
      render: (rowData) => (
        <Typography
          fontSize="1rem"
          maxWidth="360px"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {capitalizeFirstLetterOfSentence(rowData.title)}
        </Typography>
      ),
    },
    // Assigned
    {
      field: "assignedUsers",
      title: tGeneral("assigned"),
      headerStyle: {
        textAlign: "center",
        width: "16%",
        maxWidth: "16%",
        fontWeight: "bold",
      },
      cellStyle: {
        fontSize: 12,
        textAlign: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "16%",
        maxWidth: "16%",
      },
      initialEditValue: "",
      render: (rowData) =>
        rowData?.assignedTo?.length > 0 ? (
          <Box display="flex" justifyContent="center">
            <AvatarGroup
              max={3}
              sx={{
                "& .MuiAvatar-root": { width: 28, height: 28, fontSize: 15 },
                "& .MuiTooltip-tooltip": {
                  border: "solid skyblue 1px",
                  color: "deepskyblue",
                },
                "& .MuiAvatarGroup-root": {
                  marginX: "auto",
                  textAlign: "center",
                },
              }}
            >
              {rowData.assignedTo.map((user) => {
                if (user.urlAvatar != null) {
                  return (
                    <Avatar
                      key={user.id}
                      alt={user.name}
                      src={user?.urlAvatar}
                      sx={{
                        bgcolor: "#ffc900",
                        boxShadow: "0 0 5px 0 rgba(200, 200, 200, 1)",
                      }}
                    >
                      {`${user.name.split(" ")[0][0]}${
                        user.name.split(" ")[1][0]
                      }`}
                    </Avatar>
                  );
                }
                return (
                  <Avatar
                    key={user.id}
                    alt={user.name}
                    sx={{
                      bgcolor: "#ffc900",
                      boxShadow: "0 0 5px 0 rgba(200, 200, 200, 1)",
                    }}
                  >
                    {`${user.name.split(" ")[0][0]}${
                      user.name.split(" ")[1][0]
                    }`}
                  </Avatar>
                );
              })}
            </AvatarGroup>
          </Box>
        ) : (
          <Typography sx={{ fontSize: 12, color: darkMode }}>
            {tGeneral("noAssignedUsers")}
          </Typography>
        ),
    },
    // Actions
    {
      field: "action",
      title: tGeneral("actions"),
      initialEditValue: "",
      sorting: false,
      headerStyle: {
        textAlign: "center",
        width: "10.3%",
        maxWidth: "10.3%",
        fontWeight: "bold",
      },
      cellStyle: {
        fontSize: 12,
        textAlign: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "10.3%",
        maxWidth: "10.3%",
      },
      render: (rowData) => (
        <>
          <IconButton
            type="submit"
            size="small"
            title={tGeneral("seeDetails")}
            onClick={() => {
              navigate(
                `/enterprise/${idEnterprise}/project/${idProject}/tasks/${rowData.id}`,
              );
            }}
            sx={{
              color: darkMode ? "white" : "black",
              ":hover": {
                color: "#FBCB04",
              },
              m: [0, 0, 0, 0],
            }}
          >
            <RemoveRedEye />
          </IconButton>
          {canModifyTask && (
            <IconButton
              type="submit"
              size="small"
              title={tGeneral("delete")}
              onClick={() => {
                setIdTaskToDelete(rowData.id);
                setOpenDelete(true);
              }}
              sx={{
                color: "red",
                m: [0, 0, 0, 0],
              }}
            >
              <Delete />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  // Topic menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleComment = (idTopic, message, files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("idObject", idTopic);
    formData.append("comment", message);
    formData.append("module", "meetings");
    commentTopic(formData);
    PopUpAlert("success", "Send comment successfully");
  };

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  useEffect(() => {
    if (tourActive && currentGuideMe === "meetings-view") {
      if (stepIndex === 6) {
        setOpen(true);
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }
  }, [tourActive, stepIndex]);

  return (
    <>
      <Accordion
        expanded={open}
        sx={{ backgroundColor: "transparent" }}
        data-tour="view-meetings-6"
      >
        <AccordionSummary>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row">
                <IconButton onClick={() => setOpen(!open)}>
                  <ExpandMore
                    sx={{
                      transform: open ? "rotate(180deg)" : "none",
                      color: darkMode ? "white" : "black",
                    }}
                  />
                </IconButton>
                <Typography mt={1} whiteSpace="pre-wrap">
                  {topic?.title}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" px={5} columnGap={5}>
                {/* Comments */}
                <Box display="flex" flexDirection="row">
                  <Message sx={{ color: "#B0B7C1" }} />
                  <Typography ml={1} color="#B0B7C1">
                    {topic?.comments?.length}
                  </Typography>
                </Box>
                {/* Tasks */}
                <Box display="flex" flexDirection="row">
                  <Typography mr={1} color="#B0B7C1">
                    {topic?.tasks?.length}
                  </Typography>
                  <StickyNote2 sx={{ color: "#B0B7C1" }} />
                </Box>
              </Box>
            </Box>
            {canModifyTask && (
              <Box>
                <IconButton onClick={handleClick}>
                  <MoreVert sx={{ color: darkMode ? "white" : "black" }} />
                </IconButton>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {/* Comments */}
          <Box
            display="flex"
            flexDirection="column"
            pl={10} // inMobile don't use (change)
            pr={2}
            width="100%"
            data-tour="view-meetings-7"
          >
            <Divider />
            <Typography fontWeight="bold" sx={{ my: 2 }}>
              {tGeneral("comments")}
            </Typography>
            {topic?.comments?.map((comment) => (
              <Box key={comment.id} display="flex" width="100%">
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Card sx={{ borderRadius: "100%", p: "2px" }}>
                    <Avatar src={comment?.urlAvatar} />
                  </Card>
                </Box>
                <Box flex={1}>
                  <MessageBox
                    createdDate={comment?.createdDate}
                    createdBy={`${comment?.firstName} ${comment?.lastName}`}
                    files={comment?.files}
                    text={comment?.comment}
                    question
                    isTopic
                  />
                </Box>
              </Box>
            ))}
            {topic?.comments.length === 0 && (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                alignItems="center"
              >
                <Typography mb={2}>{tGeneral("noComments")}</Typography>
              </Box>
            )}
            {canBeComment && (
              <MessageReply
                reply={(message, files) =>
                  handleComment(topic?.id, message, files)
                }
                placeholder={tGeneral("newComment")}
                isLoading={isCommentTopicLoading}
                showConnection={false}
              />
            )}
          </Box>
          {/* Tasks */}
          <Box
            display="flex"
            flexDirection="column"
            pl={10} // inMobile don't use (change)
            pr={2}
            width="100%"
            data-tour="view-meetings-8"
          >
            <Divider />
            <Typography fontWeight="bold" sx={{ my: 2 }}>
              {tGeneral("tasks")}
            </Typography>
            <Box display="flex" flexDirection="column" width="100%">
              {topic?.tasks?.length > 0 ? (
                <MaterialTable
                  sx={{ overflowY: "scroll" }}
                  icons={tableIcons}
                  columns={columns}
                  data={topic?.tasks}
                  localization={tableLocalization}
                  options={{
                    showTitle: false,
                    toolbar: false,
                    search: false,
                    emptyRowsWhenPaging: false,
                    sorting: false,
                    maxBodyHeight: "65vh",
                    actionsColumnIndex: -1,
                    selection: false,
                    paging: true,
                    exportButton: false,
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 20, 30],
                    showFirstLastPageButtons: true,
                    headerStyle: {
                      textAlign: "center",
                      fontSize: "1rem",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                    },
                    rowStyle: {
                      height: "10px",
                      overflowWrap: "break-word",
                    },
                    padding: "dense",
                    draggable: false,
                  }}
                  disableSelectionOnClick
                />
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  alignItems="center"
                >
                  <Typography mb={2}>{tGeneral("noTasks")}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* Topic menu */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            const params = { topic: topic?.id };
            navigate({
              pathname: `/enterprise/${idEnterprise}/project/${idProject}/tasks/form`,
              search: `?${createSearchParams(params)}`,
            });
          }}
        >
          <ListItemIcon>
            <StickyNote2 sx={{ color: "black" }} />
          </ListItemIcon>
          <ListItemText>{tMeetings("newTask")}</ListItemText>
        </MenuItem>
      </Menu>
      {/* Delete dialog */}
      <Modal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          sx={(theme) => ({
            height: "auto",
            borderRadius: "20px",
            p: "25px 40px",
            width: "30%",
            [theme.breakpoints.down("lg")]: {
              width: "50%",
            },
          })}
        >
          <Box display="flex" justifyContent="right">
            <IconButton onClick={() => setOpenDelete(false)}>
              <Close fontSize="1.5625rem" />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={DeleteImg} alt="Delete" width="70px" />
            <Typography
              sx={{
                fontWeight: "bold",
                color: darkMode ? "white" : "black",
                mt: 1,
              }}
            >
              {tMeetings("deleteTask").toUpperCase()}
            </Typography>
            <Typography
              sx={{
                color: darkMode ? "white" : "black",
                transform: "none",
                mx: 1,
                mt: 2,
                textAlign: "center",
              }}
            >
              {tMeetings("deleteTaskQuestion")}
            </Typography>
            {/* Actions */}
            <Box display="flex" columnGap="16px" mt="20px" width="100%">
              <Button
                sx={{
                  width: "50%",
                  color: "black",
                  backgroundColor: "white",
                  borderColor: "black",
                  border: 1,
                  ":hover": {
                    color: "white",
                    backgroundColor: "#b4b4b4",
                  },
                  py: "0.5rem",
                }}
                fullWidth
                type="submit"
                variant="contained"
                onClick={() => setOpenDelete(false)}
              >
                {tGeneral("cancel")}
              </Button>
              <Button
                sx={{
                  width: "50%",
                  color: "black",
                  backgroundColor: "#ffc900",
                  ":hover": {
                    color: "black",
                    backgroundColor: "#d9ab00",
                  },
                  py: "0.5rem",
                }}
                type="submit"
                variant="contained"
                onClick={() => {
                  deleteCurrentTask();
                }}
              >
                {tGeneral("accept")}
              </Button>
            </Box>
          </Box>
        </Card>
      </Modal>
    </>
  );
}

export default TopicItemDetail;
