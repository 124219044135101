import { datadogRum } from "@datadog/browser-rum";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Lock,
  LockOpen,
  Remove,
  RemoveRedEye,
  Search,
  ViewColumn,
} from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  ButtonGroup,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

// Helpers
import { capitalizeFirstLetterOfSentence } from "../../../services/helpers/capitalizeFirstLetterOfSentence";
import {
  getCreatorInfo,
  getSelectedUsers,
  getUsersByUserId,
} from "../../../services/helpers/userInfo";

import { PopUpAlert } from "../../../components/PopUpAlert";
import { EmptyState, TitleWidgets } from "../../../components/shared";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import {
  useGetMeetingsQuery,
  useRecoverMeetingMutation,
} from "../../../features/project/modules/meetings/meetingApiSlice";
import { setCurrentMeeting } from "../../../features/project/modules/meetings/meetingSlice";
import { selectCurrentProject } from "../../../features/project/projectSlice";
import { useGetAllProjectUsersQuery } from "../../../features/project/projectApiSlice";
import { useAppTourContext } from "../../../components/supportButton/context/context";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow followCursor />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 10,
  },
}));

function MeetingsRecycle() {
  const { idProject } = useParams();
  const darkMode = useSelector(selectTheme);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t: tMeetings } = useTranslation("meetings");
  const { t: tGeneral } = useTranslation("general");

  const currentProject = useSelector(selectCurrentProject);

  const statusProps = {
    open: {
      label: tGeneral("open_action").toUpperCase(),
      styles: {
        backgroundColor: "#5ce381",
        color: "white",
        fontSize: "0.75rem",
        width: "100px",
        fontWeight: "medium",
      },
    },
    close: {
      label: tGeneral("closed").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#c2c3c3",
        fontSize: "0.75rem",
        width: "100px",
        fontWeight: "medium",
      },
    },
    draft: {
      label: tGeneral("draft").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#fe9300",
        fontSize: "0.75rem",
        width: "100px",
        fontWeight: "medium",
      },
    },
    stillOpen: {
      label: tGeneral("still_open").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#ffc900",
        fontSize: "0.75rem",
        width: "100px",
        fontWeight: "medium",
      },
    },
    notDefined: {
      label: tGeneral("not_defined").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#2196f3",
        fontSize: "0.75rem",
      },
    },
  };

  function formatDate(simpleDate) {
    const date = new Date(simpleDate);

    const months = [
      tGeneral("january"),
      tGeneral("february"),
      tGeneral("march"),
      tGeneral("april"),
      tGeneral("may"),
      tGeneral("june"),
      tGeneral("july"),
      tGeneral("august"),
      tGeneral("september"),
      tGeneral("october"),
      tGeneral("november"),
      tGeneral("december"),
    ];

    const dateBirthFormat = `${date.getDate()} ${
      months[date.getMonth()]
    } ${date.getFullYear()}`;

    return dateBirthFormat;
  }

  const [paramsMeetings, setParamsMeetings] = useState(null);

  const { data: projectUsers, isSuccessUsers } =
    useGetAllProjectUsersQuery(idProject);
  const { data: projectMeetings, isSuccess } = useGetMeetingsQuery(
    paramsMeetings,
    {
      skip: paramsMeetings === null,
    },
  );
  const [recoverMeetings] = useRecoverMeetingMutation();

  const [data, setData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [restoreIds, setRestoreIds] = useState([]);

  // Search
  const [searchValue, setSearchValue] = useState("");
  const customSearchFunction = (value) => {
    setSearchValue(value);
  };

  const Color = {
    color: darkMode ? "white" : "black",
  };

  useEffect(() => {
    if (paramsMeetings === null) {
      setParamsMeetings({
        idProject,
        bActive: false,
        attendence: undefined,
        creator: undefined,
      });
    }

    if (projectUsers && projectMeetings) {
      let usersList = projectUsers
        .filter(function (obj) {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.id,
          name: user.user.firstName + " " + user.user.lastName,
          urlAvatar: user.user.urlAvatar,
          idUser: user.user.id,
        }));
      setUserList(usersList);

      setData(
        projectMeetings.map((meeting) => ({
          ...meeting,
          creator: getCreatorInfo(meeting.createdBy, usersList),
          date: formatDate(meeting.date),
          closedDate: formatDate(meeting.closedDate),
          attendanceByIdUser: getUsersByUserId(
            meeting.attendenceList,
            usersList,
          ),
          attendanceUsers: getAttendance(
            getSelectedUsers(meeting.attendenceList, usersList),
            meeting.confirmedAttendenceList,
          ),
          personList: getSelectedUsers(meeting.attendenceList, usersList),
          usersDistribution: getSelectedUsers(
            meeting.distributionList,
            usersList,
          ),
        })),
      );
    }
  }, [projectUsers, projectMeetings]);

  function getAttendance(attendanceList, confirmedAttendanceList) {
    return attendanceList.map((user) => ({
      ...user,
      attendance: confirmedAttendanceList.indexOf(user.id) > -1 ? true : false,
    }));
  }

  const columns = [
    // Private
    {
      field: "bPrivate",
      title: tGeneral("private"),
      sorting: false,
      align: "center",
      width: "5%",
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
      },
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
      },
      initialEditValue: false,
      render: (rowData) => (rowData.bPrivate ? <Lock /> : <LockOpen />),
    },
    // Num
    {
      field: "number",
      title: "Num",
      initialEditValue: "",
      sorting: false,
      align: "center",
      width: "10%",
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
      },
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
      },
    },
    // title
    {
      field: "title",
      title: tGeneral("title"),
      initialEditValue: "",
      sorting: false,
      align: "left",
      width: "30%",
      cellStyle: {
        width: "30%",
        maxWidth: "30%",
      },
      headerStyle: {
        width: "30%",
        maxWidth: "30%",
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      render: (rowData) => (
        <Typography fontSize="1rem">
          {capitalizeFirstLetterOfSentence(rowData.title).substring(0, 38)}
          {rowData.title.length > 38 ? "..." : ""}
        </Typography>
      ),
    },
    // status
    {
      field: "status",
      title: tGeneral("status"),
      initialEditValue: "",
      sorting: false,
      align: "center",
      width: "10%",
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
      },
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
      },
      render: (rowData) => (
        <Chip
          sx={statusProps[rowData.status].styles}
          label={statusProps[rowData.status].label}
        />
      ),
    },

    // Attendance
    {
      field: "attendance",
      title: tGeneral("attendance"),
      initialEditValue: "",
      sorting: false,
      align: "center",
      width: "15%",
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
      },
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
      },
      render: (rowData) =>
        rowData?.attendanceUsers?.length > 0 ? (
          <Box display="flex" justifyContent="center">
            <LightTooltip
              title={rowData?.attendanceUsers?.map((user) => (
                <Box
                  key={user.id}
                  sx={{
                    display: "flex",
                    marginY: "2px",
                  }}
                >
                  <Check
                    fontSize="small"
                    style={{
                      color: user.attendance ? "#ffc900" : "transparent",
                      marginRight: 5,
                    }}
                  />
                  {user.urlAvatar != null ? (
                    <Avatar
                      key={user.id}
                      alt={user.name}
                      src={user?.urlAvatar}
                      sx={{
                        bgcolor: "#ffc900",
                        width: 24,
                        height: 24,
                        fontSize: 12,
                        boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                      }}
                      style={{
                        border: "0.8px solid white",
                      }}
                    >
                      {`${user.name?.split(" ")[0][0]}${
                        user.name?.split(" ")[1][0]
                      }`}
                    </Avatar>
                  ) : (
                    <Avatar
                      key={user.id}
                      alt={user.name}
                      sx={{
                        bgcolor: "#ffc900",
                        width: 24,
                        height: 24,
                        fontSize: 12,
                        boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                      }}
                      style={{
                        border: "0.8px solid white",
                      }}
                    >
                      {`${user.name?.split(" ")[0][0]}${
                        user.name?.split(" ")[1][0]
                      }`}
                    </Avatar>
                  )}
                  <Typography sx={{ fontSize: 15 }}>
                    &nbsp;{user.name}
                  </Typography>
                </Box>
              ))}
            >
              <AvatarGroup
                max={3}
                sx={{
                  "& .MuiAvatar-root": { width: 28, height: 28, fontSize: 15 },
                  "& .MuiTooltip-tooltip": {
                    border: "solid skyblue 1px",
                    color: "deepskyblue",
                  },
                  "& .MuiAvatarGroup-root": {
                    marginX: "auto",
                    textAlign: "center",
                  },
                }}
              >
                {rowData.attendanceUsers.map((user) => {
                  if (user.urlAvatar != null) {
                    return (
                      <Avatar
                        key={user.id}
                        alt={user.name}
                        src={user?.urlAvatar}
                        sx={{
                          bgcolor: "#ffc900",
                          boxShadow: "0 0 5px 0 rgba(200, 200, 200, 1)",
                        }}
                      >
                        {`${user.name?.split(" ")[0][0]}${
                          user.name?.split(" ")[1][0]
                        }`}
                      </Avatar>
                    );
                  } else {
                    return (
                      <Avatar
                        key={user.id}
                        alt={user.name}
                        sx={{
                          bgcolor: "#ffc900",
                          boxShadow: "0 0 5px 0 rgba(200, 200, 200, 1)",
                        }}
                      >
                        {`${user.name?.split(" ")[0][0]}${
                          user.name?.split(" ")[1][0]
                        }`}
                      </Avatar>
                    );
                  }
                })}
              </AvatarGroup>
            </LightTooltip>
          </Box>
        ) : (
          <Typography color={Color} sx={{ fontSize: 12 }}>
            {tGeneral("noAttendanceUsers")}
          </Typography>
        ),
    },

    // Date
    {
      field: "date",
      title: tGeneral("date"),
      initialEditValue: "",
      align: "center",
      width: "15%",
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
      },
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
      },
      render: (rowData) => (
        <Typography fontSize="1rem">
          {rowData.date} {rowData.hour}
        </Typography>
      ),
    },
    // close date
    {
      field: "closedDate",
      title: tGeneral("closedDate"),
      initialEditValue: "",
      sorting: false,
      align: "center",
      width: "15%",
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
      },
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
      },
      render: (rowData) =>
        rowData.status !== "close" ? (
          <Chip
            sx={[statusProps["stillOpen"].styles, { width: "130px" }]}
            label={statusProps["stillOpen"].label}
          />
        ) : (
          <Typography sx={{ fontSize: "1rem" }}>
            {rowData.closedDate}
          </Typography>
        ),
    },
    // actions
    {
      field: "action",
      title: tGeneral("actions"),
      initialEditValue: "",
      sorting: false,
      align: "center",
      width: "5%",
      render: (rowData) => (
        <ButtonGroup>
          <Tooltip title={tGeneral("seeDetails")}>
            <IconButton
              type="submit"
              size="small"
              onClick={() => {
                dispatch(setCurrentMeeting(rowData));
                // navigate(`/project/${idProject}/meetings/details`);
                navigate(`./../${rowData.id}`);
              }}
              sx={{
                ...Color,
                ":hover": {
                  color: "#FBCB04",
                },
                m: [0, 0, 0, 0],
              }}
              data-tour={
                rowData.tableData?.id === 0 ? "recycle-meetings-3" : ""
              }
            >
              <RemoveRedEye />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ),
    },
  ];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  // Select items for restore
  const handleSelectMeetings = (rows) => {
    const selected = rows.map((meeting) => meeting?.id);
    setRestoreIds(selected);
  };

  // Restore meeting
  const restoreMeeting = async () => {
    try {
      const response = await recoverMeetings({ ids: restoreIds }).unwrap();
      if (response) {
        PopUpAlert("success", tMeetings("meetingRecovered"));
      }
    } catch (err) {
      PopUpAlert("error", tMeetings("errorMeetingRecovered"));
      datadogRum.addError({
        message: "Meetings - Restore - Error",
        stack: err.stack ? err.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: currentProject?.idEnterprise,
          module: "Meetings",
          project: currentProject?.id,
        },
        timestamp: Date.now(),
      });
    }
  };

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (tourActive && stepIndex === 2 && projectMeetings && isSuccess) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, projectMeetings, isSuccess]);

  return (
    <>
      <TitleWidgets
        title={tMeetings("meetingsRecycleBin")}
        backEnabled
        customBackFunctionEnabled
        customBackFunction={() => {
          navigate(-1);
        }}
        supportDisabled
        isRecycle
        restoreDisabled={!restoreIds.length}
        onRestore={restoreMeeting}
      />
      {data?.length > 0 ? (
        <Box sx={{ height: 500, width: "100%" }}>
          <MaterialTable
            icons={tableIcons}
            columns={columns}
            data={data.filter(function (obj) {
              return obj.title
                .toLowerCase()
                .includes(searchValue.toLowerCase());
            })}
            localization={tableLocalization}
            options={{
              toolbar: false,
              search: false,
              emptyRowsWhenPaging: false,
              sorting: false,
              selection: true,
              selectionProps: {
                className: "checkbox-recycle",
              },
              exportButton: false,
              actionsColumnIndex: -1,
              pageSize: 30,
              pageSizeOptions: [10, 20, 30],
              showFirstLastPageButtons: true,
              headerStyle: {
                fontSize: 12,
                height: "30px",
                fontWeight: "bold",
              },
              rowStyle: {
                height: "25px",
                overflowWrap: "break-word",
              },
              padding: "dense",
              draggable: false,
              overflowY: "auto",
            }}
            checkboxSelection
            disableSelectionOnClick
            onSelectionChange={handleSelectMeetings}
          />
        </Box>
      ) : (
        <EmptyState title={tMeetings("noMeetings")} module="meetings" />
      )}
    </>
  );
}

export default MeetingsRecycle;
