export const photos = {
  addPhoto: "写真を追加",
  timeline: "タイムライン",
  albums: "アルバム",
  album: "アルバム",
  photos: "写真",
  addAlbum: "アルバムを追加",
  photosPerPage: "ページごとの写真数",
  deleteImagesTitle: "画像を削除",
  deleteImagesContent: "これらの画像を削除してもよろしいですか？",
  deleteAlbumsContent: "これらのアルバムを削除してもよろしいですか？",
  deleteImagetTitle: "画像を削除",
  deleteImageContent: "この画像を削除してもよろしいですか？",
  setLocation: "場所を設定",
  setAlbumCover: "アルバムカバーに設定",
  setAlbumCoverConfirm: "この画像をアルバムカバーに設定してもよろしいですか？",
  movePhotoAlbumTitle: "アルバムを変更",
  movePhotoLocationTitle: "場所を変更",
  movePhotoLocationSubtitle: "写真を場所に移動",
  movePhotoAlbumSubtitle: "写真をアルバムに移動",
  movePhotoLocationSuccess: "画像の場所が正常に更新されました",
  movePhotoAlbumSuccess: "画像のアルバムが正常に更新されました",
  selectedPhotos: "選択した写真",
  perPage: "ページごと",
  noImagesCreated: "この時点で作成された画像はありません",
  noAlbumsCreated: "この時点で作成されたアルバムはありません",
  addImagesValidation:
    "追加する場合は、[画像を追加]ボタンをクリックしてプロセスを開始してください",
  addAlbumsValidation:
    "追加する場合は、[アルバムを追加]ボタンをクリックしてプロセスを開始してください",
  projectConfigurationUpdate: "プロジェクトの設定が正常に更新されました",
  newPhotos: "新しい写真",
  images: "枚の画像",
  youHaveUploaded: "アップロードされた画像があります",
  restoreAlbumsQuestion: "このアルバムを復元してもよろしいですか？",
  restoreImagesQuestion: "これらの画像を復元してもよろしいですか？",
  deletePermanentlyQuestion: "これを永久に削除してもよろしいですか？",
  albumName: "アルバム名",
  imagesDeletedSuccessfully: "画像が正常に削除されました",
  imageCoverSetSuccessfully: "画像のカバーが正常に設定されました",
  albumDeletedSuccessfully: "アルバムが正常に削除されました",
  imagesRestoredSuccessfully: "画像が正常に復元されました",
  // ADD Photo - guide me steps
  step1_addPhotos_title: "1. 写真を追加",
  step1_addPhotos_text:
    "タイムラインビューで、ここをクリックして1つまたは複数のメディアファイルを追加します",
  step2_addPhotos_title: "2. 場所",
  step2_addPhotos_text: "事前に設定された場所を選択します",
  step3_addPhotos_title: "3. アルバム",
  step3_addPhotos_text: "ファイルのためのアルバムを選択します",
  step4_addPhotos_title: "4. ファイルを追加",
  step4_addPhotos_text:
    "写真やビデオファイルなど、任意の種類のファイルを添付するか、点線のボックス内にドラッグアンドドロップします",
  step5_addPhotos_title: "5. 確認",
  step5_addPhotos_text:
    "アップロードされたメディアファイルの情報を確認し、公開するためにここをクリックします",
  stepFinal_addPhotos: "写真の追加方法を学びました",
  // ADD Album - guide me steps
  step1_addAlbums_title: "1. アルバムを追加",
  step1_addAlbums_text:
    "アルバムビューで、ここをクリックしてアルバムを追加します。",
  step2_addAlbums_title: "2. アルバム名",
  step2_addAlbums_text: "アルバムに名前を付けます",
  step3_addAlbums_title: "3. 追加",
  step3_addAlbums_text:
    "アルバムをアルバムダッシュボードに追加するには、ここをクリックします",
  stepFinal_addAlbums: "アルバムの追加方法を学びました",
  // VIEW - guide me steps
  step1_viewPhotos_title: "1. メディアファイルを表示",
  step1_viewPhotos_text: "メディアファイルまたはビデオをクリックして表示します",
  step2_viewPhotos_title: "2. メディアファイルの表示",
  step2_viewPhotos_text: "ファイルをクリックすると、フルスケールで表示されます",
  step3_viewPhotos_title: "3. 情報",
  step3_viewPhotos_text:
    "メディアファイルプレビューの右上に、撮影日、アップロード日とアップロード者、場所が表示されます",
  step4_viewPhotos_title: "4. コメント",
  step4_viewPhotos_text:
    "他の人があなたの観察を見るために、メディアファイルにコメントします",
  step5_viewPhotos_title: "5. 写真/ビデオをダウンロード",
  step5_viewPhotos_text:
    "選択したメディアファイルをデバイスにダウンロードするには、ここをクリックします",
  step6_viewPhotos_title: "6. 画像/ビデオを削除",
  step6_viewPhotos_text:
    "スーパーアドミンまたは管理者の場合、アップロードされたメディアファイルを削除できます",
  step7_viewPhotos_title: "7. その他のオプション",
  step7_viewPhotos_text:
    "カーソルを三点リーダーの上に置くと、以下のオプションが表示されるポップアップメニューが表示されます:",
  step7_viewPhotos_text1_1: "アルバムを変更",
  step7_viewPhotos_text1_2:
    "選択したメディアファイルを別のアルバムに移動します",
  step7_viewPhotos_text2_1: "場所を変更",
  step7_viewPhotos_text2_2: "メディアファイルが参照する場所を変更します",
  step7_viewPhotos_text3_1: "アルバムカバーに設定",
  step7_viewPhotos_text3_2:
    "選択した写真を所属するアルバムのカバーに設定します（これは写真のみで機能します）",
  stepFinal_viewPhotos: "写真の表示方法を学びました",
  // RECYCLE - guide me steps
  step1_recyclePhotos_title: "1. ゴミ箱",
  step1_recyclePhotos_text:
    "ここをクリックしてゴミ箱メニューを開きます。これにより、現在のビューに応じて写真/アルバムメニューに移動します。",
  step2_recyclePhotos_title: "2. 復元するファイル/アルバムを選択",
  step2_recyclePhotos_text: "これはゴミ箱のメニューです",
  step3_recyclePhotos_title: "3. 復元",
  step3_recyclePhotos_text:
    "選択したファイル/アルバムを復元するには、ここをクリックします",
  step4_recyclePhotos_title: "4. 削除",
  step4_recyclePhotos_text:
    "選択したファイル/アルバムを永久に削除するには、ここをクリックします",
  stepFinal_recyclePhotos: "ゴミ箱とのやり取り方法を学びました",
  // EXPORT - guide me steps
  step1_exportPhotos_title: "1. メディアファイルを選択",
  step1_exportPhotos_text:
    "エクスポートしたいすべてのメディアファイルのチェックボックスを選択します。必要に応じて、1日、1週間、または1ヶ月のファイル全体を選択することもできます",
  step2_exportPhotos_title: "2. アクション",
  step2_exportPhotos_text:
    "ここをクリックすると、PDF形式でメディアファイルをエクスポートするオプションが表示されるポップアップメニューが表示されます（1ページ、2ページ、または4ページごと）。",
  stepFinal_exportPhotos: "メディアファイルのエクスポート方法を学びました",
  // SETTINGS - guide me steps
  step1_settingsPhotos_title: "1. 設定",
  step1_settingsPhotos_text:
    "ここをクリックして設定のポップアップメニューを開きます",
  step2_settingsPhotos_title: "2. 配布リスト",
  step2_settingsPhotos_text:
    "すべてのメディアファイルとアルバムを表示する権限を持つ人々を選択します",
  step3_settingsPhotos_title: "3. 保存",
  step3_settingsPhotos_text: "設定を保存します",
  stepFinal_settingsPhotos: "モジュールの設定とのやり取り方法を学びました",
  // other
  obligatory: "必須",
  public: "公開",
  private: "非公開",
  congratulations: "おめでとうございます！",
  rfi_title_module: "RFIs",
  no_rfis_title: "現在、RFIは作成されていません",
  no_rfis_text:
    "追加する場合は、[RFIを追加]ボタンをクリックしてプロセスを開始してください",
  meeting: "ミーティング",
  workflow: "ワークフロー",
  guideMe: "ガイド",
  support: "サポート",
  days: "日",
  rfi_recycle_bin: "RFIゴミ箱",
  addSpec: "仕様を追加",
  imageUploadedMessage: "画像が正常にアップロードされました。",
  captureSite: "サイトキャプチャ",
  dateUploaded: "アップロード日",
};
