import { ArrowBack, Delete, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { Albums } from "../../../components/projectDashboard/Photos";
import { PhotoGallery } from "../../../components/projectDashboard/Photos/PhotoGallery";
import { ModalWithAction } from "../../../components/shared";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import {
  useGetAllProjectImagesQuery,
  usePermanentDeleteAlbumMutation,
  usePermanentDeleteImageMutation,
} from "../../../features/project/imageApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
} from "../../../features/project/projectSlice";
import { useAlbumGallery, useImageGallery } from "../../../hooks";

function PhotoRecycleBin() {
  const { t: tGeneral } = useTranslation("general");
  const { t: tPhotos } = useTranslation("photos");
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [groupBy, setGroupBy] = React.useState("month");

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const [isPermanentDeleteOpen, setIsPermanentDeleteOpen] =
    React.useState(false);

  const [
    permaDeleteImage,
    {
      isLoading: isImagePermanentDeleteLoading,
      isSuccess: isPermanentDeleteImageSuccess,
      isError: isPermanentDeleteImageError,
    },
  ] = usePermanentDeleteImageMutation();
  const [
    permaDeleteAlbum,
    {
      isLoading: isAlbumPermanentDeleteLoading,
      isSuccess: isPermanentDeleteAlbumSuccess,
      isError: isPermanentDeleteAlbumError,
    },
  ] = usePermanentDeleteAlbumMutation();

  const isPermanentDeleteLoading = React.useMemo(() => {
    if (isImagePermanentDeleteLoading || isAlbumPermanentDeleteLoading) {
      return true;
    }
    return false;
  }, [isImagePermanentDeleteLoading, isAlbumPermanentDeleteLoading]);

  const isAlbums = Boolean(location?.pathname.match(/albums/g));

  const currentProject = useSelector(selectCurrentProject);

  React.useEffect(() => {
    if (isPermanentDeleteAlbumSuccess || isPermanentDeleteImageSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        `${isAlbums ? tPhotos("albums") : tPhotos("photos")} ${tGeneral(
          "deletedPermanently",
        )}`,
      );
      setIsPermanentDeleteOpen(false);
      handleClearImageGallery();
      handleClearAlbumGallery();
    }
    if (isPermanentDeleteAlbumError || isPermanentDeleteImageError) {
      PopUpAlert("error", "Error!", tGeneral("errorOccurred"));
      setIsPermanentDeleteOpen(false);
      handleClearImageGallery();
      handleClearAlbumGallery();
    }
  }, [
    isPermanentDeleteImageError,
    isPermanentDeleteAlbumError,
    isPermanentDeleteImageSuccess,
    isPermanentDeleteAlbumSuccess,
  ]);

  const {
    handleSelectAlbum,
    selectedAlbums,
    handleOpen,
    handleClose,
    isAlbumsOpen,
    isRestoreLoading,
    handleAction,
    handleClearAlbumGallery,
  } = useAlbumGallery(true);

  const {
    selectedDates,
    selectedImages,
    isDeleteOpen,
    onOpenDelete,
    onCloseDelete,
    isDeleteLoading,
    handleSelectImage,
    handleDeleteImages,
    handleClearImageGallery,
  } = useImageGallery(true);

  const { data: retreivedImages, isSuccess } = useGetAllProjectImagesQuery(
    {
      bActive: false,
      projectId: currentProject.id,
      idAlbum: params?.idAlbum ?? "",
      groupBy,
    },
    { skip: isAlbums },
  );

  const handlePermanentDelete = async () => {
    if (isAlbums) {
      await permaDeleteAlbum({ ids: selectedAlbums });
    } else {
      await permaDeleteImage({ ids: selectedImages });
    }
  };

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 2 &&
      currentGuideMe === "photos-recycle" &&
      retreivedImages &&
      isSuccess
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, retreivedImages, isSuccess]);

  return (
    <Box flex={1}>
      <Box display="flex" alignItems="center" width="100%" gap="10px" mb="20px">
        <IconButton
          onClick={() => {
            navigate(-1, {
              state: { stateShowTab: isAlbums ? 1 : 0 },
            });
          }}
        >
          <ArrowBack color="#000" />
        </IconButton>
        <Typography variant="h4">
          {tGeneral("recycleBin")}{" "}
          {isAlbums ? tPhotos("albums") : tPhotos("photos")}
        </Typography>
        <Box flex={1} />
        <Button
          disabled={selectedAlbums.length === 0 && selectedImages.length === 0}
          sx={{ minWidth: "0", p: "16px 40px", mt: "5px" }}
          variant="contained"
          data-tour="recycle-photos-3"
          onClick={() => {
            if (isAlbums) {
              handleOpen();
            } else {
              onOpenDelete();
            }
          }}
        >
          <Typography fontSize="1.125rem">{tGeneral("restore")}</Typography>
        </Button>
        <Button
          disabled={selectedAlbums.length === 0 && selectedImages.length === 0}
          sx={{ minWidth: "0", p: "17px", mt: "5px", mr: "10px" }}
          variant="contained"
          onClick={() => setIsPermanentDeleteOpen(true)}
          data-tour="recycle-photos-4"
        >
          <Delete />
        </Button>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Tabs
          value={groupBy}
          onChange={(_, newValue) => {
            setGroupBy(newValue);
          }}
        >
          <Tab value="day" label={tGeneral("day")} />
          <Tab value="week" label={tGeneral("week")} />
          <Tab value="month" label={tGeneral("month")} />
        </Tabs>
      </Box>
      {isAlbums ? (
        <Albums
          restore
          handleSelectAlbum={handleSelectAlbum}
          selectedAlbums={selectedAlbums}
        />
      ) : (
        <PhotoGallery
          images={retreivedImages}
          selectedImages={selectedImages}
          selectedDates={selectedDates}
          setSelectedImages={handleSelectImage}
          isRecycle
        />
      )}
      <ModalWithAction
        isOpen={isAlbumsOpen}
        onClose={handleClose}
        title={tGeneral("restore")}
        content={tPhotos("restoreAlbumsQuestion")}
        actionLabel={tGeneral("confirm")}
        action={handleAction}
        isLoading={isRestoreLoading}
      />
      <ModalWithAction
        isOpen={isDeleteOpen}
        onClose={onCloseDelete}
        title={tGeneral("restore")}
        content={tPhotos("restoreImagesQuestion")}
        actionLabel={tGeneral("confirm")}
        isLoading={isDeleteLoading}
        action={handleDeleteImages}
      />
      <ModalWithAction
        isOpen={isPermanentDeleteOpen}
        onClose={() => setIsPermanentDeleteOpen(false)}
        title={tGeneral("delete")}
        content={tPhotos("deletePermanentlyQuestion")}
        actionLabel={tGeneral("delete")}
        isLoading={isPermanentDeleteLoading}
        action={handlePermanentDelete}
      />
    </Box>
  );
}

export { PhotoRecycleBin };
