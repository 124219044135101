// Third-party library imports
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Icon imports
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import RefreshIcon from "@mui/icons-material/Refresh";

// Project-specific absolute imports
import { useGetProjectDrawingsQuery } from "../../../../features/project/analyticsApiSlice";
import { selectCurrentProject } from "../../../../features/project/projectSlice";

function Drawings({ size, isMock }) {
  const { t: tAnalytics } = useTranslation("analytics");
  const currentProject = useSelector(selectCurrentProject);

  const {
    data: drawingData,
    isError,
    isLoading,
    refetch,
  } = useGetProjectDrawingsQuery(currentProject?.id, {
    skip: isMock,
  });

  const handleRefetch = () => {
    refetch(); // Call refetch to reload data
  };

  // Function to transform drawingData into the desired format
  const chartData = [
    {
      id: tAnalytics("done"), // Unique identifier for each slice
      label: tAnalytics("done"),
      value: isMock ? 10 : drawingData?.doneCount || 0,
      color: "#6BC497",
    },
    {
      id: tAnalytics("revision"),
      label: tAnalytics("revision"),
      value: isMock ? 5 : drawingData?.revisionCount || 0,
      color: "#F9DF3A",
    },
    {
      id: tAnalytics("pending"),
      label: tAnalytics("pending"),
      value: isMock ? 2 : drawingData?.pendingCount || 0,
      color: "#CACACA",
    },
  ];
  // Get the total drawings
  const total = isMock
    ? 150
    : drawingData?.doneCount +
      drawingData?.revisionCount +
      drawingData?.pendingCount;

  let content;
  switch (size) {
    case "sm":
    case "md":
      content = (
        <Box
          sx={{
            position: "relative",
            height: { xs: "85%", lg: "100%" },
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* Main data box (no graph) */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mt: "16px",
              padding: "8px",
            }}
          >
            {/* Total title */}
            <Typography
              sx={{
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                color: "#1F1F1F",
              }}
            >
              {tAnalytics("total")}
            </Typography>
            {/* Total number */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                alignItems: {
                  xs: "flex-start",
                  lg: "center",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "35px", lg: "45px" },
                  fontStyle: "normal",
                  fontWeight: 700,
                  color: "#1F1F1F",
                  mr: "8px",
                }}
              >
                {total}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#4B4B4B",
                }}
              >
                {tAnalytics("drawingsCount", { count: total })}
              </Typography>
            </Box>

            {/* Box for Custom Legends */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                gap: "8px",
                flexDirection: "column",
              }}
            >
              {/* Custom Legends */}
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <CircleIcon sx={{ color: "#6BC497", fontSize: "10px" }} />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#4B4B4B",
                  }}
                >
                  {chartData[0].value} {tAnalytics("done")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <CircleIcon sx={{ color: "#F9DF3A", fontSize: "10px" }} />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#4B4B4B",
                  }}
                >
                  {chartData[1].value} {tAnalytics("revision")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <CircleIcon sx={{ color: "#CACACA", fontSize: "10px" }} />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#4B4B4B",
                  }}
                >
                  {chartData[2].value} {tAnalytics("pending")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "50%", height: "100%" }}>
            <ResponsivePie
              data={chartData}
              margin={{ top: 15, right: 0, bottom: 15, left: 0 }}
              innerRadius={0.7}
              startAngle={-115}
              padAngle={0}
              cornerRadius={0}
              borderWidth={0}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              colors={{ datum: "data.color" }}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              arcLabelsSkipAngle={10}
            />
          </Box>
        </Box>
      );
      break;
    case "lg":
    default:
      content = <></>;
  }

  if (isLoading && !isError && !isMock)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (isError && !isLoading && !isMock)
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          sx={{
            color: "#8E8E8E",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
          }}
        >
          {tAnalytics("somethingWentWrong")}
        </Typography>
        <IconButton onClick={handleRefetch}>
          <RefreshIcon />
        </IconButton>
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", gap: "8px" }}>
        <NoteAltOutlinedIcon sx={{ color: "#356AD0" }} />
        <Typography
          sx={{ fontSize: "16px", fontStyle: "normal", fontWeight: "500" }}
        >
          {tAnalytics("drawingsProgress")}
        </Typography>
      </Box>
      {content}
    </Box>
  );
}

export { Drawings };
