import { Download } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import EnglishManual from "../../assets/english-manual.pdf";
import SpanishManual from "../../assets/spanish-manual.pdf";
import { selectLocale } from "../../features/preferences/preferencesSlice";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function UserManual() {
  const { t: tGeneral } = useTranslation("general");
  const [pdf, setPdf] = useState(null);
  const [pdfPages, setPdfPages] = useState(0);
  const currentLocale = useSelector(selectLocale);

  useEffect(() => {
    if (currentLocale) {
      fetch(currentLocale === "en" ? EnglishManual : SpanishManual)
        .then((res) => res.arrayBuffer())
        .then((arrayBuffer) => setPdf(arrayBuffer));
    }
  }, [currentLocale]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = currentLocale === "en" ? EnglishManual : SpanishManual;
    link.download = "user-manual.pdf";
    link.click();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize="36px" align="left" fontWeight="bold" mb={3}>
          {tGeneral("userManual")}
        </Typography>
        <Box>
          <Button onClick={handleDownload} color="primary" variant="contained">
            <Download />
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        {pdf && (
          <Document
            file={currentLocale === "en" ? EnglishManual : SpanishManual}
            onLoadSuccess={(pdf) => setPdfPages(pdf.numPages)}
          >
            {Array.from(Array(pdfPages), (_, i) => (
              <div style={{ border: "1px solid black" }}>
                <Page
                  pageNumber={i + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </div>
            ))}
          </Document>
        )}
      </Box>
    </Box>
  );
}

export default UserManual;
