import React, { useEffect, useContext } from "react";
import { Navigate, useLocation } from "react-router";
import { Route, Routes } from "react-router-dom";
// Theme
import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";

import darkTheme from "./assets/theme/darkTheme";
import defaultTheme from "./assets/theme/defaultTheme";
import { selectTheme } from "./features/preferences/preferencesSlice";
// Views
import RequireAuth from "./features/auth/RequireAuth";
// Enterprise Views
import EnterpriseDashboardLayout from "./components/enterpriseDashboard/EnterpriseDashboardLayout";
import { AddRoles } from "./views/enterpriseDashboard/AddRoles";
import Admins from "./views/enterpriseDashboard/Admins";
import Community from "./views/enterpriseDashboard/Community";
import EnterpriseSettings from "./views/enterpriseDashboard/EnterpriseSettings";
import ExternalContacts from "./views/enterpriseDashboard/ExternalContacts";
import GeneralDocuments from "./views/enterpriseDashboard/GeneralDocuments";
import KPI from "./views/enterpriseDashboard/KPI";
import Lists from "./views/enterpriseDashboard/Lists";
import Notifications from "./views/enterpriseDashboard/Notifications";
import ProjectsList from "./views/enterpriseDashboard/ProjectsList";
import Roles from "./views/enterpriseDashboard/Roles";

// Redux selectors

import {
  selectAdminUsers,
  selectCurrentEnterprise,
} from "./features/enterprise/enterpriseSlice";
import { selectCurrentUser } from "./features/userSettings/userSettingsSlice";

import ProjectRoutes from "./ProjectRoutes";
import CaslContext from "./utils/caslContext";
import { Onboarding } from "./views/Onboarding";
import { Directory } from "./views/enterpriseDashboard/Directory";

function EnterpriseRoutes() {
  const darkMode = useSelector(selectTheme);
  const theme = darkMode ? darkTheme : defaultTheme;

  const abilityEnterprise = useContext(CaslContext);
  const currentEnterprise = useSelector(selectCurrentEnterprise);

  const adminUsers = useSelector(selectAdminUsers);
  const currentUser = useSelector(selectCurrentUser);
  const location = useLocation();
  const { pathname } = useLocation();
  const isEnterpriseDashboard = Boolean(
    pathname.match(/enterprise\/[A-Za-z0-9-]+\/dashboard/g),
  );
  useEffect(() => {
    if (
      adminUsers &&
      currentEnterprise &&
      currentUser &&
      abilityEnterprise &&
      isEnterpriseDashboard
    ) {
      // verify if user is an enterprise admin
      const isEnterpriseAdmin = adminUsers.filter((element) => {
        return element.idUser === currentUser.id;
      });
      const { can, rules } = new AbilityBuilder(createMongoAbility);
      if (isEnterpriseAdmin.length > 0) {
        can("add", "externalContact");
        can("edit", "externalContact");
        can("delete", "externalContact");
        can("view", "enterpriseInfo");
        can("add", "project");
        can("view", "projectCount");
        can("add", "user");
        can("edit", "user");
        can("add", "roles");
        can("edit", "roles");
        can("delete", "roles");
        can("settings", "roles");
      }
      if (currentEnterprise?.planType === "user") {
        can("be", "per user");
      } else {
        can("be", "per enterprise");
      }

      abilityEnterprise.update(rules);
    }
  }, [
    adminUsers,
    currentEnterprise,
    currentUser,
    abilityEnterprise,
    isEnterpriseDashboard,
  ]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {/* CaslContext - for user roles and perimissions */}
        <CaslContext.Provider value={abilityEnterprise}>
          <Routes>
            {/* public routes */}
            {/* navigate to enterprise by default */}
            <Route path="onboarding" element={<Onboarding />} />
            {/* protected routes */}
            <Route
              path="/"
              element={
                <Navigate
                  to={`enterprise/${currentEnterprise?.id}/dashboard`}
                />
              }
            />
            <Route element={<RequireAuth />}>
              {/* enterprise dashboard routes */}
              <Route
                path="enterprise/:idEnterprise"
                element={<EnterpriseDashboardLayout />}
              >
                <Route index path="dashboard" element={<ProjectsList />} />
                <Route path="admins" element={<Admins />} />
                {/* <Route path="userManual" element={<UserManual />} /> */}
                <Route path="directory" element={<Directory />} />
                <Route path="community" element={<Community />} />
                <Route path="contacts" element={<ExternalContacts />} />
                <Route path="roles" element={<Roles />} />
                <Route path="generalDocuments" element={<GeneralDocuments />} />
                <Route path="lists" element={<Lists />} />
                <Route path="kpis" element={<KPI />} />
                <Route path="addRoles" element={<AddRoles />} />
                <Route path="editRole/:idRole" element={<AddRoles />} />
                <Route
                  path="enterpriseSettings"
                  element={<EnterpriseSettings />}
                />
                <Route path="notifications" element={<Notifications />} />
                <Route
                  path="project/:idProject/*"
                  element={<ProjectRoutes />}
                />
              </Route>
            </Route>
          </Routes>
        </CaslContext.Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

const testSteps = [
  {
    selector: "[data-tour='first-step']",
    content: "This is step 1",
  },
];

export default EnterpriseRoutes;
